import { useMemo, useState } from 'react';
import { useAppSelector } from '../../hooks/useAppRedux';
import { Bookmark, ExpandLess, ExpandMore } from '@mui/icons-material';
import { ListItemButton, ListItemIcon, ListItemText, Collapse, List } from '@mui/material';

import { ClipbookCreateField } from './clipbook/ClipbookCreateField';
import { ClipbookMenuItem } from './clipbook/ClipbookMenuItem';

export const SidebarClipbook: React.FC = () => {
  const [expanded, setExpanded] = useState(false);
  const nbrOfClipbooks = useAppSelector(state => state.clipbooks.clipbooks.length);

  const clipbookEntries = useMemo(() => {
    return Array.from({ length: nbrOfClipbooks }, (_, i) => i);
  }, [nbrOfClipbooks]);

  return (
    <>
      <ListItemButton onClick={() => setExpanded(!expanded)}>
        <ListItemIcon>
          <Bookmark />
        </ListItemIcon>
        <ListItemText primary="Sparade bilder" />
        {expanded ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={expanded}>
        <ClipbookCreateField />
        <List component="div" disablePadding>
          {clipbookEntries.map(index => (
            <ClipbookMenuItem key={index} clipbookIndex={index} />
          ))}
        </List>
      </Collapse>
    </>
  );
};
