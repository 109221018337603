import { ZoomOut, ZoomIn, Fullscreen } from '@mui/icons-material';
import { Tooltip, IconButton, Popover, Button, ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';
import { useState } from 'react';

export type Props = {
  isEnlarged: boolean;
  toggleEnlarged: () => void;
  setFullScreen: () => void;
};

export function ImageCardZoomButton({ isEnlarged, toggleEnlarged, setFullScreen }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleClose = () => setAnchorEl(null);
  const handleZoomRightClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setAnchorEl(e.currentTarget);

  const open = anchorEl != null;

  return (
    <>
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        style={{ zIndex: zIndex.drawer + 1 }}
      >
        <MenuList id="split-button-menu">
          <MenuItem
            component={Button}
            dense
            onClick={() => {
              setFullScreen();
              handleClose();
            }}
          >
            <ListItemIcon>
              <Fullscreen fontSize="small" />
            </ListItemIcon>

            <ListItemText disableTypography sx={{ textTransform: 'none' }}>
              {'Fullskärm'}
            </ListItemText>
          </MenuItem>
        </MenuList>
      </Popover>

      <Tooltip title="Förstora bilden. Högerklicka för fler alternativ.">
        <IconButton
          color="primary"
          onClick={toggleEnlarged}
          onContextMenu={e => {
            e.preventDefault();
            handleZoomRightClick(e);
            return false;
          }}
        >
          {isEnlarged ? <ZoomOut /> : <ZoomIn />}
        </IconButton>
      </Tooltip>
    </>
  );
}
