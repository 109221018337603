import React, { useRef } from 'react';

import styles from './CaseSelectableText.module.css';

interface Props {
  children?: React.ReactNode;
  label?: React.ReactNode;
  className?: string;
}

export const CaseSelectableText: React.FC<Props> = ({ label, className, children }) => {
  const selectableTextRef = useRef(null);

  const selectText = () => {
    if (selectableTextRef.current == null) {
      return;
    }

    const element = selectableTextRef.current as unknown;

    const range = document.createRange();
    range.selectNodeContents(element as Node);

    const sel = window.getSelection();

    if (sel == null) {
      return;
    }

    sel.removeAllRanges();
    sel.addRange(range);
  };

  const classes = [styles.selectableText];

  if (className) {
    classes.push(className);
  }

  return (
    <div className={classes.join(' ')} onDoubleClick={selectText}>
      {label && <span>{label}:</span>}
      <div ref={selectableTextRef}>{children}</div>
    </div>
  );
};
