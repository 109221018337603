import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Case, DerivedImage, Image, ImageOrigin, ImagePointer, ImageType, ImageVersion } from '../../api/caseApiTypes';
import { ensureSupportedImageType, FileUploadInput } from '../../api/commonApiTypes';
import { FilterableImage } from '../../api/filterTypes';
import * as Api from '../../api/imageApi';
import { Clause, OrderBy } from '../../api/searchApiTypes';
import { ThunkConfig } from '../../app/store';
import { IsDirty } from '../../helpers/ObjectHelpers';
import { SearchContext, SearchState } from '../../hooks/useSearchState';
import { pushToast, ToastNotification } from '../../appSlice';
import { RootState } from '../../app/rootReducer';
import { SelectionContext } from './selectedImagesContext/selectionContextContext';

interface QueryInfo {
  query?: string;
  orderBy?: OrderBy[];
  filterTree?: Clause<FilterableImage>;
  pageSize?: number;
  searchOnlyFilename?: boolean;
}

export enum BatchMediaSaveState {
  None,
  InProgress,
  Finished,
}

export type BatchMediaSaveFailure = {
  error: string;
  media: Partial<Image>;
};

export type BatchMediaSave = {
  operation: string;
  numTotal: number;
  numSuccessful: number;
  numFailed: number;
  state: BatchMediaSaveState;
  failures: BatchMediaSaveFailure[];
};

interface ImagesState {
  queryByContext: Record<number, QueryInfo>;
  imagesByContext: Record<number, SearchState<Image>>;
  imagesById: Record<string, Image>;
  imageIdsByLookupEntry: Record<number, string>;
  error: string | null;
  isLoading: boolean;
  isSearching: boolean;
  fileUploadInProgress: boolean;
  selected: Record<SelectionContext, Image[]>;
  pendingChanges: Record<string, Partial<Image>>;
  productImageByArticleId: Record<string, Image | null>;
  zoomedImage: ImageVersion | undefined;
  batchMediaSave: BatchMediaSave | undefined;
}

const initialState: ImagesState = {
  productImageByArticleId: {},
  queryByContext: {},
  imagesByContext: {},
  imagesById: {},
  imageIdsByLookupEntry: {},
  error: null,
  isLoading: false,
  isSearching: false,
  fileUploadInProgress: false,
  selected: {
    [SelectionContext.Global]: [],
    [SelectionContext.ImagePicker]: [],
    [SelectionContext.ClipbookPopover]: [],
  },
  pendingChanges: {},
  zoomedImage: undefined,
  batchMediaSave: undefined,
};

const forEachSearchContext = (searchContexts: Record<number, SearchState<Image>>, action: (context: SearchState<Image>) => void) => {
  for (let context in searchContexts) {
    action(searchContexts[context]);
  }
};

const addProductImagesByArticleId = (collection: Record<string, Image | null>, images: Image[]) => {
  for (const image of images) {
    if (image.type === ImageType.Product) {
      for (const articleId of image.pimAssociatedArticles) {
        collection[articleId] = image;
      }
    }
  }
};

export const imagesSlice = createSlice({
  name: 'images',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    startSearching(state) {
      state.isSearching = true;
    },
    searchSuccess(state, action: PayloadAction<SearchState<Image> & { continuation: number; searchContext: SearchContext }>) {
      const { items: newItems, count, continuation, searchContext } = action.payload;

      addProductImagesByArticleId(state.productImageByArticleId, newItems);

      const items = (state.imagesByContext[searchContext]?.items ?? []).slice();
      for (let i = 0; i < newItems.length; i++) {
        items[continuation + i] = newItems[i];
      }

      state.imagesByContext[searchContext] = {
        items,
        count,
      };

      state.isSearching = false;
    },
    searchFailed(state, action: PayloadAction<{ error: string; searchContext: SearchContext }>) {
      state.imagesByContext[action.payload.searchContext] = {
        items: [],
        count: 0,
      };
      state.isSearching = false;
      state.error = action.payload.error;
    },
    resetResults(state, action: PayloadAction<{ searchContext: SearchContext; queryInfo: QueryInfo }>) {
      const { searchContext, queryInfo } = action.payload;
      state.queryByContext[searchContext] = queryInfo;
      state.imagesByContext[searchContext] = {
        items: [],
        count: 0,
      };
    },
    getImageSuccess(state, action: PayloadAction<Image>) {
      state.isLoading = false;
      const image = action.payload;

      addProductImagesByArticleId(state.productImageByArticleId, [image]);

      forEachSearchContext(state.imagesByContext, context => {
        const images = context.items;
        const index = images.findIndex(c => c.id === image.id);
        if (index !== -1) {
          images[index] = image;
        }
      });

      for (const context of Object.keys(state.selected) as SelectionContext[]) {
        const index = state.selected[context].findIndex(c => c.id === image.id);
        if (index !== -1) {
          state.selected[context][index] = image;
        }
      }

      state.imagesById[image.id] = image;
      if (image.lookupEntry) state.imageIdsByLookupEntry[image.lookupEntry] = image.id;
    },
    getImagesSuccess(state, action: PayloadAction<Image[]>) {
      state.isLoading = false;
      const images = action.payload;
      addProductImagesByArticleId(state.productImageByArticleId, images);
      for (const image of images) {
        forEachSearchContext(state.imagesByContext, context => {
          const images = context.items;
          const index = images.findIndex(c => c.id === image.id);
          if (index !== -1) {
            images[index] = image;
          }
        });

        state.imagesById[image.id] = image;
        if (image.lookupEntry) state.imageIdsByLookupEntry[image.lookupEntry] = image.id;
      }
    },
    fetchProductImageByArticleId(state, action: PayloadAction<string[]>) {
      for (const articleId of action.payload) {
        state.productImageByArticleId[articleId] = null;
      }
    },
    fetchProductImageByArticleIdSuccess(state, action: PayloadAction<Image[]>) {
      addProductImagesByArticleId(state.productImageByArticleId, action.payload);
    },
    setDerivedImages(state, action: PayloadAction<{ imageId: string; versionId: string; derivedImages: DerivedImage[]; eTag: string }>) {
      const { imageId, versionId, derivedImages, eTag } = action.payload;

      const updateStateItem = (image: Image | undefined) => {
        if (image != null) {
          image.eTag = eTag;
          const item = image.versions[versionId];
          if (item != null) {
            item.derivedImages = derivedImages;
          }
        }
      };

      forEachSearchContext(state.imagesByContext, context => {
        updateStateItem(context.items.find(image => image.id === imageId));
      });
      updateStateItem(state.imagesById[imageId]);
    },
    getImageFailed(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetSelectedContext(state, action: PayloadAction<SelectionContext>) {
      state.selected[action.payload] = [];
    },
    setSelectedMedia(
      state,
      { payload: { selectionContext, media } }: PayloadAction<{ selectionContext: SelectionContext; media: Image[] }>,
    ) {
      state.selected[selectionContext] = media;
    },
    addMediaToSelection(
      state,
      { payload: { selectionContext, media } }: PayloadAction<{ selectionContext: SelectionContext; media: Image[] }>,
    ) {
      state.selected[selectionContext] = state.selected[selectionContext].concat(media);
    },
    removeMediaFromSelection(
      state,
      { payload: { selectionContext, media } }: PayloadAction<{ selectionContext: SelectionContext; media: Image }>,
    ) {
      const mediaIndex = state.selected[selectionContext].findIndex(img => img.id === media.id);
      if (mediaIndex >= 0) {
        state.selected[selectionContext].splice(mediaIndex, 1);
      }
    },
    zoomImage(state, { payload: { image } }: PayloadAction<{ image: ImageVersion }>) {
      state.zoomedImage = image;
    },
    closeZoomedImage(state) {
      state.zoomedImage = undefined;
    },
    errorConsumed(state) {
      state.error = null;
    },
    startFileUpload(state) {
      state.fileUploadInProgress = true;
    },
    endFileUpload(state) {
      state.fileUploadInProgress = false;
    },
    startBatchMediaSave(state, { payload: { numTotal, operation } }: PayloadAction<{ operation: string; numTotal: number }>) {
      state.batchMediaSave = {
        operation,
        numTotal,
        numFailed: 0,
        numSuccessful: 0,
        state: BatchMediaSaveState.InProgress,
        failures: [],
      };
    },
    endBatchMediaSave(state) {
      if (state.batchMediaSave) {
        state.batchMediaSave.state = BatchMediaSaveState.Finished;
      }
    },
    closeBatchMediaSave(state) {
      state.batchMediaSave = undefined;
    },
    batchMediaSaveSuccess(state) {
      if (state.batchMediaSave) {
        state.batchMediaSave.numSuccessful += 1;
      }
    },
    batchMediaSaveFail(state, { payload: { error, media } }: PayloadAction<{ error: string; media: Partial<Image> }>) {
      if (state.batchMediaSave) {
        state.batchMediaSave.numFailed += 1;
        state.batchMediaSave.failures.push({
          error,
          media,
        });
      }
    },
    setPendingChanges(state, action: PayloadAction<Record<string, Partial<Image>>>) {
      Object.keys(action.payload).forEach(key => {
        state.pendingChanges[key] = action.payload[key];
      });
    },
    clearPendingChanges(state, { payload: imageIds }: PayloadAction<string[]>) {
      imageIds.forEach(id => {
        delete state.pendingChanges[id];
      });
    },
  },
});

export const {
  startLoading,
  startSearching,
  searchSuccess,
  searchFailed,
  resetResults,
  getImageSuccess,
  getImagesSuccess,
  getImageFailed,
  setDerivedImages,
  resetSelectedContext,
  setSelectedMedia,
  addMediaToSelection,
  removeMediaFromSelection,
  errorConsumed,
  startFileUpload,
  endFileUpload,
  zoomImage,
  closeZoomedImage,
  fetchProductImageByArticleIdSuccess,
  fetchProductImageByArticleId,
  startBatchMediaSave,
  endBatchMediaSave,
  closeBatchMediaSave,
  batchMediaSaveSuccess,
  batchMediaSaveFail,
  setPendingChanges,
  clearPendingChanges,
} = imagesSlice.actions;

export const search = createAsyncThunk<
  void,
  QueryInfo & {
    continuation?: number;
    searchContext: SearchContext;
  },
  ThunkConfig
>('images/search', async ({ continuation = 0, searchContext, ...queryInfo }, { dispatch, getState }) => {
  try {
    const { query, filterTree, orderBy, pageSize, searchOnlyFilename } = queryInfo;

    dispatch(startSearching());

    if (IsDirty(queryInfo, getState().images.queryByContext[searchContext])) {
      dispatch(resetResults({ searchContext, queryInfo }));
    }

    const { items, count } = await Api.search(query || '', filterTree, orderBy || [], continuation, pageSize, searchOnlyFilename);

    dispatch(searchSuccess({ items, count, continuation, searchContext }));
  } catch (error: any) {
    dispatch(searchFailed(error.toString()));
  }
});

export const searchProductImageForArticles = createAsyncThunk<void, string[], ThunkConfig>(
  'images/findProductImageForArticle',
  async (articleIds, { dispatch }) => {
    try {
      dispatch(startSearching());
      dispatch(fetchProductImageByArticleId(articleIds));

      const images = await Api.findProductImagesForArticleIds(articleIds);

      dispatch(fetchProductImageByArticleIdSuccess(images));
    } catch (error: any) {
      dispatch(searchFailed(error.toString()));
    }
  },
);

export const getById = createAsyncThunk('images/getById', async (id: string, { dispatch }) => {
  try {
    dispatch(startLoading());

    const result = await Api.getById(id);

    dispatch(getImageSuccess(result));
  } catch (error: any) {
    dispatch(getImageFailed(error.toString()));
  }
});

export const getBatchByIds = createAsyncThunk('images/getBatchByIds', async (ids: string[], { dispatch }) => {
  try {
    if (ids.length === 0) {
      return;
    }

    dispatch(startLoading());

    const result = await Api.getBatchByIds(ids);

    dispatch(getImagesSuccess(result));
  } catch (error: any) {
    dispatch(getImageFailed(error.toString()));
  }
});

export const toggleSelectedMedia = createAsyncThunk<void, { selectionContext: SelectionContext; media: Image }, ThunkConfig>(
  'images/toggleSelected',
  async ({ selectionContext, media }, { getState, dispatch }) => {
    const state = getState();
    const idx = state.images.selected[selectionContext].findIndex(m => m.id === media.id);
    if (idx < 0) {
      dispatch(addMediaToSelection({ selectionContext, media: [media] }));
      dispatch(ensureImagesLoaded([media.id]));
    } else {
      dispatch(removeMediaFromSelection({ selectionContext, media }));
    }
  },
);

export const updateImage = createAsyncThunk<void, { imageId: string; image: Partial<Image>; onSuccess?: () => void }>(
  'images/updateImage',
  async ({ imageId, image, onSuccess }, { dispatch }) => {
    try {
      dispatch(startLoading());

      const result = await Api.updateImage(imageId, image);
      dispatch(getImageSuccess(result));
      onSuccess?.();
    } catch (error: any) {
      dispatch(getImageFailed(error.toString()));
    }
  },
);

export const uploadVersion = createAsyncThunk<void, { image: Image; previousVersion: ImageVersion } & FileUploadInput, ThunkConfig>(
  'images/uploadVersion',
  async ({ image, previousVersion, files, onUploadProgress }, { dispatch }) => {
    const onError = (fileName: string) => dispatch(pushToast(ErrorToastFileIsMissingExtension(fileName)));
    try {
      dispatch(startFileUpload());
      files.forEach(ensureSupportedImageType(onError));

      if (files.length !== 1) {
        dispatch(getImageFailed('Kan bara ersätta bilder med exakt en bild'));
        return;
      }

      const origin: ImageOrigin = {
        originSystem: 'Mediabank',
        originIdentifier: previousVersion.id,
      };

      const result = await Api.uploadVersion(image.id, origin, files[0], onUploadProgress);

      dispatch(getImageSuccess(result));
    } catch (error: any) {
      dispatch(getImageFailed(error.toString()));
    } finally {
      dispatch(endFileUpload());
    }
  },
);

export const setImageActiveVersion = createAsyncThunk<void, { imageId: string; activeVersion: string }>(
  'images/setImageActiveVersion',
  async ({ imageId, activeVersion }, { dispatch }) => {
    try {
      dispatch(startLoading());

      const result = await Api.setImageActiveVersion(imageId, activeVersion);

      dispatch(getImageSuccess(result));
    } catch (error: any) {
      dispatch(getImageFailed(error.toString()));
    }
  },
);

export const updateImageType = createAsyncThunk<void, { imageId: string; type: ImageType }>(
  'images/updateImage',
  async ({ imageId, type }, { dispatch }) => {
    try {
      dispatch(startLoading());

      const result = await Api.updateImageType(imageId, type);

      dispatch(getImageSuccess(result));
    } catch (error: any) {
      dispatch(getImageFailed(error.toString()));
    }
  },
);

export const updateDerivedImages = createAsyncThunk<void, { imageId: string; versionId: string }, ThunkConfig>(
  'images/updateDerivedImages',
  async ({ imageId, versionId }, { dispatch }) => {
    try {
      const image = await Api.getById(imageId);

      const derivedImages = image.versions[versionId].derivedImages;
      const eTag = image.eTag;
      dispatch(setDerivedImages({ imageId, versionId, derivedImages, eTag }));
    } catch (error: any) {
      dispatch(getImageFailed(error.toString()));
    }
  },
);

export const refreshImageIfTracked = createAsyncThunk<void, string, ThunkConfig>(
  'images/refreshImageIfTracked',
  async (id, { dispatch, getState }) => {
    const { imagesByContext, imagesById, isLoading } = getState().images;

    if (isLoading) {
      return;
    }

    const trackedLoaded = id in imagesById;

    let trackedThroughSearchResults = false;
    forEachSearchContext(imagesByContext, context => {
      if (context.items?.some(c => c.id === id)) {
        trackedThroughSearchResults = true;
      }
    });

    if (!trackedLoaded && !trackedThroughSearchResults) {
      return;
    }

    dispatch(getById(id));
  },
);

type EnsureImageLoadedType = Case | ImagePointer | string;
export const ensureImagesLoaded = createAsyncThunk<void, EnsureImageLoadedType[] | EnsureImageLoadedType, ThunkConfig>(
  'images/ensureImagesLoaded',
  async (data, { dispatch, getState }) => {
    const items = Array.isArray(data) ? data : [data];
    try {
      let toCheck: string[] = [];
      for (const item of items) {
        if (typeof item === 'string') {
          toCheck.push(item);
          continue;
        }

        let pointers: ImagePointer[] = [];

        if ('imagePointers' in item) {
          pointers = pointers.concat(item.imagePointers ?? []);
        }
        if ('videoPointers' in item) {
          pointers = pointers.concat(item.videoPointers ?? []);
        }
        if ('reference' in item) {
          pointers = pointers.concat(item.reference?.imagePointers ?? []);
        }
        if ('imageId' in item) {
          pointers = pointers.concat(item);
        }

        toCheck = toCheck.concat(pointers.map(pointer => pointer.imageId));
      }

      const { imagesById } = getState().images;
      const unloadedImages = Array.from(new Set(toCheck)).filter(id => !(id in imagesById));

      if (unloadedImages.length > 0) {
        dispatch(startLoading());
        const result = await Api.getBatchByIds(unloadedImages);
        dispatch(getImagesSuccess(result));
      }
    } catch (error: any) {
      dispatch(getImageFailed(error.toString()));
    }
  },
);

const ErrorToastFileIsMissingExtension = (fileName: string): ToastNotification => ({
  message: `Filen ${fileName} saknar filändelse eller har en ogiltig filändelse.`,
  variant: 'error',
  persist: true,
  preventDuplicate: false,
});

export const isImageSelected = (context: SelectionContext, imageId: string) =>
  createSelector(
    (state: RootState) => state.images.selected[context],
    images => images.some(img => img.id === imageId),
  );
export const isAnyMediaSelected = (context: SelectionContext) =>
  createSelector(
    (state: RootState) => state.images.selected[context],
    images => images.length > 0,
  );
// Maps all imageIds into an array, for ids where the image has not been loaded, the image attribute
// will be null
export const selectAllImagesById = (imageIds: string[]) =>
  createSelector(
    (state: RootState) => state.images.imagesById,
    imagesById => imageIds.map(imgId => ({ id: imgId, image: imagesById[imgId] })),
  );

// Maps the loaded imageIds into an array of Image
export const selectImagesById = (imageIds: string[]) =>
  createSelector(
    (state: RootState) => state.images.imagesById,
    imagesById => imageIds.map(imgId => imagesById[imgId]).filter(img => !!img),
  );

export const imageHasPendingChanges = (imageId?: string) =>
  createSelector(
    (state: RootState) => state.images.pendingChanges,
    pendingChanges => !!imageId && !!pendingChanges[imageId],
  );

export const anyImagesDeleted = (imageIds: string[]) =>
  createSelector(
    (state: RootState) => state.images.imagesById,
    imagesById => imageIds.map(imgId => imagesById[imgId]).filter(img => !!img && (img.state === 256 || img.removedFromPim)).length > 0,
  );

export default imagesSlice.reducer;
