import { Table, TableBody, TableRow, TableCell } from '@mui/material';
import { M3Article } from '../../../api/m3Api';
import { formatDate } from '../../date/DateFormatter';

interface Props {
  article: M3Article;
}

function formatValue(value: string | undefined) {
  if (!value) {
    return '-';
  }
  return value;
}

type InfoProps = {
  name: string;
  value?: string;
};
function InfoProperty({ name, value }: InfoProps) {
  return (
    <>
      <TableCell>
        <strong>{name}</strong>
      </TableCell>
      <TableCell>{formatValue(value)}</TableCell>
    </>
  );
}

export function CaseArticleInfo({ article }: Props) {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <InfoProperty name="Säljstart" value={formatDate(article.attributes.itemSalesStart)} />
          <InfoProperty name="Säljslut" value={formatDate(article.attributes.itemSalesEnd)} />
        </TableRow>
      </TableBody>
    </Table>
  );
}
