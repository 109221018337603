import React from 'react';

import { CaseTextField } from './CaseTextField';
import { MediaValue, getMediaValue } from './MediaValue';

interface Props {
  articleId?: MediaValue<string | undefined>[];
  onChange: (value: string) => void;
  readonly?: boolean;
}

export const CaseArticleIdField: React.FC<Props> = ({ articleId, onChange, readonly }) => (
  <CaseTextField
    label="M-nummer"
    name="articleNumber"
    value={getMediaValue(articleId, '', 'Blandade värden')}
    setValue={onChange}
    disabled={readonly}
    fullWidth
  />
);
