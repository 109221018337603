import React, { useState } from 'react';

import { Delete, PictureAsPdf, Image, PlayCircleOutline } from '@mui/icons-material';
import LinkIcon from '@mui/icons-material/Link';
import { ListItem, ListItemText, ListItemAvatar, Avatar, Typography, Link, IconButton, ListItemSecondaryAction } from '@mui/material';

import styles from './AttachmentListItem.module.css';

import { Attachment } from '../../api/caseApiTypes';
import { FullScreenDialog } from '../../commonComponents/FullScreenDialog';
import { GetThumbnailUri, HasPreviewVideo, HasThumbnail } from '../../helpers/DerivedImageHelpers';
import { confirmDelete } from '../../helpers/confirmDeleteHelper';
import { formatDateTime } from '../date/DateFormatter';

interface Props {
  attachment: Attachment;
  onDelete?: (attachment: Attachment) => void;
  readonly?: boolean;
}

export const AttachmentListItem: React.FC<Props> = ({ attachment, onDelete, readonly }) => {
  const [open, setOpen] = useState(false);

  const openFullScreen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const bytesToText = (bytes: number, decimals = 2) => {
    // https://stackoverflow.com/a/18650828
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const mediaTypeToIcon = (mediaType: string) => {
    switch (mediaType) {
      case 'image/jpeg':
      case 'image/jpg':
      case 'image/bmp':
      case 'image/gif':
      case 'image/png':
      case 'image/tiff':
        return <Image />;
      case 'application/pdf':
        return <PictureAsPdf />;
      case 'video/mp4':
      case 'video/mov':
        return <PlayCircleOutline />;
      default:
        return <LinkIcon />;
    }
  };

  const hasThumb = HasThumbnail(attachment, 320);
  const hasVideoPreview = HasPreviewVideo(attachment);

  const avatar = hasThumb ? (
    <div className={styles.thumb} onClick={openFullScreen}>
      <img alt="" src={GetThumbnailUri(attachment, 320)}></img>
      {hasVideoPreview && (
        <div className={styles.playIcon}>
          <PlayCircleOutline color="secondary" />
        </div>
      )}
    </div>
  ) : (
    <Avatar>{mediaTypeToIcon(attachment.mediaType)}</Avatar>
  );

  return (
    <ListItem>
      <ListItemAvatar>{avatar}</ListItemAvatar>
      <ListItemText
        primary={
          <Link href={attachment.uri} target="_blank" rel="noreferrer">
            <Typography variant="body2">{attachment.title || attachment.uri}</Typography>
          </Link>
        }
        secondary={bytesToText(attachment.size)}
      />
      {attachment.created && <Typography variant="caption">{formatDateTime(attachment.created)}</Typography>}
      {hasThumb && <FullScreenDialog alt={attachment.title} item={attachment} open={open} onClose={onClose} />}
      {onDelete && !readonly && (
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="delete" onClick={confirmDelete(() => onDelete(attachment), 'resurs')}>
            <Delete />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};
