import React, { useState } from 'react';

import { Box, Checkbox } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { formatISO, parseISO } from 'date-fns';

import { FilterTreeSubComponentProps } from '../../../../api/filterTypes';

const parseToDate = (s: string): Date | null => {
  if (!s) {
    return null;
  }
  return parseISO(s);
};

const isValidDate = (d: any): boolean => {
  return d && !isNaN(d) && d instanceof Date;
};

const formatISODateOrEmpty = (d: Date | null): string => (d ? formatISO(d) : '');

type LimitType = 'from' | 'to';

export const FilterTreeTimeIntervalComponent: React.FC<FilterTreeSubComponentProps> = ({ state, setState }) => {
  const propValues = state.value ?? [];
  const [from, to] = [parseToDate(propValues[0]), parseToDate(propValues[1])];

  // Remember the set date the picker was used before it was disabled
  // so the user get the same result next time.
  const [lastDate, setLastDate] = useState({ from: null, to: null } as Record<LimitType, Date | null>);

  const setLastUsedDate = (date: Date | null, limit: LimitType) => {
    switch (limit) {
      case 'from':
        setLastDate({
          from: date,
          to: to,
        });
        break;
      case 'to':
        setLastDate({
          from: from,
          to: date,
        });
        break;
    }
  };

  const setDate = (date: Date | null, limit: LimitType) => {
    switch (limit) {
      case 'from':
        setState({ value: [formatISODateOrEmpty(date), formatISODateOrEmpty(to)], includeNull: true });
        break;
      case 'to':
        setState({ value: [formatISODateOrEmpty(from), formatISODateOrEmpty(date)], includeNull: true });
    }
  };

  const onCheck = (limit: LimitType) => {
    switch (limit) {
      case 'from':
        setDate(from ? null : lastDate[limit] ?? new Date(), limit);
        break;
      case 'to':
        setDate(to ? null : lastDate[limit] ?? new Date(), limit);
        break;
    }
  };

  const onChangeDate = (date: Date | null, limit: LimitType) => {
    // When the user deletes all input, the date is null. Clear date.
    if (!date) {
      setDate(null, limit);
      setLastUsedDate(null, limit);
      // Date can also be invalid, 'Invalid Date', which does not parse. Hence this extra check.
    } else if (isValidDate(date)) {
      setDate(date, limit);
      setLastUsedDate(date, limit);
    }
    // Not valid date. Do nothing.
  };

  interface DateFilterFieldProps {
    property: LimitType;
    value: Date | null;
    label: string;
    onChangeDate: (date: Date | null, limit: LimitType) => void;
  }

  const DateFilterField = ({ property, value, label, onChangeDate }: DateFilterFieldProps) => (
    <Box width={'160px'} marginLeft={'1.5em'} display={'flex'} flexDirection={'row'} alignItems={'end'}>
      <Checkbox checked={!!value} onChange={() => onCheck(property)} />
      <DatePicker label={label} value={value} onChange={date => onChangeDate(date, property)} disabled={!value} />
    </Box>
  );

  return (
    <>
      <DateFilterField label="Från" onChangeDate={onChangeDate} value={from} property={'from'} />
      <DateFilterField label="Till" onChangeDate={onChangeDate} value={to} property={'to'} />
    </>
  );
};
