import { CloudDownload } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Image } from '../../../api/caseApiTypes';
import { useSelectionContext } from './selectionContextContext';
import { useAppSelector } from '../../../hooks/useAppRedux';

function downloadImagesLink(images: Image[]) {
  const query = images.map(image => `items=${image.id},${image.activeVersion}`).join('&');
  return '/api/image/batch/download?' + query;
}

interface Props {
  images: Image[];
}

export function DownloadMediaButton({ images }: Props) {
  return (
    <IconButton href={downloadImagesLink(images)} title="Ladda ner markerade bilder">
      <CloudDownload />
    </IconButton>
  );
}

export function DownloadSelectedMediaButton() {
  const selectionContext = useSelectionContext();
  const selectedImages = useAppSelector(state => state.images.selected[selectionContext]);
  return <DownloadMediaButton images={selectedImages} />;
}
