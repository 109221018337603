import React, { useEffect } from 'react';
import styles from './MultiMediaPreview.module.css';

import { Accordion, AccordionDetails, AccordionSummary, IconButton, Tooltip, Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { Image } from '../../../../api/caseApiTypes';
import { SingleMediaPreview } from './SingleMediaPreview';
import { GetThumbnailUri } from '../../../../helpers/DerivedImageHelpers';
import { ImageEnrichmentForm } from '../../../enrichment/ImageEnrichmentForm';

export type Props = {
  media: Image[];
};

function activeVersion(media: Image) {
  return media.versions?.[media.activeVersion];
}

type MediaAccordionState = 'closed' | 'open' | 'editing';
const emptyPanelStates = (media: Image[]): Record<string, MediaAccordionState> =>
  media.reduce((acc, m) => ({ ...acc, [m.id]: 'closed' }), {});
const toggleOpenEditing = (state: MediaAccordionState) => (state === 'open' ? 'editing' : 'open');

export function MultiMediaPreview({ media }: Props) {
  const [panelStates, setPanelStates] = React.useState<Record<string, MediaAccordionState>>(emptyPanelStates(media));
  const setPanelState = (panel: string, state: MediaAccordionState) => setPanelStates({ ...panelStates, [panel]: state });
  const isPanelExpanded = (panel: string) => panelStates[panel] !== 'closed';
  const isEditingMedia = (panel: string) => panelStates[panel] === 'editing';
  const isOpen = (panel: string) => panelStates[panel] === 'open';
  const toogleIsExpanded = (panel: string) => (_: React.SyntheticEvent, __: boolean) =>
    setPanelState(panel, isPanelExpanded(panel) ? 'closed' : 'open');
  const toogleEditing = (panel: string) => setPanelState(panel, isPanelExpanded(panel) ? toggleOpenEditing(panelStates[panel]) : 'editing');

  const toogleEditButton = (panel: string) => (e: React.MouseEvent) => {
    e.stopPropagation();
    toogleEditing(panel);
  };

  useEffect(() => {
    // remove old
    if (media.length < Object.keys(panelStates).length) {
      const newPanelStates = emptyPanelStates(media);
      Object.keys(panelStates).forEach(key => {
        if (newPanelStates[key]) {
          newPanelStates[key] = panelStates[key];
        }
      });
      setPanelStates(newPanelStates);
    }
    // add new
    if (media.length > Object.keys(panelStates).length) {
      const newMedia = media.filter(m => !panelStates[m.id]);
      setPanelStates({ ...panelStates, ...emptyPanelStates(newMedia) });
    }
  }, [media, panelStates]);

  return (
    <>
      {media.map(m => (
        <Accordion expanded={isPanelExpanded(m.id)} onChange={toogleIsExpanded(m.id)} key={m.id}>
          <AccordionSummary classes={{ content: styles.titleFlexFix }}>
            <Tooltip title={m.productName}>
              <Typography variant="subtitle2" sx={{ alignSelf: 'end' }}>
                {m.productName}
              </Typography>
            </Tooltip>
            <Tooltip title={activeVersion(m)?.fileName}>
              <Typography variant="caption" sx={{ alignSelf: 'end' }} className={styles.fileName}>
                {activeVersion(m)?.fileName}
              </Typography>
            </Tooltip>
            <IconButton onClick={toogleEditButton(m.id)} className={styles.editButton}>
              <Edit />
            </IconButton>
            <Tooltip title={<img alt="" src={GetThumbnailUri(activeVersion(m), 320)} className={styles.tooltip} />} placement="left">
              <img alt={activeVersion(m)?.fileName} src={GetThumbnailUri(activeVersion(m), 320)} className={styles.previewImage} />
            </Tooltip>
          </AccordionSummary>
          <AccordionDetails>
            {isEditingMedia(m.id) && <ImageEnrichmentForm media={[m]} onCancelAndConfirm={() => setPanelState(m.id, 'open')} />}
            {isOpen(m.id) && <SingleMediaPreview media={m} />}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}
