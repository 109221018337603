import { Dispatch, createAsyncThunk } from '@reduxjs/toolkit';
import { retry } from '../../helpers/Retry';
import * as Api from '../../api/imageApi';
import { Image } from '../../api/caseApiTypes';
import { batchMediaSaveFail, batchMediaSaveSuccess, endBatchMediaSave, getImageSuccess, startBatchMediaSave } from './imagesSlice';
import { batchExecute } from '../../helpers/Batching';

export async function batchProcessMedia<TDispatch extends Dispatch>(
  dispatch: TDispatch,
  media: Partial<Image>,
  action: (media: Partial<Image>) => Promise<void>,
) {
  try {
    await retry(
      () => action(media),
      3, // retries
      1000, //delay
    );

    dispatch(batchMediaSaveSuccess());
  } catch (e) {
    if (e instanceof Error) {
      dispatch(batchMediaSaveFail({ error: e.message, media }));
    } else {
      dispatch(batchMediaSaveFail({ error: 'unknown', media }));
    }
  }
}

export const batchUpdateMedia = createAsyncThunk<void, { images: Partial<Image>[]; onComplete: () => void }>(
  'images/batchUpdateMedia',
  async ({ images, onComplete }, { dispatch }) => {
    dispatch(startBatchMediaSave({ numTotal: images.length, operation: 'Spara berikning' }));

    await batchExecute(images, 10, media =>
      batchProcessMedia(dispatch, media, async media => {
        const result = await Api.updateImage(media.id!, media);
        dispatch(getImageSuccess(result));
      }),
    );

    dispatch(endBatchMediaSave());

    onComplete();
  },
);
