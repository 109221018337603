import { CaseType, StateEnum } from '../../api/caseApiTypes';
import { SearchContext } from '../../hooks/useSearchState';
import { CaseKanbanBoard } from './CaseKanbanBoard';
import { ProductContextualLaneDefinitions } from './ProductContextualLaneDefinitions';

export const ProductContextualKanbanSupportedTypes = [CaseType.ProductContextual];

export const ProductContextualKanbanFilters = {
  defaultFilters: {
    type: { value: ProductContextualKanbanSupportedTypes.map(state => state.toString()) },
    Case_CaseState: {
      value: [StateEnum.ordered, StateEnum.planned, StateEnum.reviewPending].map(state => state.toString()),
    },
  },
};

export const ProductContextualCaseKanbanPlan = () =>
  CaseKanbanBoard({
    filters: ProductContextualKanbanFilters,
    lanes: ProductContextualLaneDefinitions,
    searchContext: SearchContext.PlanningProductContextualKanban,
  });

