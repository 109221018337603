import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../hooks/useAppRedux';
import { addHours } from 'date-fns';
import { Box, CircularProgress, styled, Typography } from '@mui/material';
import { DatesSetArg, EventApi, EventChangeArg, EventContentArg, EventInput } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin, { DropArg, EventReceiveArg } from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';

import styles from './Calendar.module.css';

import { confirmCase, plannedCase } from '../cases/caseDetailSlice';
import { Case, GetProductNameForCase, StateEnum, UserTag } from '../../api/caseApiTypes';
import { CalendarEvent } from './CalendarEvent';
import { PendingChangesDrawer } from '../cases/PendingChangesDrawer';
import { SelectableUserTag } from '../tags/SelectableUserTag';
import { cancelTokens } from '../../api/caseApi';
import { SearchContext, useCaseSearchState } from '../../hooks/useSearchState';

interface TimeSpan {
  start: Date;
  end: Date;
}

interface PendingChange {
  id: string;
  start: Date;
  end: Date;
}

interface Props {
  start: string;
  onChangedTimeWindow?: (timeSpan: TimeSpan) => void;
  readonly?: boolean;
  compact?: boolean;
  searchContext?: SearchContext;
}

export const Calendar: React.FC<Props> = ({ start, onChangedTimeWindow, readonly, compact, searchContext }) => {
  const dispatch = useAppDispatch();

  const context = searchContext ?? SearchContext.PlanningCalendar;
  const { items: cases } = useCaseSearchState(context);

  const [selectedUserTag, setSelectedUserTag] = useState<UserTag | null>(null);
  const [events, setEvents] = useState<EventInput[]>([]);
  const [pendingChanges, setPendingChanges] = useState<PendingChange[]>([]);

  useEffect(() => {
    setEvents(eventsBasedOnCases);
    setPendingChanges(changesYetToBeApplied);
    // eslint-disable-next-line
  }, [cases]);

  useEffect(() => {
    setEvents(eventsBasedOnCases);
    // eslint-disable-next-line
  }, [selectedUserTag]);

  const eventsBasedOnCases = () => {
    return (
      cases
        ?.filter(c => c.state.state >= StateEnum.planned)
        ?.filter(c => selectedUserTag == null || c.userTags.some(tag => tag.id === selectedUserTag.id))
        .map(item => {
          /*
           * Retain any pending changes even though the cases
           * have been updated. This means that a concurrent update
           * by another user will never overwrite pending changes.
           */
          const pendingChange = pendingChanges.find(c => c.id === item.id);
          return {
            id: item.id,
            sourceId: item.id,
            title: GetProductNameForCase(item),
            start: pendingChange != null ? pendingChange.start : item.plannedStart,
            end: pendingChange != null ? pendingChange.end : item.plannedEnd,
            item: item,
            color: 'white',
          } as EventInput;
        }) ?? []
    );
  };

  const changesYetToBeApplied = () => {
    const stillPartOfPlanning = (item?: Case) => {
      return item != null && item.state.state >= StateEnum.planned;
    };

    const stillPending = (pendingChange: PendingChange, item?: Case) => {
      const start = item?.plannedStart == null ? null : new Date(item.plannedStart);
      const end = item?.plannedEnd == null ? null : new Date(item.plannedEnd);

      return start?.getTime() !== pendingChange.start?.getTime() || end?.getTime() !== pendingChange.end?.getTime();
    };

    return pendingChanges.filter(pendingChange => {
      const item = cases?.find(e => e.id === pendingChange.id);

      return stillPartOfPlanning(item) && stillPending(pendingChange, item);
    });
  };

  const onUserTagSelect = (userTag: UserTag) => {
    setSelectedUserTag(selectedUserTag === userTag ? null : userTag);
  };

  const onEventPlanningUpdate = (arg: EventChangeArg | EventReceiveArg) => {
    const event = arg.event;
    if (event?.start == null || event?.end == null) {
      throw Error('Cannot plan event without start or end timestamp');
    }

    const currentChangesExceptThoseFor = (id: string) => {
      return pendingChanges.filter(change => change.id !== id);
    };

    const hasChangedFromOriginal = (event: EventApi) => {
      return event?.startStr !== event?.extendedProps.item?.plannedStart || event?.endStr !== event?.extendedProps.item?.plannedEnd;
    };

    const changes = currentChangesExceptThoseFor(event.id);

    if (hasChangedFromOriginal(event)) {
      setPendingChanges(changes.concat({ id: event.id, start: event.start, end: event.end }));
      return;
    }

    setPendingChanges(changes);
  };

  const onEventDrop = (arg: DropArg) => {
    if (arg.date == null) {
      throw Error('Cannot plan event where start timestamp is null');
    }

    const id = arg.draggedEl.getAttribute('id');
    const item = cases?.find(c => c.id === id);
    if (item == null) {
      return;
    }

    /* TODO: Acquire duration (2 hours) from argument in some kind of way to avoid duplication? */
    dispatch(plannedCase({ item, start: arg.date, end: addHours(arg.date, 2) }));
  };

  const onRemove = (id: string) => {
    const item = cases?.find(c => c.id === id);
    if (item != null) {
      dispatch(confirmCase(item));
    }
  };

  const onDateChange = (dates: DatesSetArg) => {
    onChangedTimeWindow?.({ start: dates.start, end: dates.end });
  };

  const renderCalendarEvent = (event: EventContentArg) => (
    <CalendarEvent
      eventInfo={event}
      compact={event.view.type !== dayView}
      onUserTagSelect={onUserTagSelect}
      onRemove={onRemove}
      readonly={readonly}
      item={cases?.find(c => c.id === event.event.id)}
    />
  );

  const savePendingChanges = () => {
    pendingChanges.forEach(c => {
      const item = cases?.find(item => item.id === c.id);
      if (item == null) {
        return;
      }

      if (c.start != null && c.end != null) {
        dispatch(plannedCase({ item, start: c.start, end: c.end }));
      }
    });
  };

  const weekView = 'timeGridWeek';
  const dayView = 'timeGridDay';

  const initialView = compact ? dayView : weekView;

  const headerToolbar = compact
    ? false
    : {
        start: 'prev,next today',
        center: 'title',
        end: `${weekView},${dayView}`,
      };

  // If there is a cancelToken for the active search context, then search is in progress.
  const isLoading = cancelTokens[context] != null;

  const calendar = (
    <div className={styles.calendarWrapper}>
      <div className={styles.calendar}>
        <FullCalendarWrapper>
          <FullCalendar
            plugins={[timeGridPlugin, interactionPlugin]}
            initialView={initialView}
            events={events}
            eventContent={renderCalendarEvent}
            locale="sv"
            editable={!readonly}
            droppable={!readonly}
            weekends={false}
            weekNumbers={true}
            weekText="V"
            slotMinTime="07:00:00"
            slotMaxTime="18:00:00"
            slotDuration="00:15:00"
            slotLabelInterval="01:00"
            allDaySlot={false}
            headerToolbar={headerToolbar}
            buttonText={{
              today: 'Idag',
              month: 'Månad',
              week: 'Vecka',
              day: 'Dag',
              list: 'Lista',
            }}
            drop={onEventDrop}
            eventChange={onEventPlanningUpdate}
            datesSet={onDateChange}
            height={compact ? '100%' : 'auto'}
            dayHeaders={!compact}
            nowIndicator={true}
            initialDate={start}
          />
        </FullCalendarWrapper>
      </div>
      {isLoading && (
        <div className={styles.overlay}>
          <CircularProgress />
        </div>
      )}
    </div>
  );

  if (compact) {
    return calendar;
  }

  return (
    <>
      {selectedUserTag != null && (
        <Box className={styles.filterContainer}>
          <Box className={styles.filter}>
            <Typography variant="subtitle1">Filtrerar på</Typography>
            <Box className={styles.selectedUserTag}>
              <SelectableUserTag userTag={selectedUserTag} size="small" onSelect={() => onUserTagSelect(selectedUserTag)} />
            </Box>
          </Box>
        </Box>
      )}
      {calendar}
      <PendingChangesDrawer pendingChangesExist={pendingChanges.length > 0} onSave={savePendingChanges} />
    </>
  );
};

const FullCalendarWrapper = styled(Box)(({ theme }) => {
  const isLightMode = theme.palette.mode === 'light';
  return {
    thead: {
      backgroundColor: isLightMode ? '#fafafa' : '#333',
    },
    '.fc-event-main': {
      padding: '0 !important',
    },
    '.fc-timegrid-event': {
      border: 'none !important',
      boxShadow: 'none !important',
      borderRadius: '0.5em !important',
    },
    '--fc-border-color': isLightMode ? '#ccc' : '#666',
  };
});
