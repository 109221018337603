import React from 'react';
import { IconButton, Paper, Typography, useTheme } from '@mui/material';
import styles from './CaseKanbanLane.module.css';
import { Add, Remove } from '@mui/icons-material';

interface Props {
  children?: React.ReactNode;
  title: string;
  collapsed: boolean;
  onToggleCollapsed: () => void;
}

export const CaseKanbanLane: React.FC<Props> = ({ title, collapsed, onToggleCollapsed, children }) => {
  const { palette } = useTheme();

  const backgroundColor = palette.mode === 'light' ? '#eee' : '#222';

  const addClasses = (className: string) => {
    const classes = [className];
    if (collapsed) {
      classes.push(styles.collapsed);
    } else {
      classes.push(styles.expanded);
    }
    return classes.join(' ');
  };

  const Icon = collapsed ? Add : Remove;

  return (
    <Paper className={addClasses(styles.lane)} style={{ backgroundColor }}>
      <div className={addClasses(styles.header)}>
        <IconButton onClick={() => onToggleCollapsed()} className={addClasses(styles.button)} size="small" disableRipple disableFocusRipple>
          <Icon />
        </IconButton>
        <Typography variant="h6">{title}</Typography>
      </div>
      {!collapsed && children}
    </Paper>
  );
};
