import axios from 'axios';

export interface FeatureFlags {}

export async function getFeatureFlags(): Promise<FeatureFlags | null> {
  const { status, data } = await axios.get<FeatureFlags>('/api/features');

  if (status === 200) {
    return data;
  }

  return null;
}
