import axios from 'axios';

export async function getVersion(): Promise<string> {
  const url = `/`;

  const { headers } = await axios.head(url);

  const version = headers['x-mio-version'];
  const versionInformational = headers['x-mio-version-informational'];

  return version && versionInformational ? `${version} (${versionInformational})` : 'undefined';
}
