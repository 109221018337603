import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/rootReducer';
import { LocalStorageKeys } from '../hooks/useStorage';

type SectionState = {
  show: boolean;
  time: number;
};

type SectionStateMap = { [id: string]: SectionState };

export type AssignedUser = {
  id: string;
  fullName: string;
};

export interface CaseDetailSectionsState {
  sections: SectionStateMap;
  caseAssignedUsers: {
    owners: AssignedUser[];
    planners: AssignedUser[];
  };
}

const emptyState: CaseDetailSectionsState = {
  sections: {},
  caseAssignedUsers: {
    owners: [],
    planners: [],
  },
};

// Max age is 30 days (in milliseconds)
const MaxSectionStateAge = 30 * 24 * 3600 * 1000;

function filterOldStates(sections: SectionStateMap) {
  var keys = Object.keys(sections);
  const now = new Date().getTime();
  for (let key of keys) {
    const time = sections[key].time;
    if (!time) {
      delete sections[key];
    } else if (now - time > MaxSectionStateAge) {
      delete sections[key];
    }
  }

  return sections;
}

const initialState: CaseDetailSectionsState = {
  sections: filterOldStates(
    JSON.parse(window.localStorage.getItem(LocalStorageKeys.caseDetailSections) || JSON.stringify(emptyState.sections)) as SectionStateMap,
  ),
  caseAssignedUsers: JSON.parse(
    window.localStorage.getItem(LocalStorageKeys.caseAssignedUsers) || JSON.stringify(emptyState.caseAssignedUsers),
  ),
};

function sectionId(caseId: string, section: string) {
  return `${caseId}___${section}`;
}

export const localUserState = createSlice({
  name: 'localUserState',
  initialState,
  reducers: {
    setShowCaseDetailSection(state, { payload }: PayloadAction<{ caseId: string; section: string; show: boolean }>) {
      const { caseId, section, show } = payload;
      state.sections[sectionId(caseId, section)] = { show, time: new Date().getTime() };
      window.localStorage.setItem(LocalStorageKeys.caseDetailSections, JSON.stringify(state.sections));
    },
    rememberLocalCaseAssignedOwner(state, { payload }: PayloadAction<{ id: string; fullName: string }>) {
      const { id, fullName } = payload;
      if (id === '') {
        return;
      }
      const idx = state.caseAssignedUsers.owners.findIndex(o => o.id === id);
      if (idx >= 0) {
        state.caseAssignedUsers.owners[idx] = { id, fullName };
      } else {
        state.caseAssignedUsers.owners.push({ id, fullName });
      }
      window.localStorage.setItem(LocalStorageKeys.caseAssignedUsers, JSON.stringify(state.caseAssignedUsers));
    },
    removeLocalCaseAssignedOwner(state, { payload }: PayloadAction<{ id: string }>) {
      const { id } = payload;
      const idx = state.caseAssignedUsers.owners.findIndex(o => o.id === id);
      if (idx >= 0) {
        state.caseAssignedUsers.owners.splice(idx, 1);
      }
      window.localStorage.setItem(LocalStorageKeys.caseAssignedUsers, JSON.stringify(state.caseAssignedUsers));
    },
    rememberLocalCaseAssignedPlanner(state, { payload }: PayloadAction<{ id: string; fullName: string }>) {
      const { id, fullName } = payload;
      if (id === '') {
        return;
      }
      const idx = state.caseAssignedUsers.planners.findIndex(o => o.id === id);
      if (idx >= 0) {
        state.caseAssignedUsers.planners[idx] = { id, fullName };
      } else {
        state.caseAssignedUsers.planners.push({ id, fullName });
      }
      window.localStorage.setItem(LocalStorageKeys.caseAssignedUsers, JSON.stringify(state.caseAssignedUsers));
    },
    removeLocalCaseAssignedPlanner(state, { payload }: PayloadAction<{ id: string }>) {
      const { id } = payload;
      const idx = state.caseAssignedUsers.planners.findIndex(o => o.id === id);
      if (idx >= 0) {
        state.caseAssignedUsers.planners.splice(idx, 1);
      }
      window.localStorage.setItem(LocalStorageKeys.caseAssignedUsers, JSON.stringify(state.caseAssignedUsers));
    },
  },
});

export const {
  setShowCaseDetailSection,
  rememberLocalCaseAssignedOwner,
  rememberLocalCaseAssignedPlanner,
  removeLocalCaseAssignedOwner,
  removeLocalCaseAssignedPlanner,
} = localUserState.actions;

export const selectShowCaseDetailSection = (caseId: string, section: string) => (state: RootState) =>
  state.localUserState.sections[sectionId(caseId, section)]?.show;

export const selectCasePlanners = (state: RootState) => state.localUserState.caseAssignedUsers.planners;
export const selectCaseOwners = (state: RootState) => state.localUserState.caseAssignedUsers.owners;

export default localUserState.reducer;
