import { Badge, Typography } from '@mui/material';
import { useAppSelector } from '../../../hooks/useAppRedux';
import { selectActiveClipbook } from '../clipbooksSlice';

export function ActiveClipbookText() {
  const activeClipbook = useAppSelector(selectActiveClipbook);
  return (
    <>
      {!!activeClipbook ? (
        <Badge badgeContent={activeClipbook.images.length} color="primary">
          <Typography>{activeClipbook.name}</Typography>
        </Badge>
      ) : (
        <Typography variant="body2" fontStyle="italic">
          Ingen grupp vald
        </Typography>
      )}
    </>
  );
}
