import React from 'react';

import { Attachment, AttachmentCategory, Case } from '../../../api/caseApiTypes';
import { CaseDeliveryStatusField } from '../formFieldComponents/CaseDeliveryStatusField';
import { CaseDescriptionField } from '../formFieldComponents/CaseDescriptionField';
import { CaseDetailEnrichmentSubForm } from './CaseDetailEnrichmentSubForm';
import { CaseFormSection } from './CaseFormSection';
import { CaseAttachments } from '../CaseAttachments';
import { Right } from '../../../api/userApi';
import { CaseImageRequestForm } from './CaseImageRequestForm';

interface Props {
  caze: Case;
  onChange: (partial: Partial<Case>) => void;
  readonly?: boolean;
  isNewCase?: boolean;
  onDeleteAttachment: (attachment: Attachment) => void;
}

export const GenericCaseDetailFormFields: React.FC<Props> = ({ caze, onChange, onDeleteAttachment, readonly, isNewCase }) => {
  return (
    <>
      <CaseDeliveryStatusField caze={caze} onChange={onChange} readonly={readonly} />

      <CaseFormSection
        title="Beställningsinformation"
        caseId={caze.id}
        description="Beskriv dina önskemål för bilderna ex. Kampanj, målgrupp, stil och viktig info"
      >
        <CaseDescriptionField description={caze.description} onChange={v => onChange({ description: v })} readonly={readonly} />
        <CaseAttachments
          caze={caze}
          onDelete={onDeleteAttachment}
          category={AttachmentCategory.SupplementaryInformation}
          right={Right.WriteCase}
          isNewCase={isNewCase}
        />
      </CaseFormSection>
      <CaseImageRequestForm caze={caze} />
      <CaseDetailEnrichmentSubForm caze={caze} onChange={onChange} />
    </>
  );
};
