import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';

import * as BatchImageOperationApi from '../../api/batchImageOperationApi';
import { BatchImageOperationApiThunkInput } from '../../api/batchImageOperationApi';
import * as Api from '../../api/caseApi';
import {
  Article,
  Attachment,
  AttachmentCategory,
  Case,
  caseCtor,
  CaseEnrichmentHolder,
  CaseImageRequest,
  CaseRelation,
  CaseType,
  CaseVideoRequest,
  DefaultCaseImageRequest,
  DefaultCaseVideoRequest,
  EnumerationItem,
  Enumerations,
  Image,
  ImagePointer,
  ProjectTag,
  SystemTag,
  UserTag,
} from '../../api/caseApiTypes';
import { ensureSupportedImageType, ensureSupportedVideoType, FileUploadInput } from '../../api/commonApiTypes';
import { AppDispatch, ThunkConfig } from '../../app/store';
import { pushToast, ToastNotification } from '../../appSlice';
import { GetDifference, IsDirty } from '../../helpers/ObjectHelpers';
import { ensureImagesLoaded } from '../images/imagesSlice';
import { deleteCaseSuccess, getById, getByLookupEntry, getCaseSuccess } from './casesSlice';
import _ from 'lodash';
import { DeepPartial } from '../../helpers/DeepPartial';
import { RootState } from '../../app/rootReducer';

interface CaseDetailState {
  case: Case | null;
  isDirty: boolean;
  persistedCase?: Case;
  error: string | null;
  imageUploadInProgress: number;
  attachmentUploadInProgress: number;
}

const initialState: CaseDetailState = {
  case: null,
  isDirty: false,
  persistedCase: undefined,
  error: null,
  imageUploadInProgress: 0,
  attachmentUploadInProgress: 0,
};

export const caseDetailSlice = createSlice({
  name: 'caseDetail',
  initialState,
  reducers: {
    setCaseState(state, action: PayloadAction<Case | null>) {
      state.case = action.payload;
    },
    updateCaseState(state, action: PayloadAction<Partial<Case>>) {
      if (state.case != null) {
        Object.assign(state.case, action.payload);
      }
    },
    addCaseImageRequestItem(state, action: PayloadAction<{}>) {
      if (state.case === null) {
        return;
      }
      if (!state.case.imageRequests) {
        state.case.imageRequests = [];
      }
      state.case.imageRequests.push(DefaultCaseImageRequest());
    },
    updateCaseImageRequestItem(state, action: PayloadAction<{ index: number; props: Partial<CaseImageRequest> }>) {
      const { index, props } = action.payload;
      if (state.case === null) {
        return;
      }
      let imageRequest = state.case.imageRequests[index];
      if (!imageRequest) {
        return;
      }
      Object.assign(imageRequest, props);
    },
    removeCaseImageRequestItem(state, action: PayloadAction<{ index: number }>) {
      const { index } = action.payload;
      if (state.case === null) {
        return;
      }
      state.case.imageRequests.splice(index, 1);
    },
    updateCaseVideoRequestItem(state, action: PayloadAction<{ props: DeepPartial<CaseVideoRequest> }>) {
      const { props } = action.payload;

      if (state.case === null) {
        return;
      }

      let videoRequest = state.case.videoRequest || DefaultCaseVideoRequest;
      // Deep merge with the new properties
      state.case.videoRequest = _.merge(videoRequest, props);
    },
    removeCaseVideoRequestItem(state) {
      if (state.case === null) {
        return;
      }
      state.case.videoRequest = undefined;
    },
    setDirty(state, action: PayloadAction<boolean>) {
      state.isDirty = action.payload;
    },
    setPointers(state, action: PayloadAction<ImagePointer[]>) {
      if (state.case != null) {
        state.case.imagePointers = action.payload;
      }
    },
    setEtag(state, action: PayloadAction<string>) {
      if (state.case != null) {
        state.case.etag = action.payload;
      }
    },
    setPersistedCase(state, action: PayloadAction<Case>) {
      state.persistedCase = action.payload;
    },
    caseDetailFailed(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    errorConsumed(state) {
      state.error = null;
    },
    startImageUpload(state) {
      state.imageUploadInProgress += 1;
    },
    endImageUpload(state) {
      if (state.imageUploadInProgress > 0) {
        state.imageUploadInProgress -= 1;
      }
    },
    startAttachmentUpload(state) {
      state.attachmentUploadInProgress += 1;
    },
    endAttachmentUpload(state) {
      if (state.attachmentUploadInProgress > 0) {
        state.attachmentUploadInProgress -= 1;
      }
    },
  },
});

export const {
  setCaseState,
  updateCaseState,
  setDirty,
  setPointers,
  setEtag,
  setPersistedCase,
  caseDetailFailed,
  errorConsumed,
  startImageUpload,
  endImageUpload,
  startAttachmentUpload,
  endAttachmentUpload,
  addCaseImageRequestItem,
  updateCaseImageRequestItem,
  removeCaseImageRequestItem,
  updateCaseVideoRequestItem,
  removeCaseVideoRequestItem,
} = caseDetailSlice.actions;

export const initializeCaseDetailItem = createAsyncThunk<void, { id?: string; caseType?: CaseType }, ThunkConfig>(
  'caseDetail/init',
  async ({ id, caseType }, { dispatch, getState }) => {
    let caze = caseCtor(caseType ?? CaseType.Unknown);

    let isValid = true;

    if (id != null) {
      const lookupEntry = Number(id);
      const isLegacyId = isNaN(lookupEntry);

      if (isLegacyId) {
        await dispatch(getById(id));
      } else {
        await dispatch(getByLookupEntry(lookupEntry));
      }

      const { cases, app } = getState();

      const entityId = isLegacyId ? id : cases.caseIdsByLookupEntry[lookupEntry];
      const result = await sanitiseCase(cases.casesById[entityId], app.enumerations, dispatch);

      caze = result.item;
      isValid = result.isValid;
    }

    await dispatch(setDirty(!isValid));
    await dispatch(setCaseState(caze));
    await dispatch(setPersistedCase(caze));
  },
);

export const cleanupCaseDetail = createAsyncThunk('caseDetail/cleanup', async (_, { dispatch }) => {
  dispatch(setDirty(false));

  dispatch(setCaseState(null));
});

export const sanitiseCase = async <T extends Partial<Case>>(item: T, enumerations: Enumerations | null, dispatch: AppDispatch) => {
  if (item == null || enumerations == null) {
    return { item, isValid: true };
  }

  // Ensure item is not a readonly state object, and that images are an array
  item = { ...item };
  if ('imagePointers' in item) {
    item.imagePointers = (item.imagePointers ?? []).map(pointer => ({ ...pointer }));
  }

  const isValid = EnsureCaseValid(enumerations, item);
  if (!isValid) {
    dispatch(
      pushToast({
        message: 'Värden för fotograf och stylist har nollställts, vänligen granska och spara rätt värden',
        variant: 'info',
        persist: true,
        preventDuplicate: true,
      }),
    );
  }

  return { item, isValid };
};

export const updateCaseDetailItem = createAsyncThunk<void, Partial<Case>, ThunkConfig>(
  'caseDetail/update',
  async (propsToUpdate, { dispatch, getState }) => {
    const state = getState();
    // Don't allow updates to a state that isn't initialised
    if (state.caseDetail.case != null) {
      const persisted = state.cases.casesById[state.caseDetail.case.id ?? ''];

      const { isValid, item } = await sanitiseCase(propsToUpdate, state.app.enumerations, dispatch);

      dispatch(updateCaseState(item));
      dispatch(setDirty(!isValid || IsDirty(getState().caseDetail.case, persisted)));
    }
  },
);

export const addCaseImageRequest = createAsyncThunk<void, {}, ThunkConfig>(
  'caseDetail/addImageRequest',
  async (_, { dispatch, getState }) => {
    const state = getState();

    if (state.caseDetail.case !== null) {
      const persisted = state.cases.casesById[state.caseDetail.case.id ?? ''];

      dispatch(addCaseImageRequestItem({}));
      dispatch(setDirty(IsDirty(getState().caseDetail.case, persisted)));
    }
  },
);

export const updateCaseImageRequest = createAsyncThunk<void, { index: number; imageRequest: Partial<CaseImageRequest> }, ThunkConfig>(
  'caseDetail/updateImageRequest',
  async ({ index, imageRequest }, { dispatch, getState }) => {
    const state = getState();

    if (state.caseDetail.case !== null) {
      const persisted = state.cases.casesById[state.caseDetail.case.id ?? ''];

      dispatch(updateCaseImageRequestItem({ index, props: imageRequest }));
      dispatch(setDirty(IsDirty(getState().caseDetail.case, persisted)));
    }
  },
);

export const updateCaseVideoRequest = createAsyncThunk<void, { videoRequest: DeepPartial<CaseVideoRequest> }, ThunkConfig>(
  'caseDetail/updateVideoRequest',
  async ({ videoRequest }, { dispatch, getState }) => {
    const state = getState();

    if (state.caseDetail.case !== null) {
      const persisted = state.cases.casesById[state.caseDetail.case.id ?? ''];

      dispatch(updateCaseVideoRequestItem({ props: videoRequest }));
      dispatch(setDirty(IsDirty(getState().caseDetail.case, persisted)));
    }
  },
);

export const removeCaseVideoRequest = createAsyncThunk<void, {}, ThunkConfig>(
  'caseDetail/removeVideoRequest',
  async (_, { dispatch, getState }) => {
    const state = getState();

    if (state.caseDetail.case !== null) {
      const persisted = state.cases.casesById[state.caseDetail.case.id ?? ''];

      dispatch(removeCaseVideoRequestItem());
      dispatch(setDirty(IsDirty(getState().caseDetail.case, persisted)));
    }
  },
);

export const removeCaseImageRequest = createAsyncThunk<void, { index: number }, ThunkConfig>(
  'caseDetail/removeImageRequest',
  async ({ index }, { dispatch, getState }) => {
    const state = getState();

    if (state.caseDetail.case !== null) {
      const persisted = state.cases.casesById[state.caseDetail.case.id ?? ''];

      dispatch(removeCaseImageRequestItem({ index }));
      dispatch(setDirty(IsDirty(getState().caseDetail.case, persisted)));
    }
  },
);
export const setImageActiveVersion = createAsyncThunk<void, { caseId: string; imageId: string; activeVersion: string }>(
  'images/setImageActiveVersion',
  async ({ caseId, imageId, activeVersion }, { dispatch }) => {
    const { imagePointers, etag } = await Api.setImageActiveVersion(caseId, imageId, activeVersion);

    dispatch(setPointers(imagePointers));
    if (etag) dispatch(setEtag(etag));
  },
);

export const updateCaseWithPersisted = createAsyncThunk<void, Case, ThunkConfig>(
  'caseDetail/updateCaseWithPersisted',
  async (persistedCase, { dispatch, getState }) => {
    const prevPersistedCase = getState().caseDetail.persistedCase;
    const activeCase = getState().caseDetail.case;

    const unsavedChanges = GetDifference(prevPersistedCase, activeCase);
    const incomingChanges = GetDifference(activeCase, persistedCase);

    dispatch(setPersistedCase(persistedCase));
    dispatch(updateCaseDetailItem({ ...activeCase, ...incomingChanges, ...unsavedChanges }));
  },
);

export const upsertCase = createAsyncThunk<
  void,
  { item: Case; navigate: NavigateFunction; onSuccess?: () => void; nbrRetries?: number },
  ThunkConfig
>('caseDetail/upsert', async ({ item, navigate, onSuccess, nbrRetries = 0 }, { dispatch, getState }) => {
  try {
    const result = item.id ? await Api.updateCase(item) : await Api.createCase(item);

    dispatch(getCaseSuccess(result));
    onSuccess?.();

    // https://github.com/ReactTraining/react-router/issues/3498
    // https://github.com/ReactTraining/react-router/issues/5237

    if (!item.lookupEntry && result.lookupEntry) {
      navigate(`/case/${result.lookupEntry}`, { replace: true });
    }
    dispatch(updateCaseWithPersisted(result));
  } catch (error: any) {
    // tries to fetch and retry upsert if we didn't have the correct etag and therefore the latest data
    if (nbrRetries < 5 && error.response?.status === 409) {
      await dispatch(getById(item.id!));
      const updatedCase = getState().cases.casesById[item.id!];
      await dispatch(upsertCase({ item: updatedCase, navigate, onSuccess, nbrRetries: nbrRetries + 1 }));
    } else {
      dispatch(caseDetailFailed(error.toString()));
    }
  }
});

export const createFromImagePointers = createAsyncThunk<void, BatchImageOperationApiThunkInput, ThunkConfig>(
  'caseDetail/createFromImagePointers',
  async ({ imagePointers, navigate }, { dispatch }) => {
    try {
      const result = await BatchImageOperationApi.createRetouch(imagePointers);

      dispatch(getCaseSuccess(result));

      navigate(`/case/${result.lookupEntry}`);

      dispatch(updateCaseWithPersisted(result));
    } catch (error: any) {
      dispatch(caseDetailFailed(error.toString()));
    }
  },
);

export const resetCase = createAsyncThunk<void, Case, ThunkConfig>('caseDetail/reset', async (item: Case, { dispatch }) => {
  try {
    const result = await Api.resetCase(item.id);

    dispatch(getCaseSuccess(result));
    dispatch(updateCaseWithPersisted(result));
  } catch (error: any) {
    dispatch(caseDetailFailed(error.toString()));
  }
});

export const orderedCase = createAsyncThunk<void, Case, ThunkConfig>('caseDetail/order', async (item: Case, { dispatch }) => {
  try {
    const result = await Api.orderedCase(item.id);

    dispatch(getCaseSuccess(result));
    dispatch(updateCaseWithPersisted(result));
  } catch (error: any) {
    dispatch(caseDetailFailed(error.toString()));
  }
});

export const confirmCase = createAsyncThunk<void, Case, ThunkConfig>('caseDetail/confirm', async (item: Case, { dispatch }) => {
  try {
    const result = await Api.confirmCase(item.id);

    dispatch(getCaseSuccess(result));
    dispatch(updateCaseWithPersisted(result));
  } catch (error: any) {
    dispatch(caseDetailFailed(error.toString()));
  }
});

export const plannedCase = createAsyncThunk<void, { item: Case; start: Date; end: Date }, ThunkConfig>(
  'caseDetail/plan',
  async ({ item, start, end }, { dispatch }) => {
    try {
      const result = await Api.plannedCase(item.id, start, end);

      dispatch(getCaseSuccess(result));
      dispatch(updateCaseWithPersisted(result));
    } catch (error: any) {
      dispatch(caseDetailFailed(error.toString()));
    }
  },
);

export const caseReviewPending = createAsyncThunk<void, Case, ThunkConfig>('caseDetail/reviewPending', async (item: Case, { dispatch }) => {
  try {
    const result = await Api.caseReviewPending(item.id);

    dispatch(getCaseSuccess(result));
    dispatch(updateCaseWithPersisted(result));
  } catch (error: any) {
    dispatch(caseDetailFailed(error.toString()));
  }
});

export const caseAwaitingRetouch = createAsyncThunk<void, Case, ThunkConfig>(
  'caseDetail/awaitingRetouch',
  async (item: Case, { dispatch }) => {
    try {
      const result = await Api.caseAwaitingRetouch(item.id);

      dispatch(getCaseSuccess(result));
      dispatch(updateCaseWithPersisted(result));
    } catch (error: any) {
      dispatch(caseDetailFailed(error.toString()));
    }
  },
);

export const caseRetouchInProgress = createAsyncThunk<void, Case, ThunkConfig>(
  'caseDetail/retouchInProgress',
  async (item: Case, { dispatch }) => {
    try {
      const result = await Api.caseRetouchInProgress(item.id);

      dispatch(getCaseSuccess(result));
      dispatch(updateCaseWithPersisted(result));
    } catch (error: any) {
      dispatch(caseDetailFailed(error.toString()));
    }
  },
);

export const caseRetouchReviewPending = createAsyncThunk<void, Case, ThunkConfig>(
  'caseDetail/retouchReviewPending',
  async (item: Case, { dispatch }) => {
    try {
      const result = await Api.caseRetouchReviewPending(item.id);

      dispatch(getCaseSuccess(result));
      dispatch(updateCaseWithPersisted(result));
    } catch (error: any) {
      dispatch(caseDetailFailed(error.toString()));
    }
  },
);

export const closeCase = createAsyncThunk<void, Case, ThunkConfig>('caseDetail/close', async (item: Case, { dispatch }) => {
  try {
    const result = await Api.closeCase(item.id);

    dispatch(getCaseSuccess(result));
    dispatch(updateCaseWithPersisted(result));
  } catch (error: any) {
    dispatch(caseDetailFailed(error.toString()));
  }
});

export const deleteCase = createAsyncThunk<void, { item: Case; navigate: NavigateFunction }, ThunkConfig>(
  'caseDetail/delete',
  async ({ item, navigate }, { dispatch }) => {
    try {
      await Api.deleteCase(item.id);

      dispatch(deleteCaseSuccess(item));
      navigate(`/cases`, { replace: true });
    } catch (error: any) {
      dispatch(caseDetailFailed(error.toString()));
    }
  },
);

export const addSystemTags = createAsyncThunk<void, { item: Case; systemTags: SystemTag[] }, ThunkConfig>(
  'caseDetail/system-tag/add',
  async ({ item, systemTags }, { dispatch }) => {
    try {
      const result = await Api.addSystemTags(item.id, systemTags);

      dispatch(getCaseSuccess(result));
      dispatch(updateCaseWithPersisted(result));
    } catch (error: any) {
      dispatch(caseDetailFailed(error.toString()));
    }
  },
);

export const addUserTags = createAsyncThunk<void, { item: Case; userTags: UserTag[] }, ThunkConfig>(
  'caseDetail/user-tag/add',
  async ({ item, userTags }, { dispatch }) => {
    try {
      const result = await Api.addUserTags(item.id, userTags);

      dispatch(getCaseSuccess(result));
      dispatch(updateCaseWithPersisted(result));
    } catch (error: any) {
      dispatch(caseDetailFailed(error.toString()));
    }
  },
);

export const removeSystemTag = createAsyncThunk<void, { item: Case; systemTag: SystemTag }, ThunkConfig>(
  'caseDetail/system-tag/remove',
  async ({ item, systemTag }, { dispatch }) => {
    try {
      const result = await Api.removeSystemTag(item.id, systemTag);

      dispatch(getCaseSuccess(result));
      dispatch(updateCaseWithPersisted(result));
    } catch (error: any) {
      dispatch(caseDetailFailed(error.toString()));
    }
  },
);

export const removeUserTag = createAsyncThunk<void, { item: Case; userTag: UserTag }, ThunkConfig>(
  'caseDetail/user-tag/remove',
  async ({ item, userTag }, { dispatch }) => {
    try {
      const result = await Api.removeUserTag(item.id, userTag);

      dispatch(getCaseSuccess(result));
      dispatch(updateCaseWithPersisted(result));
    } catch (error: any) {
      dispatch(caseDetailFailed(error.toString()));
    }
  },
);

export const setProjectTag = createAsyncThunk<void, { item: Case; projectTag: ProjectTag }, ThunkConfig>(
  'caseDetail/project-tag/set',
  async ({ item, projectTag }, { dispatch }) => {
    try {
      const result = await Api.setProjectTag(item.id, projectTag);

      dispatch(getCaseSuccess(result));
      dispatch(updateCaseWithPersisted(result));
    } catch (error: any) {
      dispatch(caseDetailFailed(error.toString()));
    }
  },
);

export const clearProjectTag = createAsyncThunk<void, Case, ThunkConfig>('caseDetail/project-tag/clear', async (caze, { dispatch }) => {
  try {
    const result = await Api.clearProjectTag(caze.id);

    dispatch(getCaseSuccess(result));
    dispatch(updateCaseWithPersisted(result));
  } catch (error: any) {
    dispatch(caseDetailFailed(error.toString()));
  }
});

export const upsertArticles = createAsyncThunk<void, { item: Case; articles: Article[] }, ThunkConfig>(
  'caseDetail/articlesBatch/add',
  async ({ item, articles }, { dispatch }) => {
    try {
      if (articles == null || articles.length === 0) {
        return;
      }

      const result = await Api.upsertArticles(item.id, articles);

      dispatch(getCaseSuccess(result));
      dispatch(updateCaseWithPersisted(result));
    } catch (error: any) {
      dispatch(caseDetailFailed(error.toString()));
    }
  },
);

export const removeArticle = createAsyncThunk<void, { item: Case; id: string }, ThunkConfig>(
  'caseDetail/article/remove',
  async ({ item, id }, { dispatch }) => {
    try {
      const result = await Api.removeArticle(item.id, id);

      dispatch(getCaseSuccess(result));
      dispatch(updateCaseWithPersisted(result));
    } catch (error: any) {
      dispatch(caseDetailFailed(error.toString()));
    }
  },
);

export const uploadImage = createAsyncThunk<void, { item: Case } & FileUploadInput, ThunkConfig>(
  'caseDetail/image/upload',
  async ({ item, files, onUploadProgress }, { dispatch }) => {
    const onError = (fileName: string) => dispatch(pushToast(ErrorToastFileIsMissingExtension(fileName)));

    try {
      dispatch(startImageUpload());
      files.forEach(ensureSupportedImageType(onError));

      const result = await Api.uploadImage(item.id, files, onUploadProgress);
      dispatch(ensureImagesLoaded(result));

      dispatch(getCaseSuccess(result));
      dispatch(updateCaseWithPersisted(result));
    } catch (error: any) {
      dispatch(caseDetailFailed(error.toString()));
    } finally {
      dispatch(endImageUpload());
    }
  },
);

export const uploadVideo = createAsyncThunk<void, { item: Case } & FileUploadInput, ThunkConfig>(
  'caseDetail/video/upload',
  async ({ item, files, onUploadProgress }, { dispatch }) => {
    const onError = (fileName: string) => dispatch(pushToast(ErrorToastFileIsMissingExtension(fileName)));

    try {
      dispatch(startImageUpload());
      files.forEach(ensureSupportedVideoType(onError));

      const result = await Api.uploadVideo(item.id, files, onUploadProgress);
      dispatch(ensureImagesLoaded(result));

      dispatch(getCaseSuccess(result));
      dispatch(updateCaseWithPersisted(result));
    } catch (error: any) {
      dispatch(caseDetailFailed(error.toString()));
    } finally {
      dispatch(endImageUpload());
    }
  },
);

export interface CaseAndImageFileUploadInput extends FileUploadInput {
  caze: Case;
  image: Image;
}

interface RetouchUploadVariantImagesArgs extends CaseAndImageFileUploadInput {
  copyArticles: boolean;
}

export const retouchUploadVariantImages = createAsyncThunk<void, RetouchUploadVariantImagesArgs, ThunkConfig>(
  'caseDetail/image/uploadVariants',
  async ({ caze, image, files, onUploadProgress, copyArticles }, { dispatch }) => {
    const onError = (fileName: string) => dispatch(pushToast(ErrorToastFileIsMissingExtension(fileName)));
    try {
      dispatch(startImageUpload());
      files.forEach(ensureSupportedImageType(onError));

      const result = await Api.retouchUploadVariantImages(caze.id, image.id, files, copyArticles, onUploadProgress);
      dispatch(ensureImagesLoaded(result));

      dispatch(getCaseSuccess(result));
      dispatch(updateCaseWithPersisted(result));
    } catch (error: any) {
      dispatch(caseDetailFailed(error.toString()));
    } finally {
      dispatch(endImageUpload());
    }
  },
);

export const retouchReplaceImage = createAsyncThunk<void, CaseAndImageFileUploadInput, ThunkConfig>(
  'caseDetail/image/replace',
  async ({ caze, image, files, onUploadProgress }, { dispatch }) => {
    const onError = (fileName: string) => dispatch(pushToast(ErrorToastFileIsMissingExtension(fileName)));
    try {
      dispatch(startImageUpload());
      files.forEach(ensureSupportedImageType(onError));

      if (files.length !== 1) {
        dispatch(caseDetailFailed('Kan bara ersätta bilder med exakt en bild'));
        return;
      }

      const result = await Api.retouchReplaceImage(caze.id, image.id, files[0], onUploadProgress);

      dispatch(getCaseSuccess(result));
      dispatch(updateCaseWithPersisted(result));
    } catch (error: any) {
      dispatch(caseDetailFailed(error.toString()));
    }
    dispatch(endImageUpload());
  },
);

export type ReplaceMediaAction = typeof replaceImage | typeof replaceVideo;

export const replaceImage = createAsyncThunk<void, CaseAndImageFileUploadInput, ThunkConfig>(
  'caseDetail/image/replace',
  async ({ caze, image, files, onUploadProgress }, { dispatch }) => {
    const onError = (fileName: string) => dispatch(pushToast(ErrorToastFileIsMissingExtension(fileName)));
    try {
      dispatch(startImageUpload());
      files.forEach(ensureSupportedImageType(onError));

      if (files.length !== 1) {
        dispatch(caseDetailFailed('Kan bara ersätta bilder med exakt en bild'));
        return;
      }

      const result = await Api.replaceImage(caze.id, image.id, files[0], onUploadProgress);

      dispatch(getCaseSuccess(result));
      dispatch(updateCaseWithPersisted(result));
    } catch (error: any) {
      dispatch(caseDetailFailed(error.toString()));
    } finally {
      dispatch(endImageUpload());
    }
  },
);

export const replaceVideo = createAsyncThunk<void, CaseAndImageFileUploadInput, ThunkConfig>(
  'caseDetail/video/replace',
  async ({ caze, image, files, onUploadProgress }, { dispatch }) => {
    const onError = (fileName: string) => dispatch(pushToast(ErrorToastFileIsMissingExtension(fileName)));
    try {
      dispatch(startImageUpload());
      files.forEach(ensureSupportedVideoType(onError));

      if (files.length !== 1) {
        dispatch(caseDetailFailed('Kan bara ersätta filmer med exakt en film'));
        return;
      }

      const result = await Api.replaceVideo(caze.id, image.id, files[0], onUploadProgress);

      dispatch(getCaseSuccess(result));
      dispatch(updateCaseWithPersisted(result));
    } catch (error: any) {
      dispatch(caseDetailFailed(error.toString()));
    } finally {
      dispatch(endImageUpload());
    }
  },
);

type ImageArgument = { caze: Case; image: Image };

export const removeImage = createAsyncThunk<void, ImageArgument, ThunkConfig>(
  'caseDetail/image/remove',
  async ({ caze, image }, { dispatch }) => {
    try {
      const result = await Api.removeImage(caze.id, image.id);

      dispatch(getCaseSuccess(result));
      dispatch(updateCaseWithPersisted(result));
    } catch (error: any) {
      dispatch(caseDetailFailed(error.toString()));
    }
  },
);

export const removeAttachment = createAsyncThunk<void, { item: Case; attachment: Attachment }, ThunkConfig>(
  'caseDetail/attachment/remove',
  async ({ item, attachment }, { dispatch }) => {
    try {
      const result = await Api.removeAttachment(item.id, attachment.id, attachment.category);

      dispatch(getCaseSuccess(result));
      dispatch(updateCaseWithPersisted(result));
    } catch (error: any) {
      dispatch(caseDetailFailed(error.toString()));
    }
  },
);

interface UploadAttachmentArgs extends FileUploadInput {
  caze: Case;
  category: AttachmentCategory;
}

export const uploadAttachment = createAsyncThunk<void, UploadAttachmentArgs, ThunkConfig>(
  'caseDetail/attachment/upload',
  async ({ caze, files, category, onUploadProgress }, { dispatch }) => {
    dispatch(startAttachmentUpload());
    try {
      const result = await Api.uploadAttachment(caze.id, files, category, onUploadProgress);

      dispatch(getCaseSuccess(result));
      dispatch(updateCaseWithPersisted(result));
    } catch (error: any) {
      dispatch(caseDetailFailed(error.toString()));
    }
    dispatch(endAttachmentUpload());
  },
);

export const addComment = createAsyncThunk<void, { item: Case; comment: string }, ThunkConfig>(
  'caseDetail/comment/add',
  async ({ item, comment }, { dispatch }) => {
    try {
      const result = await Api.addComment(item.id, comment);

      dispatch(getCaseSuccess(result));
      dispatch(updateCaseWithPersisted(result));
    } catch (error: any) {
      dispatch(caseDetailFailed(error.toString()));
    }
  },
);

export const deleteComment = createAsyncThunk<void, { item: Case; commentId: string }, ThunkConfig>(
  'caseDetail/comment/remove',
  async ({ item, commentId }, { dispatch }) => {
    try {
      const result = await Api.deleteComment(item.id, commentId);

      dispatch(getCaseSuccess(result));
      dispatch(updateCaseWithPersisted(result));
    } catch (error: any) {
      dispatch(caseDetailFailed(error.toString()));
    }
  },
);

export const deleteRelation = createAsyncThunk<void, { item: Case; relation: CaseRelation }, ThunkConfig>(
  'caseDetail/relations/remove',
  async ({ item, relation }, { dispatch }) => {
    try {
      const result = await Api.deleteRelation(item.id, relation.caseId);

      dispatch(getCaseSuccess(result));
      dispatch(updateCaseWithPersisted(result));
    } catch (error: any) {
      dispatch(caseDetailFailed(error.toString()));
    }
  },
);

export const assignCaseOwner = createAsyncThunk<void, { caze: Case; userId: string }, ThunkConfig>(
  'caseDetail/assignOwner',
  async ({ caze, userId }, { dispatch }) => {
    try {
      const result = await Api.assignCaseOwner(caze, userId);

      dispatch(getCaseSuccess(result));
      dispatch(updateCaseWithPersisted(result));
    } catch (error: any) {
      dispatch(caseDetailFailed(error.toString()));
    }
  },
);

export const assignCasePlanner = createAsyncThunk<void, { caze: Case; userId: string }, ThunkConfig>(
  'caseDetail/assignPlanner',
  async ({ caze, userId }, { dispatch }) => {
    try {
      const result = await Api.assignCasePlanner(caze, userId);

      dispatch(getCaseSuccess(result));
      dispatch(updateCaseWithPersisted(result));
    } catch (error: any) {
      dispatch(caseDetailFailed(error.toString()));
    }
  },
);

export default caseDetailSlice.reducer;

const EnsureCaseValid = (enumerations: Enumerations, caze: Partial<Case>) => EnsureEnrichmentValid(enumerations, caze);

const EnsureEnrichmentValid = (enumerations: Enumerations, item: Partial<CaseEnrichmentHolder>) => {
  let isValid = true;

  if (!IsValidEnumerationValue(enumerations.photographers, item.photographer)) {
    item.photographer = undefined;
    isValid = false;
  }
  if (!IsValidEnumerationValue(enumerations.stylists, item.stylist)) {
    item.stylist = undefined;
    isValid = false;
  }

  return isValid;
};

const IsValidEnumerationValue = (options: EnumerationItem[], value: string | null | undefined) => {
  if (value == null) {
    return true;
  }

  return options.some(option => option.key === value);
};

const ErrorToastFileIsMissingExtension = (fileName: string): ToastNotification => ({
  message: `Filen ${fileName} saknar filändelse eller har en ogiltig filändelse.`,
  variant: 'error',
  persist: true,
  preventDuplicate: false,
});

export const selectCaseDetailItem = (state: RootState, id: string) => {
  const lookupEntry = Number(id);
  const isEntityId = isNaN(lookupEntry);

  const entityId = isEntityId ? id : state.cases.caseIdsByLookupEntry[lookupEntry];
  return state.cases.casesById[entityId];
};
