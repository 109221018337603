import { DerivedImageHolder } from '../api/caseApiTypes';

export type ThumbnailImageSize = 320 | 1920;

export const GetThumbnailUri = (holder: DerivedImageHolder | undefined, size: ThumbnailImageSize) => {
  const derivedImages = holder?.derivedImages;

  return derivedImages?.find(di => di.width === size && di.height === size && di.format === 'jpg' && di.dimensionUnit === 'px')?.uri;
};

export function GetPreviewVideoUri(item?: DerivedImageHolder) {
  if (!item) {
    return undefined;
  }
  const video = item.derivedImages.find(di => di.format === 'mp4');
  return video?.uri;
}

export function HasPreviewVideo(item?: DerivedImageHolder) {
  return GetPreviewVideoUri(item) != null;
}

export const HasThumbnail = (holder: DerivedImageHolder | undefined, size: ThumbnailImageSize) => GetThumbnailUri(holder, size) != null;
