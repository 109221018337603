import { useAppSelector } from '../../../hooks/useAppRedux';
import { RemoveFromClipbookIconButton } from '../../clipbooks/components/RemoveFromClipbookIconButton';
import { useSelectionContext } from './selectionContextContext';

export function RemoveSelectedFromClipbookIconButton() {
  const selectionContext = useSelectionContext();
  const selectedImages = useAppSelector(state => state.images.selected[selectionContext]);
  const selectedIds = selectedImages.map(m => m.id);
  const title = 'Ta bort valda bilder från den aktiva gruppen';
  return <RemoveFromClipbookIconButton imageIds={selectedIds} title={title} />;
}
