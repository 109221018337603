import React, { useState } from 'react';
import { Box, Grid, styled, Tooltip, Typography } from '@mui/material';

import styles from './CaseTitle.module.css';

import { Case, CaseTypeConverter } from '../../../api/caseApiTypes';
import { CaseSelectableText } from '../layoutComponents/CaseSelectableText';
import { CaseTextField } from './CaseTextField';
import { formatDateTime } from '../../date/DateFormatter';
import { assignCaseOwner, assignCasePlanner } from '../caseDetailSlice';
import { useAppDispatch } from '../../../hooks/useAppRedux';
import { useEitherRights } from '../../../hooks/useRights';
import { Right } from '../../../api/userApi';
import { CaseUserAssignmentModal } from './CaseUserAssignmentModal';
import {
  rememberLocalCaseAssignedOwner,
  rememberLocalCaseAssignedPlanner,
  removeLocalCaseAssignedOwner,
  removeLocalCaseAssignedPlanner,
  selectCaseOwners,
  selectCasePlanners,
} from '../../localUserStateSlice';
import { ShowPlanner } from '../forms/CaseFormLogic';

interface Props {
  caze: Case;
  isNewCase: boolean;
  onCaseTitleChange: (title: string) => void;
}

export const CaseTitle: React.FC<Props> = ({ caze, onCaseTitleChange, isNewCase }) => {
  const dispatch = useAppDispatch();
  const canUpdateUser = useEitherRights([Right.WriteCase, Right.Administration]) && !isNewCase;

  const id = caze.lookupEntry ?? caze.id;
  const title = id === '' ? '-' : id;
  const ownerTitle = [caze.ownerFullName, caze.ownerEmail, '-'].find(val => val != null && val !== '');
  const plannerTitle = [caze.plannerFullName, caze.plannerEmail, '-'].find(val => val != null && val !== '');

  const [openModal, setOpenModal] = useState<'set-owner' | 'set-planner' | 'none'>('none');

  const setOwner = () => {
    if (canUpdateUser) {
      setOpenModal('set-owner');
    }
  };
  const setPlanner = () => {
    if (canUpdateUser) {
      setOpenModal('set-planner');
    }
  };
  const closeModal = () => {
    setOpenModal('none');
  };

  const updateOwner = (userId: string, fullName: string) => {
    dispatch(rememberLocalCaseAssignedOwner({ id: userId, fullName }));
    dispatch(assignCaseOwner({ caze, userId }));
    closeModal();
  };

  const updatePlanner = (userId: string, fullName: string) => {
    dispatch(rememberLocalCaseAssignedPlanner({ id: userId, fullName }));
    dispatch(assignCasePlanner({ caze, userId }));
    closeModal();
  };

  const onRemoveLocallySavedOwner = (userId: string) => {
    dispatch(removeLocalCaseAssignedOwner({ id: userId }));
  };
  const onRemoveLocallySavedPlanner = (userId: string) => {
    dispatch(removeLocalCaseAssignedPlanner({ id: userId }));
  };

  const showPlanner = ShowPlanner(caze);

  return (
    <Grid className={styles.container} container justifyContent="space-between">
      <Grid item className={styles.titleItem}>
        <Typography variant="body2">
          {isNewCase ? 'Ny beställning - ' : ''}
          {CaseTypeConverter.ToName(caze.type)}
        </Typography>
        <InputWrapper width="100%">
          <CaseTextField className={styles.titleInput} label="Titel" value={caze.title} setValue={onCaseTitleChange} naked required />
        </InputWrapper>
        {caze.id != null && (
          <CaseSelectableText label="ID" className={styles.caseInfo}>
            <Typography variant="body2">{title}</Typography>
          </CaseSelectableText>
        )}
        <div onClick={setOwner} className={canUpdateUser ? styles.setAssignedUser : ''}>
          <CaseSelectableText label="Beställare" className={styles.caseInfo}>
            <Typography variant="body2">{ownerTitle}</Typography>
          </CaseSelectableText>
        </div>
        <CaseUserAssignmentModal
          isVisible={openModal === 'set-owner'}
          onClose={closeModal}
          type="Beställare"
          onSaveUser={updateOwner}
          onRemoveLocallySavedUser={onRemoveLocallySavedOwner}
          selectDefaultUsers={selectCaseOwners}
          value={caze.ownerFullName}
        />
        {showPlanner && (
          <div onClick={setPlanner} className={canUpdateUser ? styles.setAssignedUser : ''}>
            <CaseSelectableText label="Produktionsplanerare" className={styles.caseInfo}>
              <Typography variant="body2">{plannerTitle}</Typography>
            </CaseSelectableText>
          </div>
        )}
        <CaseUserAssignmentModal
          isVisible={openModal === 'set-planner'}
          onClose={closeModal}
          type="Produktionsplanerare"
          onSaveUser={updatePlanner}
          onRemoveLocallySavedUser={onRemoveLocallySavedPlanner}
          selectDefaultUsers={selectCasePlanners}
          value={caze.plannerFullName}
        />
      </Grid>
      <Grid item className={styles.titleItem}>
        <Box className={styles.caseInfo} display="flex" flexDirection="column" alignItems="flex-end">
          <Tooltip placement={'right'} title={`Skapad av ${caze.createdByFullName}`}>
            <Typography variant="body2">Skapad: {formatDateTime(caze.created)}</Typography>
          </Tooltip>
          <Tooltip placement={'right'} title={`Senast ändrad av ${caze.modifiedByFullName}`}>
            <Typography variant="body2">Ändrad: {formatDateTime(caze.modified)}</Typography>
          </Tooltip>
        </Box>
      </Grid>
    </Grid>
  );
};

const InputWrapper = styled(Box)(({ theme }) => {
  const borderColor = theme.palette.mode === 'light' ? 'black' : 'white';
  return {
    '*': {
      width: '100%',
    },
    '.Mui-focused, >:hover': {
      borderBottom: `1px solid ${borderColor}`,
    },
  };
});
