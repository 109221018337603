import React, { Fragment, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Avatar,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { sv } from 'date-fns/locale';

import styles from './Comments.module.css';

import { Comment } from '../../api/caseApiTypes';
import { Right } from '../../api/userApi';
import { confirmDelete } from '../../helpers/confirmDeleteHelper';
import { useAppSelector } from '../../hooks/useAppRedux';
import { useRights } from '../../hooks/useRights';
import { TextBoxWithMention } from './TextBoxWithMention';

interface Props {
  className?: string;
  comments?: Comment[];
  onAdd: (comment: string) => void;
  onDelete: (commentId: string) => void;
}

const getAuthor = (comment: Comment): string => comment.authorEmail ?? 'Okänd användare';

const getTimestamp = (comment: Comment): string => comment.timestamp && format(new Date(comment.timestamp), 'PPp', { locale: sv });

const getAvatar = (): JSX.Element => <Avatar alt="avatar" />;

// finds patterns matching @[name](id) and replaces it with @name
function formatMentions(comment: Comment) {
  return comment.content.replace(/@\[([\w\såäöÅÄÖ]+)]\([\w-]+\)/g, '@$1');
}

export const Comments: React.FC<Props> = ({ className, comments, onAdd, onDelete }) => {
  const canAdd = useRights(Right.Comment);
  const isAdmin = useRights(Right.Administration);

  const [addCommentExpanded, setExpanded] = useState(false);
  const [commentContent, setCommentContent] = useState('');

  const user = useAppSelector(state => state.app.user);

  const handleAddComment = () => {
    onAdd(commentContent);
    reset();
  };

  const reset = () => {
    setCommentContent('');
    setExpanded(false);
  };

  const getAddCommentBox = (): JSX.Element => {
    return (
      <ListItemText
        primary={
          <>
            <TextBoxWithMention commentContent={commentContent} setCommentContent={setCommentContent} />
            <div className={styles.buttons}>
              <Button size="small" color="primary" onClick={() => setExpanded(false)}>
                Stäng
              </Button>
              <Button
                className={styles.save}
                type="submit"
                variant="contained"
                color="primary"
                disabled={!commentContent}
                onClick={handleAddComment}
              >
                Lägg till
              </Button>
            </div>
          </>
        }
      />
    );
  };

  const getAddCommentButton = (): JSX.Element => {
    return (
      <div className={styles.action}>
        <Button size="small" onClick={() => setExpanded(true)}>
          Ny Kommentar
        </Button>
      </div>
    );
  };

  const getComment = (comment: Comment, index: number) => {
    const renderDivider = index !== comments!.length - 1 || canAdd;

    const author = getAuthor(comment);

    const canDelete = isAdmin || (user != null && author === user.email);

    return (
      <Fragment key={comment.id}>
        <ListItem key={comment.id} alignItems="flex-start">
          <ListItemAvatar>{getAvatar()}</ListItemAvatar>
          <ListItemText
            className={styles.commentContent}
            primary={<Typography variant="subtitle2">{author}</Typography>}
            secondary={
              <>
                {getTimestamp(comment)}
                <Typography component="span" variant="body2" className={styles.commentContent} color="textPrimary">
                  {` - ${formatMentions(comment)}`}
                </Typography>
              </>
            }
          />
          {canDelete && (
            <ListItemSecondaryAction className={styles.deleteButtonContainer}>
              <IconButton edge="end" aria-label="delete" onClick={confirmDelete(() => onDelete(comment.id), 'kommentar')}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
        {renderDivider && <Divider />}
      </Fragment>
    );
  };

  return (
    <div className={className}>
      <List>
        {comments?.map(getComment)}
        {canAdd && (
          <Fragment key="add-comment">
            <ListItem key="add-comment" alignItems="flex-start">
              <ListItemAvatar>
                <Avatar>
                  <AddIcon />
                </Avatar>
              </ListItemAvatar>
              {addCommentExpanded ? getAddCommentBox() : getAddCommentButton()}
            </ListItem>
          </Fragment>
        )}
      </List>
    </div>
  );
};
