// react-table type definitions are community-provided...
// useTable does take care of generating they key prop
// but the linting rule fails to evaluate it correctly

/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import React from 'react';
import { Column } from 'react-table';

import styles from './CasesTable.module.css';

import { CaseStateConverter, CaseTypeConverter, ColoredTag, SearchCaseBase, UserTag } from '../../api/caseApiTypes';
import { ColorTag } from '../tags/ColorTag';
import { SelectableUserTag } from '../tags/SelectableUserTag';
import { formatDate } from '../date/DateFormatter';

export enum ColumnEnum {
  State = 'state',
  Type = 'type',
  Title = 'title',
  OwnerName = 'ownerName',
  RetouchedByFullName = 'retouchedByFullName',
  Project = 'project',
  Tags = 'tags',
  Created = 'created',
  Modified = 'modified',
  ProductionDeadline = 'productionDeadline',
  ApprovalDeadline = 'approvalDeadline',
}

const renderColoredTag = (tag?: ColoredTag, index?: number) =>
  tag == null ? undefined : <ColorTag key={index} hex={tag.color} label={tag.name} size="small" />;

const renderUserTags = (userTags: UserTag[]) => userTags.map((tag, index) => <SelectableUserTag key={index} userTag={tag} size="small" />);

const noWrap = (value: string) => (
  <div className={styles.noWrap} title={value}>
    {value}
  </div>
);

export const ColumnDefinitions: Record<ColumnEnum, Column<SearchCaseBase>> = {
  state: {
    Header: 'Beställningsstatus',
    accessor: 'state',
    Cell: ({ value }) => noWrap(CaseStateConverter.ToText(value)),
  },
  type: {
    Header: 'Beställningstyp',
    accessor: 'type',
    Cell: ({ value }) => <>{CaseTypeConverter.ToName(value)}</>,
  },
  title: {
    Header: 'Titel',
    // @ts-ignore: flawed definitions in react table
    disableSortBy: true,
    accessor: ({ title, productName }: any) => ({ title, productName }),
    Cell: ({ value: { title, productName } }: any) => noWrap(title ?? productName),
  },
  ownerName: {
    Header: 'Beställare',
    // @ts-ignore: flawed definitions in react table
    accessor: 'ownerFullName',
    Cell: ({ value }: any) => noWrap([value, '-'].find(val => val != null && val !== '')),
  },
  retouchedByFullName: {
    Header: 'Retuschör',
    accessor: 'retouchedByFullName',
    // @ts-ignore: flawed definitions in react table
    Cell: ({ value }: any) => noWrap(value),
  },
  project: {
    Header: 'Projekt',
    accessor: 'projectTag',
    // @ts-ignore: flawed definitions in react table
    disableSortBy: true,
    Cell: ({ value }) => <>{renderColoredTag(value)}</> ?? <></>,
  },
  tags: {
    Header: 'Taggar',
    accessor: ({ systemTags, userTags }) => ({ systemTags, userTags }),
    // @ts-ignore: flawed definitions in react table
    disableSortBy: true,
    Cell: ({ value: { systemTags, userTags } }: any) => (
      <div className={styles.chips}>
        {systemTags.map(renderColoredTag)}
        {renderUserTags(userTags)}
      </div>
    ),
  },
  created: {
    Header: 'Skapad',
    accessor: 'created',
    Cell: ({ value }) => noWrap(formatDate(value)),
    // @ts-ignore: flawed definitions in react table
    sortDescFirst: true,
  },
  modified: {
    Header: 'Ändrad',
    accessor: 'modified',
    Cell: ({ value }) => noWrap(formatDate(value)),
    // @ts-ignore: flawed definitions in react table
    sortDescFirst: true,
  },
  productionDeadline: {
    Header: 'Prod. deadline',
    accessor: 'photoAndRetouchDeadline',
    Cell: ({ value }) => noWrap(formatDate(value)),
    // @ts-ignore: flawed definitions in react table
    sortDescFirst: true,
  },
  approvalDeadline: {
    Header: 'Godk. deadline',
    accessor: 'approvalDeadline',
    Cell: ({ value }) => noWrap(formatDate(value)),
    // @ts-ignore: flawed definitions in react table
    sortDescFirst: true,
  },
};
