import { Typography, Stack, Box, Tooltip, IconButton } from '@mui/material';
import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/useAppRedux';
import { imageHasPendingChanges, isImageSelected, setImageActiveVersion, toggleSelectedMedia } from '../../images/imagesSlice';
import { ImageCardVersionDetails } from '../ImageCardVersionDetails';
import { Image } from '../../../api/caseApiTypes';
import styles from './ImageCardHeader.module.css';
import { ImageCardSelectButton } from './ImageCardSelectButton';
import { Bookmark, SaveAs } from '@mui/icons-material';
import { imageIsInActiveClipbook, removeImagesFromClipbook } from '../../clipbooks/clipbooksSlice';
import { useSelectionContext } from '../../images/selectedImagesContext/selectionContextContext';

export type ImageCardHeaderProps = {
  image: Image | undefined;
  displayedVersion: string | undefined;
  disableInteraction: boolean | undefined;
  onImageActiveVersionChange?: (imageId: string, activeVersion: string) => void;
};

export function ImageCardHeader({ image, onImageActiveVersionChange, displayedVersion, disableInteraction }: ImageCardHeaderProps) {
  const dispatch = useAppDispatch();
  const selectionContext = useSelectionContext();
  const removeFromClipbook = () => {
    if (window.confirm('Är du säker på att du vill ta bort bilden från aktiva gruppen?') === false) {
      return;
    }
    dispatch(removeImagesFromClipbook({ imageIds: [image?.id || ''] }));
  };
  const isSelected = useAppSelector(isImageSelected(selectionContext, image?.id || ''));
  const hasUnsavedChanges = useAppSelector(imageHasPendingChanges(image?.id));
  const isInActiveClipbook = useAppSelector(imageIsInActiveClipbook(image?.id));
  const toggleSelected = () => {
    if (image) {
      dispatch(toggleSelectedMedia({ selectionContext, media: image }));
    }
  };

  const onActiveVersionChange = useCallback(
    async (activeVersion: string) => {
      if (image?.id != null) {
        const imageId = image.id;
        if (onImageActiveVersionChange) {
          await onImageActiveVersionChange(imageId, activeVersion);
        } else {
          await dispatch(setImageActiveVersion({ imageId, activeVersion }));
        }
      }
    },
    [dispatch, image?.id, onImageActiveVersionChange],
  );

  const productNameTitle = `Produktnamn: ${image?.productName}`;
  const productName = (
    <Typography gutterBottom variant="subtitle2" component="p" title={productNameTitle} className={styles.cardHeader}>
      {image?.productName}
    </Typography>
  );

  const imageVersionDetails = image && (
    <ImageCardVersionDetails
      image={image}
      displayedVersion={displayedVersion}
      disableInteraction={disableInteraction}
      onActiveVersionChange={onActiveVersionChange}
    />
  );

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      {productName}
      {imageVersionDetails}
      {<Box sx={{ flexGrow: 1 }} />}
      {hasUnsavedChanges && (
        <Tooltip title="Bilden har osparade förändringar">
          <SaveAs color={'secondary'} />
        </Tooltip>
      )}
      {isInActiveClipbook && (
        <Tooltip title="Ta bort bilden från aktiva gruppen">
          <IconButton onClick={removeFromClipbook} color="primary" style={{ opacity: '0.7', padding: 0 }}>
            <Bookmark />
          </IconButton>
        </Tooltip>
      )}
      {<ImageCardSelectButton isSelected={isSelected} onClick={toggleSelected} />}
    </Stack>
  );
}
