import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { endOfWeek, startOfWeek } from 'date-fns';

import styles from './CaseCalendarPlan.module.css';

import { CaseType, StateEnum } from '../../api/caseApiTypes';
import { FilterableCase, FilterState } from '../../api/filterTypes';
import { Right } from '../../api/userApi';
import { SearchQuery } from '../../commonComponents/SearchQuery';
import { useAppDispatch, useAppSelector } from '../../hooks/useAppRedux';
import { useEitherRights } from '../../hooks/useRights';
import { SearchContext } from '../../hooks/useSearchState';
import { SessionStorageKeys, useSessionStorage } from '../../hooks/useStorage';
import { useUserFilters } from '../../hooks/useUserFilters';
import { Calendar } from '../calendar/Calendar';
import { CasePropClauseResolverConfig } from '../sidebar/filter/propClauseResolver/resolverConfigs';
import { SearchFilters, SearchFilterTranslator } from '../sidebar/filter/searchFilterTranslator';
import { PlannableCasesList } from './PlannableCasesList';
import { search } from './casesSlice';
import { useSearchParams } from 'react-router-dom';
import { formatDate } from '../date/DateFormatter';
import { QueryParam } from '../../App';

export const CalendarSupportedTypes = [CaseType.ContextualPhoto, CaseType.InstallationPhoto, CaseType.Video];

export const CalendarSupportedStates = [StateEnum.planned, StateEnum.inProgress, StateEnum.reviewPending];

const translator = new SearchFilterTranslator<FilterableCase>(CasePropClauseResolverConfig);

const plannableCasesFilters: SearchFilters<FilterableCase> = {
  defaultFilters: {
    type: { value: CalendarSupportedTypes.map(type => type.toString()) },
  },
};

const planningCalendarFilters = {
  defaultFilter: {
    type: { value: CalendarSupportedTypes.map(type => type.toString()) },
  },
  requiredFilters: {
    Case_CaseState: { value: [StateEnum.confirmed].map(state => state.toString()) },
  },
};

const adjustCalendarFilterWithDates = (
  filterState: FilterState<FilterableCase>,
  startDate: string,
  endDate: string,
): FilterState<FilterableCase> => {
  return {
    ...filterState,
    plannedStart: { value: [startDate] },
    plannedEnd: { value: [endDate] },
  };
};

const adjustPlannableCasesFilterWithDates = (
  filterState: FilterState<FilterableCase>,
  startDate: string,
  endDate: string,
): FilterState<FilterableCase> => {
  return {
    ...filterState,
    plannedStart: { value: [startDate], includeNull: true },
    plannedEnd: { value: [endDate], includeNull: true },
  };
};

const ensureFilterContainsAllApplicableStates = (filterState: FilterState<FilterableCase>): FilterState<FilterableCase> => {
  return {
    ...filterState,
    Case_CaseState: { value: [...CalendarSupportedStates, StateEnum.confirmed].map(state => state.toString()) },
  };
};

export const CaseCalendarPlan = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const startDateParam = searchParams.get(QueryParam.StartDate);
  const userFilters = useUserFilters(SearchContext.PlanningCalendar);
  const { isSearching } = useAppSelector(state => state.cases);

  const canPlan = useEitherRights([Right.PlanCalendar, Right.Administration]);
  const dispatch = useAppDispatch();

  const [query, setQuery] = useSessionStorage(SessionStorageKeys.searchQuery, '');

  const monday = 1;
  const [startDate, setStartDate] = useState(
    startOfWeek(startDateParam ?? Date.now(), {
      weekStartsOn: monday,
    }).toISOString(),
  );
  const [endDate, setEndDate] = useState(
    endOfWeek(startDateParam ?? Date.now(), {
      weekStartsOn: monday,
    }).toISOString(),
  );

  useEffect(() => {
    if (userFilters) {
      dispatch(
        search({
          query,
          orderBy: [{ id: 'photoAndRetouchDeadline', desc: false }],
          filterTree: translator.getClauses({
            ...plannableCasesFilters,
            userFilters: adjustPlannableCasesFilterWithDates(ensureFilterContainsAllApplicableStates(userFilters), startDate, endDate),
          }),
          searchContext: SearchContext.PlannableCasesList,
        }),
      );
    }
  }, [dispatch, query, userFilters, endDate, startDate]);

  useEffect(() => {
    if (userFilters) {
      dispatch(
        search({
          filterTree: translator.getClauses({
            ...planningCalendarFilters,
            userFilters: adjustCalendarFilterWithDates(userFilters, startDate, endDate),
          }),
          searchContext: SearchContext.PlanningCalendar,
        }),
      );
    }
  }, [dispatch, userFilters, startDate, endDate]);

  const moveTo = (start: Date, end: Date) => {
    if (start >= new Date(startDate) && end <= new Date(endDate)) {
      /*
       * In case the time boundaries have not expanded,
       * we do not have to search for new cases.
       */
      return;
    }

    setSearchParams({ startDate: formatDate(start) });
    setStartDate(start.toISOString());
    setEndDate(end.toISOString());
  };

  return (
    <>
      <Box className={styles.container}>
        {canPlan && (
          <Box className={styles.searchPanel}>
            <Box className={styles.header}>
              <SearchQuery className={styles.search} onSearch={value => setQuery(value)} isSearching={isSearching} />
            </Box>
            <PlannableCasesList />
          </Box>
        )}
        <Box className={styles.calendar}>
          <Calendar start={startDate} onChangedTimeWindow={timeSpan => moveTo(timeSpan.start, timeSpan.end)} readonly={!canPlan} />
        </Box>
      </Box>
    </>
  );
};
