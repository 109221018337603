import { HubConnectionBuilder, LogLevel, HubConnection } from '@microsoft/signalr';

const isDev = process.env.NODE_ENV === 'development';

const hubs: Record<string, HubConnection> = {};

const startSignalRConnection = async (connection: HubConnection) => {
  try {
    await connection.start();
  } catch (err) {
    console.error('SignalR Connection Error: ', err);
    setTimeout(() => startSignalRConnection(connection), 5000);
  }
};

export const createSignalRConnection = async (hubName: string) => {
  if (hubName in hubs) {
    return hubs[hubName];
  }

  const options = {
    logMessageContent: isDev,
    logger: isDev ? LogLevel.Information : LogLevel.Error,
  };

  const connection = new HubConnectionBuilder()
    .withUrl(`/api/hub/${hubName}`, options)
    .withAutomaticReconnect()
    .configureLogging(options.logger)
    .build();

  await startSignalRConnection(connection);

  hubs[hubName] = connection;

  return connection;
};
