import React from 'react';

import { Typography } from '@mui/material';

import { Article, Attachment, AttachmentCategory, Case, DiningSetCase } from '../../../api/caseApiTypes';
import { Right } from '../../../api/userApi';
import { CaseArticles } from '../CaseArticles';
import { CaseAttachments } from '../CaseAttachments';
import { CaseDescriptionField } from '../formFieldComponents/CaseDescriptionField';
import { CaseExpansion } from '../layoutComponents/CaseExpansion';
import { CaseDetailEnrichmentSubForm } from './CaseDetailEnrichmentSubForm';

interface Props {
  caze: DiningSetCase;
  isNewCase?: boolean;
  readonly?: boolean;
  onChange: (partial: Partial<Case>) => void;
  onUpsertArticle?: (article: Article) => void;
  onUpsertManyArticles?: (articles: Article[]) => void;
  onDeleteArticle?: (article: Article) => void;
  onDeleteAttachment: (attachment: Attachment) => void;
}

export const DiningSetCaseDetailFormFields: React.FC<Props> = ({
  caze,
  isNewCase,
  readonly,
  onChange,
  onUpsertArticle,
  onUpsertManyArticles,
  onDeleteArticle,
  onDeleteAttachment,
}) => {
  return (
    <>
      <Typography variant="h6">Artiklar</Typography>
      <CaseArticles
        pluralVerb="artiklar"
        articles={caze.articles}
        onAddMany={onUpsertManyArticles}
        onUpsert={onUpsertArticle}
        onDelete={onDeleteArticle}
        isNewCase={isNewCase}
        readonly={readonly}
        renderExtraInfo
        maxArticles={7}
      />

      <Typography variant="h5">Beställningsinformation</Typography>

      <CaseDescriptionField description={caze.description} onChange={v => onChange({ description: v })} readonly={readonly} />

      <CaseDetailEnrichmentSubForm caze={caze} onChange={onChange} />

      <CaseExpansion title="Bilagor" hidden={isNewCase} compact>
        <CaseAttachments
          caze={caze}
          onDelete={onDeleteAttachment}
          category={AttachmentCategory.SupplementaryInformation}
          right={Right.WriteCase}
        />
      </CaseExpansion>
    </>
  );
};
