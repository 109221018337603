import React from 'react';

import { Article, Attachment, AttachmentCategory, Case, ContextualPhotoCase, InstallationPhotoCase } from '../../../api/caseApiTypes';
import { CaseArticles } from '../CaseArticles';
import { CaseDescriptionField } from '../formFieldComponents/CaseDescriptionField';
import { CaseDimensions } from '../formFieldComponents/CaseDimensions';
import { CaseTextField } from '../formFieldComponents/CaseTextField';
import { CaseDetailEnrichmentSubForm } from './CaseDetailEnrichmentSubForm';
import { CaseFormSection } from './CaseFormSection';
import { CaseAttachments } from '../CaseAttachments';
import { Right } from '../../../api/userApi';
import { CaseImageRequestForm } from './CaseImageRequestForm';

type TCase = ContextualPhotoCase | InstallationPhotoCase;

interface Props {
  caze: TCase;
  isNewCase?: boolean;
  readonly?: boolean;
  onChange: (partial: Partial<Case>) => void;
  onUpsertArticle?: (article: Article) => void;
  onUpsertManyArticles?: (articles: Article[]) => void;
  onDeleteArticle?: (article: Article) => void;
  onDeleteAttachment: (attachment: Attachment) => void;
}

export const PhotoCaseDetailFormFields: React.FC<Props> = ({
  caze,
  isNewCase,
  readonly,
  onChange,
  onUpsertArticle,
  onUpsertManyArticles,
  onDeleteArticle,
  onDeleteAttachment,
}) => {
  return (
    <>
      <CaseFormSection caseId={caze.id} title="Artiklar" description="Dessa artiklar ska vara med i bilderna">
        <CaseArticles
          articles={caze.articles}
          onUpsert={onUpsertArticle}
          onAddMany={onUpsertManyArticles}
          onDelete={onDeleteArticle}
          isNewCase={isNewCase}
          readonly={readonly}
          renderExtraInfo
        />
      </CaseFormSection>

      <CaseFormSection
        caseId={caze.id}
        title="Beställningsinformation"
        description="Beskriv dina önskemål för bilderna ex. Kampanj, målgrupp, stil och viktig info"
      >
        <CaseDescriptionField description={caze.description} onChange={v => onChange({ description: v })} readonly={readonly} />
        {caze.brief && caze.brief !== '' && (
          <CaseTextField
            variant="outlined"
            multiline
            label="Brief"
            name="brief"
            value={caze.brief || ''}
            minRows={3}
            setValue={brief => onChange({ brief })}
            disabled={readonly}
          />
        )}
        <CaseDimensions caze={caze} onChange={onChange} readonly={readonly} />
        <CaseAttachments
          caze={caze}
          onDelete={onDeleteAttachment}
          category={AttachmentCategory.SupplementaryInformation}
          right={Right.WriteCase}
          isNewCase={isNewCase}
        />
      </CaseFormSection>

      <CaseImageRequestForm caze={caze} />
      <CaseDetailEnrichmentSubForm caze={caze} onChange={onChange} />
    </>
  );
};
