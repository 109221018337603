import React, { useMemo, useState } from 'react';

import { Close } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, IconButton, Tab } from '@mui/material';

import { Right } from '../../../api/userApi';
import { useRights } from '../../../hooks/useRights';
import { ImageEnrichmentForm } from '../../enrichment/ImageEnrichmentForm';
import { MediaPreview } from './mediaPreview/MediaPreview';
import { useAppSelector } from '../../../hooks/useAppRedux';
import { useSelectionContext } from '../selectedImagesContext/selectionContextContext';
import { selectActiveClipbook } from '../../clipbooks/clipbooksSlice';
import { useGetImages } from '../../clipbooks/hooks';

export type ImageGridDetailViewImageSource = 'selected' | 'clipbook';

interface Props {
  onClose: () => void;
  imageSource?: ImageGridDetailViewImageSource | null;
}

export const ImagesGridDetailsView: React.FC<Props> = ({ onClose, imageSource = 'selected' }) => {
  const [selectedImageTab, setSelectedImageTab] = useState('0');
  const hasImageEditingAccess = useRights(Right.ManageImage);

  const selectionContext = useSelectionContext();
  const selectedImages = useAppSelector(state => state.images.selected[selectionContext]);

  const activeClipbook = useAppSelector(selectActiveClipbook);
  const clipbookImages = useGetImages(activeClipbook?.images ?? []);

  const { images, title } = useMemo(() => {
    switch (imageSource) {
      case 'selected':
        return { images: selectedImages, title: selectedImages.length > 1 ? 'Gruppinformation' : 'Bildinformation' };
      case 'clipbook':
        return { images: clipbookImages, title: activeClipbook?.name ?? '' };
      case null:
        return { images: [], title: '' };
    }
  }, [imageSource, selectedImages, clipbookImages, activeClipbook]);

  if (images.length === 0) {
    return <></>;
  }

  const tabEnrichmentText = images.length > 1 ? 'Gruppberikning' : 'Berikning';
  return (
    <Box>
      <TabContext value={selectedImageTab}>
        <TabList onChange={(_, newValue) => setSelectedImageTab(newValue)}>
          <Tab label={title} value="0" />
          {hasImageEditingAccess && <Tab label={tabEnrichmentText} value="1" />}
          <IconButton onClick={() => onClose()} size="small" sx={{ position: 'absolute', right: 10, top: 10 }}>
            <Close />
          </IconButton>
        </TabList>
        <TabPanel value="0">
          <MediaPreview media={images} />
        </TabPanel>
        <TabPanel value="1">
          <ImageEnrichmentForm media={images} />
        </TabPanel>
      </TabContext>
    </Box>
  );
};
