export type MediaValue<T> = {
  imageId: string;
  value: T;
};

export function getMediaValue<T, TMixed>(
  values: MediaValue<T>[] | undefined,
  noValuesDefault: T,
  mixedValuesDefault: T | TMixed,
  areEqual?: (v0: T, v1: T) => boolean,
): T | TMixed | undefined {
  if (values == null || values.length === 0) return noValuesDefault;

  const equalCompare = areEqual || ((v0, v1) => v0 === v1);

  if (values.every(v => equalCompare(v.value, values[0].value))) {
    return values[0].value;
  }
  return mixedValuesDefault;
}

export function makeMediaValue<T>(value: T, imageId?: string): MediaValue<T>[] {
  return [
    {
      imageId: imageId || '',
      value,
    },
  ];
}
