import React, { useMemo, useState } from 'react';
import { AppBar, Button, IconButton, Toolbar, Typography, useTheme } from '@mui/material';
import {Brightness4, Brightness7, Search, Settings, ViewInArTwoTone} from '@mui/icons-material';

import { M3SearchModal } from '../m3Search/M3SearchDialog';

import styles from './Header.module.css';
import logo from '../../images/logo.svg';

import { Right, User } from '../../api/userApi';
import { useEitherRights } from '../../hooks/useRights';
import { AdminPanel } from '../admin/AdminPanel';
import { useEnvironment } from '../../hooks/useEnvironment';

interface Props {
  user: User | null;
  darkModeEnabled: boolean;
  toggleDarkMode: () => void;
}

export const Header: React.FC<Props> = ({ user, darkModeEnabled, toggleDarkMode }) => {
  const environment = useEnvironment();
  const canWriteTags = useEitherRights([Right.Administration, Right.WriteTags]);
  const { palette, zIndex } = useTheme();
  const [administrationOpen, setAdministrationOpen] = useState(false);
  const [m3SearchModalIsVisible, setM3SearchModalIsVisible] = useState(false);

  const paletteColour = palette.header ?? palette.primary;
  const backgroundColor = paletteColour.main;

  const ModeIcon = darkModeEnabled ? Brightness7 : Brightness4;
  const modeText = (darkModeEnabled ? 'Avaktivera' : 'Aktivera') + ' dark mode';
  const contrastTextColour = paletteColour.contrastText;

  const contrastTextIsDark = useMemo(() => {
    const match = contrastTextColour?.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);
    if (!Array.isArray(match) || match.length < 3) return false;

    const { r, g, b } = {
      r: Number(match[1]),
      g: Number(match[2]),
      b: Number(match[3]),
    };

    return r < 30 && g < 30 && b < 30;
  }, [contrastTextColour]);

  const signOut = () => (window.location.href = '/user/logout');

  let env = null;
  if (window.location.hostname === 'localhost') {
    env = 'DEV';
  } else if (window.location.hostname.includes('test')) {
    env = 'TEST';
  } else if (window.location.hostname.includes('qa')) {
    env = 'QA';
  }

  const logoStyle: React.CSSProperties = {
    filter: contrastTextIsDark ? 'invert(100%)' : undefined,
  };

  let subDomain3DPortal = environment === 'prod' ? 'sortiment' : 'sortiment-test';
  const open3dPortal = () => window.open(`https://${subDomain3DPortal}.mio.photo/?portal=true`, '_blank');

  return (
    <AppBar className={styles.container} position="fixed" style={{ zIndex: zIndex.drawer + 1, backgroundColor }}>
      <Toolbar variant="dense" className={styles.toolbar} style={{ color: contrastTextColour }}>
        <div className={styles.left}>
          <img src={logo} alt="Mio Mediaportal" className={styles.logo} style={logoStyle} />
          {env && (
            <Typography>
              <strong>{env}</strong>
            </Typography>
          )}
        </div>

        <div className={styles.user}>
          <IconButton onClick={open3dPortal} title="Öppna 3D portalen">
            <ViewInArTwoTone style={{ color: contrastTextColour }} />
          </IconButton>
          <IconButton onClick={() => setM3SearchModalIsVisible(true)} title="Sök artikel">
            <Search style={{ color: contrastTextColour }} />
          </IconButton>
          <IconButton onClick={toggleDarkMode} title={modeText}>
            <ModeIcon style={{ color: contrastTextColour }} />
          </IconButton>
          {canWriteTags && (
            <IconButton onClick={() => setAdministrationOpen(!administrationOpen)}>
              <Settings style={{ color: contrastTextColour }} />
            </IconButton>
          )}
          <div className={styles.name}>{user?.fullName || user?.email || user?.id || 'null'}</div>
          <Button color="inherit" onClick={() => signOut()}>
            Logga Ut
          </Button>
        </div>
      </Toolbar>
      <AdminPanel isVisible={administrationOpen} onClose={() => setAdministrationOpen(!administrationOpen)} />
      <M3SearchModal selectMultiple={false} isVisible={m3SearchModalIsVisible} onClose={() => setM3SearchModalIsVisible(false)} />
    </AppBar>
  );
};
