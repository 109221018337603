import { useAppSelector } from './useAppRedux';
import { Right } from '../api/userApi';

const AllRights = [
  Right.ReadCase,
  Right.WriteCase,
  Right.Administration,
  Right.ConfirmCase,
  Right.CloseCase,
  Right.ManageImage,
  Right.Comment,
  Right.WriteTags,
];

export const useRightsFn = (either = false) => {
  // Until fully implemented in backend, pretend all users have all rights.
  const scopes = useAppSelector(state => state.app.user?.rights) ?? AllRights;

  return (rights: Right | Right[] | undefined): boolean => {
    if (!Array.isArray(rights)) {
      if (!rights) {
        return true;
      }
      return scopes.includes(rights);
    }

    if (either) {
      return rights.some(r => scopes.includes(r));
    } else {
      return rights.every(r => scopes.includes(r));
    }
  };
};

export const useRights = (rights: Right | Right[] | undefined, either = false) => {
  const getRight = useRightsFn(either);
  return getRight(rights);
};

export const useEitherRights = (rights: Right[]) => useRights(rights, true);
