import React from 'react';

import { Clear, Search } from '@mui/icons-material';
import { InputAdornment, CircularProgress, IconButton } from '@mui/material';

import styles from './SearchQuery.module.css';

import { CaseTextField } from '../features/cases/formFieldComponents/CaseTextField';
import { SessionStorageKeys, useSessionStorage } from '../hooks/useStorage';

interface Props {
  className?: string;
  onSearch: (query: string) => void;
  isSearching: boolean;
}

export const SearchQuery: React.FC<Props> = ({ onSearch, className, isSearching }) => {
  const [query, setQuery] = useSessionStorage(SessionStorageKeys.searchQuery, '');

  const submit = (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleChange = (value: string) => {
    setQuery(value);
    onSearch(value);
  };

  const classes = [styles.container, className].filter(s => s).join(' ');

  return (
    <div className={classes}>
      <CaseTextField
        className={styles.searchBar}
        aria-label="Query"
        value={query}
        setValue={value => handleChange(value)}
        autoFocus={true}
        label={'Sök'}
        onSubmit={submit}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start" className={styles.wrapper}>
              <IconButton onClick={_ => handleChange('')}>
                <Clear />
              </IconButton>
              <Search />
              {isSearching && <CircularProgress size={30} className={styles.loading} />}
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};
