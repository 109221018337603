import { Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import styles from './GenericSupplierDesignFields.module.css';

import { EnumerationItem, Enumerations, GenericSupplierDesign } from '../../../api/caseApiTypes';
import { useAppSelector } from '../../../hooks/useAppRedux';
import { CaseTextField } from './CaseTextField';
import { CaseField } from '../layoutComponents/CaseField';
import { MediaValue, getMediaValue } from './MediaValue';

interface Props {
  item?: MediaValue<GenericSupplierDesign | undefined>[] | undefined;
  onChange: (changer: SupplierDesignChange) => void;
  readonly?: boolean;
}

export const ImageFloorFields: React.FC<Props> = ({ item, onChange, readonly }) => {
  return <GenericSupplierDesignFields item={item} onChange={onChange} readonly={readonly} enumeration="floorSuppliers" title="Golv" />;
};

export const ImageTilingFields: React.FC<Props> = ({ item, onChange, readonly }) => {
  return <GenericSupplierDesignFields item={item} onChange={onChange} readonly={readonly} enumeration="tilingSuppliers" title="Kakel" />;
};

export const ImageWallpaperFields: React.FC<Props> = ({ item, onChange, readonly }) => {
  return <GenericSupplierDesignFields item={item} onChange={onChange} readonly={readonly} enumeration="wallPaperSuppliers" title="Tapet" />;
};

type SupplierDesignChange = (original: GenericSupplierDesign | undefined) => GenericSupplierDesign | undefined;

interface GenericSupplierDesignFieldsProps {
  item?: MediaValue<GenericSupplierDesign | undefined>[] | undefined;
  onChange: (changer: SupplierDesignChange) => void;
  readonly?: boolean;
  enumeration: keyof Enumerations;
  title: string;
}

function getSelectedSupplier(enumerationSupplier: EnumerationItem[], itemValue: GenericSupplierDesign | undefined) {
  if (enumerationSupplier.some(s => s.key === itemValue?.supplier)) {
    return itemValue?.supplier;
  }
  if (itemValue?.supplier === 'mixed') {
    return 'mixed';
  }
  return 'unset';
}

const GenericSupplierDesignFields: React.FC<GenericSupplierDesignFieldsProps> = ({ item, enumeration, onChange, readonly, title }) => {
  const enumerationSupplier = useAppSelector(state => state.app.enumerations?.[enumeration]) ?? [];

  const supplierValue = getMediaValue(
    item,
    { design: '', supplier: '' },
    { design: 'Blandade värden', supplier: 'mixed' },
    (v0, v1) => v0?.supplier === v1?.supplier,
  );

  const designValue = getMediaValue(
    item,
    { design: '', supplier: '' },
    { design: 'Blandade värden', supplier: 'mixed' },
    (v0, v1) => v0?.design === v1?.design,
  );

  const setValue = (update?: Partial<GenericSupplierDesign>) => {
    if (update?.design == null && update?.supplier == null) {
      onChange(_ => undefined);
    } else {
      onChange(original => ({ ...original, ...update }));
    }
  }

  const selectedSupplier = getSelectedSupplier(enumerationSupplier, supplierValue);

  const onSelectSupplier = (event: SelectChangeEvent) => {
    if (event.target.value === 'unset') {
      setValue(undefined);
    } else {
      const supplier = event.target.value as string;
      setValue({ supplier });
    }
  };

  const getOption = (option: EnumerationItem) => (
    <MenuItem key={option.key} value={option.key}>
      {option.value}
    </MenuItem>
  );

  const supplierInvalid = !(supplierValue?.supplier || !designValue?.design);
  const designInvalid = !(!supplierValue?.supplier || designValue?.design);

  return (
    <CaseField label={title}>
      <Grid container spacing={2} className={styles.formBody} alignItems="flex-end">
        <Grid item xs={6}>
          <InputLabel shrink>Leverantör</InputLabel>
          <Select value={selectedSupplier} onChange={onSelectSupplier} readOnly={false} error={supplierInvalid}>
            <MenuItem key="unset" value="unset">
              <i>Ej vald</i>
            </MenuItem>
            <MenuItem key="mixed" value="mixed" style={selectedSupplier !== 'mixed' ? { display: 'none' } : {}}>
              <i>Blandade värden</i>
            </MenuItem>
            {enumerationSupplier?.map(getOption)}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <CaseTextField
            label="Design"
            name="design"
            value={designValue?.design}
            setValue={design => setValue({ design })}
            disabled={readonly}
            error={designInvalid}
          />
        </Grid>
      </Grid>
    </CaseField>
  );
};
