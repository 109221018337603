export const useEnvironment = () => {
  if (window.location.hostname === 'localhost') {
    return 'dev';
  } else if (window.location.hostname.includes('test')) {
    return 'test';
  } else if (window.location.hostname.includes('qa')) {
    return 'qa';
  } else {
    return 'prod';
  }
};
