import React from 'react';
import { CaseTextField } from './CaseTextField';
import { MediaValue, getMediaValue } from './MediaValue';

interface Props {
  productName?: MediaValue<string | undefined>[];
  onChange: (value: string) => void;
  readonly?: boolean;
}

export const CaseProductNameField: React.FC<Props> = ({ productName, onChange, readonly }) => (
  <CaseTextField
    required
    label="Produktnamn"
    name="productName"
    value={getMediaValue(productName, '', 'Blandade värden')}
    setValue={onChange}
    disabled={readonly}
  />
);
