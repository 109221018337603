import axios, { Canceler } from 'axios';
import { replaceNbsps } from '../helpers/StringHelpers';

let canceler: Canceler | null = null;

const m3BaseUrl = '/api/m3-search';

export async function searchWithFilters({
  queries,
  continuation,
  pageSize,
}: {
  queries: M3SearchQuery[];
  continuation?: number;
  pageSize?: number;
}): Promise<M3SearchResult> {
  canceler?.();

  const cancelToken = new axios.CancelToken(c => (canceler = c));

  const queryString = queries.map(({ searchField, value }) => `${searchField}:(${value}*)`).join(' AND ');

  const token = continuation ? `&token=${continuation}` : '';
  pageSize ??= 24;
  let url = `${m3BaseUrl}?query=${queryString}&size=${pageSize}${token}`;
  const { data } = await axios.get<M3Article[]>(url, { cancelToken });
  canceler = null;
  return { hits: data.length, token: continuation, variants: transformM3ArticleData(data), pageSize };
}

export async function freeTextSearch(query: string): Promise<M3SearchResult> {
  canceler?.();

  const cancelToken = new axios.CancelToken(c => (canceler = c));

  const { data } = await axios.get<M3Article[]>(`${m3BaseUrl}/search?text=${query}`, { cancelToken });

  canceler = null;

  function compareDates(a: M3Article, b: M3Article) {

    if (!(a.attributes.itemPublicationDate)) return 1; // Treat as earliest
    if (!(b.attributes.itemPublicationDate)) return -1; // Treat as latest

    const dateA = new Date(a.attributes.itemPublicationDate);
    const dateB = new Date(b.attributes.itemPublicationDate);

    if (isNaN(dateA.getTime())) return 1; // Treat as earliest
    if (isNaN(dateB.getTime())) return -1; // Treat as latest

    return dateB.getTime() - dateA.getTime(); // Sort in desc order
  }

  // Temp fix to handle that they don't paginate
  return { hits: 0, token: 0, variants: transformM3ArticleData(data.sort(compareDates)), pageSize: 1 };
}
export async function getByM3Ids(ids: string[]): Promise<M3Article[]> {
  if (ids.length === 0) {
    return [];
  }

  let url = `${m3BaseUrl}?query=m3Id:(${ids.join(' OR ')})&size=${ids.length > 100 ? ids.length : 100}`;

  const { data } = await axios.get<M3Article[]>(url);
  return transformM3ArticleData(data);
}

function transformM3ArticleData(data: M3Article[]) : M3Article[] {
  return data.map((d) => (
    {
      ...d,
      description: replaceNbsps(d.description)
    }
  ));
}

export const M3SearchOptions = [
  'm3Id',
  'name',
  'description',
  'productArea',
  'attributes.itemCountryOfOrigin',
  'attributes.itemDistributor',
  // 'attributes.itemDiameter',
  // 'attributes.itemHeight',
  // 'attributes.itemWeight',
  // 'attributes.itemMaterial',
  // 'attributes.itemPublicationDate',
  // 'attributes.itemSalesEnd',
  // 'attributes.itemSalesStart',
] as const;

export type M3SearchOption = (typeof M3SearchOptions)[number];

export class M3SearchOptionsConverter {
  public static readonly Mapping: Record<M3SearchOption, string> = {
    m3Id: 'M-Nummer',
    name: 'Produktnamn',
    description: 'Beskrivning',
    productArea: 'Produktområde',
    'attributes.itemCountryOfOrigin': 'Ursprungsland',
    'attributes.itemDistributor': 'Distributör',
    // 'attributes.itemDiameter': 'Diameter',
    // 'attributes.itemHeight': 'Höjd',
    // 'attributes.itemWeight': 'Vikt',
    // 'attributes.itemMaterial': 'Material',
    // 'attributes.itemPublicationDate': 'Publiceringsdatum',
    // 'attributes.itemSalesEnd': 'Slutdatum',
    // 'attributes.itemSalesStart': 'Startdatum',
  };

  public static ToName(type: M3SearchOption): string {
    return this.Mapping[type];
  }
}

export type M3SearchQuery = {
  value: string;
  searchField: M3SearchOption;
};

export type M3Article = {
  id: string;
  m3Id?: string;
  name: string;
  description: string;
  productArea: string;
  attributes: {
    itemCategoryManager?: string;
    itemCFI1?: string;
    itemCountryOfOrigin?: string;
    itemDiameter?: string;
    itemDistributor?: string;
    itemEan?: string;
    itemFSC?: string;
    itemHeight?: string;
    itemLength?: string;
    itemDepth?: string;
    itemWidth?: string;
    itemWeight?: string;
    itemMaterial?: string;
    itemPublicationDate?: string;
    itemSalesEnd?: string;
    itemSalesStart?: string;
  };
};

export interface M3SearchResult {
  hits: number;
  token: number | undefined;
  pageSize: number;
  variants: M3Article[];
}

export enum FetchResult {
  NotSearched = 1,
  Loading,
  NotFound,
  Found,
}
