import React, { useEffect } from 'react';
import styles from './ProjectTagModal.module.css';

import { Box, TextField, Grid } from '@mui/material';

import { ProjectTag } from '../../api/caseApiTypes';
import { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { formatISO, parseISO } from 'date-fns';
import { ColorTag } from './ColorTag';
import { SketchPicker } from 'react-color';
import { CaseDialog } from '../cases/layoutComponents/CaseDialog';
import { IsDirty } from '../../helpers/ObjectHelpers';

interface Props {
  tag: ProjectTag;
  open: boolean;
  onSave: (tag: ProjectTag) => void;
  onClose: () => void;
  onEdit: (tag: ProjectTag) => void;
}

export const ProjectTagModal: React.FC<Props> = ({ tag, open, onSave, onClose, onEdit }) => {
  const [pendingChange, setPendingChange] = useState(tag);
  const [isDirty, setIsDirty] = useState(IsDirty(tag, pendingChange));

  const onChange = (partial: Partial<ProjectTag>) => {
    setPendingChange(Object.assign({}, pendingChange, partial));
  };

  useEffect(() => {
    if (pendingChange != null) {
      setIsDirty(IsDirty(pendingChange, tag));
    }
  }, [pendingChange, tag]);

  const saveDisabled = !isDirty;

  const parseDate = (value?: string) => {
    if (value == null) {
      return null;
    }

    return parseISO(value);
  };

  const parseString = (value?: Date | null) => {
    if (value == null) {
      return;
    }

    return formatISO(value);
  };

  const validateSketchPickerSurrogate = (
    <input style={{ height: 0, width: 0, opacity: 0 }} required type={'text'} value={pendingChange.color}></input>
  );

  return (
    <CaseDialog
      isVisible={open}
      onClose={onClose}
      saveDisabled={saveDisabled}
      onSave={() => (pendingChange.id ? onEdit(pendingChange) : onSave(pendingChange))}
    >
      <Grid className={styles.modalContainer} container direction="row" justifyContent="center" alignItems="center">
        <Box className={styles.picker}>
          <SketchPicker color={pendingChange.color} onChange={props => onChange({ color: props.hex })} />
          {validateSketchPickerSurrogate}
        </Box>
        <Box className={styles.form}>
          <Box className={styles.tagPreview}>
            <ColorTag hex={pendingChange.color} label={pendingChange.name} size="small" />
          </Box>
          <TextField
            className={styles.modalInput}
            name="name"
            value={pendingChange.name}
            label="Namn"
            onChange={v => onChange({ name: v.currentTarget.value })}
            required
          />
          <TextField
            className={styles.modalInput}
            name="description"
            value={pendingChange.description}
            label="Beskrivning"
            onChange={v => onChange({ description: v.currentTarget.value })}
          />
          <DatePicker
            label="Aktivt från datum"
            value={parseDate(pendingChange.activeFrom)}
            onChange={v => onChange({ activeFrom: parseString(v) })}
            slotProps={{ textField: { fullWidth: true } }}
          />
          <DatePicker
            label="Aktivt till datum"
            value={parseDate(pendingChange.activeTo)}
            onChange={v => onChange({ activeTo: parseString(v) })}
            slotProps={{ textField: { fullWidth: true } }}
          />
        </Box>
      </Grid>
    </CaseDialog>
  );
};
