// react-table type definitions are community-provided...
// useTable does take care of generating they key prop
// but the linting rule fails to evaluate it correctly

/* eslint-disable react/jsx-key */

/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useMemo, useState } from 'react';

import { Tune } from '@mui/icons-material';
import {
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Column, TableOptions, useSortBy, useTable } from 'react-table';

import styles from './CasesTable.module.css';

import { CaseBase, CaseType, CaseTypes, SearchCaseBase, StateEnum, States } from '../../api/caseApiTypes';
import { FilterableCase } from '../../api/filterTypes';
import { Right } from '../../api/userApi';
import { SearchQuery } from '../../commonComponents/SearchQuery';
import { useAppDispatch } from '../../hooks/useAppRedux';
import { useRights } from '../../hooks/useRights';
import { SearchContext, useCaseSearchState } from '../../hooks/useSearchState';
import { LocalStorageKeys, SessionStorageKeys, useLocalStorage, useSessionStorage } from '../../hooks/useStorage';
import { useUserFilters } from '../../hooks/useUserFilters';
import { CasePropClauseResolverConfig } from '../sidebar/filter/propClauseResolver/resolverConfigs';
import { SearchFilters, SearchFilterTranslator } from '../sidebar/filter/searchFilterTranslator';
import { ColumnDefinitions, ColumnEnum } from './CasesTableColumns';
import { CasesTableSettings } from './CasesTableSettings';
import { search } from './casesSlice';

const DefaultColumnsEnabled: Record<ColumnEnum, boolean> = {
  approvalDeadline: false,
  created: true,
  modified: true,
  productionDeadline: false,
  ownerName: true,
  retouchedByFullName: false,
  title: true,
  project: true,
  state: true,
  tags: true,
  type: true,
};

const DefaultColumnOrder: ColumnEnum[] = [
  ColumnEnum.State,
  ColumnEnum.Type,
  ColumnEnum.Title,
  ColumnEnum.OwnerName,
  ColumnEnum.RetouchedByFullName,
  ColumnEnum.Project,
  ColumnEnum.Tags,
  ColumnEnum.ApprovalDeadline,
  ColumnEnum.ProductionDeadline,
  ColumnEnum.Created,
  ColumnEnum.Modified,
];

const RequiredColumns = [ColumnEnum.Title];

interface SortByReactTable {
  id: ColumnEnum;
  desc: boolean;
}

const translator = new SearchFilterTranslator<FilterableCase>(CasePropClauseResolverConfig);

const CasesTableFilters: SearchFilters<FilterableCase> = {
  defaultFilters: {
    Case_CaseState: {
      value: States.AllExcept([StateEnum.none, StateEnum.closed, StateEnum.deleted]).map(state => state.toString()),
    },
    type: { value: CaseTypes.AllExcept(CaseType.Unknown).map(state => state.toString()) },
  },
};

const isSameSorting = (a: SortByReactTable, b: SortByReactTable) => a.id === b.id && a.desc === b.desc;

export const CasesTable: React.FC = () => {
  const hasSystemAccess = useRights(Right.ReadCase);
  const dispatch = useAppDispatch();
  const [continuation, setContinuation] = useState(0);

  const [query, setQuery] = useSessionStorage(SessionStorageKeys.searchQuery, '');

  const searchContext = SearchContext.AllCasesList;
  const { items, count, isSearching } = useCaseSearchState(searchContext);
  const userFilters = useUserFilters(searchContext);

  const itemsLoaded = items.length;
  const canLoadMore = count > itemsLoaded;

  const loadMore = () => {
    if (!isSearching) {
      setContinuation(itemsLoaded);
    }
  };

  const [columnsEnabled, setColumnsEnabled] = useLocalStorage(LocalStorageKeys.caseTableColumnsEnabled, DefaultColumnsEnabled);
  const [columnSorted, setColumnSorted] = useLocalStorage(LocalStorageKeys.caseTableColumnsSorted, {
    id: ColumnEnum.Created,
    desc: true,
  } as SortByReactTable);

  const missingRequiredColumns = RequiredColumns.some(column => !columnsEnabled[column]);

  useEffect(() => {
    setColumnsEnabled(columns => {
      for (const column of RequiredColumns) {
        columns[column] = true;
      }
      return columns;
    });
  }, [missingRequiredColumns, setColumnsEnabled]);

  const [settingsVisible, setSettingsVisible] = useState(false);

  const columns: Column<SearchCaseBase>[] = useMemo(
    () =>
      DefaultColumnOrder.filter(
        column => (columnsEnabled[column] ?? DefaultColumnsEnabled[column]) || RequiredColumns.includes(column),
      ).map(column => ColumnDefinitions[column]),
    [columnsEnabled],
  );

  const options: TableOptions<SearchCaseBase> = {
    columns,
    data: items ?? [],
    manualSortBy: true,
    initialState: {
      // @ts-ignore: flawed definitions in react table
      sortBy: [columnSorted],
    },
    useControlledState: state => {
      // @ts-ignore: flawed definitions in react table
      const tableSorting = state['sortBy'][0];
      if (tableSorting && !isSameSorting(columnSorted, tableSorting)) {
        setColumnSorted(tableSorting);
      }

      return React.useMemo(
        () => ({
          ...state,
        }),

        [state],
      );
    },
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: {
      // @ts-ignore: flawed definitions in react table
      sortBy,
    },
  } = useTable<CaseBase>(options, useSortBy);

  const navigate = useNavigate();
  useEffect(() => {
    if (userFilters) {
      dispatch(
        search({
          query,
          orderBy: sortBy,
          filterTree: translator.getClauses({ ...CasesTableFilters, userFilters }),
          continuation,
          searchContext,
        }),
      );
    }
  }, [dispatch, query, sortBy, userFilters, continuation, searchContext]);

  useEffect(() => {
    setContinuation(0);
  }, [query, sortBy, userFilters]);

  if (!hasSystemAccess) {
    return (
      <Grid container justifyContent="center" alignContent="center">
        <Grid item>
          <Typography>Du saknar behörighet att använda Mediaportalen. Kontakta IT support om du behöver åtkomst.</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Container className={styles.container}>
        <div className={styles.header}>
          <SearchQuery onSearch={value => setQuery(value)} isSearching={isSearching} />
        </div>
        <CasesTableSettings
          isVisible={settingsVisible}
          onClose={() => setSettingsVisible(false)}
          columnsEnabled={columnsEnabled}
          columnOrder={DefaultColumnOrder}
          toggleColumn={(column: ColumnEnum) => setColumnsEnabled({ ...columnsEnabled, [column]: !columnsEnabled[column] })}
          requiredColumns={RequiredColumns}
        />

        <Paper className={styles.paper}>
          <div className={styles.settingsRow}>
            <IconButton className={styles.settingsButton} size="small" onClick={() => setSettingsVisible(true)}>
              <Tune />
            </IconButton>
          </div>
          <TableContainer>
            <Table size="small" {...getTableProps()}>
              <TableHead>
                {headerGroups.map(headerGroup => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                      <TableCell align="left" {...column.getHeaderProps(column.getSortByToggleProps())}>
                        <TableSortLabel active={column.isSorted} direction={column.isSortedDesc ? 'desc' : 'asc'}>
                          {column.render('Header')}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                {rows.map(row => {
                  prepareRow(row);
                  return (
                    <TableRow
                      className={styles.caseRow}
                      {...row.getRowProps({
                        // @ts-ignore: flawed definitions in react table
                        onClick: () => navigate(`/case/${row.original.lookupEntry ?? row.original.id}`),
                      })}
                    >
                      {row.cells.map(cell => {
                        return (
                          <TableCell align="left" {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
                {canLoadMore && <LoadMore onClick={loadMore} />}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Container>
    </>
  );
};

const LoadMore: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const { palette } = useTheme();
  const { isSearching } = useCaseSearchState(SearchContext.AllCasesList);

  const backgroundColor = palette.mode === 'light' ? '#fafafa' : '#333';
  const borderColor = palette.mode === 'light' ? '#ddd' : '#555';

  const loadMoreStyle: React.CSSProperties = {
    backgroundColor,
    borderColor,
    borderLeft: `1px solid ${borderColor}`,
    borderRight: `1px solid ${borderColor}`,
  };

  return (
    <TableRow>
      <TableCell colSpan={100} align="center" onClick={onClick} style={loadMoreStyle} className={styles.loadMoreContainer}>
        <div>{isSearching ? <CircularProgress /> : 'Ladda fler...'}</div>
      </TableCell>
    </TableRow>
  );
};
