import { Case, LegacyDeliveryStatus, StateEnum } from '../../api/caseApiTypes';
import { progressCase } from '../cases/casesSlice';
import { Right } from '../../api/userApi';
import { AsyncThunkAction } from '@reduxjs/toolkit';

export type KanbanAction = (caze: Case, priority?: number) => AsyncThunkAction<void, any, any>;

export interface KanbanLane {
  title: string;
  filter: (caze: Case) => boolean;
  action?: KanbanAction;
  right?: Right;
}

const kanbanRetouchStates = [StateEnum.awaitingRetouch, StateEnum.retouchInProgress, StateEnum.retouchReviewPending];

export const LaneDefinitions: Readonly<KanbanLane[]> = [
  {
    title: 'Inväntar leverans',
    filter: caze => caze.state.state === StateEnum.confirmed && caze.deliveryStatus === LegacyDeliveryStatus.Ordered,
    action: (item, priority) =>
      progressCase({
        item,
        priority,
        caseState: StateEnum.confirmed,
        deliveryStatus: LegacyDeliveryStatus.Ordered,
      }),
  },
  {
    title: 'Levererad bekräftad för foto',
    filter: caze => caze.state.state === StateEnum.confirmed && caze.deliveryStatus === LegacyDeliveryStatus.Available,
    action: (item, priority) =>
      progressCase({
        item,
        priority,
        caseState: StateEnum.confirmed,
        deliveryStatus: LegacyDeliveryStatus.Available,
      }),
  },
  {
    title: 'Redo att köras in',
    // prev filter: is planned, to avoid performing migrations of old cases we set up a filter that catches states that should not be possible in the current config.
    // TODO: filter, the current state should be planned and deliveryStatus should be delivered
    filter: caze => caze.state.state === StateEnum.planned && caze.deliveryStatus !== LegacyDeliveryStatus.Finished,
    action: (item, priority) =>
      progressCase({
        item,
        priority,
        caseState: StateEnum.planned,
        deliveryStatus: LegacyDeliveryStatus.Delivered,
      }),
  },
  {
    title: 'Inväntar fotografering',
    // se above entry
    // TODO: remove Inprogress from filter
    filter: caze =>
      caze.state.state === StateEnum.inProgress ||
      (caze.state.state === StateEnum.planned && caze.deliveryStatus === LegacyDeliveryStatus.Finished),
    action: (item, priority) =>
      progressCase({
        item,
        priority,
        caseState: StateEnum.planned,
        deliveryStatus: LegacyDeliveryStatus.Finished,
      }),
  },
  {
    title: 'Fotograferad',
    filter: caze => caze.state.state === StateEnum.reviewPending,
    action: (item, priority) =>
      progressCase({
        item,
        priority,
        caseState: StateEnum.reviewPending,
      }),
  },
  {
    title: 'Retusch',
    filter: caze => kanbanRetouchStates.includes(caze.state.state),
    action: (item, priority) =>
      progressCase({
        item,
        priority,
        caseState: kanbanRetouchStates[0],
      }),
    right: Right.CloseCase,
  },
];
