import React, { PropsWithChildren } from 'react';
import styles from './CaseField.module.css';
import { Typography, useTheme } from '@mui/material';

interface Props {
  label: string;
}

export const CaseField: React.FC<PropsWithChildren<Props>> = ({ label, children }) => {
  const { palette } = useTheme();

  const borderColor = palette.mode === 'light' ? '#ccc' : '#444';
  const style: React.CSSProperties = { border: `1px solid ${borderColor}` };

  return (
    <fieldset className={styles.fieldset} style={style}>
      <Typography component="legend" variant="body1" className={styles.legend}>
        {label}
      </Typography>
      {children}
    </fieldset>
  );
};
