import { CaseType, StateEnum, States } from '../../api/caseApiTypes';
import { SearchContext } from '../../hooks/useSearchState';
import { CaseKanbanBoard } from './CaseKanbanBoard';
import { LaneDefinitions } from './LaneDefinitions';

export const KanbanSupportedTypes = [CaseType.SingleProduct, CaseType.ProductCollection, CaseType.DiningSet];

export const KanbanFilters = {
  defaultFilters: {
    type: { value: KanbanSupportedTypes.map(state => state.toString()) },
    Case_CaseState: {
      value: States.AllExcept([StateEnum.none, StateEnum.incoming, StateEnum.closed, StateEnum.deleted]).map(state => state.toString()),
    },
  },
};

export const CaseKanbanPlan = () =>
  CaseKanbanBoard({ filters: KanbanFilters, lanes: LaneDefinitions, searchContext: SearchContext.PlanningKanban });
