import React from 'react';
import styles from './SelectTagModal.module.css';

import { Box } from '@mui/material';
import { CaseDialog } from '../cases/layoutComponents/CaseDialog';

interface Props {
  children?: React.ReactNode;
  open: boolean;
  onSave: () => void;
  onClose: () => void;
  savelabel?: string;
}

export const SelectTagModal: React.FC<Props> = ({ children, open, ...rest }) => (
  <CaseDialog isVisible={open} {...rest}>
    <Box className={styles.modalContent}>{children}</Box>
  </CaseDialog>
);
