import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Header } from './features/header/Header';
import { Sidebar } from './features/sidebar/Sidebar';
import { useMediaQuery } from '@mui/material';
import { LocalStorageKeys, useLocalStorage } from './hooks/useStorage';
import { useAppDispatch, useAppSelector } from './hooks/useAppRedux';
import { QueryParam, Routes } from './App';
import styles from './Layout.module.css';
import { setUseDarkMode } from './appSlice';
import { BatchMediaSaveProgress } from './features/enrichment/BatchMediaSaveProgress';

export const Layout: React.FC = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [darkModeEnabled, setDarkModeEnabled] = useLocalStorage<boolean | undefined>(LocalStorageKeys.useDarkMode, undefined);
  const localUseDarkMode = darkModeEnabled ?? prefersDarkMode;
  const { useDarkMode, isAuthenticated, user } = useAppSelector(state => state.app);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (localUseDarkMode !== useDarkMode) {
      dispatch(setUseDarkMode(localUseDarkMode));
    }
  }, [dispatch, localUseDarkMode, useDarkMode]);

  const toggleDarkMode = () => {
    setDarkModeEnabled(!localUseDarkMode);
  };

  useEffect(() => {
    if (!isAuthenticated && !window.location.pathname.startsWith(Routes.Login)) {
      let loginUrl = `${Routes.Login}?${QueryParam.RedirectTo}=${encodeURIComponent(window.location.pathname + window.location.search)}`;
      navigate(loginUrl, { replace: true });
    }
  }, [isAuthenticated, navigate]);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Header user={user} darkModeEnabled={localUseDarkMode} toggleDarkMode={toggleDarkMode} />
      <Sidebar />
      <main>
        <Outlet />
      </main>
      <BatchMediaSaveProgress />
    </div>
  );
};
