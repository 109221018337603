import React from 'react';

import { ImageState, ImageStateConverter, ImageStates } from '../../../api/caseApiTypes';
import { FormControl, InputLabel } from '@mui/material';
import { MediaValueSelect, OptionInfo } from './MediaValueSelect';
import { MediaValue } from './MediaValue';

interface Props {
  imageState?: MediaValue<ImageState | undefined>[] | undefined;
  onChange: (value?: ImageState) => void;
  readonly?: boolean;
}

const options: OptionInfo<ImageState>[] = ImageStates.All().map(imageState => ({
  key: imageState,
  label: ImageStateConverter.ToName(imageState),
}));

export const ImageStateField: React.FC<Props> = ({ onChange, readonly, imageState }) => {
  return (
    <FormControl>
      <InputLabel shrink>Bildstatus</InputLabel>
      <MediaValueSelect values={imageState} defaultValue={ImageState.None} onChange={onChange} readonly={readonly} options={options} />
    </FormControl>
  );
};
