import React, { ForwardedRef, MouseEvent, forwardRef, useMemo, useState } from 'react';

import { Box, Card, CardActionArea, CardActions, CardMedia, CircularProgress, IconButton, Tooltip } from '@mui/material';

import styles from './MediaCard.module.css';

import { AdditionalAction } from '../features/image/imageCard/ImageCard';
import { FullScreenDialog } from './FullScreenDialog';
import { DerivedImageHolder } from '../api/caseApiTypes';
import { MediaVisualizer } from './MediaVisualizer';

export interface Props {
  className?: string;
  cardHeader?: JSX.Element;
  shouldDisplaySpinner?: boolean;
  image?: DerivedImageHolder;
  imageThumbnailSrc: string;
  imageTitle?: string;
  thumbnailWrapper?: (children: JSX.Element) => JSX.Element;
  cardBody?: JSX.Element;

  actions?: AdditionalAction[];
  additionalButtons?: JSX.Element[];
  cardFooter?: JSX.Element;
  onClick?: () => void;
  onClickImage?: (event: MouseEvent<HTMLElement>) => void;
  disableInteraction?: boolean;
  raised?: boolean;
  style?: React.CSSProperties;
  large?: boolean;
}

function MediaCardComponent(
  {
    className,
    cardHeader,
    image,
    imageThumbnailSrc,
    shouldDisplaySpinner,
    imageTitle,
    cardBody,
    actions,
    additionalButtons,
    cardFooter,
    thumbnailWrapper,
    onClick,
    onClickImage,
    disableInteraction,
    raised,
    style,
    large,
  }: Props,
  cardRef: ForwardedRef<HTMLDivElement>,
) {
  const [open, setOpen] = useState(false);
  const handleClickImage = (e: MouseEvent<HTMLElement>) => {
    if (onClickImage != null) {
      onClickImage?.(e);
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (onClick != null && disableInteraction === false) {
    throw Error("Invalid combination of properties on ImageCard, can't set onClick unless disableInteraction is true");
  }
  const mediaStyle = `${styles.media} ${large ? styles.large : styles.small}`;

  const cardMedia = shouldDisplaySpinner ? (
    <CardMedia className={styles.media} title={imageTitle} component={CircularProgress} disableShrink />
  ) : large ? (
    <MediaVisualizer className={mediaStyle} item={image} alt={imageTitle} autoPlayVideo={true} size="full" />
  ) : (
    <CardMedia className={mediaStyle} alt={imageTitle} title={imageTitle} component="img" image={imageThumbnailSrc} />
  );

  const smallThumb = (
    <div className={`${styles.mediaWrapper} ${large ? styles.large : styles.small}`}>
      {thumbnailWrapper != null ? thumbnailWrapper(cardMedia) : cardMedia}
    </div>
  );

  const additionalActionComponents = useMemo(
    () =>
      (actions ?? []).map(({ icon, action, tooltip, disabled = false }) => {
        const button = (
          <IconButton color="primary" onClick={action} disabled={disabled} size="small">
            {icon}
          </IconButton>
        );

        if (disabled) {
          return button;
        }

        return <Tooltip title={tooltip}>{button}</Tooltip>;
      }),
    [actions],
  );

  const classes = [styles.card];
  if (className != null) {
    classes.push(className);
  }
  if (!large) {
    classes.push(styles.smallCard);
  }

  const cardClasses = classes.join(' ');

  if (disableInteraction) {
    return (
      <>
        <Card className={cardClasses} variant="elevation" raised={raised} style={style} onClick={onClick}>
          {cardHeader}
          {smallThumb}
          {cardBody}
          {cardFooter}
        </Card>
      </>
    );
  }

  const shouldRenderDialog = onClickImage == null && image != null;

  return (
    <>
      <Card ref={cardRef} className={cardClasses} variant="elevation" raised={raised} style={style}>
        {cardHeader}
        <CardActionArea onClick={handleClickImage} disabled={!image}>
          {smallThumb}
        </CardActionArea>
        <Box className={styles.cardBody}>{cardBody}</Box>
        <CardActions className={styles.buttonWrapper}>
          {[...(additionalButtons ?? []), ...additionalActionComponents].map((button, idx) => (
            <React.Fragment key={idx}>{button}</React.Fragment>
          ))}
        </CardActions>
        {cardFooter}
      </Card>
      {shouldRenderDialog && <FullScreenDialog alt={imageTitle} item={image} open={open} onClose={handleClose} />}
    </>
  );
}

export const MediaCard = forwardRef<HTMLDivElement, Props>(MediaCardComponent);
