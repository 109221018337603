import React from 'react';
import styles from './TagsTable.module.css';
import { Column, TableOptions, useSortBy, useTable } from 'react-table';

import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface Props<T extends object> {
  columns: Column<T>[];
  data: T[];
  onClickOnAdd: () => void;
}

export function TagsTable<T extends object>({ columns, data, onClickOnAdd }: Props<T>) {
  const options: TableOptions<T> = {
    columns,
    data,
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<T>(options, useSortBy);

  return (
    <Box className={styles.container}>
      <TableContainer className={styles.table}>
        <Table size="small" {...getTableProps()}>
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell align="left" {...column.getHeaderProps()} className={styles.tableHead}>
                    {column.render('Header')}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <TableCell align="left" {...cell.getCellProps()}>
                      <Box>{cell.render('Cell')}</Box>
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className={styles.newTag}>
        <Button variant="outlined" color="primary" startIcon={<AddIcon />} onClick={() => onClickOnAdd()}>
          Skapa ny
        </Button>
      </Box>
    </Box>
  );
}
