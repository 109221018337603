import { Case, Image } from '../api/caseApiTypes';
import { useAppSelector } from './useAppRedux';

export enum SearchContext {
  AllCasesList,
  PlannableCasesList,
  PlanningCalendar,
  PlanningKanban,
  KanbanBadge,
  PlanningProductContextualKanban,
  ContextualKanbanBadge,
  ImagesGrid,
  RetouchImagePicker,
}

export interface SearchState<T> {
  items: T[];
  count: number;
}

export const useCaseSearchState = (searchContext: SearchContext): SearchState<Case> & { isSearching: boolean } => {
  const state = useAppSelector(state => state.cases.casesByContext[searchContext]) ?? { items: [], count: 0 };
  const isSearching = useAppSelector(state => state.cases.isSearching);

  return { ...state, isSearching };
};

export const useImageSearchState = (searchContext: SearchContext): SearchState<Image> & { isSearching: boolean } => {
  const state = useAppSelector(state => state.images.imagesByContext[searchContext]) ?? { items: [], count: 0 };
  const isSearching = useAppSelector(state => state.images.isSearching);

  return { ...state, isSearching };
};
