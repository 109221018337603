import {
  FilterTreeCalendarCaseStateComponent,
  FilterTreePhotographerComponent,
  FilterTreeStylistComponent,
  FilterTreeCalendarCaseTypesComponent,
} from '../filterComponents/FilterTreeComponentTemplate';
import { FilterableCase, FilterPropertyRecord } from '../../../../api/filterTypes';
import { FilterTreeProjectTagComponent, FilterTreeSystemTagComponent } from '../filterComponents/FilterTreeTagComponent';

export const planningCalendarFilterConfiguration: FilterPropertyRecord<FilterableCase> = {
  Case_ProjectTagId: {
    title: 'Projekt',
    Component: FilterTreeProjectTagComponent,
    alwaysShowFilter: true,
  },
  Case_SystemTagIds: {
    title: 'Taggar',
    Component: FilterTreeSystemTagComponent,
    alwaysShowFilter: true,
  },
  Case_CaseState: {
    title: 'Beställningsstatus',
    Component: FilterTreeCalendarCaseStateComponent,
  },
  type: {
    title: 'Beställningstyp',
    Component: FilterTreeCalendarCaseTypesComponent,
  },
  photographer: {
    title: 'Fotograf',
    Component: FilterTreePhotographerComponent,
  },
  stylist: {
    title: 'Stylist',
    Component: FilterTreeStylistComponent,
  },
};
