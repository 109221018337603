import React from 'react';

import { Case, ContextualPhotoCase, InstallationPhotoCase } from '../../../api/caseApiTypes';
import { CaseTextField } from './CaseTextField';

type TCase = ContextualPhotoCase | InstallationPhotoCase;

interface Props {
  caze: TCase;
  onChange: (partial: Partial<Case>) => void;
  readonly?: boolean;
}

export const CaseDimensions: React.FC<Props> = ({ caze, onChange, readonly }) => {
  return (
    <>
      {caze.imageDimensions && caze.imageDimensions !== '' && (
        <CaseTextField
          label="Bilddimensioner (t.ex. önskad bildstorlek, stående/liggande)"
          name="imageDimensions"
          value={caze.imageDimensions || ''}
          setValue={imageDimensions => onChange({ imageDimensions })}
          disabled={readonly}
        />
      )}
    </>
  );
};
