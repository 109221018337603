import { Button, CircularProgress } from '@mui/material';
import styles from './LoadMoreItem.module.css';
import React from 'react';

interface Props {
  className?: string;
  itemsLoaded: number;
  count: number;
  pageSize?: number;
  isSearching: boolean;
  loadMore: () => void;
}

export const LoadMoreItem: React.FC<Props> = ({ itemsLoaded, count, pageSize, isSearching, loadMore, className }) => {
  if (count <= itemsLoaded) {
    return null;
  }

  let textContents: string;

  if (pageSize) {
    const nextPage = Math.ceil(itemsLoaded / pageSize) + 1;
    const lastPage = Math.ceil(count / pageSize);

    textContents = nextPage === lastPage ? 'Ladda resterande' : `Ladda sida ${nextPage} / ${lastPage}`;
  } else {
    textContents = 'Ladda fler';
  }

  const classes = [className, styles.container].filter(c => c).join(' ');

  return (
    <div className={classes}>
      <Button variant="contained" onClick={loadMore} disabled={isSearching}>
        {isSearching ? <CircularProgress size="2em" /> : textContents}
      </Button>
    </div>
  );
};
