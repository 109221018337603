import React from 'react';

import { DatePicker } from '@mui/x-date-pickers';
import { formatISO, parseISO } from 'date-fns';

import { Case } from '../../../api/caseApiTypes';

interface Props {
  caze: Case;
  onChange: (partial: Partial<Case>) => void;
  readonly?: boolean;
}

export const CaseDeadlineDates: React.FC<Props> = ({ caze, onChange, readonly }) => {
  const parseDate = (value?: string) => {
    if (value == null) {
      return null;
    }

    return parseISO(value);
  };

  const parseString = (value?: Date | null) => {
    if (value == null) {
      return;
    }

    return formatISO(value, {
      representation: 'complete',
    });
  };

  return (
    <>
      <DatePicker
        label="Produktionsdeadline"
        value={parseDate(caze.photoAndRetouchDeadline)}
        onChange={v => onChange({ photoAndRetouchDeadline: parseString(v) })}
        disabled={readonly}
        slotProps={{ textField: { fullWidth: true } }}
      />
      <DatePicker
        label="Publiceringsdatum"
        value={parseDate(caze.approvalDeadline)}
        onChange={v => onChange({ approvalDeadline: parseString(v) })}
        disabled={readonly}
        slotProps={{ textField: { fullWidth: true } }}
      />
    </>
  );
};
