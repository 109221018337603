import axios, { Canceler } from 'axios';
import _ from 'lodash';

import { SearchContext } from '../hooks/useSearchState';
import {
  Article,
  AttachmentCategory,
  AttachmentCategoryConverter,
  Case,
  CaseTypeConverter,
  CaseUserAssignment,
  Enumerations,
  Image,
  LegacyDeliveryStatus,
  ProjectTag,
  RetouchCase,
  SearchResult,
  StateEnum,
  SystemTag,
  UserTag,
} from './caseApiTypes';
import { UploadProgressHandlerForFile } from './commonApiTypes';
import { FilterableCase } from './filterTypes';
import { Clause } from './searchApiTypes';
import { uploadFiles } from './uploadApi';

// Allows search views to check if search is in progress for their context
export const cancelTokens: Record<string, Canceler | null> = {};

export async function search(
  query: string,
  filter: Clause<FilterableCase> | undefined,
  orderBy: string,
  searchContext: SearchContext,
  continuation?: number,
  pageSize: number = 100,
): Promise<SearchResult<Case>> {
  cancelTokens[searchContext]?.();

  const cancelToken = new axios.CancelToken(c => (cancelTokens[searchContext] = c));

  const searchFilter = {
    continuation,
    pageSize,
    filter,
    query,
    orderBy,
  };

  const { data } = await axios.post<SearchResult<Case>>('/api/case/search/', searchFilter, { cancelToken });

  cancelTokens[searchContext] = null;

  return data;
}

export async function getByDate(start: Date, end: Date, continuation?: string): Promise<SearchResult<Case>> {
  const url = `/api/case/search?pageSize=100&continuation=${continuation || ''}`;

  const { data } = await axios.get<SearchResult<Case>>(url);

  return data;
}

export async function getById(id: string): Promise<Case> {
  const url = `/api/case/cases/${id}`;

  const { data } = await axios.get<Case>(url);

  return data;
}

export async function getByLookupEntry(lookupEntry: number): Promise<Case> {
  const url = `/api/case/cases/lookup/${lookupEntry}`;

  const { data } = await axios.get<Case>(url);

  return data;
}

export async function getImageById(id: string, imageId: string): Promise<Image> {
  const url = `/api/case/cases/${id}/image/${imageId}`;

  const { data } = await axios.get<Image>(url);

  return data;
}

export async function createCase(item: Case): Promise<Case> {
  const apiBaseUrl = getCaseSpecificApiBaseUrl(item);

  const { data } = await axios.post<Case>(apiBaseUrl, item);

  return data;
}

export async function updateCase(item: Case): Promise<Case> {
  const apiBaseUrl = getCaseSpecificApiBaseUrl(item);
  const url = `${apiBaseUrl}/${item.id}`;

  const { data } = await axios.put<Case>(url, item);

  return data;
}

export async function setImageActiveVersion(caseId: string, imageId: string, activeVersion: string): Promise<Case> {
  const url = `/api/case/cases/${caseId}/image/${imageId}/active-version`;

  const { data } = await axios.put<Case>(url, { activeVersion });

  return data;
}

export async function progressCase(
  item: Case,
  priority?: number | undefined,
  caseState?: StateEnum | undefined,
  deliveryStatus?: LegacyDeliveryStatus | undefined,
): Promise<Case> {
  const apiBaseUrl = getCaseSpecificApiBaseUrl(item);
  const url = `${apiBaseUrl}/${item.id}/progress`;

  const { data } = await axios.put<Case>(url, {
    priority,
    caseState,
    deliveryStatus,
  });

  return data;
}

export async function resetCase(id: string): Promise<Case> {
  const url = `/api/case/cases/${id}/reset`;

  const { data } = await axios.post<Case>(url, null);

  return data;
}

export async function orderedCase(id: string): Promise<Case> {
  const url = `/api/case/cases/${id}/ordered`;

  const { data } = await axios.post<Case>(url, null);

  return data;
}

export async function confirmCase(id: string): Promise<Case> {
  const url = `/api/case/cases/${id}/confirm`;

  const { data } = await axios.post<Case>(url, null);

  return data;
}

export async function plannedCase(id: string, start: Date, end: Date): Promise<Case> {
  const url = `/api/case/cases/${id}/planned`;

  const { data } = await axios.post<Case>(url, {
    start,
    end,
  });

  return data;
}

export async function caseReviewPending(id: string): Promise<Case> {
  const url = `/api/case/cases/${id}/reviewpending`;

  const { data } = await axios.post<Case>(url, null);

  return data;
}

export async function caseAwaitingRetouch(id: string): Promise<Case> {
  const url = `/api/case/cases/${id}/awaitingretouch`;

  const { data } = await axios.post<Case>(url, null);

  return data;
}

export async function caseRetouchInProgress(id: string): Promise<Case> {
  const url = `/api/case/cases/${id}/retouchinprogress`;

  const { data } = await axios.post<Case>(url, null);

  return data;
}

export async function caseRetouchReviewPending(id: string): Promise<Case> {
  const url = `/api/case/cases/${id}/retouchreviewpending`;

  const { data } = await axios.post<Case>(url, null);

  return data;
}

export async function closeCase(id: string): Promise<Case> {
  const url = `/api/case/cases/${id}/close`;

  const { data } = await axios.post<Case>(url, null);

  return data;
}

export async function deleteCase(id: string): Promise<void> {
  const url = `/api/case/cases/${id}`;

  await axios.delete<Case>(url);
}

export async function addTag(id: string, label: string): Promise<Case> {
  const url = `/api/case/cases/${id}/tag?label=${encodeURIComponent(label)}`;

  const { data } = await axios.post<Case>(url, null);

  return data;
}

export async function addSystemTags(id: string, systemTags: SystemTag[]): Promise<Case> {
  const queryPairs = systemTags.map(tag => `tagIds=${tag.id}`);
  const queryString = queryPairs.join('&');

  const url = `/api/case/cases/${id}/system-tag?${queryString}`;

  const { data } = await axios.post<Case>(url, null);

  return data;
}

export async function addUserTags(id: string, userTags: UserTag[]): Promise<Case> {
  const queryPairs = userTags.map(tag => `tagIds=${tag.id}`);
  const queryString = queryPairs.join('&');

  const url = `/api/case/cases/${id}/user-tag?${queryString}`;

  const { data } = await axios.post<Case>(url, null);

  return data;
}

export async function removeSystemTag(id: string, systemTag: SystemTag): Promise<Case> {
  const url = `/api/case/cases/${id}/system-tag/${systemTag.id}`;

  const { data } = await axios.delete<Case>(url);

  return data;
}

export async function removeUserTag(id: string, userTag: UserTag): Promise<Case> {
  const url = `/api/case/cases/${id}/user-tag/${userTag.id}`;

  const { data } = await axios.delete<Case>(url);

  return data;
}

export async function setProjectTag(id: string, projectTag: ProjectTag): Promise<Case> {
  const url = `/api/case/cases/${id}/project-tag/${projectTag.id}`;

  const { data } = await axios.put<Case>(url);

  return data;
}

export async function clearProjectTag(id: string): Promise<Case> {
  const url = `/api/case/cases/${id}/project-tag`;

  const { data } = await axios.delete<Case>(url);

  return data;
}

export async function upsertArticles(id: string, articles: Article[]): Promise<Case> {
  const url = `/api/case/cases/${id}/articles`;

  const { data } = await axios.put<Case>(url, { articles });

  return data;
}

export async function removeArticle(id: string, articleId: string): Promise<Case> {
  const url = `/api/case/cases/${id}/article/${encodeURIComponent(articleId)}`;

  const { data } = await axios.delete<Case>(url);

  return data;
}

export async function uploadImage(caseId: string, files: File[], onUploadProgress?: UploadProgressHandlerForFile): Promise<Case> {
  await uploadFiles(
    files,
    'uploadCaseImage',
    {
      caseId,
    },
    onUploadProgress,
  );

  return await getById(caseId);
}

export async function uploadVideo(caseId: string, files: File[], onUploadProgress?: UploadProgressHandlerForFile): Promise<Case> {
  await uploadFiles(
    files,
    'uploadCaseVideo',
    {
      caseId,
    },
    onUploadProgress,
  );

  return await getById(caseId);
}

export async function retouchUploadVariantImages(
  caseId: string,
  imageId: string,
  files: File[],
  copyArticles: boolean,
  onUploadProgress?: UploadProgressHandlerForFile,
): Promise<Case> {
  await uploadFiles(
    files,
    'uploadRetouchCaseImageVariant',
    {
      caseId,
      imageId,
      copyArticles: copyArticles.toString(),
    },
    onUploadProgress,
  );
  return await getById(caseId);
}

export async function retouchReplaceImage(
  caseId: string,
  imageId: string,
  file: File,
  onUploadProgress?: UploadProgressHandlerForFile,
): Promise<Case> {
  await uploadFiles(
    [file],
    'replaceRetouchCaseImage',
    {
      caseId,
      imageId,
    },
    onUploadProgress,
  );

  return await getById(caseId);
}

export async function replaceImage(
  caseId: string,
  imageId: string,
  file: File,
  onUploadProgress?: UploadProgressHandlerForFile,
): Promise<Case> {
  await uploadFiles([file], 'replaceCaseImage', { caseId, imageId }, onUploadProgress);

  return await getById(caseId);
}

export async function replaceVideo(
  caseId: string,
  imageId: string,
  file: File,
  onUploadProgress?: UploadProgressHandlerForFile,
): Promise<Case> {
  await uploadFiles([file], 'replaceCaseVideo', { caseId, imageId }, onUploadProgress);

  return await getById(caseId);
}

export async function updateImage(caseId: string, image: Image): Promise<Case> {
  const url = `/api/case/cases/${caseId}/image/${image.id}`;

  const { data } = await axios.put<Case>(url, image);

  return data;
}

export async function removeImage(caseId: string, imageId: string): Promise<Case> {
  const url = `/api/case/cases/${caseId}/image/${imageId}`;

  const { data } = await axios.delete<Case>(url);

  return data;
}

export async function addImageTag(caseId: string, imageId: string, label: string): Promise<Case> {
  const url = `/api/case/cases/${caseId}/image/${imageId}/tag?label=${encodeURIComponent(label)}`;

  const { data } = await axios.post<Case>(url, null);

  return data;
}

export async function removeImageTag(caseId: string, imageId: string, label: string): Promise<Case> {
  const url = `/api/case/cases/${caseId}/image/${imageId}/tag?label=${encodeURIComponent(label)}`;

  const { data } = await axios.delete<Case>(url);

  return data;
}

export async function addAttachment(caseId: string, uri: string, title: string, mediaType: string): Promise<Case> {
  const url = `/api/case/cases/${caseId}/attachment`;

  // If the uri is an empty string, we want to pass an empty property
  const normalizedUri = uri ? uri : undefined;

  const { data } = await axios.post<Case>(url, { uri: normalizedUri, title, mediaType });

  return data;
}

export async function removeAttachment(caseId: string, attachmentId: string, category: AttachmentCategory): Promise<Case> {
  const slug = AttachmentCategoryConverter.ToSlug(category);

  const url = `/api/case/cases/${caseId}/${slug}/${attachmentId}`;

  const { data } = await axios.delete<Case>(url);

  return data;
}

export async function uploadAttachment(
  caseId: string,
  files: File[],
  category: AttachmentCategory,
  onUploadProgress?: UploadProgressHandlerForFile,
): Promise<Case> {
  await uploadFiles(
    files,
    'uploadAttachment',
    {
      caseId,
      category: category.toString(),
    },
    onUploadProgress,
  );

  return await getById(caseId);
}

export async function addComment(caseId: string, content: string): Promise<Case> {
  const url = `/api/case/cases/${caseId}/comment`;

  const { data } = await axios.post<Case>(url, { content });

  return data;
}

export async function deleteComment(caseId: string, commentId: string): Promise<Case> {
  const url = `/api/case/cases/${caseId}/comments/${commentId}`;

  const { data } = await axios.delete<Case>(url);

  return data;
}

export async function deleteRelation(caseId: string, targetCaseId: string): Promise<Case> {
  const url = `/api/case/cases/${caseId}/relations/${targetCaseId}`;

  const { data } = await axios.delete<Case>(url);

  return data;
}

function getCaseSpecificApiBaseUrl(item: Case) {
  return `/api/case/${CaseTypeConverter.ToSlug(item.type)}-cases`;
}

export function getReferenceFilesUri(item: RetouchCase) {
  return `/api/case/retouch-cases/${item.id}/reference-files`;
}

export function getInspirationFilesUri(item: RetouchCase) {
  return `/api/case/retouch-cases/${item.id}/inspiration-files`;
}

export async function getEnumerations(): Promise<Enumerations> {
  const url = '/api/case/enumerations/';

  const { data } = await axios.get<Enumerations>(url);

  // Sort the data so that it is easier for the user to select items
  data.photographers = _.sortBy(data.photographers, item => item.value);
  data.projectNames = _.sortBy(data.projectNames, item => item.value);
  data.wallColorSuppliers = _.sortBy(data.wallColorSuppliers, item => item.value);
  data.wallPaperSuppliers = _.sortBy(data.wallPaperSuppliers, item => item.value);
  data.floorSuppliers = _.sortBy(data.floorSuppliers, item => item.value);
  data.tilingSuppliers = _.sortBy(data.tilingSuppliers, item => item.value);

  return data;
}

export async function assignCaseOwner(caze: Case, userId: string): Promise<Case> {
  const url = `/api/case/cases/${caze.id}/owner`;

  const body: CaseUserAssignment = { userId };
  const { data } = await axios.post<Case>(url, body);

  return data;
}

export async function assignCasePlanner(caze: Case, userId: string): Promise<Case> {
  const url = `/api/case/cases/${caze.id}/planner`;

  const body: CaseUserAssignment = { userId };
  const { data } = await axios.post<Case>(url, body);

  return data;
}
