import React from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { formatISO, parseISO } from 'date-fns';

import styles from './ImageActivationFields.module.css';

import { Image, ImageDates } from '../../../api/caseApiTypes';
import { EnrichmentMedia } from '../../enrichment/ImageEnrichmentForm';
import { getMediaValue } from './MediaValue';

interface BaseProps extends Props {
  firstField: ImageDates;
  firstFieldLabel: string;
  secondField: ImageDates;
  secondFieldLabel: string;
}

interface Props {
  item: EnrichmentMedia;
  onChange: (partial: Partial<Image>) => void;
  readonly?: boolean;
}

const ImageDateFieldsPair: React.FC<BaseProps> = ({
  item,
  onChange,
  readonly,
  firstField,
  firstFieldLabel,
  secondFieldLabel,
  secondField,
}) => {
  const parseDate = (value?: string) => {
    if (value == null) {
      return undefined;
    }

    return parseISO(value);
  };

  const parseString = (value?: Date | null) => {
    if (value == null) {
      return;
    }

    return formatISO(value, {
      representation: 'date',
    });
  };

  const firstFieldData = parseDate(getMediaValue(item[firstField], undefined, undefined));
  const secondFieldData = parseDate(getMediaValue(item[secondField], undefined, undefined));

  return (
    <div className={styles.container}>
      <DatePicker
        label={firstFieldLabel}
        value={firstFieldData ?? null}
        onChange={v => onChange({ [firstField]: parseString(v) })}
        disabled={readonly}
        maxDate={secondFieldData}
        slotProps={{ textField: { fullWidth: true } }}
      />
      <DatePicker
        label={secondFieldLabel}
        value={secondFieldData ?? null}
        onChange={v => onChange({ [secondField]: parseString(v) })}
        disabled={readonly}
        minDate={firstFieldData}
        slotProps={{ textField: { fullWidth: true } }}
      />
    </div>
  );
};

export const ImageDeadlineDates: React.FC<Props> = props => {
  return (
    <ImageDateFieldsPair
      firstField="photoAndRetouchDeadline"
      firstFieldLabel="Produktionsdeadline"
      secondField="publicationDate"
      secondFieldLabel="Publiceringsdatum"
      {...props}
    />
  );
};

export const ImageActivationDates: React.FC<Props> = props => {
  return (
    <ImageDateFieldsPair
      firstField="activeFrom"
      firstFieldLabel="Aktivt från datum"
      secondField="activeTo"
      secondFieldLabel="Aktivt till datum"
      {...props}
    />
  );
};
