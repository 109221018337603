import { useCallback, useEffect, useMemo } from 'react';

import { FetchResult } from '../api/m3Api';
import { searchProductImageForArticles } from '../features/images/imagesSlice';
import { useAppDispatch, useAppSelector } from './useAppRedux';
import { usePrevious } from './usePrevious';

interface Props {
  articleIds?: string[];
  shouldFetch?: boolean;
}

export const useArticleImages = ({ articleIds = [], shouldFetch = false }: Props) => {
  const dispatch = useAppDispatch();
  const { productImageByArticleId } = useAppSelector(state => state.images);

  const findImageForArticle = useCallback(
    (articleId?: string) => {
      if (articleId === undefined) {
        return { fetchResult: FetchResult.NotFound, image: null };
      }

      const image = productImageByArticleId[articleId];

      if (image === undefined) {
        return { fetchResult: FetchResult.NotSearched, image: null };
      } else if (image === null) {
        return { fetchResult: FetchResult.NotFound, image: null };
      } else {
        return { fetchResult: FetchResult.Found, image };
      }
    },
    [productImageByArticleId],
  );

  const missingProductImages = useMemo(
    () => articleIds.filter(articleId => findImageForArticle(articleId).fetchResult === FetchResult.NotSearched),
    [articleIds, findImageForArticle],
  );
  const previousMissingProductImages = usePrevious(missingProductImages);

  const newMissingProductImages = useMemo(
    () => missingProductImages.filter(articleId => !previousMissingProductImages?.includes(articleId)),
    [missingProductImages, previousMissingProductImages],
  );

  useEffect(() => {
    if (newMissingProductImages.length > 0 && shouldFetch) {
      dispatch(searchProductImageForArticles(newMissingProductImages));
    }
  }, [dispatch, newMissingProductImages, shouldFetch]);

  return { findImageForArticle };
};
