import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProjectTag, SystemTag, UserTag } from '../../api/caseApiTypes';
import * as Api from '../../api/tagApi';

interface TagsState {
  systemTags: SystemTag[];
  userTags: UserTag[];
  projectTags: ProjectTag[];
  error: string | null;
}

const initialState: TagsState = {
  systemTags: [],
  userTags: [],
  projectTags: [],
  error: null,
};

export const TagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    searchSystemTagSuccess(state, action: PayloadAction<SystemTag[]>) {
      state.systemTags = action.payload;
    },
    searchUserTagSuccess(state, action: PayloadAction<UserTag[]>) {
      state.userTags = action.payload;
    },
    searchProjectTagSuccess(state, action: PayloadAction<ProjectTag[]>) {
      state.projectTags = action.payload;
    },
    requestFailed(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
    createSystemTagSuccess(state, action: PayloadAction<SystemTag>) {
      state.systemTags.push(action.payload);
    },
    createUserTagSuccess(state, action: PayloadAction<UserTag>) {
      state.userTags.push(action.payload);
    },
    createProjectTagSuccess(state, action: PayloadAction<ProjectTag>) {
      state.projectTags.push(action.payload);
    },
    updateSystemTagSuccess(state, action: PayloadAction<SystemTag>) {
      const index = state.systemTags.findIndex(t => t.id === action.payload.id);
      if (index !== -1) {
        state.systemTags.splice(index, 1, action.payload);
      }
    },
    updateUserTagSuccess(state, action: PayloadAction<UserTag>) {
      const index = state.userTags.findIndex(t => t.id === action.payload.id);
      if (index !== -1) {
        state.userTags.splice(index, 1, action.payload);
      }
    },
    updateProjectTagSuccess(state, action: PayloadAction<ProjectTag>) {
      const index = state.projectTags.findIndex(t => t.id === action.payload.id);
      if (index !== -1) {
        state.projectTags.splice(index, 1, action.payload);
      }
    },
    deleteSystemTagSuccess(state, action: PayloadAction<string>) {
      const id = action.payload;
      const index = state.systemTags.findIndex(t => t.id === id);
      if (index !== -1) {
        state.systemTags.splice(index, 1);
      }
    },
    deleteUserTagSuccess(state, action: PayloadAction<string>) {
      const id = action.payload;
      const index = state.userTags.findIndex(t => t.id === id);
      if (index !== -1) {
        state.userTags.splice(index, 1);
      }
    },
    deleteProjectTagSuccess(state, action: PayloadAction<string>) {
      const id = action.payload;
      const index = state.projectTags.findIndex(t => t.id === id);
      if (index !== -1) {
        state.projectTags.splice(index, 1);
      }
    },
    errorConsumed(state) {
      state.error = null;
    },
  },
});

export const {
  searchSystemTagSuccess,
  searchUserTagSuccess,
  searchProjectTagSuccess,
  requestFailed,
  createSystemTagSuccess,
  createUserTagSuccess,
  createProjectTagSuccess,
  updateSystemTagSuccess,
  updateUserTagSuccess,
  updateProjectTagSuccess,
  deleteSystemTagSuccess,
  deleteUserTagSuccess,
  deleteProjectTagSuccess,
  errorConsumed,
} = TagsSlice.actions;

export const searchSystemTags = createAsyncThunk<void, { query: string; pageSize?: number }>(
  'system-tags/search',
  async ({ query, pageSize }, { dispatch }) => {
    try {
      const result = await Api.searchSystemTags(query, pageSize);

      dispatch(searchSystemTagSuccess(result.items));
    } catch (error: any) {
      dispatch(requestFailed(error.toString()));
    }
  },
);

export const getAllSystemTags = () => searchSystemTags({ query: '' });

export const searchUserTags = createAsyncThunk<void, { query: string; pageSize?: number }>(
  'user-tags/search',
  async ({ query, pageSize }, { dispatch }) => {
    try {
      const result = await Api.searchUserTags(query, pageSize);

      dispatch(searchUserTagSuccess(result.items));
    } catch (error: any) {
      dispatch(requestFailed(error.toString()));
    }
  },
);

export const getAllUserTags = () => searchUserTags({ query: '' });

export const searchProjectTags = createAsyncThunk<void, { query: string; pageSize?: number }>(
  'project-tags/search',
  async ({ query, pageSize }, { dispatch }) => {
    try {
      const result = await Api.searchProjectTags(query, pageSize);

      dispatch(searchProjectTagSuccess(result.items));
    } catch (error: any) {
      dispatch(requestFailed(error.toString()));
    }
  },
);

export const getAllProjectTags = () => searchProjectTags({ query: '' });

export const createSystemTag = createAsyncThunk<void, SystemTag>('system-tags/create', async (tag, { dispatch }) => {
  try {
    const result = await Api.createSystemTag(tag);

    dispatch(createSystemTagSuccess(result));
  } catch (error: any) {
    dispatch(requestFailed(error.toString()));
  }
});

export const createUserTag = createAsyncThunk<void, UserTag>('user-tags/create', async (tag, { dispatch }) => {
  try {
    const result = await Api.createUserTag(tag);

    dispatch(createUserTagSuccess(result));
  } catch (error: any) {
    dispatch(requestFailed(error.toString()));
  }
});

export const createProjectTag = createAsyncThunk<void, ProjectTag>('project-tags/create', async (tag, { dispatch }) => {
  try {
    const result = await Api.createProjectTag(tag);

    dispatch(createProjectTagSuccess(result));
  } catch (error: any) {
    dispatch(requestFailed(error.toString()));
  }
});

export const updateSystemTag = createAsyncThunk<void, SystemTag>('system-tags/update', async (tag, { dispatch }) => {
  try {
    const result = await Api.updateSystemTag(tag);

    dispatch(updateSystemTagSuccess(result));
  } catch (error: any) {
    dispatch(requestFailed(error.toString()));
  }
});

export const updateUserTag = createAsyncThunk<void, UserTag>('user-tags/create', async (tag, { dispatch }) => {
  try {
    const result = await Api.updateUserTag(tag);

    dispatch(updateUserTagSuccess(result));
  } catch (error: any) {
    dispatch(requestFailed(error.toString()));
  }
});

export const updateProjectTag = createAsyncThunk<void, ProjectTag>('project-tags/create', async (tag, { dispatch }) => {
  try {
    const result = await Api.updateProjectTag(tag);

    dispatch(updateProjectTagSuccess(result));
  } catch (error: any) {
    dispatch(requestFailed(error.toString()));
  }
});

export const deleteSystemTag = createAsyncThunk<void, string>('system-tags/delete', async (id, { dispatch }) => {
  try {
    await Api.deleteSystemTag(id);

    dispatch(deleteSystemTagSuccess(id));
  } catch (error: any) {
    dispatch(requestFailed(error.toString()));
  }
});

export const deleteUserTag = createAsyncThunk<void, string>('user-tags/delete', async (id, { dispatch }) => {
  try {
    await Api.deleteUserTag(id);

    dispatch(deleteUserTagSuccess(id));
  } catch (error: any) {
    dispatch(requestFailed(error.toString()));
  }
});

export const deleteProjectTag = createAsyncThunk<void, string>('project-tags/delete', async (id, { dispatch }) => {
  try {
    await Api.deleteProjectTag(id);

    dispatch(deleteProjectTagSuccess(id));
  } catch (error: any) {
    dispatch(requestFailed(error.toString()));
  }
});

export default TagsSlice.reducer;
