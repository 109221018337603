import { FilterStatePropertyValue } from '../../../../api/filterTypes';
import { Clause, SearchComparison } from '../../../../api/searchApiTypes';
import { ctrDateCondition } from './propResolverHelper';

export const datePropClauseResolver =
  <T>(operator: SearchComparison) =>
  (property: keyof T, propertyValue: FilterStatePropertyValue): Clause<T> => {
    const [date] = propertyValue.value;
    const includeNull = propertyValue.includeNull ?? false;

    return ctrDateCondition(property, includeNull, operator, date);
  };
