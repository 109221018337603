import React from 'react';
import { useState } from 'react';

import { ChevronRight, Delete } from '@mui/icons-material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Badge, IconButton } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

import { FilterPropertyConfig, FilterTreeRootComponentProps, FilterableItem, FilterStatePropertyValue } from '../../../api/filterTypes';

export const FilterComponent: React.FC<FilterTreeRootComponentProps> = ({ filterState, setFilterState, filterConfig }) => {
  type PropType = keyof FilterableItem;

  const [openProperties, setOpenProperties] = useState<(keyof FilterableItem)[]>([]);

  const toggleOpen = (value: PropType) => () => {
    const index = openProperties.indexOf(value);
    const arr = [...openProperties];
    if (index === -1) {
      arr.push(value);
    } else {
      arr.splice(index, 1);
    }
    setOpenProperties(arr);
  };

  const stateSetter = (property: PropType) => (newPropertyState: FilterStatePropertyValue) => {
    const conf = filterConfig[property];
    if (conf == null) return;
    setFilterState({
      ...filterState,
      [property]: newPropertyState,
    });
  };

  const filterCount = (property: PropType): number => {
    return filterState[property]?.value.map(s => s?.length >= 1)?.filter(b => b)?.length || 0;
  };

  const PropertyItemComponent = (property: PropType, config: FilterPropertyConfig | undefined) => {
    if (config == null) {
      return null;
    }
    const open = openProperties.includes(property);

    const { Component, title, alwaysShowFilter } = config;
    const currentState = filterState[property] ?? { value: [] };

    if (alwaysShowFilter != null) {
      return <Component key={property} state={currentState} setState={stateSetter(property)} />;
    }

    return (
      <React.Fragment key={property}>
        <ListItem
          secondaryAction={
            filterCount(property) !== 0 && (
              <IconButton onClick={() => stateSetter(property)({ value: [] })}>
                <Badge badgeContent={filterCount(property)} color="primary">
                  <Delete />
                </Badge>
              </IconButton>
            )
          }
          disablePadding
        >
          <ListItemButton onClick={toggleOpen(property)} dense>
            {open ? <ExpandMore /> : <ChevronRight />}
            {title}
          </ListItemButton>
        </ListItem>
        {open && <Component state={currentState} setState={stateSetter(property)} />}
      </React.Fragment>
    );
  };

  const activePropertyConfigurations = Object.typedKeys(filterConfig)
    .map(property => ({ property, config: filterConfig[property] }))
    .filter(x => x.config != null);

  return <>{activePropertyConfigurations.map(({ property, config }) => PropertyItemComponent(property, config))}</>;
};
