import React from 'react';

import styles from './CaseFormSidebar.module.css';

interface Props {
  children?: React.ReactNode;
}

export const CaseFormSidebar: React.FC<Props> = ({ children }) => {
  return <div className={styles.right}>{children}</div>;
};
