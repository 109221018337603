import { createAsyncThunk, createSlice, PayloadAction, unwrapResult } from '@reduxjs/toolkit';
import { VariantType } from 'notistack';
import { getEnumerations } from './api/caseApi';
import { Enumerations } from './api/caseApiTypes';
import { createSignalRConnection } from './api/signalRConnectionManager';
import { getUser, User } from './api/userApi';
import { getVersion } from './api/versionApi';
import { ThunkConfig } from './app/store';
import { refreshImageIfTracked, updateDerivedImages } from './features/images/imagesSlice';
import { refreshCaseIfTracked, updateAttachmentDerivedImages, updateCasePointersIfActive } from './features/cases/casesSlice';
import { FilterState, FilterableItem } from './api/filterTypes';
import { FeatureFlags, getFeatureFlags } from './api/featureApi';

interface AppState {
  version: string | null;
  user: User | null;
  enumerations: Enumerations | null;
  isAuthenticated: boolean | undefined;
  error: string | null;
  isLoading: boolean;
  isInitialized: boolean;
  toast: ToastNotification | null;
  activeFilterConsumers: Record<string, string[]>;
  filter: Record<string, FilterState<FilterableItem>>;
  useDarkMode?: boolean;
  featureFlags: FeatureFlags;
}

const initialState: AppState = {
  version: null,
  user: null,
  enumerations: null,
  isAuthenticated: undefined,
  error: null,
  isLoading: false,
  isInitialized: false,
  toast: null,
  activeFilterConsumers: {},
  filter: {},
  useDarkMode: true,
  featureFlags: {},
};

export interface ToastNotification {
  message: string;
  variant?: VariantType;
  persist?: boolean;
  preventDuplicate?: boolean;
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    getUserAuthenticated(state, action: PayloadAction<User>) {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    getUserNotAuthenticated(state) {
      state.user = null;
      state.isAuthenticated = false;
    },
    getUserFailed(state, action: PayloadAction<string>) {
      state.user = null;
      state.isAuthenticated = false;
      state.error = action.payload;
    },
    getVersionSuccess(state, action: PayloadAction<string>) {
      state.version = action.payload;
    },
    getVersionFailed(state, action: PayloadAction<string>) {
      state.version = null;
      state.error = action.payload;
    },
    getEnumerationsSuccess(state, action: PayloadAction<Enumerations>) {
      state.enumerations = action.payload;
    },
    getEnumerationsFailed(state, action: PayloadAction<string>) {
      state.enumerations = null;
      state.error = action.payload;
    },
    getAppInitialised(state) {
      state.isInitialized = true;
    },
    pushToast(state, action: PayloadAction<ToastNotification>) {
      state.toast = action.payload;
    },
    toastConsumed(state) {
      state.toast = null;
    },
    setFilterState(state, action: PayloadAction<Record<string, FilterState<FilterableItem>>>) {
      state.filter = action.payload;
    },
    setFeatureFlags(state, { payload: { flags } }: PayloadAction<{ flags: FeatureFlags }>) {
      state.featureFlags = flags;
    },
    getFeatureFlagsFailed(state, { payload: { error } }: PayloadAction<{ error: string }>) {
      state.featureFlags = {};
      state.error = error;
    },
    addActiveFilterConsumer(state, action: PayloadAction<{ filter: string; filterConsumer: string }>) {
      const filter = state.activeFilterConsumers[action.payload.filter];
      if (!filter) {
        state.activeFilterConsumers[action.payload.filter] = [action.payload.filterConsumer];
      } else if (!filter.includes(action.payload.filterConsumer)) {
        filter.push(action.payload.filterConsumer);
      }
    },
    removeActiveFilterConsumer(state, action: PayloadAction<{ filter: string; filterConsumer: string }>) {
      const filter = state.activeFilterConsumers[action.payload.filter];
      state.activeFilterConsumers[action.payload.filter] = filter.filter(consumer => consumer !== action.payload.filterConsumer);
    },
    setUseDarkMode(state, action: PayloadAction<boolean>) {
      state.useDarkMode = action.payload;
    },
  },
});

export const {
  getUserAuthenticated,
  getUserNotAuthenticated,
  getUserFailed,
  getVersionSuccess,
  getVersionFailed,
  getEnumerationsSuccess,
  getEnumerationsFailed,
  getAppInitialised,
  pushToast,
  toastConsumed,
  setFilterState,
  setFeatureFlags,
  getFeatureFlagsFailed,
  addActiveFilterConsumer,
  removeActiveFilterConsumer,
  setUseDarkMode,
} = appSlice.actions;

export default appSlice.reducer;

export const fetchUser = createAsyncThunk('users/get', async (_, { dispatch }) => {
  try {
    const user = await getUser();

    if (user) {
      return dispatch(getUserAuthenticated(user));
    } else {
      dispatch(getUserNotAuthenticated());
    }
  } catch (error: any) {
    dispatch(getUserFailed(error.toString()));
  }

  return Promise.reject();
});

export const fetchFeatureFlags = createAsyncThunk('featuresFlags/get', async (_, { dispatch }) => {
  try {
    const featureFlags = await getFeatureFlags();
    if (featureFlags) {
      return dispatch(setFeatureFlags({ flags: featureFlags }));
    }
  } catch (error: any) {
    dispatch(getFeatureFlagsFailed({ error: error.toString() }));
  }

  return Promise.reject();
});

export const fetchVersion = createAsyncThunk<void, void, ThunkConfig>('version', async (_, { dispatch, getState }) => {
  try {
    const version = await getVersion();

    const currentVersion = getState().app.version;
    if (currentVersion != null && currentVersion !== version) {
      window.location.reload();
    }

    dispatch(getVersionSuccess(version));
  } catch (error: any) {
    dispatch(getVersionFailed(error.toString()));
  }
});

export const fetchEnumerations = createAsyncThunk('enumerations', async (_, { dispatch }) => {
  try {
    const enumerations = await getEnumerations();
    dispatch(getEnumerationsSuccess(enumerations));
  } catch (error: any) {
    dispatch(getEnumerationsFailed(error.toString()));
  }
});

let versionCheckTimeout: NodeJS.Timeout | null = null;

const getDelay = () => 60_000 + Math.ceil(Math.random() * 2000) - 1000;

export const initialiseApp = createAsyncThunk<void, void, ThunkConfig>('app/initialize', async (_, { dispatch, getState }) => {
  const { isInitialized } = getState().app;
  if (isInitialized) {
    throw Error("Undefined behaviour, already initialised SPA can't be re-initialised");
  }

  const userChain = dispatch(fetchUser())
    .then(unwrapResult)
    .then(
      () => {
        dispatch(setupSignalR());
        dispatch(fetchEnumerations());
        dispatch(fetchFeatureFlags());
      },
      () => {},
    );

  const versionChain = dispatch(fetchVersion());

  await Promise.all([userChain, versionChain]).then(() => dispatch(getAppInitialised()));

  const queueVersionCheck = () => {
    versionCheckTimeout = setTimeout(() => {
      dispatch(fetchVersion());
      queueVersionCheck();
    }, getDelay());
  };

  if (versionCheckTimeout != null) {
    clearTimeout(versionCheckTimeout);
  }

  queueVersionCheck();
});

const setupSignalR = createAsyncThunk<void, void, ThunkConfig>('app/signalr', async (_, { dispatch }) => {
  const caseHubConnection = await createSignalRConnection('case');
  caseHubConnection.on('UpdateCase', caseId => {
    dispatch(refreshCaseIfTracked(caseId));
  });
  caseHubConnection.on('UpdateCaseImagePointers', caseId => {
    dispatch(updateCasePointersIfActive(caseId));
  });
  caseHubConnection.on('UpdateAttachmentDerivedImages', (caseId: string, attachmentId: string) => {
    dispatch(updateAttachmentDerivedImages({ caseId, attachmentId }));
  });

  const imageHubConnection = await createSignalRConnection('image');
  imageHubConnection.on('UpdateImage', (imageId: string) => {
    dispatch(refreshImageIfTracked(imageId));
  });
  imageHubConnection.on('UpdateImageDerivedImages', (imageId: string, versionId: string) => {
    dispatch(updateDerivedImages({ imageId, versionId }));
  });
});

export const GetEnumerationByKey = (key: keyof Enumerations) => (state: { app: AppState }) => state.app.enumerations?.[key];
