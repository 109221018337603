import { Article } from '../../api/caseApiTypes';
import { CaseArticles } from '../cases/CaseArticles';
import { CaseField } from '../cases/layoutComponents/CaseField';

export type ArticleChange = (articles: Article[]) => Article[];

interface Props {
  articles: Article[];
  maxArticles: number | undefined;
  noun: string;
  onChange: (change: ArticleChange) => void;
  readonly?: boolean;
}

export const ImageArticles: React.FC<Props> = ({ articles, maxArticles, noun, onChange, readonly }) => {

  const onUpsertArticle = (updated: Article) => onUpsertManyArticles([updated])
  const onUpsertManyArticles = (updates: Article[]) => {
    onChange(oldArticles => {
      const newArticles = oldArticles.slice();
      for (let updated of updates) {
        const existingIndex = oldArticles.findIndex(article =>  article.id === updated.id);
        if (existingIndex < 0) {
          newArticles.push(updated);
        } else {
          newArticles.splice(existingIndex, 1, updated);
        }
      }
      return newArticles;
    });
  };

  const onDeleteArticle = (article: Article) => {
    onChange(oldArticles => oldArticles.filter(other => other.articleId !== article.articleId));
  };

  return (
    <CaseField label={noun}>
      <CaseArticles
        articles={articles}
        onUpsert={onUpsertArticle}
        onAddMany={onUpsertManyArticles}
        onDelete={onDeleteArticle}
        readonly={readonly}
        renderDeliveryStatus={false}
        maxArticles={maxArticles}
        pluralVerb={noun}
      />
    </CaseField>
  );
};
