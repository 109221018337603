import { Tooltip, Checkbox } from '@mui/material';

export type Props = {
  onClick: () => void;
  isSelected: boolean;
};

export function ImageCardSelectButton({ isSelected, onClick }: Props) {
  return (
    <>
      <Tooltip title={`${isSelected ? 'Avmarkera' : 'Markera'} bilden.`}>
        <Checkbox
          color="primary"
          sx={{ opacity: isSelected ? '100%' : '20%', padding: 0 }}
          onClick={onClick}
          checked={isSelected}
          onContextMenu={e => {
            e.preventDefault();
            // handleRightClick(e);
            return false;
          }}
        ></Checkbox>
      </Tooltip>
    </>
  );
}
