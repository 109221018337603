import React from 'react';
import styles from './ColorTag.module.css';
import { Box, Chip } from '@mui/material';

interface Props {
  hex: string;
  label: string;
  size?: 'small' | 'medium';
  onDelete?: () => void;
}

export const ColorTag: React.FC<Props> = ({ hex, label, size, onDelete }) => {
  if (hex == null) {
    return <></>;
  }

  const colorTagStyle = {
    backgroundColor: hex,
  };

  return (
    <Chip
      className={styles.chip}
      avatar={<Box className={styles.color} style={colorTagStyle} />}
      size={size}
      label={label}
      onDelete={onDelete}
    />
  );
};
