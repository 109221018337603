import { CheckBox, Bookmark } from '@mui/icons-material';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { selectActiveClipbook } from '../../clipbooks/clipbooksSlice';
import { useAppSelector } from '../../../hooks/useAppRedux';
import { ImageGridDetailViewImageSource } from './ImagesGridDetailsView';

export const ImageSourceIcon: React.FC<ImageGridDetailViewImageSource | null> = source => {
  switch (source) {
    case 'selected':
      return <CheckBox />;
    case 'clipbook':
      return <Bookmark />;
  }
  return <></>;
};

export const ToggleImageSourceButton: React.FC<{
  source: ImageGridDetailViewImageSource;
  onClick: (source: ImageGridDetailViewImageSource) => void;
}> = ({ source, onClick }) => {
  const activeClipbook = useAppSelector(selectActiveClipbook);

  if (activeClipbook === undefined) {
    if (source === 'clipbook') {
      onClick('selected');
    }
    return <></>;
  }

  const onChange = (_: React.MouseEvent<HTMLElement>, newSource: string) => {
    if (newSource !== null) {
      onClick(newSource as ImageGridDetailViewImageSource);
    }
  };
  return (
    <ToggleButtonGroup sx={{ m: '0 0.5em' }} color="primary" value={source} exclusive onChange={onChange} aria-label="bildkälla">
      <ToggleButton title="Markerade bilder i detaljvy" value="selected">
        {ImageSourceIcon('selected')}
      </ToggleButton>
      <ToggleButton title="Aktivgrupp i detaljvy" value="clipbook">
        {ImageSourceIcon('clipbook')}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
