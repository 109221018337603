import React from 'react';
import styles from './CaseContainer.module.css';
import { Box } from '@mui/material';
import { CaseType, CaseTypeConverter } from '../../../api/caseApiTypes';

interface Props {
  children?: React.ReactNode;
  type: CaseType;
  disableFlair?: boolean;
  className?: string;
  component?: React.ElementType;
  style?: React.CSSProperties;
}

export const CaseContainer: React.FC<Props & React.HTMLAttributes<HTMLElement>> = ({
  children,
  type,
  className,
  disableFlair = false,
  ...rest
}) => {
  const typeSlug = CaseTypeConverter.ToSlug(type);
  if (typeSlug == null) {
    return <></>;
  }

  const classes = [styles.container];

  if (!disableFlair && typeSlug in styles) {
    // @ts-ignore
    classes.push(styles.flair, styles[typeSlug]);
  }

  if (className != null) {
    classes.push(className);
  }

  return (
    <Box className={classes.join(' ')} {...rest}>
      {children}
    </Box>
  );
};
