import React, { useEffect, useState } from 'react';

import { Add, Delete } from '@mui/icons-material';
import { Badge, Box, IconButton } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

import { ProjectTag, SystemTag } from '../../../../api/caseApiTypes';
import { FilterTreeSubComponentProps } from '../../../../api/filterTypes';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useAppRedux';
import { ColorTag } from '../../../tags/ColorTag';
import { SelectMultipleProjectTagModal } from '../../../tags/SelectMultipleProjectTagModal';
import { SelectSystemTagModal, TagModalProps } from '../../../tags/SelectSystemTagModal';
import { getAllProjectTags, getAllSystemTags } from '../../../tags/TagsSlice';

type FilterTreeTagComponentProps = FilterTreeSubComponentProps & {
  tagType: 'systemTags' | 'projectTags';
  title: string;
  modal: React.FC<TagModalProps>;
};

const TagComponent: React.FC<FilterTreeTagComponentProps> = ({ state, setState, tagType, title, modal }) => {
  const tags = useAppSelector(state => state.tags[tagType]);
  const [open, setOpen] = useState(false);
  const setter = (value: string[]) => setState({ ...state, value });
  const propValues = state.value ?? [];

  const removeFromState = (key: string) => () => {
    setter(propValues.filter(s => s !== key));
  };

  const addToState = (keys: (ProjectTag | SystemTag)[]) => {
    const newKeys = keys.map(tag => tag.id).filter(k => !propValues.includes(k));
    setter([...propValues, ...newKeys]);
    setOpen(false);
  };

  const filterCount = propValues.length;

  const visibleTags = tags.filter(t => propValues.includes(t.id));

  return (
    <Box>
      <ListItem
        secondaryAction={
          filterCount !== 0 && (
            <IconButton onClick={() => setter([])}>
              <Badge badgeContent={filterCount} color="primary">
                <Delete />
              </Badge>
            </IconButton>
          )
        }
        disablePadding
      >
        <ListItemButton onClick={() => setOpen(true)} dense>
          <Add />
          {title}
        </ListItemButton>
      </ListItem>
      {modal({ open, onClose: () => setOpen(false), onSave: addToState, saveLabel: 'Filtrera' })}
      <Box display="flex" flexDirection="column" paddingLeft="1em" alignItems="baseline">
        {visibleTags.map(tag => (
          <ColorTag key={tag.id} size="small" hex={tag.color} label={tag.name} onDelete={removeFromState(tag.id)} />
        ))}
      </Box>
    </Box>
  );
};

export const FilterTreeSystemTagComponent: React.FC<FilterTreeSubComponentProps> = rest => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllSystemTags());
  }, [dispatch]);

  return <TagComponent tagType="systemTags" title="Taggar" {...rest} modal={SelectSystemTagModal} />;
};

export const FilterTreeProjectTagComponent: React.FC<FilterTreeSubComponentProps> = rest => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllProjectTags());
  }, [dispatch]);

  return <TagComponent tagType="projectTags" title="Projekt" {...rest} modal={SelectMultipleProjectTagModal} />;
};
