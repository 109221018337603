import { useAppSelector } from '../../../hooks/useAppRedux';
import { AddToClipbookIconButton } from '../../clipbooks/components/AddToClipbookIconButton';
import { useSelectionContext } from './selectionContextContext';

export function AddSelectedToClipbookIconButton() {
  const selectionContext = useSelectionContext();
  const selectedImages = useAppSelector(state => state.images.selected[selectionContext]);
  const selectedIds = selectedImages.map(m => m.id);
  const title = 'Lägg till valda bilder i den aktiva gruppen';
  return <AddToClipbookIconButton imageIds={selectedIds} title={title} />;
}
