import { IsEqual } from './ObjectHelpers';

export function areListsEqual<T>(list1: T[], list2: T[]): boolean {
  if (list1.length !== list2.length) {
    return false;
  }
  for (let i = 0; i < list1.length; i++) {
    if (typeof list1[i] === 'object' && !IsEqual(list1[i], list2[i])) {
      return false;
    } else if (list1[i] !== list2[i]) {
      return false;
    }
  }
  return true;
}
