export function createBatches<T>(items: T[], size: number): T[][] {
  let result: T[][] = [];

  for (let i = 0; i < items.length; i += size) {
    result.push(items.slice(i, i + size));
  }
  return result;
}

export async function batchExecute<T>(items: T[], batchSize: number, action: (item: T) => Promise<void>) {
  const batches = createBatches(items, batchSize);
  for (let batch of batches) {
    let tasks = [];
    for (let item of batch) {
      tasks.push(action(item));
    }
    await Promise.allSettled(tasks);
  }
}
