import React from 'react';
import { Box, Divider, IconButton, Paper, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { EventContentArg } from '@fullcalendar/core';

import styles from './CalendarEvent.module.css';
import { Case, CaseType, CaseTypeConverter, GetCaseName, GetProductNameForCase, lookupEnum, UserTag } from '../../api/caseApiTypes';
import { CaseContainer } from '../cases/layoutComponents/CaseContainer';
import { ArticleIdsList } from '../articles/ArticleIdsList';
import { SelectableUserTag } from '../tags/SelectableUserTag';
import { useAppSelector } from '../../hooks/useAppRedux';
import { Videocam } from '@mui/icons-material';

interface Props {
  eventInfo: EventContentArg;
  onUserTagSelect: (userTag: UserTag) => void;
  onRemove: (id: string) => void;
  compact?: boolean;
  readonly?: boolean;
  item?: Case;
}

export const CalendarEvent: React.FC<Props> = ({ eventInfo, onUserTagSelect, onRemove, compact, readonly, item }) => {
  const photographerName = lookupEnum(useAppSelector(state => state.app.enumerations)?.photographers, item?.photographer);
  const stylistName = lookupEnum(useAppSelector(state => state.app.enumerations)?.stylists, item?.stylist);

  if (item == null) {
    return <></>;
  }

  const onClickOnRemove = (event: React.MouseEvent) => {
    if (window.confirm(`Är du säker på att du vill ta bort ${GetCaseName(item!)} från kalendern?`)) {
      onRemove(item!.id);
    }

    event.stopPropagation();
  };

  const openCaseInNewTab = () => {
    window.open(`/case/${item?.lookupEntry ?? eventInfo.event.id}`, '_blank');
  };

  // If this case contains a video request, but is not a video case, display an icon
  const containsVideo = item.videoRequest != null || item.type === CaseType.Video;

  return (
    <CaseContainer component={Paper} type={item.type}>
      <Box className={styles.event} boxShadow={3} onClick={openCaseInNewTab}>
        <Box className={styles.eventHeader}>
          <div className={styles.iconArea}>
            {!readonly && (
              <IconButton size="small" onClick={onClickOnRemove}>
                <CancelIcon fontSize="small" />
              </IconButton>
            )}
            {containsVideo && <Videocam />}
          </div>
          <Typography variant="caption">{eventInfo.timeText} - </Typography>
          <Typography variant="caption">{CaseTypeConverter.ToName(item.type)}</Typography>
          <Typography variant="subtitle1">{item.title}</Typography>
          <Typography variant="subtitle2">{GetProductNameForCase(item)}</Typography>
          {item.photographer && (
            <>
              <Divider />
              <Typography variant="subtitle2">{photographerName}</Typography>
            </>
          )}
          {item.stylist && (
            <>
              <Divider />
              <Typography variant="subtitle2">{stylistName}</Typography>
            </>
          )}
        </Box>
        {!compact && (
          <Box className={styles.eventBody}>
            <Typography variant="body2">{item?.description}</Typography>
          </Box>
        )}
        <Box className={styles.eventFooter}>
          <ArticleIdsList item={item} />
          <Box>
            {item.userTags?.map((tag, index) => (
              <SelectableUserTag key={index} userTag={tag} size="small" onSelect={() => onUserTagSelect(tag)} />
            ))}
          </Box>
        </Box>
      </Box>
    </CaseContainer>
  );
};
