import React from 'react';

import { Article, Attachment, AttachmentCategory, Case, VideoCase } from '../../../api/caseApiTypes';
import { CaseArticles } from '../CaseArticles';
import { CaseDetailEnrichmentSubForm } from './CaseDetailEnrichmentSubForm';
import { CaseFormSection } from './CaseFormSection';
import { CaseAttachments } from '../CaseAttachments';
import { Right } from '../../../api/userApi';
import { CaseVideoRequestForm } from './CaseVideoRequestForm';

type TCase = VideoCase;

interface Props {
  caze: TCase;
  isNewCase?: boolean;
  readonly?: boolean;
  onChange: (partial: Partial<Case>) => void;
  onUpsertArticle?: (article: Article) => void;
  onUpsertManyArticles?: (articles: Article[]) => void;
  onDeleteArticle?: (article: Article) => void;
  onDeleteAttachment: (attachment: Attachment) => void;
}

export const VideoCaseDetailFormFields: React.FC<Props> = ({
  caze,
  isNewCase,
  readonly,
  onChange,
  onUpsertArticle,
  onUpsertManyArticles,
  onDeleteArticle,
  onDeleteAttachment,
}) => {
  return (
    <>
      <CaseFormSection caseId={caze.id} title="Artiklar" description="Dessa artiklar ska vara med i filmerna">
        <CaseArticles
          articles={caze.articles}
          onUpsert={onUpsertArticle}
          onAddMany={onUpsertManyArticles}
          onDelete={onDeleteArticle}
          isNewCase={isNewCase}
          readonly={readonly}
          renderExtraInfo
        />
      </CaseFormSection>

      <CaseVideoRequestForm caze={caze}>
        <CaseAttachments
          caze={caze}
          onDelete={onDeleteAttachment}
          category={AttachmentCategory.SupplementaryInformation}
          right={Right.WriteCase}
          isNewCase={isNewCase}
        />
      </CaseVideoRequestForm>
      <CaseDetailEnrichmentSubForm caze={caze} onChange={onChange} />
    </>
  );
};
