import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/useAppRedux';
import { useNavigate, useParams } from 'react-router-dom';
import { CaseType, CaseTypeConverter } from '../../api/caseApiTypes';
import { CaseDetail } from './CaseDetail';
import { cleanupCaseDetail, initializeCaseDetailItem } from './caseDetailSlice';
import { ResetAppTitle, SetAppTitle } from '../../helpers/AppTitleHelpers';
import { Routes } from '../../App';

export const CreateCase = () => {
  const { typeSlug } = useParams();
  const caseType = CaseTypeConverter.ToType(typeSlug);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const item = useAppSelector(state => state.caseDetail.case);

  useEffect(() => {
    SetAppTitle('Ny beställning', CaseTypeConverter.ToName(caseType));
    dispatch(initializeCaseDetailItem({ caseType }));
    return () => {
      ResetAppTitle();
      dispatch(cleanupCaseDetail());
    };
  }, [dispatch, caseType]);

  // DiningSet cannot be created as of 2023-10
  // To ensure that no one creates a DiningSet case, we redirect to the cases page
  if (caseType === CaseType.DiningSet) {
    navigate(Routes.Cases);
    return null;
  }

  if (item == null) {
    return null;
  }

  return <CaseDetail item={item} isNewCase={true} />;
};
