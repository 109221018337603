import React, { SyntheticEvent, useEffect, useState } from 'react';

import { Alert, Snackbar } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../hooks/useAppRedux';
import { errorConsumed as caseDetailErrorConsumed } from '../cases/caseDetailSlice';
import { errorConsumed as caseErrorConsumed } from '../cases/casesSlice';
import { errorConsumed as imageErrorConsumed } from '../images/imagesSlice';
import { errorConsumed as tagErrorConsumed } from '../tags/TagsSlice';

export const ErrorNotifier: React.FC = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const caseError = useAppSelector(state => state.cases.error);
  const caseDetailError = useAppSelector(state => state.caseDetail.error);
  const imageError = useAppSelector(state => state.images.error);
  const tagError = useAppSelector(state => state.tags.error);

  // It shouldn't be an issue, it isn't for ImageSearch but when TagSearch is cancelled it throws an error.
  // SO we ignore it instead of showing an error message.
  const wasCancelled = (error: string | null) => error && error.includes('CanceledError');

  useEffect(() => {
    setOpen([caseError, caseDetailError, imageError, tagError].some(error => error != null && !wasCancelled(error)));
  }, [caseError, caseDetailError, imageError, tagError]);

  const handleClose = (_: SyntheticEvent | Event, reason?: string) => {
    if (reason !== 'clickaway') {
      dispatch(caseErrorConsumed());
      dispatch(caseDetailErrorConsumed());
      dispatch(imageErrorConsumed());
      dispatch(tagErrorConsumed());
    }
  };

  let errorText = 'Hoppsan, något gick fel! Kontakta support om problemet kvarstår.';

  // This is a hack to detect 413 errors. The backend should return a proper error enum so we can have proper error messages.
  // mio.photo is behind CloudFlare which limits all requests to 100MB and some endpoints have a limit of 1.83GB(2_000_000_000 bytes).
  const is413Error = (errors: (string | null)[]) => errors.some(error => error && error.includes('413'));
  if (is413Error([caseError, caseDetailError, imageError, tagError])) {
    errorText = 'Filen/filerna är för stor. Kontakta support om problemet kvarstår.';
  }

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={10000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {errorText}
        </Alert>
      </Snackbar>
    </div>
  );
};
