import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks/useAppRedux';
import { BatchMediaSave, BatchMediaSaveState, closeBatchMediaSave } from '../images/imagesSlice';
import { pushToast } from '../../appSlice';
import { useEffect } from 'react';

function BatchMediaSaveInProgressContent({ batchSave }: { batchSave: BatchMediaSave }) {
  const progress = ((batchSave.numFailed + batchSave.numSuccessful) / batchSave.numTotal) * 100;

  return (
    <>
      <DialogTitle>{batchSave.operation}</DialogTitle>
      <DialogContent>
        <LinearProgress variant="determinate" value={progress} />
      </DialogContent>
    </>
  );
}

function BatchMediaSaveFinishedContent({ batchSave }: { batchSave: BatchMediaSave }) {
  const dispatch = useAppDispatch();

  const close = () => dispatch(closeBatchMediaSave());

  const hasFailed = batchSave.numFailed > 0;

  useEffect(() => {
    if (!hasFailed && batchSave.state === BatchMediaSaveState.Finished) {
      dispatch(closeBatchMediaSave());
      dispatch(
        pushToast({
          message: `${batchSave.numSuccessful} bilder sparade`,
          variant: 'success',
          persist: false,
        }),
      );
    }
  }, [hasFailed, dispatch, batchSave]);

  return (
    <>
      <DialogTitle>{batchSave.operation} - klar</DialogTitle>
      <DialogContent>
        {batchSave.numSuccessful > 0 && <Typography>{batchSave.numSuccessful} lyckades</Typography>}
        {hasFailed && <Typography>{batchSave.numFailed} misslyckades:</Typography>}
        {batchSave.failures.map(f => (
          <Typography key={f.media.id}>
            {f.media.productName} - {f.media.versions?.[f.media.activeVersion || '']?.fileName}
          </Typography>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Stäng</Button>
      </DialogActions>
    </>
  );
}

export function BatchMediaSaveProgress() {
  const batchSave = useAppSelector(state => state.images.batchMediaSave);

  // Hide when no save in progress or only save for one item
  if (!batchSave || batchSave.numTotal <= 1) return <></>;

  const isInProgress = batchSave.state === BatchMediaSaveState.InProgress;

  return (
    <Dialog open={true} fullWidth={true} maxWidth="md">
      {isInProgress ? <BatchMediaSaveInProgressContent batchSave={batchSave} /> : <BatchMediaSaveFinishedContent batchSave={batchSave} />}
    </Dialog>
  );
}
