import React, { useCallback, useState } from 'react';

import { CheckCircleOutlined, RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { Box, Button, Card, CardMedia, Stack, Typography } from '@mui/material';

import styles from './ImageCardVersionDetails.module.css';

import { Image, ImageOrigin, ImageOriginMetaData, ImageOriginMetadataLabelType, ImageVersion } from '../../api/caseApiTypes';
import { SlowCircularProgress } from '../../commonComponents/SlowCircularProgress';
import { GetThumbnailUri, HasThumbnail } from '../../helpers/DerivedImageHelpers';
import { ByCreatedAt } from '../../helpers/Sorting';
import { CaseDialog } from '../cases/layoutComponents/CaseDialog';
import { formatDateTime } from '../date/DateFormatter';

interface Props {
  image: Image;
  displayedVersion?: string;
  disableInteraction?: boolean;
  onActiveVersionChange?: (activeVersion: string) => void;
}

export const ImageCardVersionDetails: React.FC<Props> = ({ image, displayedVersion, disableInteraction, onActiveVersionChange }) => {
  displayedVersion ??= image.activeVersion;
  const displayedVersionData = image.versions[displayedVersion];

  const [detailsOpen, setDetailsOpen] = useState(false);
  const closeDetails = useCallback(() => setDetailsOpen(false), [setDetailsOpen]);

  const versionsArray = Object.values(image.versions).sort(ByCreatedAt('asc'));

  if (versionsArray.length < 2) {
    return null;
  }

  if (displayedVersionData == null) {
    // sometimes when uploading new images,
    // the image.versions does not yet contain the new image.
    // In that case we just return
    return null;
  }

  const openDetails = disableInteraction ? undefined : () => setDetailsOpen(true);
  const classes: string[] = [];
  if (image.activeVersion !== displayedVersion) {
    classes.push(styles.versionWarning);
  }
  if (!disableInteraction) {
    classes.push(styles.versionLabel);
  }

  const imageVersionNumber = versionsArray.findIndex(v => v.id === displayedVersion) + 1;
  const imageVersionTitle = `Version: ${imageVersionNumber} (${formatDateTime(displayedVersionData.createdAt)})`;
  return (
    <>
      <Typography
        gutterBottom
        variant="caption"
        component="p"
        title={imageVersionTitle}
        onClick={openDetails}
        className={classes.join(' ')}
        whiteSpace="nowrap"
      >
        v. {imageVersionNumber}/{versionsArray.length}
      </Typography>
      {!disableInteraction && (
        <ImageCardVersionDetailsDialog
          image={image}
          displayedVersion={displayedVersion}
          open={detailsOpen}
          onClose={closeDetails}
          onActiveVersionChange={onActiveVersionChange}
        />
      )}
    </>
  );
};

interface ModalProps {
  image: Image;
  displayedVersion: string;
  open: boolean;
  onClose: () => void;
  onActiveVersionChange?: (activeVersion: string) => void;
}

const ImageCardVersionDetailsDialog: React.FC<ModalProps> = ({ image, displayedVersion, open, onClose, onActiveVersionChange }) => (
  <CaseDialog isVisible={open} onClose={onClose}>
    <ImageCardVersionDetailsContent image={image} displayedVersion={displayedVersion} onActiveVersionChange={onActiveVersionChange} />
  </CaseDialog>
);

interface ContentProps {
  image: Image;
  displayedVersion: string;
  onActiveVersionChange?: (activeVersion: string) => void;
}

export const ImageCardVersionDetailsContent: React.FC<ContentProps> = ({ image, displayedVersion, onActiveVersionChange }) => {
  const activeVersionId = image.activeVersion;
  const versionsArray = Object.values(image.versions).sort(ByCreatedAt('asc'));

  const [selectedVersionId, setSelectedVersionId] = useState(displayedVersion);
  const selectedVersion = image.versions[selectedVersionId];
  const selectedVersionActive = selectedVersionId === activeVersionId;
  const selectedVersionNumber = versionsArray.findIndex(v => v.id === selectedVersionId) + 1;

  const selectedVersionThumbnail = HasThumbnail(selectedVersion, 1920) ? (
    <CardMedia component="img" image={GetThumbnailUri(selectedVersion, 1920)} className={styles.selectedImage} alt="Aktiv version" />
  ) : (
    <SlowCircularProgress />
  );

  const buttonText = selectedVersionActive ? 'Redan aktiv' : 'Sätt som aktiv version';

  const selectedVersionDetails = (
    <Stack direction="column">
      <Box>
        <Typography variant="subtitle2">Version: {selectedVersionNumber}</Typography>
        <Typography variant="subtitle2">Filnamn: {selectedVersion.fileName}</Typography>
        <Typography variant="subtitle2">Skapad: {formatDateTime(selectedVersion.createdAt)}</Typography>
        <ImageCardVersionDetailsOrigin origin={selectedVersion.origin} kind="long" />
      </Box>
      {onActiveVersionChange && (
        <Button
          variant="contained"
          startIcon={<CheckCircleOutlined />}
          onClick={() => onActiveVersionChange(selectedVersionId)}
          disabled={selectedVersionActive}
        >
          {buttonText}
        </Button>
      )}
    </Stack>
  );

  const selectedVersionView = (
    <Stack justifyContent="space-between" className={styles.contentArea}>
      {selectedVersionThumbnail}
      {selectedVersionDetails}
    </Stack>
  );

  const renderVersionCard = (version: ImageVersion, idx: number) => {
    const cardActive = version.id === activeVersionId;
    const versionNumber = idx + 1;

    const radioButton = (
      <Stack justifyContent="center" padding="0.3rem">
        {cardActive ? <RadioButtonChecked /> : <RadioButtonUnchecked />}
      </Stack>
    );

    const cardMedia = HasThumbnail(version, 320) ? (
      <CardMedia className={styles.media} component="img" image={GetThumbnailUri(version, 320)} />
    ) : (
      <CardMedia className={styles.media} component={SlowCircularProgress} />
    );

    return (
      <Card
        className={styles.card}
        raised={version.id === selectedVersionId}
        key={version.id}
        onClick={() => setSelectedVersionId(version.id)}
      >
        {radioButton}
        {cardMedia}
        <Stack className={styles.cardInfo} justifyContent="center">
          <Typography variant="subtitle2">Version: {versionNumber}</Typography>
          <Typography variant="subtitle2">Skapad: {formatDateTime(version.createdAt)}</Typography>
          <ImageCardVersionDetailsOrigin origin={version.origin} />
        </Stack>
      </Card>
    );
  };

  const versionList = (
    <Stack alignItems="center" spacing={2} className={styles.versionList}>
      {versionsArray.map(renderVersionCard)}
    </Stack>
  );

  return (
    <Stack direction="row" className={styles.container} spacing={2}>
      {selectedVersionView}
      {versionList}
    </Stack>
  );
};

const ImageCardVersionDetailsOrigin: React.FC<{ origin: ImageOrigin; kind?: keyof ImageOriginMetadataLabelType }> = ({
  origin: { originSystem },
  kind = 'name',
}) => {
  const meta = ImageOriginMetaData[originSystem];

  return <Typography variant="subtitle2">Från: {meta[kind]}</Typography>;
};
