import React, { useEffect } from 'react';
import { SketchPicker } from 'react-color';
import styles from './SystemTagModal.module.css';

import { Box, Grid, TextField } from '@mui/material';

import { ColorTag } from './ColorTag';
import { SystemTag } from '../../api/caseApiTypes';
import { useState } from 'react';
import { CaseDialog } from '../cases/layoutComponents/CaseDialog';
import { IsDirty } from '../../helpers/ObjectHelpers';

interface Props {
  tag: SystemTag;
  open: boolean;
  onSave: (tag: SystemTag) => void;
  onClose: () => void;
  onEdit: (tag: SystemTag) => void;
}

export const SystemTagModal: React.FC<Props> = ({ tag, open, onSave, onClose, onEdit }) => {
  const [pendingChange, setPendingChange] = useState(tag);
  const [isDirty, setIsDirty] = useState(IsDirty(tag, pendingChange));

  useEffect(() => {
    if (pendingChange != null) {
      setIsDirty(IsDirty(pendingChange, tag));
    }
  }, [pendingChange, tag]);

  const saveDisabled = !isDirty;

  const onChange = (partial: Partial<SystemTag>) => {
    setPendingChange(Object.assign({}, pendingChange, partial));
  };

  const validateSketchPickerSurrogate = (
    <input style={{ height: 0, width: 0, opacity: 0 }} required type={'text'} value={pendingChange.color}></input>
  );

  return (
    <CaseDialog
      isVisible={open}
      onClose={onClose}
      saveDisabled={saveDisabled}
      onSave={() => (pendingChange.id ? onEdit(pendingChange) : onSave(pendingChange))}
    >
      <Grid className={styles.modalContainer} container direction="row" justifyContent="center" alignItems="center">
        <Box className={styles.modalContent}>
          <Box className={styles.picker}>
            <SketchPicker color={pendingChange.color} onChange={props => onChange({ color: props.hex })} />
            {validateSketchPickerSurrogate}
          </Box>
          <Box className={styles.form}>
            <Box>
              <Box className={styles.tagPreview}>
                <ColorTag hex={pendingChange.color} label={pendingChange.name} size="small" />
              </Box>
              <TextField
                className={styles.modalInput}
                name="name"
                value={pendingChange.name}
                label="Namn"
                onChange={v => onChange({ name: v.currentTarget.value })}
                required
              />
              <TextField
                className={styles.modalInput}
                name="description"
                value={pendingChange.description}
                label="Beskrivning"
                onChange={v => onChange({ description: v.currentTarget.value })}
              />
            </Box>
          </Box>
        </Box>
      </Grid>
    </CaseDialog>
  );
};
