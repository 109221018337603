import React, { useEffect, useState } from 'react';
import styles from './Login.module.css';
import { Button, TextField } from '@mui/material';
import { useAppSelector } from '../../hooks/useAppRedux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { QueryParam, Routes } from '../../App';

export const Login = () => {
  const [searchParams] = useSearchParams();
  const redirectToValue = searchParams.get(QueryParam.RedirectTo);
  const [email, setEmail] = useState('');

  const { isAuthenticated } = useAppSelector(state => state.app);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated && !window.location.pathname.startsWith(Routes.Images)) {
      navigate(Routes.Images, { replace: true });
    }
  }, [isAuthenticated, navigate]);

  const challenge = (event: React.FormEvent<HTMLFormElement>) => {
    let redirectToQueryParam = redirectToValue ? `&${QueryParam.RedirectTo}=${redirectToValue}` : '';
    let emailQueryParam = email ? `&${QueryParam.Email}=${email}` : '';
    window.location.href = `/user/challenge?${emailQueryParam}${redirectToQueryParam}`;
    event.preventDefault();
  };

  return (
    <div className={styles.container}>
      <div className={styles.image} />

      <form className={styles.controls} onSubmit={challenge}>
        <TextField type="email" id="email" label="Email" variant="outlined" autoFocus={true} onChange={e => setEmail(e.target.value)} />

        <Button type="submit" variant="contained" color="primary">
          Logga In
        </Button>
      </form>
    </div>
  );
};
