import React, { useCallback } from 'react';

import styles from './CaseMedia.module.css';

import { Case, Image, ImagePointer, ImageState } from '../../api/caseApiTypes';
import { Right } from '../../api/userApi';
import { useAppDispatch, useAppSelector } from '../../hooks/useAppRedux';
import { useEitherRights } from '../../hooks/useRights';
import { AdditionalAction, ImageCard } from '../image/imageCard/ImageCard';
import { ImageCardAdd } from '../image/ImageCardAdd';
import { caseReviewPending, removeImage, setImageActiveVersion, uploadImage, uploadVideo } from './caseDetailSlice';
import { UploadProgressHandlerForFile } from '../../api/commonApiTypes';
import { Button } from '@mui/material';
import { ShowDeliveryDoneButton } from './forms/CaseFormLogic';

type UploadMediaAction = typeof uploadImage | typeof uploadVideo;

interface Props {
  className?: string;
  caze: Case;
  caseReadonly?: boolean;
  placeholderIfEmpty?: boolean;
  supportsMultipleImages?: boolean;
  CardWrapper?: ImageCardWrapper;
  disableAddNew?: boolean;
  highlightedImageIds?: string[];
  mediaPointers: ImagePointer[];
  uploadMediaAction: UploadMediaAction;
  showDeletedImages?: boolean;
}

export const CaseMedia: React.FC<Props> = ({
  caze,
  mediaPointers,
  className,
  caseReadonly,
  placeholderIfEmpty,
  CardWrapper,
  disableAddNew,
  highlightedImageIds,
  uploadMediaAction,
  showDeletedImages = false
}) => {
  const imagesById = useAppSelector(state => state.images.imagesById);
  const { imageUploadInProgress } = useAppSelector(state => state.caseDetail);
  const userCanUpload = useEitherRights([Right.ManageImage, Right.Administration]);
  const dispatch = useAppDispatch();

  const onImageUpload = useCallback(
    (files: File[], onUploadProgress: UploadProgressHandlerForFile) => {
      dispatch(uploadMediaAction({ item: caze, files: files, onUploadProgress: onUploadProgress }));
    },
    [dispatch, caze, uploadMediaAction],
  );

  const onImageActiveVersionChange = useCallback(
    (imageId: string, activeVersion: string) => {
      dispatch(setImageActiveVersion({ caseId: caze.id, imageId, activeVersion }));
    },
    [dispatch, caze.id],
  );

  const canAddNew = userCanUpload && !disableAddNew;

  if (!userCanUpload && (!caze || !mediaPointers || mediaPointers.length === 0)) {
    if (placeholderIfEmpty) {
      return (
        <div className={className}>
          <div className={styles.container}>
            <ImageCard caze={caze} className={styles.card} />
          </div>
        </div>
      );
    }

    return null;
  }

  const getCard = ({ imageVersion, imageId }: ImagePointer) => {
    const image = imagesById[imageId];

    if (image && !showDeletedImages && (image?.state === ImageState.Deleted || image?.removedFromPim)) {
      return;
    }

    const canDelete = Object.values(image?.versions ?? {}).every(version => version.origin?.originIdentifier === caze.id);

    const removeFunc = () => {
      dispatch(removeImage({ caze, image }));
    };

    const onRemove = canDelete ? removeFunc : undefined;

    const actions: AdditionalAction[] = [];

    const isHighlighted = highlightedImageIds?.includes(imageId) ?? false;

    const classes = [styles.card];
    if (isHighlighted) {
      classes.push(styles.highlight);
    }

    const card = (
      <ImageCard
        key={imageId}
        image={image}
        caze={caze}
        className={classes.join(' ')}
        onRemove={onRemove}
        onImageActiveVersionChange={onImageActiveVersionChange}
        readonly={!userCanUpload}
        additionalActions={actions}
        enableReplacement={userCanUpload}
        enableVariantCreation={userCanUpload}
        // Previously, the Case's imagePointers were used to determine which version to display.
        // But Mio was a bit confused by the behaviour, so we now display the active version instead.
        // displayedVersion={imageVersion}
        fileUploadInProgress={imageUploadInProgress > 0}
      />
    );

    if (!CardWrapper) {
      return card;
    }

    return <CardWrapper key={imageId} image={image} imageCard={card} readonly={caseReadonly} />;
  };
  const deliveryDone = () => {
    dispatch(caseReviewPending(caze));
  };
  const showDeliverDoneButton = ShowDeliveryDoneButton(caze);

  return (
    <>
      <div className={className}>
        {showDeliverDoneButton && (
          <div className={styles.actionRow}>
            <Button variant="contained" color="primary" onClick={deliveryDone}>
              Leverans klart
            </Button>
          </div>
        )}
        <div className={styles.container}>
          {canAddNew && (
            <ImageCardAdd onImageUpload={onImageUpload} className={styles.card} fileUploadInProgress={imageUploadInProgress > 0} />
          )}
          {mediaPointers?.map(getCard).reverse()}
        </div>
      </div>
    </>
  );
};

export interface WrapperProps {
  image: Image;
  imageCard: JSX.Element;
  readonly?: boolean;
}

export type ImageCardWrapper = React.FC<WrapperProps>;
