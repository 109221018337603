import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/useAppRedux';

import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { parseISO } from 'date-fns';

import { ProjectTag } from '../../api/caseApiTypes';
import { getAllProjectTags } from './TagsSlice';
import { ColorTag } from './ColorTag';
import { SelectTagModal } from './SelectTagModal';

interface Props {
  open: boolean;
  onSave: (tag: ProjectTag) => void;
  onClose: () => void;
}

export const SelectProjectTagModal: React.FC<Props> = ({ open, onSave, ...rest }) => {
  const dispatch = useAppDispatch();

  const { projectTags } = useAppSelector(state => state.tags);
  const [selectedTag, setSelectedTag] = useState<ProjectTag | undefined>(undefined);
  const [currentTime] = useState(new Date());

  useEffect(() => {
    if (open) {
      dispatch(getAllProjectTags());
    }
  }, [dispatch, open]);

  const onSaveTags = () => {
    if (selectedTag) {
      onSave(selectedTag);
    }
    setSelectedTag(undefined);
  };

  const isActive = useCallback(
    (tag: ProjectTag) => {
      let isActive = true;

      if (tag.activeFrom != null) {
        const activeFrom = parseISO(tag.activeFrom);
        isActive = isActive && currentTime >= activeFrom;
      }

      if (tag.activeTo != null) {
        const activeTo = parseISO(tag.activeTo);
        isActive = isActive && currentTime <= activeTo;
      }

      return isActive;
    },
    [currentTime],
  );

  const selectableTags = projectTags.filter(isActive);

  return (
    <SelectTagModal onSave={onSaveTags} open={open} {...rest}>
      <Autocomplete
        options={selectableTags}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            <ColorTag hex={option.color} label={option.name} size="small" />
          </li>
        )}
        renderTags={tags => tags.map(tag => <ColorTag key={tag.id} hex={tag.color} label={tag.name} size="small" />)}
        getOptionLabel={option => option.name}
        noOptionsText="Inga matchande taggar"
        onChange={(_, tag) => setSelectedTag(tag ?? undefined)}
        renderInput={params => <TextField {...params} label="Välj/sök bland taggar" />}
      />
    </SelectTagModal>
  );
};
