import React, { LegacyRef } from 'react';
import { Box, Typography } from '@mui/material';

import styles from './ImagesGridDetails.module.css';
import { CaseFormButtonContainer } from '../../cases/layoutComponents/CaseFormButtonContainer';

interface Props {
  children?: React.ReactNode;
  title: string;
  headerItems?: JSX.Element[];
  actions?: JSX.Element[];
  className?: string;
  bodyRef?: LegacyRef<HTMLDivElement>;
}

export const ImagesGridDetails: React.FC<Props> = ({ title, headerItems, actions, className, bodyRef, children }) => {
  const bodyClasses = [styles.body];

  if (className) {
    bodyClasses.push(className);
  }

  const hasActions = actions != null && actions.length > 0;

  return (
    <Box className={styles.container}>
      <Box className={styles.header}>
        <Typography variant="h6">{title}</Typography>

        <Box className={styles.buttons}>{headerItems?.map((item, idx) => <Box key={idx}>{item}</Box>)}</Box>
      </Box>
      <div className={bodyClasses.join(' ')} ref={bodyRef}>
        {children}
      </div>
      {hasActions && (
        <CaseFormButtonContainer className={styles.footer} renderDivider>
          {actions?.map((action, idx) => <div key={idx}>{action}</div>)}
        </CaseFormButtonContainer>
      )}
    </Box>
  );
};
