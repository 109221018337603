import { useEffect } from 'react';
import { setSelectedMedia } from '../imagesSlice';
import { useAppDispatch } from '../../../hooks/useAppRedux';
import { useSelectionContext } from './selectionContextContext';

export function useClearSelectedMedia() {
  const selectionContext = useSelectionContext();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setSelectedMedia({ selectionContext, media: [] }));
    return () => {
      dispatch(setSelectedMedia({ selectionContext, media: [] }));
    };
  }, [dispatch, selectionContext]);
}
