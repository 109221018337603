import { AxiosProgressEvent } from 'axios';
export type UploadProgressHandlerForFile = (progressEvent: AxiosProgressEvent, file: File) => void;

export interface FileUploadInput {
  files: File[];
  onUploadProgress?: UploadProgressHandlerForFile;
}

export const ensureSupportedImageType =
  (onError: (fileName: string) => void) =>
  ({ type, name }: File) => {
    if (!type.startsWith('image/')) {
      onError(name);
      throw new Error('Unsupported image type');
    }
  };

export const ensureSupportedVideoType =
  (onError: (fileName: string) => void) =>
  ({ type, name }: File) => {
    if (!type.startsWith('video/')) {
      onError(name);
      throw new Error('Unsupported video type');
    }
  };
