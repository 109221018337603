import React from 'react';

import { Box, Checkbox, FormControlLabel, List, ListItem } from '@mui/material';

import styles from './FilterComponent.module.css';

import {
  CaseStateConverter,
  CaseType,
  CaseTypeConverter,
  CaseTypes,
  Enumerations,
  ImageClassificationConverter,
  ImageClassifications,
  ImageOriginMetaData,
  ImageState,
  ImageStateConverter,
  ImageStates,
  ImageTypeConverter,
  ImageTypes,
  StateEnum,
  States,
} from '../../../../api/caseApiTypes';
import { FilterTreeSubComponentProps } from '../../../../api/filterTypes';
import { useAppSelector } from '../../../../hooks/useAppRedux';
import { CalendarSupportedStates } from '../../../cases/CaseCalendarPlan';

interface FilterData {
  key: string;
  value: string;
}

interface FilterTreeComponentTemplateProps extends FilterTreeSubComponentProps {
  data: FilterData[];
}

const sortData = (data: FilterData[]) => {
  return structuredClone(data).sort((a, b) => (a.value > b.value ? 1 : -1));
};

const ComponentTemplate: React.FC<FilterTreeComponentTemplateProps> = ({ state, setState, data }) => {
  const setter = (value: string[]) => setState({ ...state, value });
  const propValues = state.value ?? [];
  const onClickFn = (key: string) => () => {
    if (propValues.includes(key)) {
      setter(propValues.filter(s => s !== key));
    } else {
      setter([...propValues, key]);
    }
  };

  return (
    <List>
      {data.map(({ key, value }) => (
        <ListItem dense key={key} className={styles.item}>
          <FormControlLabel
            control={<Checkbox className={styles.lessPadding} checked={propValues.includes(key)} onClick={onClickFn(key)} />}
            label={value}
          />
        </ListItem>
      ))}
    </List>
  );
};

export const FilterTreeClassificationComponent: React.FC<FilterTreeSubComponentProps> = props => {
  const data = ImageClassifications.All().map(state => ({
    key: state.toString(),
    value: ImageClassificationConverter.ToName(state),
  }));

  return <ComponentTemplate data={sortData(data)} {...props} />;
};

export const FilterTreeImageStateComponent: React.FC<FilterTreeSubComponentProps> = props => {
  const data = ImageStates.AllExcept([ImageState.Deleted]).map(state => ({
    key: state.toString(),
    value: ImageStateConverter.ToName(state),
  }));
  return <ComponentTemplate data={data} {...props} />;
};

export const FilterTreeImageTypeComponent: React.FC<FilterTreeSubComponentProps> = props => {
  const data = ImageTypes.All().map(state => ({
    key: state.toString(),
    value: ImageTypeConverter.ToName(state),
  }));
  return <ComponentTemplate data={sortData(data)} {...props} />;
};

const FilterTreeCaseStateComponentWithTypes =
  (includedTypes: StateEnum[]): React.FC<FilterTreeSubComponentProps> =>
  props => {
    const data = includedTypes.map(state => ({
      key: state.toString(),
      value: CaseStateConverter.ToText({ state }),
    }));
    return <ComponentTemplate data={data} {...props} />;
  };

export const FilterTreeAllCaseStateComponent = FilterTreeCaseStateComponentWithTypes(States.AllExcept([StateEnum.none, StateEnum.deleted]));

export const FilterTreeCalendarCaseStateComponent = FilterTreeCaseStateComponentWithTypes(CalendarSupportedStates);

const FilterTreeCaseTypesComponentWithTypes =
  (includedCaseTypes: CaseType[]): React.FC<FilterTreeSubComponentProps> =>
  props => {
    const data = includedCaseTypes.map(state => ({
      key: state.toString(),
      value: CaseTypeConverter.ToName(state),
    }));
    return <ComponentTemplate data={sortData(data)} {...props} />;
  };

export const FilterTreeCaseTypesComponent = FilterTreeCaseTypesComponentWithTypes(CaseTypes.AllExcept(CaseType.Unknown));

export const FilterTreeCalendarCaseTypesComponent = FilterTreeCaseTypesComponentWithTypes([
  CaseType.ContextualPhoto,
  CaseType.InstallationPhoto,
]);

export const FilterTreeImageOriginComponent: React.FC<FilterTreeSubComponentProps> = props => {
  const data = Object.entries(ImageOriginMetaData).map(([key, value]) => ({
    key: key,
    value: value.name,
  }));
  return <ComponentTemplate data={sortData(data)} {...props} />;
};

/// Boolean filters

const BooleanComponentTemplate: React.FC<FilterTreeSubComponentProps & { data: FilterData }> = ({ state, setState, data }) => {
  const setter = (value: string[]) => setState({ ...state, value });
  const propValues = state.value ?? [];
  const onClickFn = (key: string) => () => {
    if (propValues.includes(key)) {
      setter(propValues.filter(s => s !== key));
    } else {
      setter([...propValues, key]);
    }
  };

  return (
    <Box display="flex" flexDirection="row" margin={'0.25em 0 0.25em 1em'} key={data.key}>
      <Checkbox className={styles.lessPadding} checked={propValues.includes(data.key)} onClick={onClickFn(data.key)} />
      <div>{data.value}</div>
    </Box>
  );
};

export const FilterTreeMyCasesComponent: React.FC<FilterTreeSubComponentProps> = props => {
  const user = useAppSelector(state => state.app.user);

  if (user == null) return <></>;

  const data = {
    key: user.id.toString(),
    value: 'Mina beställningar',
  };
  return <BooleanComponentTemplate data={data} {...props} />;
};

/// Enumeration Filters

interface FilterTreeEnumerationsComponentTemplateProps extends FilterTreeSubComponentProps {
  propName: keyof Enumerations;
}

const EnumerationsComponentTemplate: React.FC<FilterTreeEnumerationsComponentTemplateProps> = ({ propName, ...rest }) => {
  const enumerations = useAppSelector(state => state.app.enumerations);

  if (enumerations == null) {
    return <></>;
  }

  const data = enumerations[propName];

  return <ComponentTemplate data={sortData(data)} {...rest} />;
};

export const FilterTreeStylistComponent: React.FC<FilterTreeSubComponentProps> = props => (
  <EnumerationsComponentTemplate propName="stylists" {...props} />
);

export const FilterTreePhotographerComponent: React.FC<FilterTreeSubComponentProps> = props => (
  <EnumerationsComponentTemplate propName="photographers" {...props} />
);

export const FilterTreeEditorComponent: React.FC<FilterTreeSubComponentProps> = props => (
  <EnumerationsComponentTemplate propName="editors" {...props} />
);
