import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/rootReducer';
import { LocalStorageKeys } from '../../hooks/useStorage';

export interface Clipbook {
  name: string;
  images: string[];
}

interface ClipbooksState {
  clipbooks: Clipbook[];
  activeClipbook: number;
}

const emptyState: ClipbooksState = {
  clipbooks: [],
  activeClipbook: -1,
};

const storeClipbooks = (clipbooks: Clipbook[]) => window.localStorage.setItem(LocalStorageKeys.clipbooks, JSON.stringify(clipbooks));
const fetchClipbooks = () => JSON.parse(window.localStorage.getItem(LocalStorageKeys.clipbooks) || JSON.stringify(emptyState.clipbooks));
const storeActiveClipbook = (activeClipbook: number) =>
  window.localStorage.setItem(LocalStorageKeys.activeClipbook, JSON.stringify(activeClipbook));
const fetchActiveClipbook = () =>
  JSON.parse(window.localStorage.getItem(LocalStorageKeys.activeClipbook) || JSON.stringify(emptyState.activeClipbook));

const initialState: ClipbooksState = {
  clipbooks: fetchClipbooks(),
  activeClipbook: fetchActiveClipbook(),
};

export const clipbooksSlice = createSlice({
  name: 'clipbooks',
  initialState,
  reducers: {
    newClipbook(state, { payload: { name } }: PayloadAction<{ name: string }>) {
      const allClipbookNames = state.clipbooks.map(c => c.name);
      if (allClipbookNames.includes(name)) {
        return;
      }
      const clipbook: Clipbook = {
        name,
        images: [],
      };
      const index = state.clipbooks.push(clipbook) - 1;
      state.activeClipbook = index;
      storeActiveClipbook(state.activeClipbook);
      storeClipbooks(state.clipbooks);
    },
    removeClipbook(state, { payload: { index } }: PayloadAction<{ index: number }>) {
      state.clipbooks.splice(index, 1);
      state.activeClipbook = Math.min(state.clipbooks.length - 1, state.activeClipbook);
      storeClipbooks(state.clipbooks);
    },
    selectClipbook(state, { payload: { index } }: PayloadAction<{ index: number }>) {
      state.activeClipbook = Math.min(state.clipbooks.length - 1, index);
      storeActiveClipbook(state.activeClipbook);
    },
    addImagesToClipbook(state, { payload: { imageIds, clipbookName } }: PayloadAction<{ imageIds: string[]; clipbookName?: string }>) {
      let clipbook: Clipbook;
      if (clipbookName) {
        // Create a new clipbook
        clipbook = { name: clipbookName, images: [] };
        const index = state.clipbooks.push(clipbook) - 1;
        state.activeClipbook = index;
      } else {
        // Add to active clipbook
        if (state.activeClipbook < 0) {
          return;
        }
        clipbook = state.clipbooks[state.activeClipbook];
      }

      // Filter the images that are already added to the clipbook
      imageIds = imageIds.filter(imageId => clipbook.images.findIndex(p => p === imageId) < 0);

      clipbook.images.push(...imageIds);
      storeClipbooks(state.clipbooks);
    },
    removeImagesFromClipbook(state, { payload: { imageIds } }: PayloadAction<{ imageIds: string[] }>) {
      if (state.activeClipbook < 0) {
        return;
      }

      const clipbook = state.clipbooks[state.activeClipbook];

      for (const imageId of imageIds) {
        const index = clipbook.images.findIndex(p => p === imageId);
        if (index < 0) {
          continue;
        }
        clipbook.images.splice(index, 1);
      }

      storeClipbooks(state.clipbooks);
    },
    renameClipbook(state, { payload: { index, name } }: PayloadAction<{ index: number; name: string }>) {
      const allClipbookNames = state.clipbooks.map(c => c.name);
      if (allClipbookNames.includes(name) || name.length < 2) {
        return;
      }
      state.clipbooks[index].name = name;
      storeClipbooks(state.clipbooks);
    },
  },
});

export const addImagesToActiveClipbook = createAsyncThunk(
  'clipbooks/addImagesToActiveClipbook',
  async ({ imageIds, clipbookName }: { imageIds: string[]; clipbookName?: string }, { getState, dispatch }) => {
    const hasNoClipbooks = (getState() as RootState).clipbooks.clipbooks.length === 0;
    if (hasNoClipbooks && !clipbookName) {
      dispatch(newClipbook({ name: 'Ny grupp' }));
    }

    dispatch(addImagesToClipbook({ imageIds, clipbookName }));
  },
);

export const { newClipbook, removeClipbook, selectClipbook, addImagesToClipbook, removeImagesFromClipbook, renameClipbook } =
  clipbooksSlice.actions;

export const selectActiveClipbook = (state: RootState) =>
  state.clipbooks.activeClipbook >= 0 ? state.clipbooks.clipbooks[state.clipbooks.activeClipbook] : undefined;

export const imageIsInActiveClipbook = (imageId?: string) => (state: RootState) => {
  if (!imageId) {
    return false;
  }
  const activeClipbook = selectActiveClipbook(state);
  return activeClipbook ? activeClipbook.images.includes(imageId) : false;
};

export const selectClipbookByIndex = (index: number) => (state: RootState) => state.clipbooks.clipbooks[index];

export default clipbooksSlice.reducer;
