import { Badge, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../hooks/useAppRedux';
import { selectClipbook } from '../clipbooksSlice';
import { ActiveClipbookText } from './ActiveClipbookText';

interface Props {
  fullWidth?: boolean;
  width?: string;
  variant?: 'standard' | 'outlined' | 'filled';
  color?: string;
}

export const ClipbookSelector: React.FC<Props> = ({ fullWidth, width, variant, color }) => {
  const dispatch = useAppDispatch();
  const clipbooks = useAppSelector(state => state.clipbooks.clipbooks);
  const selectedClipbook = useAppSelector(state => state.clipbooks.activeClipbook);

  const handleSelectClipbook = (evt: SelectChangeEvent<number>) => {
    dispatch(selectClipbook({ index: evt.target.value as number }));
  };

  return clipbooks.length > 1 ? (
    <FormControl variant={variant} fullWidth={fullWidth} style={{ width }}>
      <Select
        sx={{
          '& .MuiSelect-icon': {
            color,
          },
          '& .MuiSelect-select': {
            color,
          },
        }}
        value={selectedClipbook}
        label="Grupp"
        onChange={handleSelectClipbook}
      >
        {clipbooks.map((c, i) => (
          <MenuItem key={i} value={i}>
            <Badge badgeContent={c.images.length} color="primary">
              {c.name}
            </Badge>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ) : (
    <ActiveClipbookText />
  );
};
