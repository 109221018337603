import { PlayCircleOutline } from '@mui/icons-material';
import { ImageVersion } from '../../../api/caseApiTypes';
import { HasPreviewVideo } from '../../../helpers/DerivedImageHelpers';
import styles from './MediaWithPlayIcon.module.css';

export type Props = {
  cardMedia: JSX.Element;
  imageVersion: ImageVersion | undefined;
  disablePlayIcon?: boolean;
};

export function MediaWithPlayIcon({ cardMedia, imageVersion, disablePlayIcon }: Props) {
  const hasVideoPreview = HasPreviewVideo(imageVersion);

  return (
    <>
      {cardMedia}
      {hasVideoPreview && !disablePlayIcon && (
        <div className={styles.playVideoIcon}>
          <PlayCircleOutline color="secondary" sx={{ fontSize: 60 }} />
        </div>
      )}
    </>
  );
}
