import { useEffect } from 'react';

import { FetchResult } from '../api/m3Api';
import { ArticleStatus, getByM3Ids } from '../features/m3Search/m3Slice';
import { useAppDispatch, useAppSelector } from './useAppRedux';

interface Props {
  articleId: string;
}

export const useArticle = ({ articleId }: Props) => {
  const dispatch = useAppDispatch();

  const article = useAppSelector(state => state.m3.m3ArticlesById[articleId]);

  useEffect(() => {
    if (article === undefined) {
      dispatch(getByM3Ids([articleId]));
    }
  }, [dispatch, article, articleId]);

  switch (article) {
    case undefined:
      return { fetchResult: FetchResult.NotSearched, article: null };
    case ArticleStatus.Loading:
      return { fetchResult: FetchResult.Loading, article: null };
    case ArticleStatus.NotFound:
      return { fetchResult: FetchResult.NotFound, article: null };
    default:
      return { fetchResult: FetchResult.Found, article };
  }
};
