import React from 'react';

import { ImageClassification } from '../../../api/caseApiTypes';
import { FormControl, InputLabel } from '@mui/material';
import { MediaValueSelect, OptionInfo } from './MediaValueSelect';
import { MediaValue } from './MediaValue';

interface Props {
  imageClassification: MediaValue<ImageClassification | undefined>[] | undefined;
  onChange: (value?: ImageClassification) => void;
  readonly?: boolean;
}

const options: OptionInfo<ImageClassification>[] = [
  { label: 'Ospecifierad', key: ImageClassification.Unspecified },
  { label: 'Inget rum', key: ImageClassification.NoRoom },
  { label: 'Badrum', key: ImageClassification.Bathroom },
  { label: 'Barnrum', key: ImageClassification.KidsRoom },
  { label: 'Hall', key: ImageClassification.Hallway },
  { label: 'Kontor', key: ImageClassification.Workplace },
  { label: 'Matplats', key: ImageClassification.DiningRoom },
  { label: 'Sovrum', key: ImageClassification.Bedroom },
  { label: 'Trädgård', key: ImageClassification.GardenRoom },
  { label: 'Vardagsrum', key: ImageClassification.LivingRoom },
];

export const ImageClassificationField: React.FC<Props> = ({ onChange, readonly, imageClassification }) => {
  return (
    <FormControl>
      <InputLabel shrink>Rumstyp</InputLabel>
      <MediaValueSelect
        values={imageClassification}
        defaultValue={ImageClassification.Unspecified}
        onChange={onChange}
        readonly={readonly}
        options={options}
      />
    </FormControl>
  );
};
