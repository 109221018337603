import React from 'react';

import { Image, ImageClassificationConverter, ImageStateConverter, ImageTypeConverter, lookupEnum } from '../../../api/caseApiTypes';
import { ByCreatedAt } from '../../../helpers/Sorting';
import { useAppSelector } from '../../../hooks/useAppRedux';
import { formatDate, formatDateTime } from '../../date/DateFormatter';

interface Props {
  image: Image;
}

const DataRow: React.FC<{ label: string; children?: React.ReactNode }> = ({ label, children }) => (
  <tr>
    <td>{label}:</td>
    <td>{children}</td>
  </tr>
);

export const ImagesGridDetailsEnrichmentView: React.FC<Props> = ({
  image: {
    type,
    classification,
    state,
    activeFrom,
    activeTo,
    photographer,
    stylist,
    wallpaper,
    paint,
    versions,
    floor,
    tiling,
    publicationDate,
    photoAndRetouchDeadline,
    created,
    modified,
  },
}) => {
  const imageActiveText = () => {
    if (activeFrom == null && activeTo == null) {
      return 'Alltid';
    }

    if (activeFrom != null && activeTo == null) {
      return 'från ' + formatDate(activeFrom);
    }

    if (activeFrom == null && activeTo != null) {
      return 'till ' + formatDate(activeTo);
    }

    return `${formatDate(activeFrom!)} - ${formatDate(activeTo!)}`;
  };

  const latestVersion = Object.values(versions).sort(ByCreatedAt('desc'))[0].createdAt;
  const hasManyVersions = Object.keys(versions).length > 1;

  const photographerName = lookupEnum(useAppSelector(state => state.app.enumerations)?.photographers, photographer);
  const stylistName = lookupEnum(useAppSelector(state => state.app.enumerations)?.stylists, stylist);

  return (
    <table>
      <tbody>
        <DataRow label="Bildtyp">{ImageTypeConverter.ToName(type)}</DataRow>
        <DataRow label="Rumstyp">{ImageClassificationConverter.ToName(classification)}</DataRow>
        <DataRow label="Bildstatus">{ImageStateConverter.ToName(state)}</DataRow>
        <DataRow label="Bild aktiv">{imageActiveText()}</DataRow>
        {created && <DataRow label="Skapad">{formatDateTime(created)}</DataRow>}
        {modified && created !== modified && <DataRow label="Senast uppdaterad">{formatDateTime(modified)}</DataRow>}
        {hasManyVersions && <DataRow label="Senaste versionen">{formatDateTime(latestVersion)}</DataRow>}
        {publicationDate && <DataRow label="Publiceringsdatum">{formatDate(publicationDate)}</DataRow>}
        {photoAndRetouchDeadline && <DataRow label="Foto och retusch deadline">{formatDate(photoAndRetouchDeadline)}</DataRow>}
        {photographerName && <DataRow label="Fotograf">{photographerName}</DataRow>}
        {stylistName && <DataRow label="Stylist">{stylistName}</DataRow>}
        {wallpaper && (
          <DataRow label="Tapet">
            {wallpaper.supplier} - {wallpaper.design}
          </DataRow>
        )}
        {paint && (
          <DataRow label="Väggfärg">
            {paint.supplier} - {paint.color}
          </DataRow>
        )}
        {floor && (
          <DataRow label="Golv">
            {floor.supplier} - {floor.design}
          </DataRow>
        )}
        {tiling && (
          <DataRow label="Kakel">
            {tiling.supplier} - {tiling.design}
          </DataRow>
        )}
      </tbody>
    </table>
  );
};
