import React from 'react';
import styles from './CaseFormBlock.module.css';
import { Paper } from '@mui/material';

interface Props {
  children?: React.ReactNode;
}

export const CaseFormBlock: React.FC<Props> = ({ children }) => {
  return (
    <Paper className="paper" elevation={2}>
      <div className={styles.content}>{children}</div>
    </Paper>
  );
};
