import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/useAppRedux';

import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { SystemTag } from '../../api/caseApiTypes';
import { getAllSystemTags } from './TagsSlice';
import { ColorTag } from './ColorTag';
import { SelectTagModal } from './SelectTagModal';

export interface TagModalProps {
  open: boolean;
  onSave: (tags: SystemTag[]) => void;
  onClose: () => void;
  saveLabel?: string;
}

export const SelectSystemTagModal: React.FC<TagModalProps> = ({ open, onSave, ...rest }) => {
  const dispatch = useAppDispatch();

  const { systemTags } = useAppSelector(state => state.tags);
  const [selectedTags, setSelectedTags] = useState<SystemTag[]>([]);

  useEffect(() => {
    if (open) {
      dispatch(getAllSystemTags());
    }
  }, [dispatch, open]);

  const onSaveTags = () => {
    onSave(selectedTags);
    setSelectedTags([]);
  };

  return (
    <SelectTagModal onSave={onSaveTags} open={open} {...rest}>
      <Autocomplete
        multiple
        options={systemTags}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            <ColorTag hex={option.color} label={option.name} size="small" />
          </li>
        )}
        renderTags={tags => tags.map(tag => <ColorTag key={tag.id} hex={tag.color} label={tag.name} size="small" />)}
        getOptionLabel={option => option.name}
        noOptionsText="Inga matchande taggar"
        onChange={(_, tags) => setSelectedTags(tags)}
        renderInput={params => <TextField {...params} label="Välj/sök bland taggar" />}
      />
    </SelectTagModal>
  );
};
