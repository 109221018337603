import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import styles from './CaseExpansion.module.css';
import { ExpandMore } from '@mui/icons-material';

interface Props {
  children?: React.ReactNode;
  title?: string;
  compact?: boolean;
  hidden?: boolean;
  defaultCollapsed?: boolean;
  setExpanded?: (state: boolean) => void;
  titleComponent?: JSX.Element;
}

export const CaseExpansion: React.FC<Props> = ({
  title,
  children,
  compact = false,
  hidden = false,
  defaultCollapsed = false,
  setExpanded,
  titleComponent,
}) => {
  if (hidden) return null;

  const onChange = setExpanded == null ? undefined : (_: object, expanded: boolean) => setExpanded(expanded);

  const style: React.CSSProperties = { backgroundColor: 'inherit', border: 'none' };

  return (
    <>
      {!compact && <div className={styles.divider} />}
      <Accordion
        disableGutters
        defaultExpanded={!defaultCollapsed}
        square
        variant="outlined"
        style={style}
        className={styles.expansion}
        onChange={onChange}
      >
        <AccordionSummary aria-label="Expand" expandIcon={<ExpandMore />}>
          <Grid container spacing={2} justifyContent="space-between" alignItems="center" className={styles.heading}>
            <Grid item>
              <Typography variant="subtitle2">{title}</Typography>
            </Grid>
            {titleComponent != null && (
              <Grid item onClick={e => e.stopPropagation()}>
                {titleComponent}
              </Grid>
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </>
  );
};
