import React, { useEffect, useMemo } from 'react';

import { Add, Remove } from '@mui/icons-material';

import styles from './ReferenceImages.module.css';

import { ImagePointer, RetouchCase } from '../../api/caseApiTypes';
import { useAppDispatch, useAppSelector } from '../../hooks/useAppRedux';
import { AdditionalAction, ImageCard } from '../image/imageCard/ImageCard';
import { ensureImagesLoaded } from '../images/imagesSlice';
import { replaceImage, retouchReplaceImage } from './caseDetailSlice';
import { IImagePointerContainer } from './RetouchSourceImageSection';

interface Props {
  caze?: RetouchCase;
  getImagePointers: (caze: RetouchCase | undefined) => IImagePointerContainer | undefined;
  onTogglePointer: (pointer: ImagePointer) => void;
  readonly?: boolean;
  isNewCase?: boolean;
  canUpload?: boolean;
}

export const ReferenceImages: React.FC<Props> = ({ isNewCase, canUpload, caze, getImagePointers, onTogglePointer, readonly }) => {
  const pointers = getImagePointers(caze);
  const imagePointers = useMemo(() => pointers?.imagePointers ?? [], [pointers]);
  const dispatch = useAppDispatch();
  const persistedCase = useAppSelector(state => state.caseDetail.persistedCase) as RetouchCase;
  const imageUploadInProgress = useAppSelector(state => state.caseDetail.imageUploadInProgress);
  const imagesById = useAppSelector(state => state.images.imagesById);

  const togglePointer = (pointer: ImagePointer) => () => {
    onTogglePointer(pointer);
  };

  const imageIds = imagePointers.map(({ imageId }) => imageId);

  useEffect(() => {
    dispatch(ensureImagesLoaded(imageIds));
  }, [dispatch, imageIds]);

  if (persistedCase == null) {
    return <></>;
  }

  const getCard = (pointer: ImagePointer, index: number) => {
    const { imageId, imageVersion } = pointer;
    const img = imagesById[imageId];

    if (img == null) {
      return null;
    }

    const isSelected = imagePointers.some(p => p.imageId === imageId && p.imageVersion === imageVersion);
    const selectedStyle = isSelected ? undefined : { outline: '2px solid darkred' };

    // if there is a pointer already we need to use the standard thunk instead
    const thunk = persistedCase.imagePointers.some(p => p.imageId === pointer.imageId) ? replaceImage : retouchReplaceImage;

    const actions: AdditionalAction[] = [
      {
        icon: isSelected ? <Remove /> : <Add />,
        action: togglePointer(pointer),
        tooltip: isSelected ? 'Klicka för att ta bort och välj spara för att bekräfta' : 'Bilden kommer just nu tas bort',
        disabled: readonly,
      },
    ];

    return (
      <ImageCard
        caze={caze}
        style={selectedStyle}
        image={img}
        enableReplacement={!isNewCase && canUpload}
        enableVariantCreation={!isNewCase && canUpload}
        replaceMediaAction={thunk}
        additionalActions={actions}
        disableRemoval
        fileUploadInProgress={imageUploadInProgress > 0}
      />
    );
  };

  return <div className={styles.images}>{imagePointers.map(getCard)}</div>;
};
