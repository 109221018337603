import React from 'react';
import { useAppDispatch } from '../../hooks/useAppRedux';
import { Button, Grid, Link, Stack, Step, StepButton, StepContent, Stepper, Typography } from '@mui/material';
import { Case, CaseStateConverter, CaseType, CaseTypes, StateEnum } from '../../api/caseApiTypes';
import {
  caseAwaitingRetouch,
  caseRetouchInProgress,
  caseRetouchReviewPending,
  caseReviewPending,
  closeCase,
  confirmCase,
  resetCase,
  orderedCase,
} from './caseDetailSlice';
import styles from './CaseStateProgress.module.css';
import { CaseExpansion } from './layoutComponents/CaseExpansion';
import { Link as RouterLink } from 'react-router-dom';
import { Right } from '../../api/userApi';
import { useRights, useRightsFn } from '../../hooks/useRights';
import { KanbanSupportedTypes } from '../kanban/CaseKanbanPlan';
import { AsyncThunk } from '@reduxjs/toolkit';
import { ProductContextualKanbanSupportedTypes } from '../kanban/ProductContextualCaseKanbanPlan';
import { formatDate } from '../date/DateFormatter';
import { QueryParam } from '../../App';
import { Today } from '@mui/icons-material';

interface StateInfo {
  state: StateEnum;
  right?: Right;
  secondaryButtonContent?: ((caze: Case) => React.ReactNode) | React.ReactNode;
  buttonContent: ((caze: Case) => React.ReactNode) | React.ReactNode;
  buttonAction?: AsyncThunk<void, Case, {}>;
  alternateNextState?: StateEnum;
  description: React.ReactNode;
  applicableCaseTypes: CaseType[];
}

const GetPlanningRoute = (caze: Case) => {
  let planType = 'calendar';

  if (KanbanSupportedTypes.includes(caze.type)) {
    planType = 'kanban';
  } else if (ProductContextualKanbanSupportedTypes.includes(caze.type)) {
    planType = 'product-context-kanban';
  }

  return `/${planType}-planning`;
};

const AllCaseTypes = CaseTypes.AllExcept(CaseType.DiningSet);
const CanConfirmCaseTypes = CaseTypes.AllExcept(CaseType.DiningSet, CaseType.ProductContextual);
const CanPlanCaseTypes = CaseTypes.AllExcept(CaseType.DiningSet, CaseType.Retouch);
const CanRetouchCaseTypes = CaseTypes.AllExcept(
  CaseType.DiningSet,
  CaseType.InstallationPhoto,
  CaseType.ContextualPhoto,
  CaseType.Video,
  CaseType.ProductContextual,
);
const ImageProductionCaseTypes = CaseTypes.AllExcept(CaseType.DiningSet, CaseType.Retouch);

const steps: StateInfo[] = [
  {
    state: StateEnum.incoming,
    buttonAction: resetCase,
    buttonContent: 'Återställ',
    description: <>Du kan arbeta med bildbeställningen tillsammans med dina kollegor.</>,
    applicableCaseTypes: AllCaseTypes,
  },
  {
    state: StateEnum.ordered,
    buttonAction: orderedCase,
    buttonContent: 'Beställ',
    description: <>När du är färdig och vill att beställningen ska gå vidare till planering, kan du bekräfta din beställning.</>,
    applicableCaseTypes: AllCaseTypes,
  },
  {
    state: StateEnum.confirmed,
    right: Right.ConfirmCase,
    buttonAction: confirmCase,
    buttonContent: 'Bekräfta',
    description:
      'Beställningen håller på att planeras. Du kan fortfarande arbeta med bildbeställningen, men tänk på om dina ändringar påverkar planeringen.',
    applicableCaseTypes: CanConfirmCaseTypes,
  },
  {
    state: StateEnum.planned,
    buttonContent: caze => (
      <Link rel="noopener noreferrer" component={RouterLink} to={GetPlanningRoute(caze)} target="_blank">
        Gå till planering
      </Link>
    ),
    description: (
      <>
        Arbetet med att skapa bildmaterialet i beställningen kommer att påbörjas på planerat datum. När allt är skapat, kan{' '}
        <em>beställaren</em> godkänna alla bilder.
      </>
    ),

    applicableCaseTypes: CanPlanCaseTypes,
  },
  {
    state: StateEnum.reviewPending,
    right: Right.ManageImage,
    secondaryButtonContent: caze => (
      <Link
        rel="noopener noreferrer"
        component={RouterLink}
        to={`${GetPlanningRoute(caze)}?${QueryParam.StartDate}=${formatDate(caze.plannedStart)}`}
        target="_blank"
      >
        <Stack direction="row" alignItems="center">
          <Today /> {formatDate(caze.plannedStart)}
        </Stack>
      </Link>
    ),
    buttonAction: caseReviewPending,
    alternateNextState: StateEnum.closed,
    buttonContent: 'Redo för granskning',
    description: 'Producerat arbete redo att granskas.',
    applicableCaseTypes: ImageProductionCaseTypes,
  },
  {
    state: StateEnum.awaitingRetouch,
    right: Right.CloseCase,
    buttonAction: caseAwaitingRetouch,
    buttonContent: 'Retusch önskad',
    description: 'Materialet väntar på retuschering.',
    applicableCaseTypes: CanRetouchCaseTypes,
  },
  {
    state: StateEnum.retouchInProgress,
    right: Right.ManageImage,
    buttonAction: caseRetouchInProgress,
    buttonContent: 'Retuschering påbörjad',
    description: 'Retuschering av materialet pågår.',
    applicableCaseTypes: CanRetouchCaseTypes,
  },
  {
    state: StateEnum.retouchReviewPending,
    right: Right.ManageImage,
    buttonAction: caseRetouchReviewPending,
    buttonContent: 'Redo för granskning',
    description: 'Retuscherat material redo att granskas.',
    applicableCaseTypes: CanRetouchCaseTypes,
  },
  {
    state: StateEnum.closed,
    right: Right.CloseCase,
    buttonAction: closeCase,
    buttonContent: 'Avsluta',
    description: 'Processen avslutad.',
    applicableCaseTypes: AllCaseTypes,
  },
];

interface Props {
  caze: Case;
  buttonsOnly?: boolean;
  isNewCase?: boolean;
}

export const CaseStateProgress: React.FC<Props> = ({ caze, buttonsOnly, isNewCase }) => {
  const getRight = useRightsFn();
  const canWrite = useRights(Right.WriteCase);
  const isAdmin = useRights(Right.Administration);
  const dispatch = useAppDispatch();

  const applicableSteps = steps.filter(stepInfo => stepInfo.applicableCaseTypes.includes(caze.type));
  const hasInvalidState = !applicableSteps.some(step => step.state === caze.state.state);

  const activeStep = applicableSteps.find(step => step.state === caze.state.state) ?? applicableSteps[0];

  const activeStepIndex = applicableSteps.findIndex(step => step.state === activeStep.state);

  const handleAction = (action?: AsyncThunk<void, Case, {}>) => {
    if (action == null) {
      return;
    }

    dispatch(action(caze));
  };

  const canStep = (step: StateInfo) => {
    const hasRight = step.right == null || getRight(step.right);

    return isAdmin || canWrite || hasRight;
  };

  const getStepButton = (step?: StateInfo, compact?: boolean) => {
    if (step == null || !canStep(step)) {
      return;
    }

    const onClick = step.buttonAction != null ? () => handleAction(step.buttonAction) : undefined;

    const content = step.buttonContent instanceof Function ? step.buttonContent(caze) : step.buttonContent;

    return (
      <Button variant="outlined" onClick={onClick} className={styles.button} size={compact ? 'small' : 'medium'} disabled={isNewCase}>
        {content}
      </Button>
    );
  };

  const getSecondaryButton = (step: StateInfo, compact?: boolean) => {
    if (step == null || step.secondaryButtonContent == null) {
      return;
    }

    const content = step.secondaryButtonContent instanceof Function ? step.secondaryButtonContent(caze) : step.secondaryButtonContent;

    return (
      <Button variant="outlined" className={styles.button} size={compact ? 'small' : 'medium'}>
        {content}
      </Button>
    );
  };

  const getActionButtons = (step: StateInfo, index: number, compact?: boolean) => {
    const alternateStep = applicableSteps.find(stepInfo => stepInfo.state === step.alternateNextState);

    const nextStep = applicableSteps[index + 1];
    const stepsAreDifferent = step.alternateNextState && nextStep && step.alternateNextState !== nextStep.state;
    const hasSecondaryButton = nextStep && nextStep.secondaryButtonContent != null;
    return (
      <Grid container spacing={1}>
        {stepsAreDifferent && <Grid item>{getStepButton(alternateStep, compact)}</Grid>}

        {hasSecondaryButton && <Grid item>{getSecondaryButton(nextStep, compact)} </Grid>}

        <Grid item>{getStepButton(nextStep, compact)}</Grid>
      </Grid>
    );
  };

  const getStepComponent = (step: StateInfo, index: number) => {
    const onClick = step.buttonAction != null ? () => handleAction(step.buttonAction) : undefined;

    const state = step.state;

    return (
      <Step key={state} completed={activeStepIndex > index}>
        <StepButton onClick={onClick} disabled={!isAdmin || isNewCase}>
          {CaseStateConverter.ToText({ state })}
        </StepButton>
        <StepContent>
          <Typography>{step.description}</Typography>
          {getActionButtons(step, index)}
        </StepContent>
      </Step>
    );
  };

  if (buttonsOnly) {
    return getActionButtons(activeStep, activeStepIndex, true);
  }

  return (
    <CaseExpansion
      title={CaseStateConverter.ToText(caze.state)}
      titleComponent={getActionButtons(activeStep, activeStepIndex, true)}
      compact
      defaultCollapsed
    >
      {hasInvalidState && (
        <Typography variant="body2" color="error">
          {`Beställningen en ogiltig status: ${CaseStateConverter.ToText(caze.state)}`}
        </Typography>
      )}
      <Stepper activeStep={activeStepIndex} orientation="vertical" className={styles.container}>
        {applicableSteps.map(getStepComponent)}
      </Stepper>
    </CaseExpansion>
  );
};
