/** This interface represents a "Clause" for the search filter.
 * It either contains a Condition, like 'a == b'
 * XOR
 * one or more Clauses with a predicate, like
 * '(a==b) AND (c==d) AND (e==f)`
 */
export type Clause<T> = PredicateClause<T> | ConditionClause<T> | ListConditionClause<T>;

export interface PredicateClause<T> {
  predicate: SearchPredicate;
  clauses: Clause<T>[];
}

export interface ConditionClause<T> {
  condition: Condition<T>;
}

export interface ListConditionClause<T> {
  listCondition: ListCondition<T>;
}

export enum SearchPredicate {
  And = 1,
  Or = 2,
}

export interface Condition<T> {
  property: keyof T;
  comparison: SearchComparison;
  argument: string | null;
}

export interface ListCondition<T> {
  property: keyof T;
  subProperty?: keyof T;
  comparison: ListComparison;
  argument: string | null;
}

export enum SearchComparison {
  Eq = 1,
  Neq = 2,
  Lt = 3,
  Gt = 4,
}

export enum ListComparison {
  EqAny = 1,
  NeqAll = 2,
  LtAny = 3,
  LtAll = 4,
  GtAny = 5,
  GtAll = 6,
}

export interface OrderBy {
  id: string;
  desc?: boolean;
}
