import React, { useMemo, useState } from 'react';
import { useAppDispatch } from '../../hooks/useAppRedux';

import styles from './ImageTags.module.css';

import { Box, Chip } from '@mui/material';
import { Add, Folder } from '@mui/icons-material';

import { Image, ProjectTag, SystemTag } from '../../api/caseApiTypes';
import { SelectSystemTagModal } from '../tags/SelectSystemTagModal';
import { ColorTag } from '../tags/ColorTag';
import { SelectProjectTagModal } from '../tags/SelectProjectTagModal';
import { addSystemTags, clearProjectTag, removeSystemTag, setProjectTag } from '../images/imagesSlice.tagging';

type TagType = `${'System' | 'Project'}Tag`;

interface Props {
  media: Image[];
  copyTagsFromCaseButton?: React.ReactNode;
  size?: 'small' | 'medium';
  readonly?: boolean;
}

export const ImageTags = ({ media, copyTagsFromCaseButton, size, readonly }: Props) => {
  const dispatch = useAppDispatch();
  const [newTagType, setNewTagType] = useState<TagType | null>(null);

  const onModalClose = () => {
    setNewTagType(null);
  };

  const onSaveSystemTags = (systemTags: SystemTag[]) => {
    onModalClose();

    if (systemTags.length > 0) {
      dispatch(addSystemTags({ media, systemTags }));
    }
  };

  const onDeleteSystemTag = (systemTag: SystemTag) => {
    dispatch(removeSystemTag({ media, systemTag }));
  };

  const onSetProjectTag = (projectTag: ProjectTag) => {
    onModalClose();

    dispatch(setProjectTag({ media, projectTag }));
  };

  const onClearProjectTag = (projectTag: ProjectTag) => {
    dispatch(clearProjectTag({ media, projectTag }));
  };

  let projectTags = useMemo(() => {
    const allTags = media.filter(m => !!m.projectTag).map(m => m.projectTag!);
    const tagMap: { [key: string]: ProjectTag } = {};
    for (let tag of allTags) {
      tagMap[tag.id] = tag;
    }
    return Object.values(tagMap);
  }, [media]);
  let systemTags = useMemo(() => {
    const allTags = media.flatMap(m => m.systemTags);
    const tagMap: { [key: string]: SystemTag } = {};
    for (let tag of allTags) {
      tagMap[tag.id] = tag;
    }
    return Object.values(tagMap);
  }, [media]);

  return (
    <div>
      <div className={styles.container}>
        {projectTags?.map((projectTag, index) => (
          <ColorTag key={index} hex={projectTag.color} label={projectTag.name} onDelete={() => onClearProjectTag(projectTag)} size={size} />
        ))}
        {systemTags?.map((tag, index) =>
          readonly ? (
            <ColorTag key={index} hex={tag.color} label={tag.name} size={size} />
          ) : (
            <ColorTag key={index} hex={tag.color} label={tag.name} size={size} onDelete={() => onDeleteSystemTag(tag)} />
          ),
        )}
      </div>
      <div className={styles.addTags}>
        {!readonly && (
          <Box>
            <Chip
              color="primary"
              icon={<Folder />}
              className={styles.create}
              size={size}
              label={projectTags.length > 0 ? 'Ändra projekt' : 'Välj projekt'}
              onClick={() => setNewTagType('ProjectTag')}
            />
            <SelectProjectTagModal open={newTagType === 'ProjectTag'} onClose={onModalClose} onSave={onSetProjectTag} />

            <Chip
              color="primary"
              icon={<Add />}
              className={styles.create}
              size={size}
              label="Ny tagg"
              onClick={() => setNewTagType('SystemTag')}
            />
            <SelectSystemTagModal open={newTagType === 'SystemTag'} onClose={onModalClose} onSave={onSaveSystemTags} />

            {copyTagsFromCaseButton}
          </Box>
        )}
      </div>
    </div>
  );
};
