import { useMemo, useState } from 'react';
import { Case, Image, ImagePointer, RetouchCase } from '../../api/caseApiTypes';
import { ImagePicker } from '../images/imagePicker/ImagePicker';
import { CaseFormSection } from './forms/CaseFormSection';
import { useAppSelector } from '../../hooks/useAppRedux';
import { IconButton } from '@mui/material';
import { ByPointers } from '../../helpers/Sorting';
import { IsEqual } from '../../helpers/ObjectHelpers';
import { CloudDownload } from '@mui/icons-material';
import { ReferenceImages } from './ReferenceImages';

export interface IImagePointerContainer {
  caseId?: string;
  imagePointers: ImagePointer[];
}

export type Props = {
  caze: RetouchCase;
  title: string;
  readonly: boolean | undefined;
  canUpload: boolean;
  section: 'reference' | 'inspiration';
  setHighlightedImageIds: (imageIds: string[]) => void;
  getFilesUri: (caze: RetouchCase) => string;
  getSectionPointers: (caze: RetouchCase | undefined) => IImagePointerContainer | undefined;
  onSelectImage: (image: Image) => void;
  onCasePartialChange: (partial: Partial<Case>) => void;
};

export function RetouchSourceImageSection({
  title,
  caze,
  readonly,
  canUpload,
  section,
  setHighlightedImageIds,
  getFilesUri,
  getSectionPointers,
  onCasePartialChange,
}: Props) {
  const [imagePickerPointers, setImagePickerPointers] = useState<ImagePointer[]>([]);

  const isDirty = useAppSelector(state => state.caseDetail.isDirty);

  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const imagesById = useAppSelector(state => state.images.imagesById);
  const sectionPointers = getSectionPointers(caze);

  const { lockedImagePointers, dependentImages } = useMemo(() => {
    const pointers = caze.imagePointers;
    const images = pointers.map(pointer => imagesById[pointer.imageId]);

    const sectionImages = sectionPointers?.imagePointers.map(pointer => imagesById[pointer.imageId]);

    if ([images, sectionImages].some(pointerCollection => pointerCollection?.some(image => image == null))) {
      // some image not loaded, lock all, this memo will be recomputed when all images are fetched
      return { lockedImagePointers: sectionPointers?.imagePointers, dependentImages: {} };
    }

    const dependentImages: Record<string, string[]> = {};

    const lockedImagePointers = sectionPointers?.imagePointers.filter(({ imageId }) => {
      let shouldLock = false;
      for (const pointer of pointers) {
        if (pointer.imageId === imageId) {
          if (!dependentImages[imageId]) {
            dependentImages[imageId] = [];
          }
          dependentImages[imageId].push(pointer.imageId);
          // image has been replaced (i.e. new version has been uploaded) in this case, lock pointer
          shouldLock = true;
          break;
        }
      }

      for (const image of images) {
        if (image.parentId === imageId) {
          if (!dependentImages[imageId]) {
            dependentImages[imageId] = [];
          }
          dependentImages[imageId].push(image.id);
          // variant image exists among pointers, lock pointer
          shouldLock = true;
        }
      }

      // pointer has neither replacement nor variant in images, don't lock
      return shouldLock;
    });
    return { lockedImagePointers, dependentImages };
  }, [caze.imagePointers, sectionPointers?.imagePointers, imagesById]);

  const togglePointer = (pointer: ImagePointer) => {
    if (lockedImagePointers?.some(({ imageId }) => imageId === pointer.imageId)) {
      setHighlightedImageIds(dependentImages[pointer.imageId] ?? []);
      if (timer) clearTimeout(timer);
      setTimer(setTimeout(() => setHighlightedImageIds([]), 5000));
      return;
    }
    const imagePointers = sectionPointers?.imagePointers;
    const newImagePointers = imagePointers?.some(p => IsEqual(p, pointer))
      ? imagePointers?.filter(p => !IsEqual(p, pointer))
      : imagePointers?.concat([pointer]);

    // Due to how lodash.isEqual works, we need to keep this array sorted
    // to ensure the UI doesn't incorrectly flag the Case as dirty
    newImagePointers?.sort(ByPointers);

    // For some reason caseId is always null
    onCasePartialChange({ [section]: { caseId: null, imagePointers: newImagePointers } });
  };

  const hasNoImages = (sectionPointers?.imagePointers?.length ?? 0) < 1;

  const setImagePointers = (imagePointers: ImagePointer[]) => {
    // Due to how lodash.isEqual works, we need to keep this array sorted
    // to ensure the UI doesn't incorrectly flag the Case as dirty
    imagePointers.sort(ByPointers);

    // For some reason caseId is always null
    onCasePartialChange({ [section]: { caseId: null, imagePointers } });
  };

  const imageActions = (
    <>
      <ImagePicker
        onClick={() => setImagePickerPointers(sectionPointers?.imagePointers || [])}
        selectedImagePointers={imagePickerPointers || []}
        lockedImagePointers={lockedImagePointers}
        onSelectImagePointers={setImagePointers}
      />
      <IconButton title="Ladda ner bilder" onClick={e => e.stopPropagation()} href={getFilesUri(caze)} disabled={isDirty || hasNoImages}>
        <CloudDownload fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <>
      <CaseFormSection title={title} titleComponent={imageActions} caseId={caze.id}>
        <ReferenceImages
          caze={caze}
          onTogglePointer={togglePointer}
          readonly={readonly}
          canUpload={canUpload}
          getImagePointers={getSectionPointers}
        />
      </CaseFormSection>
    </>
  );
}
