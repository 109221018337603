import React, { useState, useEffect, useMemo } from 'react';

import { Clear, Edit, Search } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, IconButton, InputAdornment} from '@mui/material';
import { CellProps, Column } from 'react-table';

import styles from './SystemTags.module.css';

import { ColoredTag, CreateNewSystemTag, SystemTag } from '../../api/caseApiTypes';
import { confirmDelete } from '../../helpers/confirmDeleteHelper';
import { useAppDispatch, useAppSelector } from '../../hooks/useAppRedux';
import { ColorTag } from './ColorTag';
import { SystemTagModal } from './SystemTagModal';
import { getAllSystemTags, createSystemTag, deleteSystemTag, updateSystemTag } from './TagsSlice';
import { TagsTable } from './TagsTable';
import { CaseTextField } from '../cases/formFieldComponents/CaseTextField';

export const SystemTags: React.FC = () => {
  const dispatch = useAppDispatch();
  const [newTagModalOpen, setNewTagModalOpen] = useState<boolean>(false);
  const { systemTags } = useAppSelector(state => state.tags);
  const [activeTag, setActiveTag] = useState(CreateNewSystemTag());
  const [filterQuery, setFilterQuery] = useState<string>('');
  const filteredTags = useMemo(() => {
    const lowerCaseInput = filterQuery.toLowerCase();
    return systemTags.filter(tag =>
      tag.description.toLowerCase().includes(lowerCaseInput) ||
      tag.name.toLowerCase().includes(lowerCaseInput)
    )
  }, [filterQuery, systemTags])

  useEffect(() => {
    dispatch(getAllSystemTags());
  }, [dispatch]);

  const onSave = (tag: SystemTag) => {
    setNewTagModalOpen(false);
    dispatch(createSystemTag(tag));
  };

  const onEdit = (tag: SystemTag) => {
    setNewTagModalOpen(false);
    dispatch(updateSystemTag(tag));
  };

  const columns: Column<ColoredTag>[] = useMemo(() => {
    const onDelete = (tag: SystemTag) => {
      if (
        window.confirm(`Är du säker på att du vill ta bort taggen ${tag.name}? Beställningar som redan har taggen på sig påverkas inte.`)
      ) {
        dispatch(deleteSystemTag(tag.id));
      }
    };

    return [
      {
        Header: 'Namn',
        accessor: 'id',
        Cell: (props: CellProps<ColoredTag>) => {
          const tag = props.row.original;
          return (
            <ColorTag hex={tag.color} label={tag.name} size="small" />
          );
        },
      },
      {
        Header: 'Beskrivning',
        accessor: 'description',
      },
      {
        accessor: 'name',
        Cell: (props: CellProps<ColoredTag>) => {
          const tag = props.row.original;
          return (
            <Box className={styles.buttons} style={{display: 'flex'}}>
              <IconButton
                size="small"
                onClick={() => {
                  setNewTagModalOpen(true);
                  setActiveTag(tag);
                }}
              >
                <Edit />
              </IconButton>
              <IconButton size="small" onClick={confirmDelete(() => onDelete(tag), 'tagg')}>
                <DeleteOutlineIcon />
              </IconButton>
            </Box>
          );
        },
      }
    ];
  }, [dispatch]);

  return (
    <>
      <CaseTextField
        className={styles.search}
        aria-label="Query"
        value={filterQuery}
        setValue={(value) => setFilterQuery(value)}
        autoFocus={true}
        label={'Sök'}
        onSubmit={() => {}
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={_ => setFilterQuery('')}>
                <Clear />
              </IconButton>
              <Search />
            </InputAdornment>
          ),
        }}
      />

      <TagsTable
        columns={columns}
        data={filterQuery !== '' ? filteredTags : systemTags}
        onClickOnAdd={() => {
          setNewTagModalOpen(true);
          setActiveTag(CreateNewSystemTag());
        }}
      />
      {newTagModalOpen && (
        <SystemTagModal tag={activeTag} open={newTagModalOpen} onSave={onSave} onEdit={onEdit} onClose={() => setNewTagModalOpen(false)} />
      )}
    </>
  );
};
