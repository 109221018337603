import React from 'react';
import styles from './CaseFormContent.module.css';

interface Props {
  children?: React.ReactNode;
}

export const CaseFormContent: React.FC<Props> = ({ children }) => {
  return <div className={styles.left}>{children}</div>;
};
