import { useTheme } from '@mui/material';
import { CaseSelectableText } from '../../cases/layoutComponents/CaseSelectableText';
import { Image } from '../../../api/caseApiTypes';
import styles from './ImageCardFooter.module.css';

export type ImageCardFooterProps = {
  image: Image | undefined;
};

export function ImageCardFooter({ image }: ImageCardFooterProps) {
  const { palette } = useTheme();

  const color = palette.mode === 'light' ? '#666' : '#aaa';

  return (
    <div style={{ color }}>
      <CaseSelectableText label="Bild ID" className={styles.infoText}>
        <span className={styles.infoTextValue}>{image?.lookupEntry}</span>
      </CaseSelectableText>
    </div>
  );
}
