import React, { useRef, useState } from 'react';

import { Add } from '@mui/icons-material';
import { Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import styles from './NewCaseButton.module.css';

import { CaseType, CaseTypeConverter } from '../../api/caseApiTypes';
import { Right } from '../../api/userApi';
import { useEitherRights } from '../../hooks/useRights';

const options = [
  CaseType.ContextualPhoto,
  CaseType.InstallationPhoto,
  CaseType.SingleProduct,
  CaseType.ProductCollection,
  CaseType.ProductContextual,
  CaseType.Retouch,
  CaseType.Video,
].map(caseType => CaseTypeConverter.Mapping[caseType]);

export const NewCaseButton = () => {
  const canCreate = useEitherRights([Right.WriteCase, Right.Administration]);
  const navigate = useNavigate();
  const { zIndex } = useTheme();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleMenuItemClick = (slug: string) => {
    navigate(`/new-case/${slug}`);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const rootClasses = [styles.container];

  if (!canCreate) {
    rootClasses.push(styles.disabled);
  }

  return (
    <div className={rootClasses.join(' ')}>
      <ButtonGroup variant="contained" color="secondary">
        <Button
          disabled={!canCreate}
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="Välj beställningstyp"
          aria-haspopup="menu"
          ref={anchorRef}
          onClick={handleToggle}
          startIcon={<Add />}
        >
          <span>Ny Beställning</span>
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        placement="right-start"
        style={{ zIndex: zIndex.drawer + 1 }}
        onResize={undefined}
        onResizeCapture={undefined}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map(option => (
                    <MenuItem key={option.slug} onClick={() => handleMenuItemClick(option.slug)}>
                      {option.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
