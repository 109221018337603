import { Delete } from '@mui/icons-material';
import { Button, Paper } from '@mui/material';

import styles from './RetouchCaseDetail.module.css';

import { Article, AttachmentCategory, DiningSetCase } from '../../../api/caseApiTypes';
import { Right } from '../../../api/userApi';
import { useAppDispatch, useAppSelector } from '../../../hooks/useAppRedux';
import { useEitherRights, useRights } from '../../../hooks/useRights';
import { ImagesGridDetailsView } from '../../images/detailsView/ImagesGridDetailsView';
import { CaseAttachments } from '../CaseAttachments';
import { CaseComments } from '../CaseComments';
import { CaseMedia } from '../CaseMedia';
import { CaseStateProgress } from '../CaseStateProgress';
import { CaseTags } from '../CaseTags';
import { CaseTypeDetailComponentProps } from '../CaseTypeDetail';
import { upsertArticles, removeArticle, uploadImage } from '../caseDetailSlice';
import { CaseDeadlineDates } from '../formFieldComponents/CaseDeadlineDates';
import { CaseExpansion } from '../layoutComponents/CaseExpansion';
import { CaseFormBlock } from '../layoutComponents/CaseFormBlock';
import { CaseFormButtonContainer } from '../layoutComponents/CaseFormButtonContainer';
import { CaseFormContent } from '../layoutComponents/CaseFormContent';
import { CaseFormSidebar } from '../layoutComponents/CaseFormSidebar';
import { DiningSetCaseDetailFormFields } from './DiningSetCaseDetailFormFields';
import { FormFieldsWrapper } from './FormFieldsWrapper';
import { isAnyMediaSelected, resetSelectedContext } from '../../images/imagesSlice';
import { useSelectionContext } from '../../images/selectedImagesContext/selectionContextContext';
import { SelectionActionButtonsCard } from '../../images/selectedImagesContext/SelectionActionButtons';

interface Props extends CaseTypeDetailComponentProps {
  caze: DiningSetCase;
}

export const DiningSetCaseDetail: React.FC<Props> = ({
  caze,
  isNewCase,
  readonly,
  onDeleteCase,
  onDeleteAttachment,
  onDeleteRelation,
  onCasePartialChange,
  onCreateRelatedCase,
}) => {
  const isAdmin = useRights(Right.Administration);
  const canUploadImage = useEitherRights([Right.Administration, Right.ManageImage]);
  const dispatch = useAppDispatch();

  const onUpsertArticle = (article: Article) => {
    dispatch(upsertArticles({ item: caze, articles: [article] }));
  };

  const onUpsertManyArticles = (articles: Article[]) => {
    dispatch(upsertArticles({ item: caze, articles }));
  };

  const onDeleteArticle = ({ id }: Article) => {
    if (id != null) {
      dispatch(removeArticle({ item: caze, id: id }));
    }
  };

  const canRemoveCase = isAdmin || !readonly;

  const selectionContext = useSelectionContext();
  const anySelected = useAppSelector(isAnyMediaSelected(selectionContext));
  const clearSelection = () => dispatch(resetSelectedContext(selectionContext));

  return (
    <>
      <CaseFormContent>
        <FormFieldsWrapper caze={caze} onChange={onCasePartialChange} isNewCase={isNewCase}>
          <DiningSetCaseDetailFormFields
            caze={caze}
            isNewCase={isNewCase}
            readonly={readonly}
            onChange={onCasePartialChange}
            onUpsertArticle={onUpsertArticle}
            onUpsertManyArticles={onUpsertManyArticles}
            onDeleteArticle={onDeleteArticle}
            onDeleteAttachment={onDeleteAttachment}
          />
        </FormFieldsWrapper>

        <CaseExpansion title="Bilder" hidden={isNewCase}>
          <CaseMedia caze={caze} mediaPointers={caze.imagePointers} caseReadonly={readonly} uploadMediaAction={uploadImage} />
        </CaseExpansion>

        {canUploadImage && (
          <CaseExpansion title="Original" hidden={isNewCase}>
            <CaseAttachments
              caze={caze}
              onDelete={onDeleteAttachment}
              category={AttachmentCategory.OriginalImage}
              right={Right.ManageImage}
            />
          </CaseExpansion>
        )}
      </CaseFormContent>
      <CaseFormSidebar>
        <CaseFormBlock>
          <CaseStateProgress caze={caze} isNewCase={isNewCase} />

          <CaseDeadlineDates caze={caze} onChange={onCasePartialChange} readonly={readonly} />

          <CaseTags caze={caze} readonly={isNewCase || readonly} size="small" />

          {canRemoveCase && (
            <CaseFormButtonContainer renderDivider>
              <Button variant="outlined" color="secondary" startIcon={<Delete />} onClick={onDeleteCase} disabled={isNewCase}>
                Ta Bort Beställningen
              </Button>
            </CaseFormButtonContainer>
          )}
        </CaseFormBlock>

        {anySelected && (
          <>
            <SelectionActionButtonsCard />
            <Paper className={styles.imageInformation}>
              <ImagesGridDetailsView onClose={clearSelection} />
            </Paper>
          </>
        )}

        <CaseFormBlock>
          <CaseExpansion title="Kommentarer" hidden={isNewCase} compact>
            <CaseComments caze={caze} />
          </CaseExpansion>
        </CaseFormBlock>
      </CaseFormSidebar>
    </>
  );
};
