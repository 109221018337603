import { DerivedImageHolder } from '../api/caseApiTypes';
import { GetPreviewVideoUri, GetThumbnailUri } from '../helpers/DerivedImageHelpers';
import styles from './MediaVisualizer.module.css';

export type Visualizer = 'image' | 'video' | undefined;
export type Size = 'full' | 'small';

export function GetVisualizer(item: string | DerivedImageHolder | undefined, size: Size | undefined): [string, Visualizer] {
  if (typeof item === 'string') {
    return [item, 'image'];
  }

  const videoUri = GetPreviewVideoUri(item);
  if (videoUri) {
    return [videoUri, 'video'];
  }

  const imageSize = size === 'full' ? 1920 : 320;
  const imageUri = GetThumbnailUri(item, imageSize);
  if (imageUri) {
    return [imageUri, 'image'];
  }

  return ['', undefined];
}

type Props = {
  item: string | DerivedImageHolder | undefined;
  alt?: string;
  autoPlayVideo?: boolean;
  size?: Size;
  className?: string;
};

export function MediaVisualizer({ item, alt, autoPlayVideo, size, className }: Props) {
  const [uri, visualizer] = GetVisualizer(item, size);
  // Default autoplay to false unless specified
  const autoPlay = autoPlayVideo === true;
  return (
    <>
      {visualizer === 'image' && <img className={className} alt={alt} src={uri} />}
      {visualizer === 'video' && (
        <video
          autoPlay={autoPlay}
          controls
          preload={autoPlay ? 'auto' : 'metadata'}
          className={`${className} ${styles.videoPreview} ${size === 'small' ? styles.small : ''}`}
          src={uri}
        ></video>
      )}
    </>
  );
}
