import { format, parseISO } from 'date-fns';

export const formatDate = (date: Date | string | undefined | null, displayFormat = 'yyyy-MM-dd', noValuePlaceholder = '-') => {
  if (date == null || date === '') {
    return noValuePlaceholder;
  }

  if (typeof date === 'string') {
    date = parseISO(date);
  }

  return format(date, displayFormat);
};

export const formatDateTime = (date: Date | string | undefined | null, displayFormat = 'yyyy-MM-dd HH:mm', noValuePlaceholder = '-') => {
  return formatDate(date, displayFormat, noValuePlaceholder);
};
