import React, { useEffect, useState } from 'react';
import { Autocomplete, IconButton, Stack, TextField } from '@mui/material';
import { CaseDialog, CaseDialogProps } from '../layoutComponents/CaseDialog';
import { searchAdUsers } from '../../../api/userApi';
import styles from './CaseUserAssignmentModal.module.css';
import { AssignedUser } from '../../localUserStateSlice';
import { RootState } from '../../../app/rootReducer';
import { useAppSelector } from '../../../hooks/useAppRedux';
import { Close } from '@mui/icons-material';

interface Props {
  value: string | undefined;
  type: string;
  onSaveUser: (userId: string, fullName: string) => void;
  onRemoveLocallySavedUser: (userId: string) => void;
  selectDefaultUsers: (state: RootState) => AssignedUser[];
}

function UserValid(user: CaseAssignmentUser | null) {
  if (!user) {
    return false;
  }
  return true;
}

let debounceTimeoutId: ReturnType<typeof setInterval>;

export type CaseAssignmentUser = {
  label: string;
  id: string;
};

function fetchUsers(query: string, callback: (data: CaseAssignmentUser[]) => void) {
  if (debounceTimeoutId) {
    clearTimeout(debounceTimeoutId);
  }
  debounceTimeoutId = setTimeout(() => {
    searchAdUsers(query)
      .then(res => res.map(user => ({ label: user.fullName, id: user.id })))
      .then(callback)
      .catch(err => {
        callback([]);
      });
  }, 400);
}

export const CaseUserAssignmentModal: React.FC<Props & CaseDialogProps> = ({
  value,
  type,
  onSaveUser,
  selectDefaultUsers,
  onRemoveLocallySavedUser,
  ...modalProps
}) => {
  const [pendingChange, setPendingChange] = useState<CaseAssignmentUser | null>(value ? { id: '', label: value } : null);

  const initialDefaultSearchResults = useAppSelector(selectDefaultUsers).map(u => ({ label: u.fullName, id: u.id }));
  const [defaultSearchResults, setDefaultSearchResults] = useState<CaseAssignmentUser[]>(initialDefaultSearchResults);
  // Get default search results from store, to remember what the user has selected before
  let [searchResult, setSearchResult] = useState<CaseAssignmentUser[]>(defaultSearchResults);

  // Make sure currently selected value exists in list, otherwise Autocomplete component is unhappy
  if (pendingChange && !searchResult.find(r => r.label === pendingChange?.label)) {
    searchResult.push(pendingChange);
  }

  // Reset pending change when value changes
  useEffect(() => {
    setPendingChange({ id: '', label: value || '' });
  }, [value]);

  const isDirty = pendingChange?.label !== value;

  const onSave = () => {
    onSaveUser(pendingChange!.id, pendingChange!.label);
    setSearchResult(defaultSearchResults);
  };

  const onRemove = (event: React.MouseEvent<HTMLElement>, userId: string) => {
    event.stopPropagation();
    onRemoveLocallySavedUser(userId);
    setDefaultSearchResults(defaultSearchResults.filter(u => u.id !== userId));
    setSearchResult(searchResult.filter(u => u.id !== userId));
  };

  const onClose = () => {
    modalProps.onClose();
  };

  const saveDisabled = !isDirty || !UserValid(pendingChange);

  return (
    <CaseDialog
      {...modalProps}
      title={`Välj ${type}`}
      closeLabel="Avbryt"
      saveDisabled={saveDisabled}
      onSave={onSave}
      onClose={onClose}
      className={styles.container}
    >
      <Autocomplete
        options={searchResult}
        isOptionEqualToValue={(option, value) => value.label === option.label}
        filterOptions={x => x} // Disable filtering since we are searching ourselves
        onChange={(_, newValue) => setPendingChange(newValue)}
        value={pendingChange}
        renderOption={(props, option) => (
          <li {...props}>
            <Stack direction="row" width={'100%'} justifyContent="space-between">
              <span>{option.label}</span>
              {defaultSearchResults.find(u => u.id === option.id) && (
                <IconButton size="small" onClick={e => onRemove(e, option.id)}>
                  <Close />
                </IconButton>
              )}
            </Stack>
          </li>
        )}
        renderInput={params => (
          <TextField
            {...params}
            label={`Välj ${type}`}
            onChange={e => {
              var query = e.target.value;
              if (!query) {
                setSearchResult(defaultSearchResults);
                return;
              }
              return fetchUsers(e.target.value, setSearchResult);
            }}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'off', // from MUI example, disables autocomplete and autofill https://stackoverflow.com/questions/48304062/material-ui-textfield-disable-browser-autocomplete
              form: {
                autocomplete: 'off',
              },
            }}
          />
        )}
      ></Autocomplete>
    </CaseDialog>
  );
};
