import { useMemo } from 'react';
import { IsDirty } from '../../helpers/ObjectHelpers';
import { useAppDispatch, useAppSelector } from '../../hooks/useAppRedux';
import { clearPendingChanges, selectImagesById, setPendingChanges } from '../images/imagesSlice';
import { GenericSupplierDesign, Image, Paint } from '../../api/caseApiTypes';
import { batchUpdateMedia } from '../images/imagesSlice.batchUpdate';

const PaintValid = (pending: Paint | undefined) => {
  if (pending == null || (!pending.supplier && !pending.color)) {
    return true;
  }
  return pending.supplier && pending.color;
};

const GenericSupplierDesignValid = (pending: GenericSupplierDesign | undefined) => {
  if (pending == null || (!pending.supplier && !pending.design)) {
    return true;
  }
  return pending.supplier && pending.design;
};

const ImageValid = (image: Image | null) => {
  return (
    image != null &&
    PaintValid(image.paint) &&
    GenericSupplierDesignValid(image.wallpaper) &&
    GenericSupplierDesignValid(image.floor) &&
    GenericSupplierDesignValid(image.tiling)
  );
};

export type MediaChange = (media: Image, updates: Partial<Image>) => Partial<Image>;

export function useBatchMediaEnrichment(selectedImages: Image[]) {
  const dispatch = useAppDispatch();
  const pendingChanges = useAppSelector(state => state.images.pendingChanges);

  const imageIds = useMemo(() => selectedImages.map(img => img.id), [selectedImages]);
  const activeChanges = useMemo(() => Object.fromEntries(imageIds.map(id => [id, pendingChanges[id]])), [pendingChanges, imageIds]);
  const hasActiveChanges = useMemo(() => Object.values(activeChanges).some(Boolean), [activeChanges]);

  const persistedImages = useAppSelector(selectImagesById(imageIds));

  const isDirty = useMemo(
    () => persistedImages.some(persistedImage => IsDirty({ ...persistedImage, ...pendingChanges[persistedImage.id] }, persistedImage)),
    [pendingChanges, persistedImages],
  );
  const enrichedImages = useMemo(
    () => persistedImages.map(persistedImage => ({ ...persistedImage, ...pendingChanges[persistedImage.id] })),
    [pendingChanges, persistedImages],
  );
  const cancelChanges = () => dispatch(clearPendingChanges(imageIds));

  const persistChanges = () => {
    dispatch(
      batchUpdateMedia({
        images: enrichedImages,
        onComplete: cancelChanges,
      }),
    );
  };

  const onMediaPartialChange = (change: MediaChange) => {
    dispatch(
      setPendingChanges(
        Object.fromEntries(
          persistedImages.map(image => {
            const update = pendingChanges[image.id];
            return [image.id, { ...update, ...change(image, update) }];
          }),
        ),
      ),
    );
  };

  const saveDisabled = !isDirty || !enrichedImages.every(image => ImageValid(image));

  return { enrichedImages, isDirty, saveDisabled, onMediaPartialChange, persistChanges, cancelChanges, hasActiveChanges };
}
