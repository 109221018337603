import { Button } from '@mui/material';
import { Case } from '../../api/caseApiTypes';
import styles from './CaseProceedStateButton.module.css';
import { GetNextStateAction } from './forms/CaseFormLogic';
import { useAppDispatch } from '../../hooks/useAppRedux';

interface Props {
  caze: Case;
  isNew: boolean;
}

export function CaseProceedStateButton({ caze, isNew }: Props) {
  const dispatch = useAppDispatch();
  const action = GetNextStateAction(caze, isNew);
  const onClick = () => action && dispatch(action.action(caze));
  return (
    <>
      {action && (
        <div className={styles.container}>
          <Button variant="contained" color="primary" onClick={onClick}>
            {action.text}
          </Button>
        </div>
      )}
    </>
  );
}
