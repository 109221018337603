import React, { useEffect } from 'react';
import styles from './PlannableCasesList.module.css';
import { Box, Link, List, ListItem, Typography } from '@mui/material';

import { Case, CaseStateConverter, CaseType, CaseTypeConverter, GetProductNameForCase, StateEnum } from '../../api/caseApiTypes';
import { CaseContainer } from './layoutComponents/CaseContainer';
import { CaseTags } from './CaseTags';
import { CaseDeadlines } from './CaseDeadlines';
import { MakePlannable } from '../calendar/Plannable';
import { SearchContext, useCaseSearchState } from '../../hooks/useSearchState';
import { Videocam } from '@mui/icons-material';

function containsVideo(item: Case) {
  return item.videoRequest != null || item.type === CaseType.Video;
}

export const PlannableCasesList: React.FC = () => {
  const { items, isSearching } = useCaseSearchState(SearchContext.PlannableCasesList);
  const listEntry = 'caseListEntry';

  useEffect(() => {
    MakePlannable(listEntry, (id: string) => {
      return {
        id: id,
        create: false,
        duration: '02:00',
        color: 'white',
      };
    });
  }, [items]);

  const confirmedCases = items.filter(item => item.state.state === StateEnum.confirmed);
  return (
    <List>
      {confirmedCases.map(item => (
        <ListItem id={item.id} key={item.id} className={listEntry} button>
          <Link
            className={styles.link}
            href={`/case/${item.lookupEntry ?? item.id}`}
            target="_blank"
            rel="noopener noreferrer"
            underline="none"
          >
            <CaseContainer type={item.type}>
              <Box className={styles.searchListItem}>
                <div className={styles.videoIcon}>{containsVideo(item) && <Videocam />}</div>
                <Typography variant="subtitle2" color="textPrimary">
                  {GetProductNameForCase(item)}
                </Typography>

                <Typography variant="body2" color="textPrimary">
                  {`${CaseTypeConverter.ToName(item.type)} - ${CaseStateConverter.ToText(item.state)}`}
                </Typography>

                <Typography variant="body2" color="textSecondary" className={styles.description}>
                  {item.description}
                </Typography>

                <CaseDeadlines photoAndRetouchDeadline={item.photoAndRetouchDeadline} approvalDeadline={item.approvalDeadline} />

                <CaseTags className={styles.tags} caze={item} size="small" readonly={true} />
              </Box>
            </CaseContainer>
          </Link>
        </ListItem>
      ))}
      {!isSearching && confirmedCases.length === 0 && (
        <ListItem>
          <Typography variant="body2" color="textSecondary">
            Din sökning matchade inga resultat.
          </Typography>
        </ListItem>
      )}
    </List>
  );
};
