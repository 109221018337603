import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ThunkConfig } from '../../app/store';
import { Case, DerivedImage, LegacyDeliveryStatus, StateEnum } from '../../api/caseApiTypes';
import * as Api from '../../api/caseApi';
import { IsDirty } from '../../helpers/ObjectHelpers';
import { ensureImagesLoaded } from '../images/imagesSlice';
import { SearchContext, SearchState } from '../../hooks/useSearchState';
import { Clause, OrderBy } from '../../api/searchApiTypes';
import { FilterableCase } from '../../api/filterTypes';
import { setEtag, setPointers, updateCaseWithPersisted } from './caseDetailSlice';

/*
 * In order to correlate a search query with its search result, we use search contexts.
 * Search contexts enable us to perform multiple different search queries simultaneously as
 * each search result can be stored in a different search context.
 *
 * For example, we can perform two simultaneous queries (one for plannable cases, one for
 * already planned cases) by specifying two appropriate search contexts, such as:
 * SearchContext.PlannableCasesList
 * SearchContext.PlanningCalendar
 *
 * The results from these queries can then be consumed in different components through:
 * casesByContext[SearchContext.PlannableCasesList]
 * casesByContext[SearchContext.PlanningCalendar]
 */

interface QueryInfo {
  query?: string;
  orderBy?: OrderBy[];
  stateFilter?: StateEnum;
  typeFilter?: string;
  filterExpression?: string;
  pageSize?: number;
}

interface QueryInfoV2 {
  query?: string;
  orderBy?: OrderBy[];
  filterTree?: Clause<FilterableCase>;
  pageSize?: number;
  searchOnlyFilename?: boolean;
}

interface CasesState {
  casesByDate: SearchState<Case> | undefined;
  casesByContext: Record<number, SearchState<Case>>;
  queryByContext: Record<number, QueryInfo>;
  casesById: Record<string, Case>;
  caseIdsByLookupEntry: Record<number, string>;
  error: string | null;
  isLoading: boolean;
  isSearching: boolean;
}

const initialState: CasesState = {
  /*
   * Note: different cases part of the state may have different amounts of data attached to them.
   * This is due to the fact that data supplied by the /cases endpoint contains additional properties
   * compared to what is supplied through the /search endpoint. In practice this means that any
   * case that has been viewed in the details view OR that has been modified in any way
   * may contain additional information (such as comments) as part of its state.
   *
   * This represents a cognitive difficulty that should be refactored away.  We can either remove
   * the differences in data that the endpoints return, or we can change the Case type so that it
   * is clear that we can expect different amount of data.
   *
   * TODO: This should be refactored and represents technical debt.
   */
  casesByContext: {},
  queryByContext: {},
  casesById: {},
  caseIdsByLookupEntry: {},
  casesByDate: undefined,
  error: null,
  isLoading: false,
  isSearching: false,
};

const forEachContext = (searchContexts: Record<number, SearchState<Case>>, action: (context: SearchState<Case>) => void) => {
  for (let context in searchContexts) {
    action(searchContexts[context]);
  }
};

export const casesSlice = createSlice({
  name: 'cases',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    startSearching(state) {
      state.isSearching = true;
    },
    searchSuccess(state, action: PayloadAction<SearchState<Case> & { continuation: number; searchContext: SearchContext }>) {
      const { items: newItems, count, continuation, searchContext } = action.payload;

      const items = (state.casesByContext[searchContext]?.items ?? []).slice();
      for (let i = 0; i < newItems.length; i++) {
        items[continuation + i] = newItems[i];
      }

      state.casesByContext[searchContext] = {
        items,
        count,
      };

      state.isSearching = false;
    },
    searchFailed(state, action: PayloadAction<{ error: string; searchContext: SearchContext }>) {
      state.casesByContext[action.payload.searchContext] = {
        items: [],
        count: 0,
      };
      state.isSearching = false;
      state.error = action.payload.error;
    },
    resetResults(state, action: PayloadAction<{ searchContext: SearchContext; queryInfo: QueryInfo }>) {
      const { searchContext, queryInfo } = action.payload;
      state.queryByContext[searchContext] = queryInfo;
      state.casesByContext[searchContext] = {
        items: [],
        count: 0,
      };
    },
    getCaseSuccess(state, action: PayloadAction<Case>) {
      state.isLoading = false;

      const caze = action.payload;

      forEachContext(state.casesByContext, context => {
        const cases = context.items;
        const index = cases.findIndex(c => c.id === caze.id);
        if (index !== -1) {
          cases[index] = caze;
        }
      });

      state.casesById[caze.id] = caze;
      if (caze.lookupEntry) state.caseIdsByLookupEntry[caze.lookupEntry] = caze.id;
    },
    getCaseFailed(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    newCaseFailed(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    deleteCaseSuccess(state, action: PayloadAction<Case>) {
      state.isLoading = false;

      forEachContext(state.casesByContext, context => {
        context.items = context.items?.filter(item => item.id !== action.payload.id);
        context.count--;
      });

      delete state.casesById[action.payload.id];
      if (action.payload.lookupEntry) delete state.caseIdsByLookupEntry[action.payload.lookupEntry];
    },
    deleteCaseFailed(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getByDateSuccess(state, action: PayloadAction<Case[]>) {
      state.isLoading = false;
    },
    getByDateFailed(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },
    errorConsumed(state) {
      state.error = null;
    },
    setDerivedImages(state, action: PayloadAction<{ caseId: string; attachmentId: string; derivedImages: DerivedImage[] }>) {
      const { caseId, attachmentId, derivedImages } = action.payload;

      const updateStateItem = (caze: Case | undefined) => {
        if (caze != null) {
          const item = caze.attachments.find(a => a.id === attachmentId);
          if (item != null) {
            item.derivedImages = derivedImages;
          }
        }
      };

      forEachContext(state.casesByContext, context => updateStateItem(context.items?.find(c => c.id === caseId)));

      updateStateItem(state.casesById[caseId]);
    },
  },
});

export const {
  startLoading,
  startSearching,
  searchSuccess,
  searchFailed,
  resetResults,
  getCaseSuccess,
  getCaseFailed,
  deleteCaseSuccess,
  getByDateSuccess,
  getByDateFailed,
  errorConsumed,
  setDerivedImages,
} = casesSlice.actions;

const mapApiOrderBy = (orderBy: string) => {
  if (orderBy === 'state') {
    return 'CaseState/SortRank';
  }

  if (orderBy === 'modified') {
    return 'Timestamp';
  }

  return orderBy.charAt(0).toUpperCase() + orderBy.slice(1);
};

export const search = createAsyncThunk<
  void,
  QueryInfoV2 & {
    continuation?: number;
    searchContext: SearchContext;
  },
  ThunkConfig
>('cases/search', async ({ continuation = 0, searchContext, ...queryInfo }, { dispatch, getState }) => {
  try {
    const { query, filterTree, orderBy, pageSize } = queryInfo;

    dispatch(startSearching());

    if (IsDirty(queryInfo, getState().cases.queryByContext[searchContext])) {
      dispatch(resetResults({ searchContext, queryInfo }));
    }

    const orderByString = (orderBy ?? []).map(sb => `${mapApiOrderBy(sb.id)} ${sb.desc ? 'desc' : 'asc'}`).join(',');

    const { items, count } = await Api.search(query || '', filterTree, orderByString, searchContext, continuation, pageSize);

    dispatch(searchSuccess({ items, count, continuation, searchContext }));
  } catch (error: any) {
    dispatch(searchFailed(error.toString()));
  }
});

export const getByDate = createAsyncThunk<void, { start: Date; end: Date }>('cases/getByDate', async ({ start, end }, { dispatch }) => {
  try {
    dispatch(startLoading());

    const { items } = await Api.getByDate(start, end);

    dispatch(getByDateSuccess(items));
  } catch (error: any) {
    dispatch(getByDateFailed(error.toString()));
  }
});

export const getById = createAsyncThunk<void, string, ThunkConfig>('cases/getById', async (id: string, { dispatch, getState }) => {
  try {
    dispatch(startLoading());

    const currentlyOpenCase = getState().caseDetail.case;

    const result = await Api.getById(id);
    dispatch(ensureImagesLoaded(result));

    dispatch(getCaseSuccess(result));
    if (currentlyOpenCase?.id === id) {
      dispatch(updateCaseWithPersisted(result));
    }
  } catch (error: any) {
    dispatch(getCaseFailed(error.toString()));
  }
});

export const getByLookupEntry = createAsyncThunk<void, number, ThunkConfig>(
  'cases/getByLookupEntry',
  async (lookupEntry: number, { dispatch }) => {
    try {
      dispatch(startLoading());

      const result = await Api.getByLookupEntry(lookupEntry);
      dispatch(ensureImagesLoaded(result));

      dispatch(getCaseSuccess(result));
    } catch (error: any) {
      dispatch(getCaseFailed(error.toString()));
    }
  },
);

export const refreshCaseIfTracked = createAsyncThunk<void, string, ThunkConfig>(
  'cases/refreshCaseIfTracked',
  async (id, { dispatch, getState }) => {
    const { casesByContext, casesById, isLoading } = getState().cases;

    if (isLoading) {
      return;
    }

    const trackedAsDetailedCase = id in casesById;

    let trackedThroughSearchResults = false;
    forEachContext(casesByContext, context => {
      if (context.items?.some(c => c.id === id)) {
        trackedThroughSearchResults = true;
      }
    });

    if (!trackedAsDetailedCase && !trackedThroughSearchResults) {
      return;
    }

    dispatch(getById(id));
  },
);

export const getAllRelatedCases = createAsyncThunk<void, string, ThunkConfig>(
  'cases/getAllRelatedCases',
  async (id, { dispatch, getState }) => {
    try {
      const { casesByContext, casesById } = getState().cases;

      let caze = casesById[id];
      if (caze == null) {
        forEachContext(casesByContext, context => {
          const item = context.items?.find(c => c.id === id);
          if (item != null) {
            caze = item;
          }
        });
      }
      if (caze == null) {
        return;
      }

      const distinct = (e1: string, index: number, elements: string[]) => elements.findIndex(e2 => e1 === e2) === index;
      const relatedCaseIds = caze?.caseRelations?.map(r => r.caseId).filter(distinct);
      const relatedCasesToRetrieve = relatedCaseIds.filter(id => casesById[id] == null);

      dispatch(startLoading());

      const results = await Promise.all(relatedCasesToRetrieve.map(id => Api.getById(id)));

      results?.forEach(result => {
        dispatch(getCaseSuccess(result));
      });
    } catch (error: any) {
      dispatch(getCaseFailed(error.toString()));
    }
  },
);

export const updateAttachmentDerivedImages = createAsyncThunk<void, { caseId: string; attachmentId: string }, ThunkConfig>(
  'cases/updateAttachmentDerivedImages',
  async ({ caseId, attachmentId }, { dispatch }) => {
    try {
      const caze = await Api.getById(caseId);

      const derivedImages = caze.attachments.find(a => a.id === attachmentId)?.derivedImages ?? [];
      dispatch(setDerivedImages({ caseId, attachmentId, derivedImages }));
    } catch (error: any) {
      dispatch(getCaseFailed(error.toString()));
    }
  },
);

export const updateCasePointersIfActive = createAsyncThunk<void, string, ThunkConfig>(
  'cases/updatePointers',
  async (caseId, { dispatch, getState }) => {
    const activeCase = getState().caseDetail.case;

    if (activeCase?.id === caseId) {
      const caze = await Api.getById(caseId);

      dispatch(setPointers(caze.imagePointers));
      if (caze.etag) dispatch(setEtag(caze.etag));
    } else {
      dispatch(refreshCaseIfTracked(caseId));
    }
  },
);

type ProgressCaseData = { item: Case; priority?: number; caseState?: StateEnum; deliveryStatus?: LegacyDeliveryStatus };
export const progressCase = createAsyncThunk<void, ProgressCaseData, ThunkConfig>(
  'cases/markProgress',
  async ({ item, priority, caseState, deliveryStatus }, { dispatch }) => {
    try {
      const result = await Api.progressCase(item, priority, caseState, deliveryStatus);

      dispatch(getCaseSuccess(result));
      dispatch(updateCaseWithPersisted(result));
    } catch (error: any) {
      dispatch(getCaseFailed(error.toString()));
    }
  },
);

export default casesSlice.reducer;
