import {
  FilterTreeCalendarCaseStateComponent,
  FilterTreePhotographerComponent,
  FilterTreeStylistComponent,
  FilterTreeCalendarCaseTypesComponent,
} from '../filterComponents/FilterTreeComponentTemplate';
import { FilterableCase, FilterPropertyRecord } from '../../../../api/filterTypes';
import { FilterTreeProjectTagComponent, FilterTreeSystemTagComponent } from '../filterComponents/FilterTreeTagComponent';

export const caseKanbanFilterConfiguration: FilterPropertyRecord<FilterableCase> = {
  photographer: {
    title: 'Fotograf',
    Component: FilterTreePhotographerComponent,
  },
};
