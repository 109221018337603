import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import styles from './Sidebar.module.css';

import { SidebarEntry, SidebarLink } from './Sidebar';
import { useRights } from '../../hooks/useRights';

interface Props {
  item: SidebarEntry;
}

export const SidebarMenuItem: React.FC<Props> = ({ item }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const hasRight = useRights(item.right, true);

  const isSelected = (path: string) => location.pathname.startsWith(path);

  const [expanded, setExpanded] = useState(item.children?.some(child => isSelected(child.path)) ?? false);

  const getLink = (link: SidebarLink, className?: string) => {
    return (
      <ListItemButton
        key={link.label}
        onClick={() => navigate(link.path)}
        selected={isSelected(link.path)}
        className={className}
        disabled={!hasRight}
      >
        <ListItemIcon>{link.icon}</ListItemIcon>
        <ListItemText primary={link.label} />
      </ListItemButton>
    );
  };

  if ('path' in item) {
    return getLink(item);
  }

  return (
    <div key={item.label}>
      <ListItemButton onClick={() => setExpanded(!expanded)} disabled={!hasRight}>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.label} />
        {expanded ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={expanded}>
        <List component="div" disablePadding>
          {item.children.map(child => getLink(child, styles.submenu))}
        </List>
      </Collapse>
    </div>
  );
};
