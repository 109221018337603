import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/useAppRedux';
import { Container, IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

import styles from './CaseDetail.module.css';

import { Attachment, Case, CaseRelation, CaseValidator, GetCaseName } from '../../api/caseApiTypes';
import { deleteCase, deleteRelation, removeAttachment, setDirty, updateCaseDetailItem, upsertCase } from './caseDetailSlice';
import { CaseTypeDetail, CaseTypeDetailComponentProps } from './CaseTypeDetail';
import { PendingChangesDrawer } from './PendingChangesDrawer';
import { ValidateFormWrapper } from './forms/FormFieldsWrapper';
import { Routes } from '../../App';
import { ImageFullScreenDisplay } from '../images/ImageFullScreenDisplay';
import { useClearSelectedMedia } from '../images/selectedImagesContext/useClearSelectedMedia';

interface Props {
  item: Case;
  isNewCase: boolean;
  readonly?: boolean;
}

export const CaseDetail: React.FC<Props> = ({ item, isNewCase, readonly = false }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isDirty = useAppSelector(state => state.caseDetail.isDirty);

  useClearSelectedMedia();

  // idx is the index we are at in the history stack
  const navigateAway = () => {
    if (window.history.state?.idx && window.history.state.idx > 1) {
      navigate(-1);
    } else {
      navigate(Routes.Cases);
    }
  };

  const onCreateRelatedCase = (caze: Case) => {
    dispatch(upsertCase({ item: caze, navigate }));
  };

  const onSave = () => {
    dispatch(setDirty(false));
    dispatch(upsertCase({ item, navigate }));
  };

  const onDeleteCase = () => {
    if (window.confirm(`Är du säker på att du vill ta bort beställningen ${GetCaseName(item)}?`)) {
      dispatch(deleteCase({ item, navigate }));
    }
  };

  const onDeleteAttachment = (attachment: Attachment) => {
    dispatch(removeAttachment({ item, attachment }));
  };

  const onDeleteRelation = (relation: CaseRelation) => {
    dispatch(deleteRelation({ item, relation }));
  };

  const onCasePartialChange = (partial: Partial<Case>) => {
    dispatch(updateCaseDetailItem(partial));
  };

  const subComponentProps: CaseTypeDetailComponentProps = {
    isNewCase,
    readonly,
    onDeleteCase,
    onDeleteAttachment,
    onDeleteRelation,
    onCasePartialChange,
    onCreateRelatedCase,
  };

  return (
    <Container className={styles.container} maxWidth={false}>
      <div className={styles.main}>
        {!isNewCase && (
          <IconButton onClick={navigateAway} size="small" disabled={isDirty} sx={{ display: 'none' }}>
            <ArrowBack />
          </IconButton>
        )}
        <CaseTypeDetail caze={item} subComponentProps={subComponentProps} />
      </div>
      {!readonly && (
        <PendingChangesDrawer
          pendingChangesExist={isDirty}
          saveDisabled={!CaseValidator.IsValid(item)}
          onSave={() => {
            if (ValidateFormWrapper()) onSave();
          }}
        />
      )}
      <ImageFullScreenDisplay />
    </Container>
  );
};
