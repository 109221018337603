import React, { useCallback, useEffect, useState } from 'react';

import { Check, Delete, FilterAlt, ImageSearch, Lock } from '@mui/icons-material';
import { Badge, Box, Grid, IconButton, Paper, useTheme } from '@mui/material';

import styles from './ImagePicker.module.css';

import { SortableImageField } from '../../../api/ImageApiTypes';
import { Image, ImagePointer } from '../../../api/caseApiTypes';
import { FilterableImage, FilterState } from '../../../api/filterTypes';
import { LoadMoreItem } from '../../../commonComponents/LoadMoreItem';
import { IsDirty, IsEqual } from '../../../helpers/ObjectHelpers';
import { ByPointers } from '../../../helpers/Sorting';
import { useAppDispatch, useAppSelector } from '../../../hooks/useAppRedux';
import { SearchContext, useImageSearchState } from '../../../hooks/useSearchState';
import { LocalStorageKeys, SessionStorageKeys, useLocalStorage, useSessionStorage } from '../../../hooks/useStorage';
import { CaseDialog } from '../../cases/layoutComponents/CaseDialog';
import { ImageCard } from '../../image/imageCard/ImageCard';
import { LocalFilterPanel } from '../../sidebar/FilterPanel';
import { ImagePropClauseResolverConfig } from '../../sidebar/filter/propClauseResolver/resolverConfigs';
import { SearchFilterTranslator } from '../../sidebar/filter/searchFilterTranslator';
import { ImagesSearchPanel } from '../ImagesSearchPanel';
import { ensureImagesLoaded, search } from '../imagesSlice';
import { SelectionContext, SelectionContextProvider } from '../selectedImagesContext/selectionContextContext';

interface PickerProps {
  children?: React.ReactNode;
  selectedImagePointers: ImagePointer[];
  lockedImagePointers?: ImagePointer[];
  onSelectImagePointers: (pointers: ImagePointer[]) => void;
  onClick?: () => void;
}

export const ImagePicker: React.FC<PickerProps> = ({ children, onClick, ...props }) => {
  const [open, setOpen] = useState(false);

  const onOpen = useCallback(() => {
    onClick?.();
    setOpen(true);
  }, [setOpen, onClick]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <SelectionContextProvider selectionContext={SelectionContext.ImagePicker}>
      <ImagePickerButton onOpen={onOpen} />
      <ImagePickerDialog {...props} open={open} onClose={onClose} />
    </SelectionContextProvider>
  );
};

interface ButtonProps {
  onOpen: () => void;
}

const ImagePickerButton: React.FC<ButtonProps> = ({ onOpen }) => (
  <IconButton
    title="Välj bild"
    onClick={e => {
      e.stopPropagation();
      onOpen();
    }}
  >
    <ImageSearch fontSize="small" />
  </IconButton>
);

interface ModalProps {
  open: boolean;
  onClose: () => void;
}

const translator = new SearchFilterTranslator<FilterableImage>(ImagePropClauseResolverConfig);

const ImagePickerDialog: React.FC<PickerProps & ModalProps> = ({
  open,
  onClose,
  selectedImagePointers,
  lockedImagePointers,
  onSelectImagePointers,
}) => {
  const { palette, zIndex } = useTheme();

  const dispatch = useAppDispatch();
  const { imagesById } = useAppSelector(state => state.images);

  const pageSize = 30;

  const [continuation, setContinuation] = useState(0);
  const [pickedImagePointers, setPickedImagePointers] = useState(selectedImagePointers ?? []);
  const [filterPanelOpen, setFilterPanelOpen] = useState(false);

  const [query, setQuery] = useSessionStorage(SessionStorageKeys.searchQuery, '');
  const [searchOnlyFilename, setSearchOnlyFilename] = useSessionStorage(SessionStorageKeys.imageSearchOnlyFilename, false);

  const [userFilters, setUserFilters] = useLocalStorage<FilterState<FilterableImage>>(LocalStorageKeys.imagePickerFilter, {});
  const [orderBy, setOrderBy] = useLocalStorage<SortableImageField>(LocalStorageKeys.imagePickerOrderBy, 'Created');
  const [orderDesc, setOrderDesc] = useLocalStorage<boolean>(LocalStorageKeys.imagePickerOrderDesc, true);

  const { items, count, isSearching } = useImageSearchState(SearchContext.RetouchImagePicker);

  const canLoadMore = count > items.length;
  const loadMore = () => setContinuation(items.length);

  useEffect(() => {
    dispatch(ensureImagesLoaded(pickedImagePointers));
  }, [dispatch, pickedImagePointers]);

  useEffect(() => {
    setPickedImagePointers(selectedImagePointers);
  }, [selectedImagePointers]);

  useEffect(() => {
    if (userFilters) {
      dispatch(
        search({
          query,
          orderBy: [{ id: orderBy, desc: orderDesc }],
          filterTree: translator.getClauses({ userFilters }),
          continuation,
          pageSize,
          searchOnlyFilename,
          searchContext: SearchContext.RetouchImagePicker,
        }),
      );
    }
  }, [dispatch, query, continuation, userFilters, orderBy, orderDesc, searchOnlyFilename]);

  const toggleImage = (pointer: ImagePointer) =>
    setPickedImagePointers(curr => {
      if (curr.some(p => IsEqual(p, pointer))) {
        return curr.filter(p => !IsEqual(p, pointer));
      } else {
        return curr.concat([pointer]);
      }
    });

  const onSave = () => {
    onSelectImagePointers(pickedImagePointers);
    onClose();
  };

  const getCard = (image: Image) => {
    if (image == null) {
      return null;
    }
    const selectedPointer = pickedImagePointers.find(({ imageId }) => imageId === image.id);
    const isSelected = selectedPointer != null;
    const displayedVersion = selectedPointer?.imageVersion;
    const canToggle = lockedImagePointers == null || lockedImagePointers.every(pointer => !IsEqual(pointer, selectedPointer));

    const selectedStyle = isSelected ? { outline: `2px solid ${palette.primary.main}` } : undefined;

    const onClick = canToggle
      ? () => {
          const pointer = selectedPointer ?? {
            imageId: image.id,
            imageVersion: image.activeVersion,
          };

          toggleImage(pointer);
        }
      : undefined;

    const cardStyles = [styles.card];
    if (!canToggle) {
      cardStyles.push(styles.locked);
    }

    return (
      <Grid item key={image.id} onClick={onClick} className={cardStyles.join(' ')}>
        <ImageCard image={image} disableInteraction raised={isSelected} style={selectedStyle} displayedVersion={displayedVersion} />
        {!canToggle && (
          <Box className={styles.locked} title="Kan ej avmarkeras">
            <Lock fontSize="small" color="disabled" />
          </Box>
        )}
      </Grid>
    );
  };

  const saveDisabled = !IsDirty([...selectedImagePointers].sort(ByPointers), [...pickedImagePointers].sort(ByPointers));

  const preSelectedImages = selectedImagePointers.map(({ imageId }) => imagesById[imageId]).filter(image => image != null);
  const uniqueSearchImages = items.filter(({ id }) => !selectedImagePointers.some(({ imageId }) => imageId === id));

  // if a user picks an image and then changes the search parameters, we want the image to remain in view until unselected
  const pickedImagesNotInSearch = pickedImagePointers
    .filter(pointer => {
      const inSelected = selectedImagePointers.some(other => IsEqual(pointer, other));
      const inSearch = uniqueSearchImages.some(({ id, activeVersion }) => IsEqual(pointer, { imageId: id, imageVersion: activeVersion }));
      return !inSelected && !inSearch;
    })
    .map(({ imageId }) => imagesById[imageId]);

  const saveProps = {
    onSave,
    saveDisabled,
    saveLabel: 'Välj',
    SaveIcon: Check,
  };

  const gridClasses = [styles.imageGrid];
  if (filterPanelOpen) {
    gridClasses.push(styles.compact);
  }

  const filterCount = Object.values(userFilters)
    .flatMap(x => x.value)
    .filter(filterOption => filterOption !== '').length;

  const additionalButtons = (
    <>
      <IconButton onClick={() => setUserFilters({})} disabled={filterCount === 0}>
        <Badge badgeContent={filterCount} color="primary">
          <Delete />
        </Badge>
      </IconButton>
      <IconButton onClick={() => setFilterPanelOpen(open => !open)}>
        <FilterAlt />
      </IconButton>
    </>
  );

  return (
    <CaseDialog isVisible={open} onClose={onClose} className={styles.container} {...saveProps}>
      <ImagesSearchPanel
        style={{ zIndex: zIndex.appBar }}
        onSearch={setQuery}
        isSearching={isSearching}
        searchOnlyFileName={searchOnlyFilename}
        toggleSearchOnlyFileName={() => setSearchOnlyFilename(value => !value)}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        orderDesc={orderDesc}
        toggleOrderDesc={() => setOrderDesc(value => !value)}
        additionalButtons={additionalButtons}
      />
      <div className={styles.content}>
        <Grid container spacing={1} className={gridClasses.join(' ')}>
          {preSelectedImages.map(getCard)}
          {pickedImagesNotInSearch.map(getCard)}
          {uniqueSearchImages.map(getCard)}
          {canLoadMore && (
            <Grid item>
              <LoadMoreItem
                className={styles.loadMoreContainer}
                itemsLoaded={items.length}
                count={count}
                pageSize={pageSize}
                isSearching={isSearching}
                loadMore={loadMore}
              />
            </Grid>
          )}
        </Grid>
        {filterPanelOpen && (
          <Paper className={styles.filterPanel}>
            <LocalFilterPanel searchContext={SearchContext.RetouchImagePicker} filterState={userFilters} stateSetter={setUserFilters} />
          </Paper>
        )}
      </div>
    </CaseDialog>
  );
};
