import { Fragment } from 'react';
import { BookmarkRemove } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../hooks/useAppRedux';
import { removeImagesFromClipbook, selectActiveClipbook } from '../clipbooksSlice';
import { Props } from './AddToClipbookIconButton';

export function RemoveFromClipbookIconButton({ imageIds, title }: Props) {
  const dispatch = useAppDispatch();
  const activeClipbook = useAppSelector(selectActiveClipbook);

  if (!activeClipbook) {
    return <Fragment />;
  }

  const onRemove = () => {
    if (activeClipbook.images.every(imageId => !imageIds.includes(imageId))) return;
    if (window.confirm('Är du säker på att du vill ta bort bilderna från den aktiva gruppen?') === false) return;

    dispatch(removeImagesFromClipbook({ imageIds }));
  };
  const bildText = imageIds.length === 1 ? 'bilden' : 'bilderna';
  const buttonText = title ?? `Ta bort ${bildText} från den aktiva gruppen`;

  return (
    <IconButton title={buttonText} onClick={onRemove}>
      <BookmarkRemove />
    </IconButton>
  );
}
