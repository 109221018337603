import React from 'react';

import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { CaseDialog, CaseDialogProps } from './layoutComponents/CaseDialog';
import { ColumnDefinitions, ColumnEnum } from './CasesTableColumns';

interface Props extends CaseDialogProps {
  columnsEnabled: Record<ColumnEnum, boolean>;
  columnOrder: ColumnEnum[];
  toggleColumn: (column: ColumnEnum) => void;
  requiredColumns: ColumnEnum[];
}

export const CasesTableSettings: React.FC<Props> = ({ columnsEnabled, columnOrder, toggleColumn, requiredColumns, ...rest }) => {
  const columnInfos = columnOrder.map(column => {
    const { Header } = ColumnDefinitions[column];
    const disabled = requiredColumns.includes(column);
    const checked = columnsEnabled[column];
    return (
      <div key={column}>
        <FormControlLabel
          checked={checked}
          label={<>{Header}</>}
          control={<Checkbox />}
          labelPlacement="top"
          onChange={() => toggleColumn(column)}
          disabled={disabled}
        />
      </div>
    );
  });

  return (
    <CaseDialog title="Valda kolumner" {...rest}>
      <Box display="flex" flexDirection="row">
        {columnInfos}
      </Box>
    </CaseDialog>
  );
};
