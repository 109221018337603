import React, { useState, useEffect, useMemo } from 'react';

import { Clear, Edit, Search } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, IconButton, InputAdornment } from '@mui/material';
import { Column } from 'react-table';

import styles from './ProjectTags.module.css';

import { CreateNewProjectTag, ProjectTag } from '../../api/caseApiTypes';
import { confirmDelete } from '../../helpers/confirmDeleteHelper';
import { useAppDispatch, useAppSelector } from '../../hooks/useAppRedux';
import { getAllProjectTags, createProjectTag, deleteProjectTag, updateProjectTag } from './TagsSlice';
import { ColorTag } from './ColorTag';
import { ProjectTagModal } from './ProjectTagModal';
import { TagsTable } from './TagsTable';
import { formatDate } from '../date/DateFormatter';
import { CaseTextField } from '../cases/formFieldComponents/CaseTextField';

export const ProjectTags: React.FC = () => {
  const dispatch = useAppDispatch();
  const [newTagModalOpen, setNewTagModalOpen] = useState<boolean>(false);
  const { projectTags } = useAppSelector(state => state.tags);
  const [activeTag, setActiveTag] = useState(CreateNewProjectTag());
  const [filterQuery, setFilterQuery] = useState<string>('');
  const filteredTags = useMemo(() => {
    const lowerCaseInput = filterQuery.toLowerCase();
    return projectTags.filter(tag =>
      tag.description.toLowerCase().includes(lowerCaseInput) ||
      tag.name.toLowerCase().includes(lowerCaseInput)
    )
  }, [filterQuery, projectTags])

  useEffect(() => {
    dispatch(getAllProjectTags());
  }, [dispatch]);

  const onSave = (tag: ProjectTag) => {
    setNewTagModalOpen(false);
    dispatch(createProjectTag(tag));
  };

  const onEdit = (tag: ProjectTag) => {
    setNewTagModalOpen(false);
    dispatch(updateProjectTag(tag));
  };

  const columns: Column<ProjectTag>[] = useMemo(() => {
    const onDelete = (tag: ProjectTag) => {
      if (
        window.confirm(`Är du säker på att du vill ta bort taggen ${tag.name}? Beställningar som redan har taggen på sig påverkas inte.`)
      ) {
        dispatch(deleteProjectTag(tag.id));
      }
    };

    return [
      {
        Header: 'Namn',
        accessor: 'name',
        Cell: props => {
          const tag = props.row.original;
          return (
            <ColorTag hex={tag.color} label={tag.name} size="small" />
          );
        },
      },
      {
        Header: 'Beskrivning',
        accessor: 'description',
      },
      {
        Header: 'Aktivt från',
        accessor: 'activeFrom',
        Cell: props => <>{formatDate(props.value)}</>,
      },
      {
        Header: 'Aktivt till',
        accessor: 'activeTo',
        Cell: props => <>{formatDate(props.value)}</>,
      },
      {
        accessor: 'id',
        Cell: props => {
          const tag = props.row.original;
          return (
            <Box className={styles.buttons}>
              <IconButton
                size="small"
                onClick={() => {
                  setNewTagModalOpen(true);
                  setActiveTag(tag);
                }}
              >
                <Edit />
              </IconButton>
              <IconButton size="small" onClick={confirmDelete(() => onDelete(tag), 'tagg')}>
                <DeleteOutlineIcon />
              </IconButton>
            </Box>
          );
        },
      },
    ];
  }, [dispatch]);

  return (
    <>
      <CaseTextField
        className={styles.search}
        aria-label="Query"
        value={filterQuery}
        setValue={(value) => setFilterQuery(value)}
        autoFocus={true}
        label={'Sök'}
        onSubmit={() => {}
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={_ => setFilterQuery('')}>
                <Clear />
              </IconButton>
              <Search />
            </InputAdornment>
          ),
        }}
      />

      <TagsTable
        columns={columns}
        data={filterQuery !== '' ? filteredTags : projectTags}
        onClickOnAdd={() => {
          setNewTagModalOpen(true);
          setActiveTag(CreateNewProjectTag());
        }}
      />
      {newTagModalOpen && (
        <ProjectTagModal tag={activeTag} open={newTagModalOpen} onSave={onSave} onEdit={onEdit} onClose={() => setNewTagModalOpen(false)} />
      )}
    </>
  );
};
