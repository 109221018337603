import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { CaseFormButtonContainer } from './CaseFormButtonContainer';
import { Close, Save, SvgIconComponent } from '@mui/icons-material';

export interface CaseDialogProps {
  children?: React.ReactNode;
  isVisible: boolean;
  onClose: () => void;
  closeLabel?: string;
  title?: string;
  buttons?: React.ReactNode;
  onSave?: () => void;
  saveDisabled?: boolean;
  saveLabel?: string;
  className?: string;
  CloseIcon?: SvgIconComponent;
  SaveIcon?: SvgIconComponent;
}

export const CaseDialog: React.FC<CaseDialogProps> = ({
  isVisible,
  title,
  buttons,
  children,
  className,
  onClose,
  closeLabel,
  CloseIcon = Close,
  onSave,
  saveLabel,
  SaveIcon = Save,
  saveDisabled,
}) => {
  const hasChildren = React.Children.count(children) > 0;
  return (
    <Dialog open={isVisible} onClose={onClose} maxWidth={false}>
      {title && (
        <DialogTitle variant="h5" gutterBottom>
          {title}
        </DialogTitle>
      )}
      <form
        onSubmit={(event: React.FormEvent) => {
          event.preventDefault();
          event.stopPropagation();
          onSave?.();
        }}
      >
        {hasChildren && <DialogContent className={className}>{children}</DialogContent>}
        <DialogActions>
          <CaseFormButtonContainer renderDivider>
            {onSave != null && (
              <Button disabled={saveDisabled} variant="contained" startIcon={<SaveIcon />} type="submit">
                {saveLabel ?? 'Spara'}
              </Button>
            )}
            <Button variant="contained" startIcon={<CloseIcon />} onClick={onClose}>
              {closeLabel ?? 'Stäng'}
            </Button>
            {buttons}
          </CaseFormButtonContainer>
        </DialogActions>
      </form>
    </Dialog>
  );
};
