import { FilterableImage, FilterStatePropertyValue } from '../../../../api/filterTypes';
import { Clause, PredicateClause, SearchComparison, SearchPredicate } from '../../../../api/searchApiTypes';
import { ctrDateCondition } from './propResolverHelper';

// This property resolver is coupled to the startDate property, the endDate is provided as a parameter.
const twoDatesIntervalPropClauseResolver =
  <T>(startDateProperty: keyof T, endDateProperty: keyof T) =>
  (_ignored: keyof T, propertyValue: FilterStatePropertyValue): PredicateClause<T> => {
    const [from, to] = propertyValue.value;
    const clauses: Clause<T>[] = [];
    const includeNull = propertyValue.includeNull ?? false;

    if (from !== '') {
      clauses.push(ctrDateCondition(startDateProperty, includeNull, SearchComparison.Gt, from));
    }

    if (to !== '') {
      clauses.push(ctrDateCondition(endDateProperty, includeNull, SearchComparison.Lt, to));
    }

    if (clauses.length === 0) {
      return { clauses: [], predicate: SearchPredicate.And };
    } else if (clauses.length === 1) {
      return clauses[0] as PredicateClause<T>;
    } else {
      return { clauses: clauses, predicate: SearchPredicate.And };
    }
  };

const dateIntervalPropClauseResolver = <T>( prop: keyof T) => twoDatesIntervalPropClauseResolver<T>(prop, prop);

export const imageCreatedIntervalPropClauseResolver = dateIntervalPropClauseResolver<FilterableImage>('created');
export const imageActiveIntervalPropClauseResolver = twoDatesIntervalPropClauseResolver<FilterableImage>('activeFrom', 'activeTo');
