import { LegacyDeliveryStatus, StateEnum } from '../../api/caseApiTypes';
import { progressCase } from '../cases/casesSlice';
import { KanbanLane } from './LaneDefinitions';

export const ProductContextualLaneDefinitions: Readonly<KanbanLane[]> = [
  {
    title: 'Beställda',
    filter: caze => caze.state.state === StateEnum.ordered,
    action: (item, priority) =>
      progressCase({
        item,
        priority,
        caseState: StateEnum.ordered,
      }),
  },
  {
    title: 'Planerade',
    filter: caze => caze.state.state === StateEnum.planned && caze.deliveryStatus === LegacyDeliveryStatus.Ordered,
    action: (item, priority) =>
      progressCase({
        item,
        priority,
        caseState: StateEnum.planned,
        deliveryStatus: LegacyDeliveryStatus.Ordered,
      }),
  },
  {
    title: 'Fotade',
    filter: caze => caze.state.state === StateEnum.planned && caze.deliveryStatus === LegacyDeliveryStatus.Finished,
    action: (item, priority) =>
      progressCase({
        item,
        priority,
        caseState: StateEnum.planned,
        deliveryStatus: LegacyDeliveryStatus.Finished,
      }),
  },
  {
    title: 'Levererade',
    filter: caze => caze.state.state === StateEnum.reviewPending,
    action: (item, priority) =>
      progressCase({
        item,
        priority,
        caseState: StateEnum.reviewPending,
      }),
  },
];
