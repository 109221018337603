import React, { useCallback, useEffect, useState } from 'react';

import { CloudUpload, Add } from '@mui/icons-material';
import { ListItem, Button, Typography, ListItemAvatar, Avatar, useTheme } from '@mui/material';
import { AxiosProgressEvent } from 'axios';
import { useDropzone } from 'react-dropzone';

import { AttachmentCategory, AttachmentCategoryConverter } from '../../api/caseApiTypes';
import { CalculateTotalProgress, DropzoneCallback, FileProgressMap } from '../../commonComponents/DropzoneWithProgress';
import { SlowCircularProgress } from '../../commonComponents/SlowCircularProgress';
import { UploadProgress } from '../../commonComponents/UploadProgress';
import { useAppSelector } from '../../hooks/useAppRedux';
import { usePrevious } from '../../hooks/usePrevious';
import { UploadProgressHandlerForFile } from '../../api/commonApiTypes';
import styles from './AddAttachmentListItem.module.css';

interface Props {
  onDrop?: DropzoneCallback;
  category: AttachmentCategory;
}

function getAttachmentUploadDescription(category: AttachmentCategory) {
  if (category === AttachmentCategory.SupplementaryInformation) {
    return 'Ladda upp, briefer, skisser och inspiration till beställningen';
  }
  return undefined;
}

export const AddAttachmentListItem: React.FC<Props> = ({ onDrop: onDropExternal, category }) => {
  const [progressPerFile, setProgressPerFile] = useState<FileProgressMap>({});

  const { attachmentUploadInProgress } = useAppSelector(state => state.caseDetail);
  const previousValues = usePrevious({ attachmentUploadInProgress });
  const [uploading, setUploading] = useState(false);

  const { palette } = useTheme();

  useEffect(() => {
    if (previousValues?.attachmentUploadInProgress && previousValues?.attachmentUploadInProgress > 0 && attachmentUploadInProgress === 0) {
      setProgressPerFile({});
      setUploading(false);
    }
  }, [attachmentUploadInProgress, previousValues?.attachmentUploadInProgress]);

  const uploadHandler: UploadProgressHandlerForFile = useCallback((e: AxiosProgressEvent, file: File) => {
    const progress = Math.round((e.progress ?? 0) * 100);
    const newProgress = progress === 100 ? undefined : { progress, total: file.size };
    setProgressPerFile(prev => ({ ...prev, [file.name]: newProgress }));
  }, []);

  const onDrop = (files: File[]) => {
    setUploading(true);
    onDropExternal?.(files, uploadHandler);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const listStyle: React.CSSProperties = {};

  const backgroundColor = palette.mode === 'light' ? '#fafafa' : '#333';

  if (isDragActive && !attachmentUploadInProgress) {
    listStyle.color = palette.primary.main;
    listStyle.backgroundColor = backgroundColor;
    listStyle.outline = `2px dashed ${palette.primary.main}`;
  }

  const categoryText = AttachmentCategoryConverter.ToText(category);

  const activeUploads = Object.values(progressPerFile).filter(progress => progress != null).length;

  const totalProgress = CalculateTotalProgress(progressPerFile);

  const getIcon = () => {
    if (activeUploads > 0) return <UploadProgress progress={totalProgress} />;
    if (uploading) return <SlowCircularProgress />;
    return isDragActive ? <CloudUpload /> : <Add />;
  };

  const addButtonDescription = getAttachmentUploadDescription(category);

  return (
    <ListItem {...getRootProps()} style={listStyle}>
      <input {...getInputProps()} />
      <ListItemAvatar>
        <Avatar>{getIcon()}</Avatar>
      </ListItemAvatar>
      {uploading ? (
        <Typography>Uppladdning pågår...</Typography>
      ) : (
        <div>
          <Button size="small">Ladda upp {categoryText}</Button>
          {addButtonDescription && (
            <div className={styles.addButtonDescription}>
              <Typography variant="caption">{addButtonDescription}</Typography>
            </div>
          )}
        </div>
      )}
    </ListItem>
  );
};
