export async function retry<T>(fn: () => Promise<T>, numRetries: number, delay: number): Promise<T> {
  for (let i = 1; i <= numRetries; ++i) {
    try {
      return await fn();
    } catch (e) {
      if (i === numRetries) {
        throw e;
      }
      await new Promise(r => setTimeout(r, delay));
    }
  }
  throw new Error('Should not get here');
}
