import axios from 'axios';
import { Case, ImagePointer } from './caseApiTypes';
import { NavigateFunction } from 'react-router-dom';

const apiBase = '/api/image/batch';

export interface BatchImageOperationApiThunkInput {
  imagePointers: ImagePointer[];
  navigate: NavigateFunction;
}

export async function createRetouch(imagePointers: ImagePointer[]): Promise<Case> {
  const url = `${apiBase}/create-retouch`;

  const { data } = await axios.post<Case>(url, {
    imagePointers,
  });

  return data;
}
