import { CardContent, Typography, useTheme } from '@mui/material';
import { ImageVersion } from '../../../api/caseApiTypes';

import styles from './ImageCardBody.module.css';

export type ImageCardBodyProps = {
  imageVersion: ImageVersion | undefined;
};
export function ImageCardBody({ imageVersion }: ImageCardBodyProps) {
  const { palette } = useTheme();
  const fileNameTitle = `Filnamn: ${imageVersion?.fileName}`;
  return (
    <CardContent className={styles.infoWrapper}>
      <Typography variant="subtitle1" title={fileNameTitle} style={{ color: palette.text.secondary }}>
        {imageVersion?.fileName}
      </Typography>
    </CardContent>
  );
}
