import { CloudDownload, Download } from '@mui/icons-material';
import { MenuList, MenuItem, IconButton, Tooltip, ListItemIcon, ListItemText, Typography, Button, Popover } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';
import { useState } from 'react';
import { ImageVersion } from '../../api/caseApiTypes';
import { pushToast } from '../../appSlice';
import { downloadFile } from '../../helpers/DownloadFile';
import { useAppDispatch } from '../../hooks/useAppRedux';
import styles from './ImageCardDownload.module.css';

interface Props {
  imageVersion?: ImageVersion;
}

export const ImageCardDownload: React.FC<Props> = ({ imageVersion }) => {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onFail = () =>
    dispatch(
      pushToast({
        message: 'Misslyckades ladda ner bild',
        variant: 'error',
        persist: false,
        preventDuplicate: true,
      }),
    );

  const onSuccess = (fileName: string) =>
    dispatch(
      pushToast({
        message: `Laddade ner filen: ${fileName}`,
        variant: 'info',
        persist: false,
        preventDuplicate: true,
      }),
    );

  const getDownloadImageComponent = () => {
    const isDisabled = !imageVersion;

    const icon = (
      <IconButton
        color="primary"
        onContextMenu={e => {
          e.preventDefault();
          handleClick(e);
          return false;
        }}
        onClick={() => {
          if (imageVersion) {
            downloadFile(imageVersion.uri, imageVersion.fileName, onFail, onSuccess);
          }
        }}
        disabled={isDisabled || imageVersion?.uri == null}
        size="small"
        aria-controls={open ? 'split-button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-label="Välj bild att ladda ner"
        aria-haspopup="menu"
      >
        <CloudDownload />
      </IconButton>
    );

    if (isDisabled) {
      return icon;
    }

    return <Tooltip title="Högerklicka för att ladda ner fler storlekar">{icon}</Tooltip>;
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      {getDownloadImageComponent()}
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        style={{ zIndex: zIndex.drawer + 1 }}
      >
        <MenuList id="split-button-menu">
          <MenuItem
            className={styles.menuItem}
            component={Button}
            dense
            onClick={() => {
              downloadFile(imageVersion?.uri ?? '', imageVersion?.fileName ?? '', onFail, onSuccess);
              handleClose();
            }}
          >
            <ListItemIcon>
              <Download fontSize="small" />
            </ListItemIcon>

            <ListItemText disableTypography className={styles.fileDownloadLabels}>
              {'Originalfil'}
            </ListItemText>
          </MenuItem>

          {imageVersion?.derivedImages.map(option => (
            <MenuItem
              className={styles.menuItem}
              key={option.uri}
              component={Button}
              dense
              onClick={() => {
                const fileName = `${imageVersion?.fileName.split('.', 2)[0]}_${option.width}x${option.height}.${option.format}`;
                downloadFile(option.uri, fileName, onFail, onSuccess);
                handleClose();
              }}
            >
              <ListItemIcon>
                <Download fontSize="small" />
              </ListItemIcon>
              <ListItemText
                className={styles.fileDownloadLabels}
              >{`${option.width}x${option.height} ${option.dimensionUnit}`}</ListItemText>
              <Typography variant="body2" color="text.secondary">
                {option.format}
              </Typography>
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </>
  );
};
