import { BookmarkAdd } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useAppDispatch } from '../../../hooks/useAppRedux';
import { addImagesToActiveClipbook } from '../clipbooksSlice';

export type Props = {
  imageIds: string[];
  title?: string;
};

export function AddToClipbookIconButton({ imageIds, title }: Props) {
  const dispatch = useAppDispatch();
  const clipbook = () => dispatch(addImagesToActiveClipbook({ imageIds }));

  const bildText = imageIds.length === 1 ? 'bilden' : 'bilderna';
  const buttonText = title ?? `Lägg till ${bildText} i den aktiva gruppen`;

  return (
    <IconButton title={buttonText} onClick={clipbook}>
      <BookmarkAdd />
    </IconButton>
  );
}
