import React from 'react';

import { Chip, Tooltip } from '@mui/material';

import { ImageOrigin, ImageOriginMetaData } from '../../../api/caseApiTypes';

export interface Props {
  origin: ImageOrigin;
}

export const ImagesGridDetailsOriginChip: React.FC<Props> = ({ origin: { originSystem } }) => {
  const { long, muiVariant, short } = ImageOriginMetaData[originSystem];

  return (
    <Tooltip title={long}>
      <Chip color={muiVariant} label={short} />
    </Tooltip>
  );
};
