import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks/useAppRedux';
import { Routes } from '../../../App';
import { removeClipbook, renameClipbook, selectClipbook } from '../../clipbooks/clipbooksSlice';
import { Bookmark, BookmarkBorder, Close, Delete, Edit, MoreVert } from '@mui/icons-material';
import { ListItemButton, IconButton, TextField, Menu, MenuItem, Box } from '@mui/material';
import { selectClipbookByIndex } from '../../clipbooks/clipbooksSlice';

import styles from './ClipbookmenuItem.module.css';

interface SidebarClipbookProps {
  clipbookIndex: number;
}

export const ClipbookMenuItem: React.FC<SidebarClipbookProps> = ({ clipbookIndex }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const index = clipbookIndex;

  const [isEditing, setIsEditing] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const clipbook = useAppSelector(selectClipbookByIndex(clipbookIndex));
  const isActiveClipbook = useAppSelector(state => state.clipbooks.activeClipbook === clipbookIndex);

  if (!clipbook) {
    return null;
  }

  const navigateToclipbook = () => {
    navigate(Routes.Clipbooks);
    dispatch(selectClipbook({ index }));
  };
  const setActiveClipbook = (event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch(selectClipbook({ index }));
  };

  const closeMenu = () => setAnchorEl(null);
  const openMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const deleteClipbook = (event: React.MouseEvent) => {
    if (window.confirm('Är du säker på att du vill ta bort bilden från aktiva gruppen?') === false) return;

    closeMenu();
    event.stopPropagation();
    dispatch(removeClipbook({ index }));
  };

  const updateClipbookName = (event: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(renameClipbook({ index, name: event.currentTarget.value }));
  const startEditing = () => {
    closeMenu();
    setIsEditing(true);
  };
  const stopEditing = (event: React.MouseEvent) => {
    setIsEditing(false);
    event.stopPropagation();
  };
  const stopEditingOnKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      setIsEditing(false);
    }
  };

  const getReadmode = (
    <>
      <span className={styles.clipbookName}>{clipbook.name}</span>
      <Box sx={{ flexGrow: 1 }} />
      <IconButton onClick={openMenu}>
        <MoreVert />
      </IconButton>
    </>
  );

  const getEditmode = (
    <>
      <TextField
        value={clipbook.name}
        className={styles.clipbookName}
        onChange={updateClipbookName}
        autoFocus
        inputProps={{ style: { fontSize: '0.8rem' } }}
        onKeyDown={stopEditingOnKeyDown}
      />
      <Box sx={{ flexGrow: 1 }} />
      <IconButton onClick={stopEditing}>
        <Close />
      </IconButton>
    </>
  );

  const icon = isActiveClipbook ? <Bookmark /> : <BookmarkBorder />;
  const content = isEditing ? getEditmode : getReadmode;
  return (
    <>
      <ListItemButton onClick={() => navigateToclipbook()} selected={isActiveClipbook} className={styles.clipbookButton}>
        <IconButton onClick={setActiveClipbook}>{icon}</IconButton>
        {content}
      </ListItemButton>
      <Menu anchorEl={anchorEl} open={open} onClose={closeMenu}>
        <MenuItem onClick={deleteClipbook}>
          <Delete /> {'Ta bort grupp'}
        </MenuItem>
        <MenuItem onClick={startEditing}>
          <Edit /> {'Ändra namn'}
        </MenuItem>
      </Menu>
    </>
  );
};
