import React from 'react';

import { ImageType, Specification } from '../../../api/caseApiTypes';
import { M3Article } from '../../../api/m3Api';
import { formatDate } from '../../date/DateFormatter';

interface Props {
  specifications: Specification[] | undefined;
}

interface PropsWithArticle extends Props {
  article: M3Article | null;
  type: ImageType;
}

const SpecificationRow: React.FC<Specification> = ({ id, value }) => (
  <tr>
    <td>{id}:</td>
    <td>{value}</td>
  </tr>
);

const ArticleCommonRows: React.FC<{ article: M3Article }> = ({ article }) => {
  const salesStart = article.attributes.itemSalesStart;
  const salesEns = article.attributes.itemSalesEnd;
  return (
    <>
      {salesStart && <SpecificationRow id="Säljstart" value={formatDate(salesStart)} />}
      {salesEns && <SpecificationRow id="Säljslut" value={formatDate(salesEns)} />}
    </>
  );
};

const withUnit = (value: string, unit: string) => `${value} ${unit}`;

const ArticleProductRows: React.FC<{ article: M3Article; isProductImage: boolean }> = ({ article, isProductImage }) => {
  const length = article.attributes.itemLength;
  const width = article.attributes.itemWidth;
  const depth = article.attributes.itemDepth;
  const weight = article.attributes.itemWeight;
  const diameter = article.attributes.itemDiameter;
  return (
    isProductImage && (
      <>
        {length && <SpecificationRow id="Längd" value={withUnit(length, 'cm')} />}
        {width && <SpecificationRow id="Bredd" value={withUnit(width, 'cm')} />}
        {depth && <SpecificationRow id="Djup" value={withUnit(depth, 'cm')} />}
        {diameter && <SpecificationRow id="Diameter" value={withUnit(diameter, 'cm')} />}
        {weight && <SpecificationRow id="Vikt" value={withUnit(weight, 'kg')} />}
      </>
    )
  );
};

export const ImagesGridDetailsPIMSpecificationsView: React.FC<PropsWithArticle> = ({ type, specifications, article }) => {
  if (!specifications || (specifications.length === 0 && article === null)) {
    return <></>;
  }

  return (
    <table>
      <tbody>
        {specifications.map(({ id, value }) => (
          <SpecificationRow key={id} id={id} value={value} />
        ))}
        {article && (
          <>
            <ArticleCommonRows article={article} />
            <ArticleProductRows article={article} isProductImage={type === ImageType.Product} />
          </>
        )}
      </tbody>
    </table>
  );
};

export const ImagesGridDetails3DSpecificationsView: React.FC<Props> = ({ specifications }) => {
  if (!specifications || specifications.length === 0) {
    return <></>;
  }

  return (
    <table>
      <tbody>
        {specifications.map(({ id, value }) => (
          <SpecificationRow key={id} id={id} value={value} />
        ))}
      </tbody>
    </table>
  );
};
