import React from 'react';

import { Box, Grid, IconButton, Paper, Stack, useTheme } from '@mui/material';

import styles from './ClipbookView.module.css';

import { Image, ImageState } from '../../api/caseApiTypes';
import { useAppDispatch, useAppSelector } from '../../hooks/useAppRedux';
import { ImageCard } from '../image/imageCard/ImageCard';
import { CreateRetouchCaseFromSelectionButton } from '../images/selectedImagesContext/CreateRetouchCaseFromSelectionButton';
import { DownloadSelectedMediaButton } from '../images/selectedImagesContext/DownloadSelectedMediaButton';
import { ImageFullScreenDisplay } from '../images/ImageFullScreenDisplay';
import { ImagesGridDetailsView } from '../images/detailsView/ImagesGridDetailsView';
import { useClearSelectedMedia } from '../images/selectedImagesContext/useClearSelectedMedia';
import { ClipbookSelector } from './components/ClipbookSelector';
import { selectActiveClipbook } from './clipbooksSlice';
import { useGetImages } from './hooks';
import { setSelectedMedia, resetSelectedContext } from '../images/imagesSlice';
import { useSelectionContext } from '../images/selectedImagesContext/selectionContextContext';
import { OutlinedLabel } from '../../helpers/OutlinedLabel';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { RemoveSelectedFromClipbookIconButton } from '../images/selectedImagesContext/RemoveSelectedFromClipbookIconButton';

enum ImageProductionApprovalState {
  Approved,
  ApprovedWhenPublished,
  NotApproved,
}

function getIsApproved(image: Image): ImageProductionApprovalState {
  if (image.state === ImageState.Approved) {
    return ImageProductionApprovalState.Approved;
  } else if (image.state === ImageState.ProofedOk) {
    // If the image is in the state Proofed OK, and the publication date has been reached, the image is
    // considered Approved.
    if (image.publicationDate && new Date(image.publicationDate) < new Date()) {
      return ImageProductionApprovalState.Approved;
    } else {
      return ImageProductionApprovalState.ApprovedWhenPublished;
    }
  }
  return ImageProductionApprovalState.NotApproved;
}

export const ClipbookView: React.FC = () => {
  const dispatch = useAppDispatch();

  const { palette } = useTheme();

  const isLightMode = palette.mode === 'light';

  const activeClipbook = useAppSelector(selectActiveClipbook);

  const selectionContext = useSelectionContext();
  const selectedImages = useAppSelector(state => state.images.selected[selectionContext]);
  const anySelected = selectedImages.length > 0;
  const clearSelection = () => dispatch(resetSelectedContext(selectionContext));
  useClearSelectedMedia();

  const images = useGetImages(activeClipbook?.images || []);

  const getStyle = (image: Image) => {
    const style: React.CSSProperties = {
      borderStyle: 'solid',
      borderWidth: '1px',
    };

    const approvedState = getIsApproved(image);
    if (approvedState === ImageProductionApprovalState.Approved) {
      style.borderColor = isLightMode ? '#fff' : 'transparent';
    } else if (approvedState === ImageProductionApprovalState.ApprovedWhenPublished) {
      style.borderColor = isLightMode ? '#fff6bb' : '#888600';
      style.backgroundColor = isLightMode ? '#fffef1' : '#201d0c';
    } else {
      style.borderColor = isLightMode ? '#fbb' : '#800';
      style.backgroundColor = isLightMode ? '#fff1f1' : '#200c0c';
    }

    return style;
  };

  const getImageCard = (image: Image) => {
    return <ImageCard key={image.id} style={getStyle(image)} image={image} disableRemoval />;
  };

  const gridClasses = [styles.imageGrid];
  if (anySelected) {
    gridClasses.push(styles.compact);
  }

  const additionalButtons = anySelected && (
    <OutlinedLabel label="Markerade bilder">
      <RemoveSelectedFromClipbookIconButton />
      <CreateRetouchCaseFromSelectionButton />
      <DownloadSelectedMediaButton />
    </OutlinedLabel>
  );

  const toogleAllSelectedButton = () => {
    if (anySelected) {
      clearSelection();
    } else {
      dispatch(setSelectedMedia({ selectionContext, media: images }));
    }
  };

  return (
    <>
      <Box className={styles.container}>
        <Paper>
          <Stack sx={{ p: '1em' }} direction="row">
            <ClipbookSelector />
            <IconButton onClick={toogleAllSelectedButton}>{anySelected ? <CheckBox /> : <CheckBoxOutlineBlank />}</IconButton>
            <Box sx={{ flexGrow: 1 }} />
            <div>{additionalButtons}</div>
          </Stack>
        </Paper>
        <Box className={styles.content}>
          <Grid container sx={{ gap: '0.5em' }} className={gridClasses.join(' ')}>
            {images.map(getImageCard)}
          </Grid>
          {anySelected && (
            <Box className={styles.details}>
              <ImagesGridDetailsView onClose={clearSelection} />
            </Box>
          )}
        </Box>
      </Box>
      <ImageFullScreenDisplay />
    </>
  );
};
