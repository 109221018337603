import { AsyncThunk } from '@reduxjs/toolkit';
import { Case, CaseType, StateEnum } from '../../../api/caseApiTypes';
import { orderedCase } from '../caseDetailSlice';

export function ShowPlanner(caze: Case) {
  return caze.state.state !== StateEnum.incoming &&
    caze.type !== CaseType.ProductContextual;
}

export function ShowEnrichmentSection(caze: Case) {
  switch (caze.type) {
    case CaseType.ProductContextual:
      return caze.state.state !== StateEnum.incoming;
    default:
      return caze.state.state !== StateEnum.incoming && caze.state.state !== StateEnum.ordered;
  }
}

export function ShowDeliverySections(caze: Case) {
  return caze.state.state !== StateEnum.incoming && caze.state.state !== StateEnum.ordered;
}

export function ShowDeliveryDoneButton(caze: Case) {
  return caze.state.state === StateEnum.planned && caze.type !== CaseType.Retouch;
}

export function ShowVideoRequestSection(caze: Case) {
  return caze.videoRequest != null || caze.type === CaseType.Video;
}

export function ShowAddVideoRequestButton(caze: Case) {
  return caze.videoRequest == null;
}

export type NextStateAction = {
  action: AsyncThunk<void, Case, {}>;
  text: string;
};

export function GetNextStateAction(caze: Case, isNew: boolean): NextStateAction | undefined {
  if (caze.state.state === StateEnum.incoming && !isNew) {
    return {
      action: orderedCase,
      text: 'Beställ',
    };
  }

  return undefined;
}
