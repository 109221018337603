import { Image } from '../../../../api/caseApiTypes';
import { MultiMediaPreview } from './MultiMediaPreview';
import { SingleMediaPreview } from './SingleMediaPreview';

export type Props = {
  media: Image[];
};

export function MediaPreview({ media }: Props) {
  switch (media.length) {
    case 0:
      return <></>;
    case 1:
      return <SingleMediaPreview media={media[0]} />;
    default:
      return <MultiMediaPreview media={media} />;
  }
}
