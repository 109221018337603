import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/useAppRedux';

import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { ProjectTag } from '../../api/caseApiTypes';
import { getAllProjectTags } from './TagsSlice';
import { ColorTag } from './ColorTag';
import { SelectTagModal } from './SelectTagModal';
import { TagModalProps } from './SelectSystemTagModal';
import { parseISO } from 'date-fns';

export const SelectMultipleProjectTagModal: React.FC<TagModalProps> = ({ open, onSave, ...rest }) => {
  const dispatch = useAppDispatch();

  const { projectTags } = useAppSelector(state => state.tags);
  const [selectedTags, setSelectedTags] = useState<ProjectTag[]>([]);
  const [currentTime] = useState(new Date());

  useEffect(() => {
    if (open) {
      dispatch(getAllProjectTags());
    }
  }, [dispatch, open]);

  const onSaveTags = () => {
    onSave(selectedTags.map(t => t));
    setSelectedTags([]);
  };

  const isActive = useCallback(
    (tag: ProjectTag) => {
      let isActive = true;

      if (tag.activeFrom != null) {
        const activeFrom = parseISO(tag.activeFrom);
        isActive = isActive && currentTime >= activeFrom;
      }

      if (tag.activeTo != null) {
        const activeTo = parseISO(tag.activeTo);
        isActive = isActive && currentTime <= activeTo;
      }

      return isActive;
    },
    [currentTime],
  );

  const selectableTags = projectTags.filter(isActive);

  return (
    <SelectTagModal onSave={onSaveTags} open={open} {...rest}>
      <Autocomplete
        multiple
        options={selectableTags}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            <ColorTag hex={option.color} label={option.name} size="small" />
          </li>
        )}
        renderTags={tags => tags.map(tag => <ColorTag key={tag.id} hex={tag.color} label={tag.name} size="small" />)}
        getOptionLabel={option => option.name}
        noOptionsText="Inga matchande taggar"
        onChange={(_, tags) => setSelectedTags(tags)}
        renderInput={params => <TextField {...params} label="Välj/sök bland taggar" />}
      />
    </SelectTagModal>
  );
};
