import { FilterStatePropertyValue } from '../../../../api/filterTypes';
import { ListComparison, PredicateClause, SearchPredicate } from '../../../../api/searchApiTypes';

// There are two kinds of lists we might filter through, list of object(ImageVersion[]) and lists of values(string[]).
// Lists of objects require [subProperty] to be specified while lists of values does not.
export const listPropClauseResolver =
  <T>(subProperty?: keyof T, comparison: ListComparison = ListComparison.EqAny) =>
  (property: keyof T, propertyValue: FilterStatePropertyValue): PredicateClause<T> => ({
    clauses: propertyValue.value.map(argument => ({ listCondition: { property, subProperty, argument, comparison } })),
    predicate: SearchPredicate.Or,
  });
