import { useAppSelector } from '../../../hooks/useAppRedux';

import styles from './ImagePaintFields.module.css';

import { Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { Case, EnumerationItem, Paint } from '../../../api/caseApiTypes';
import { CaseTextField } from './CaseTextField';
import { CaseField } from '../layoutComponents/CaseField';
import { MediaValue, getMediaValue } from './MediaValue';

type PaintValueChange = (original: Paint | undefined) => Paint | undefined;
interface Props {
  item?: MediaValue<Paint | undefined>[] | undefined;
  parentCase?: Case;
  onChange: (changer: PaintValueChange) => void;
  readonly?: boolean;
}

function getSelectedSupplier(wallColorSuppliers: EnumerationItem[], paint: Paint | undefined) {
  if (wallColorSuppliers.some(s => s.key === paint?.supplier)) {
    return paint?.supplier;
  }
  if (paint?.supplier === 'mixed') {
    return 'mixed';
  }
  return 'unset';
}

export const ImagePaintFields: React.FC<Props> = ({ item, onChange, readonly }) => {
  const wallColorSuppliers = useAppSelector(state => state.app.enumerations?.wallColorSuppliers) ?? [];

  const supplierValue = getMediaValue(
    item,
    { color: '', supplier: 'unset' },
    { color: 'Blandade värden', supplier: 'mixed' },
    (v0, v1) => v0?.supplier === v1?.supplier,
  );

  const colorValue = getMediaValue(
    item,
    { color: '', supplier: 'unset' },
    { color: 'Blandade värden', supplier: 'mixed' },
    (v0, v1) => v0?.color === v1?.color,
  );

  const setValue = (update?: Partial<Paint>) => {
    if (update?.color == null && update?.supplier == null) {
      onChange(_ => undefined);
    } else {
      onChange(original => ({ ...original, ...update }));
    }
  }

  const selectedSupplier = getSelectedSupplier(wallColorSuppliers, supplierValue);

  const onSelectSupplier = (event: SelectChangeEvent) => {
    if (event.target.value === 'unset') {
      setValue(undefined);
    } else {
      const supplier = event.target.value as string;
      setValue({ supplier });
    }
  };

  const getOption = (option: EnumerationItem) => (
    <MenuItem key={option.key} value={option.key}>
      {option.value ?? option.key}
    </MenuItem>
  );

  const supplierInvalid = !(supplierValue?.supplier || !colorValue?.color);
  const colorInvalid = !(!supplierValue?.supplier || colorValue?.color);

  return (
    <CaseField label="Väggfärg">
      <Grid container spacing={2} className={styles.formBody} alignItems="flex-end">
        <Grid item xs={6}>
          <InputLabel shrink>Leverantör</InputLabel>
          <Select value={selectedSupplier} onChange={onSelectSupplier} readOnly={false} error={supplierInvalid}>
            <MenuItem key="unset" value="unset">
              <i>Ej vald</i>
            </MenuItem>
            <MenuItem key="mixed" value="mixed" style={selectedSupplier !== 'mixed' ? { display: 'none' } : {}}>
              <i>Blandade värden</i>
            </MenuItem>
            {wallColorSuppliers.map(getOption)}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <CaseTextField
            label="Färg"
            name="color"
            value={colorValue?.color || ''}
            setValue={color => setValue({ color })}
            disabled={readonly}
            error={colorInvalid}
          />
        </Grid>
      </Grid>
    </CaseField>
  );
};
