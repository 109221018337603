import React from 'react';

import { Case } from '../../../api/caseApiTypes';
import { CaseEnrichmentForm } from './CaseEnrichmentForm';
import { CaseFormSection } from './CaseFormSection';
import { ShowEnrichmentSection } from './CaseFormLogic';

interface Props {
  caze: Case;
  onChange: (partial: Partial<Case>) => void;
  readonly?: boolean;
}

export const CaseDetailEnrichmentSubForm: React.FC<Props> = ({ caze, onChange, readonly }) => {
  return (
    <>
      {ShowEnrichmentSection(caze) && (
        <CaseFormSection
          caseId={caze.id}
          title="Bildberikning"
          description="Appliceras automatiskt på uppladdade bilder, men synkas inte till existerande bilder vid ändringar"
        >
          <CaseEnrichmentForm item={caze} onChange={onChange} readonly={readonly} />
        </CaseFormSection>
      )}
    </>
  );
};
