import React from 'react';
import { Button, Paper } from '@mui/material';

import styles from './ImageCardAdd.module.css';

import { DropzoneWithProgress } from '../../commonComponents/DropzoneWithProgress';
import { Add } from '@mui/icons-material';
import { UploadProgressHandlerForFile } from '../../api/commonApiTypes';

interface Props {
  className?: string;
  onImageUpload: (files: File[], onUploadProgress: UploadProgressHandlerForFile) => void;
  fileUploadInProgress?: boolean;
}

export const ImageCardAdd: React.FC<Props> = ({ className, onImageUpload, fileUploadInProgress }) => {
  return (
    <div className={className}>
      <Paper className={styles.container}>
        <DropzoneWithProgress onDrop={onImageUpload} fileUploadInProgress={fileUploadInProgress}>
          <Button className={styles.item}>
            <Add />
          </Button>
        </DropzoneWithProgress>
      </Paper>
    </div>
  );
};
