import { compareAsc, compareDesc, parseISO } from 'date-fns';

import { ImagePointer, ImageVersion } from '../api/caseApiTypes';

export const ByPointers = (a: ImagePointer, b: ImagePointer) => {
  const aKey = [a.imageId, a.imageVersion].join('.');
  const bKey = [b.imageId, b.imageVersion].join('.');
  return aKey.localeCompare(bKey);
};

export const ByCreatedAt = (order: 'desc' | 'asc') => (l: ImageVersion, r: ImageVersion) => {
  const desc = order === 'desc';
  const compareFunc = desc ? compareDesc : compareAsc;
  return compareFunc(parseISO(l.createdAt), parseISO(r.createdAt));
};
