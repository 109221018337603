import React from 'react';
import { CaseTextField } from './CaseTextField';

interface Props {
  description?: string;
  onChange: (value: string) => void;
  minRows?: number;
  readonly?: boolean;
  label?: string;
}

export const CaseDescriptionField: React.FC<Props> = ({ description, onChange, minRows = 8, readonly, label = 'Beskrivning' }) => {
  return (
    <CaseTextField
      variant="outlined"
      multiline
      label={label}
      name="description"
      minRows={minRows}
      value={description || ''}
      setValue={onChange}
      disabled={readonly}
    />
  );
};
