import React from 'react';

import { useTheme } from '@mui/material';
import { Mention, MentionsInput, SuggestionDataItem } from 'react-mentions';

import { searchAdUsers } from '../../api/userApi';

let debounceTimeoutId: NodeJS.Timeout;

interface Props {
  commentContent: string;
  setCommentContent: (comment: string) => void;
}

export const TextBoxWithMention: React.FC<Props> = ({ commentContent, setCommentContent }) => {
  const palette = useTheme();
  const isLightMode = palette.palette.mode === 'light';
  const mentionStyles = {
    control: {
      backgroundColor: 'inherit',
      color: palette.palette.text.primary,
      fontSize: 16,
      minHeight: 63,
    },
    input: {
      padding: '8px',
      border: `1px solid ${palette.palette.grey[isLightMode ? 300 : 700]}`,
      borderRadius: '4px',
      color: palette.palette.text.primary,
    },
    suggestions: {
      list: {
        backgroundColor: palette.palette.grey[isLightMode ? 200 : 900],
      },
      item: {
        padding: '5px 15px',
        '&focused': {
          backgroundColor: palette.palette.primary.light,
        },
      },
    },
  };

  function fetchUsers(query: string, callback: (data: SuggestionDataItem[]) => void) {
    if (debounceTimeoutId) {
      clearTimeout(debounceTimeoutId);
    }
    debounceTimeoutId = setTimeout(() => {
      if (!query) return;
      searchAdUsers(query)
        .then(res => res.map(user => ({ display: user.fullName, id: user.id })))
        .then(callback)
        .catch(err => {
          return [];
        });
    }, 400);
  }

  return (
    <MentionsInput
      style={mentionStyles}
      value={commentContent}
      onChange={event => setCommentContent(event.target.value)}
      placeholder="Ny kommentar"
      a11ySuggestionsListLabel={'Laddar användare'}
      allowSpaceInQuery
      allowSuggestionsAboveCursor={true}
      autoFocus
    >
      <Mention displayTransform={(_id, display) => `@${display}`} trigger="@" data={fetchUsers} />
    </MentionsInput>
  );
};
