import React, { createContext, useContext, useState } from 'react';

export enum SelectionContext {
  Global = 'Global',
  ImagePicker = 'ImagePicker',
  ClipbookPopover = 'ClipbookPopover',
}

const SelectionContextContext = createContext<SelectionContext>(SelectionContext.Global);

interface SelectionContextProviderProps {
  selectionContext: SelectionContext;
  children: React.ReactNode;
}

export const SelectionContextProvider: React.FC<SelectionContextProviderProps> = ({ selectionContext, children }) => {
  const [state] = useState(selectionContext);

  return <SelectionContextContext.Provider value={state}>{children}</SelectionContextContext.Provider>;
};

export const useSelectionContext = () => {
  const context = useContext(SelectionContextContext);
  if (!context) {
    throw new Error('useSelectedImages must be used within a SelectedImagesProvider');
  }
  return context;
};
