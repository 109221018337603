import React, { useEffect, useState } from 'react';

import { InputBase, TextField, TextFieldProps } from '@mui/material';

interface Props {
  label?: string;
  value: string | undefined;
  setValue: (value: string) => void;
  readonly?: boolean;
  naked?: boolean;
}

export const CaseTextField: React.FC<Props & Omit<TextFieldProps, 'label'>> = ({ label, value, setValue, readonly, naked, ...rest }) => {
  const [localValue, setLocalValue] = useState(value || '');
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  const clearTimer = () => {
    if (timer) clearTimeout(timer);
  };

  const onBlur = () => {
    if (localValue !== value) {
      clearTimer();
      setValue(localValue);
    }
  };

  const updateValue = (value: string) => {
    clearTimer();
    value = cleanDomainString(value);
    setLocalValue(value);

    setTimer(setTimeout(() => setValue(value), 200));
  };

  const cleanDomainString = (value: string) => {
    return value.replace(/\t/g, ' ').replace(/\v/g, ' ').replace(/\f/g, ' ');
  };

  useEffect(() => {
    if (value !== localValue) {
      clearTimer();
      setLocalValue(value || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  if (naked) {
    const placeholder = `${label}${rest.required ? ' *' : ''}`;
    return (
      <InputBase
        value={localValue}
        onChange={e => updateValue(e.currentTarget.value)}
        disabled={readonly}
        onBlur={onBlur}
        label={label}
        placeholder={placeholder}
        {...(rest as any)}
      />
    );
  }

  return (
    <TextField
      value={localValue}
      onChange={e => updateValue(e.currentTarget.value)}
      disabled={readonly}
      onBlur={onBlur}
      label={label}
      {...rest}
    />
  );
};
