import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/useAppRedux';
import { ensureImagesLoaded, selectAllImagesById } from '../images/imagesSlice';
import { Image } from '../../api/caseApiTypes';

export function useGetImages(imageIds: string[]): Image[] {
  const dispatch = useAppDispatch();

  const images = useAppSelector(selectAllImagesById(imageIds));

  const imagesToLoad = useMemo(() => images.filter(img => !img.image).map(img => img.id), [images]);
  useEffect(() => {
    dispatch(ensureImagesLoaded(imagesToLoad));
  }, [imagesToLoad, dispatch]);

  return useMemo(() => images.filter(img => !!img.image).map(img => img.image!), [images]);
}
