import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/useAppRedux';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';

import { CaseDetail } from './CaseDetail';
import { cleanupCaseDetail, initializeCaseDetailItem, selectCaseDetailItem } from './caseDetailSlice';
import { useRights } from '../../hooks/useRights';
import { Right } from '../../api/userApi';
import { StateEnum } from '../../api/caseApiTypes';
import { ResetAppTitle, SetAppTitle } from '../../helpers/AppTitleHelpers';

const EditCaseBaseTitle = 'Beställning';

export const EditCase = () => {
  const isAdmin = useRights(Right.Administration);
  const canWrite = useRights(Right.WriteCase);
  const dispatch = useAppDispatch();

  const { id: idParam } = useParams();
  const id = idParam as string;
  const persistedCase = useAppSelector(state => selectCaseDetailItem(state, id));

  const item = useAppSelector(state => state.caseDetail.case);

  useEffect(() => {
    SetAppTitle(EditCaseBaseTitle);
    dispatch(initializeCaseDetailItem({ id }));
    return () => {
      ResetAppTitle();
      dispatch(cleanupCaseDetail());
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (persistedCase != null) {
      SetAppTitle(EditCaseBaseTitle, persistedCase.lookupEntry);
    }
  }, [dispatch, persistedCase]);

  if (item == null) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  const writable = isAdmin || (canWrite && item.state.state <= StateEnum.confirmed);

  return <CaseDetail item={item} readonly={!writable} isNewCase={false} />;
};
