import React from 'react';
import { CircularProgress, Typography, useTheme } from '@mui/material';
import { SlowCircularProgress } from './SlowCircularProgress';

import styles from './UploadProgress.module.css';

interface Props {
  uploadCount?: number;
  progress: number;
}

export const UploadProgress: React.FC<Props> = ({ uploadCount, progress }) => {
  const { palette } = useTheme();

  const color = palette.mode === 'light' ? '#eee' : '#444';

  return (
    <div className={styles.container}>
      <div className={styles.progress}>
        {progress !== 100 ? (
          <>
            <CircularProgress style={{ color }} variant="determinate" value={100} />
            <CircularProgress className={styles.top} variant="determinate" value={progress} />
            <Typography className={styles.text} variant="caption">
              {Math.round(progress)}%
            </Typography>
          </>
        ) : (
          <SlowCircularProgress />
        )}
      </div>
      {uploadCount && (
        <Typography variant="caption">
          Laddar upp {uploadCount} {uploadCount !== 1 ? 'filer' : 'fil'}
        </Typography>
      )}
    </div>
  );
};
