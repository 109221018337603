import React from 'react';
import styles from './SelectableUserTag.module.css';

import { Chip } from '@mui/material';
import FaceIcon from '@mui/icons-material/Face';

import { UserTag } from '../../api/caseApiTypes';

interface Props {
  userTag: UserTag;
  size?: 'small' | 'medium';
  onSelect?: () => void;
  onDelete?: () => void;
}

export const SelectableUserTag: React.FC<Props> = ({ userTag, size, onSelect, onDelete }) => {
  const onClick = (e: React.MouseEvent) => {
    if (onSelect) {
      onSelect();
    }

    e.preventDefault();
    e.stopPropagation();
  };

  const onClickOnDelete = (e: React.MouseEvent) => {
    if (onDelete) {
      onDelete();
    }

    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Chip
      className={styles.chip}
      icon={<FaceIcon />}
      size={size}
      label={userTag.name}
      onClick={onSelect ? onClick : undefined}
      onDelete={onDelete ? onClickOnDelete : undefined}
    />
  );
};
