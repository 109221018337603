import React, { useCallback, useEffect, useState } from 'react';

import { CloudUpload } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import { useDropzone } from 'react-dropzone';

import styles from './DropzoneWithProgress.module.css';

import { UploadProgressHandlerForFile } from '../api/commonApiTypes';
import { usePrevious } from '../hooks/usePrevious';
import { SlowCircularProgress } from './SlowCircularProgress';
import { UploadProgress } from './UploadProgress';
import { pushToast } from '../appSlice';
import { useAppDispatch } from '../hooks/useAppRedux';

export type DropzoneCallback = (files: File[], onUploadProgress: UploadProgressHandlerForFile) => void;

interface Props {
  children?: React.ReactNode;
  onDrop?: DropzoneCallback;
  singleFile?: boolean;
  noClick?: boolean;
  fileUploadInProgress?: boolean;
}

export const DropzoneWithProgress: React.FC<Props> = ({ onDrop: onDropExternal, singleFile, noClick, fileUploadInProgress, children }) => {
  const dispatch = useAppDispatch();
  const [progressPerFile, setProgressPerFile] = useState<FileProgressMap>({});
  const [uploading, setUploading] = useState(false);
  const [shouldSetIsUploading, setShouldSetIsUploading] = useState(false);

  const previousValues = usePrevious({ fileUploadInProgress });

  useEffect(() => {
    if (shouldSetIsUploading && fileUploadInProgress === true && previousValues?.fileUploadInProgress === false) {
      setUploading(true);
      setShouldSetIsUploading(false);
    }
    if (fileUploadInProgress === false && previousValues?.fileUploadInProgress === true) {
      setProgressPerFile({});
      setUploading(false);
    }
  }, [fileUploadInProgress, previousValues?.fileUploadInProgress, shouldSetIsUploading]);

  const uploadHandler: UploadProgressHandlerForFile = useCallback((e, file) => {
    const progress = Math.round((e.progress ?? 0) * 100);
    const newProgress = progress === 100 ? undefined : { progress, total: file.size };
    setProgressPerFile(prev => ({ ...prev, [file.name]: newProgress }));
  }, []);
  const cancelUploadIfFilenameIncludesIllegalCharacters = useCallback(
    (file: File[]) => {
      // Allows only letters, hyphen, underscore, space, dot, parenthesis and numbers
      const illegalCharactersRegex = /[^a-zA-ZäÄåÅöÖ0-9-_.() ]/g;
      const filesWithIllegalCharacters = file.filter(file => illegalCharactersRegex.test(file.name));
      const shouldCancelUpload = filesWithIllegalCharacters.length > 0;
      if (shouldCancelUpload) {
        const fileString = filesWithIllegalCharacters.length > 1 ? 'Filerna' : 'Filen';
        const fileNamesWithIllegalCharacters = filesWithIllegalCharacters.map(file => file.name).join(', ');
        const wrongFormatString = filesWithIllegalCharacters.length > 1 ? 'har felaktiga filnamn' : 'har ett felaktigt filnamn';
        dispatch(
          pushToast({
            message: `${fileString} ${fileNamesWithIllegalCharacters} ${wrongFormatString}. Endast a-ö, 0-9, -_.() och mellanslag får användas.`,
            variant: 'error',
          }),
        );
      }
      return shouldCancelUpload;
    },
    [dispatch],
  );

  const onDrop = (files: File[]) => {
    if (cancelUploadIfFilenameIncludesIllegalCharacters(files)) {
      return;
    }
    const filesLargerThan200MB = files.filter(file => file.size > 200 * 1024 * 1024);
    if (filesLargerThan200MB.length > 0 && checkIfBigFilesShouldCancelUpload(filesLargerThan200MB)) {
      return;
    }
    if (fileUploadInProgress) {
      setUploading(true);
    } else {
      setShouldSetIsUploading(true);
    }
    onDropExternal?.(files, uploadHandler);
  };

  const maxFiles = singleFile ? 1 : undefined;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles,
    noClick,
  });

  const dropzoneClasses = [styles.item];

  if (isDragActive) {
    dropzoneClasses.push(styles.dragging);
  }

  const activeUploads = Object.values(progressPerFile).filter(progress => progress != null).length;

  const totalProgress = CalculateTotalProgress(progressPerFile);
  return (
    <Box className={dropzoneClasses.join(' ')} {...getRootProps()}>
      <input {...getInputProps()} />
      {uploading && !isDragActive ? (
        <Grid container justifyContent="center" alignItems="center" className={styles.item}>
          {activeUploads > 0 ? <UploadProgress uploadCount={activeUploads} progress={totalProgress} /> : <SlowCircularProgress />}
        </Grid>
      ) : (
        <Grid container justifyContent="center" alignItems="center" className={styles.item}>
          {isDragActive ? <CloudUpload /> : <Box className={styles.wrapper}>{children}</Box>}
        </Grid>
      )}
    </Box>
  );
};

const checkIfBigFilesShouldCancelUpload = (file: File[]) => {
  const fileNamesWithSize = file.map(file => `${file.name} (${Math.round(file.size / 1024 / 1024)}MB)`).join(', ');
  const fileString = file.length > 1 ? 'Filerna' : 'Filen';
  return !window.confirm(`${fileString} ${fileNamesWithSize} är större än 200MB. Vill du fortsätta med uppladdningen?`);
};

export const CalculateTotalProgress = (progressPerFile: FileProgressMap) => {
  const filesWithProgress = (Object.values(progressPerFile).filter(progress => progress != null) ?? []) as FileProgress[];
  const uploadedSoFar = filesWithProgress.reduce((acc, progress) => acc + progress.progress * progress.total, 0);
  const totalSize = filesWithProgress.reduce((acc, progress) => acc + progress.total, 0);

  if (totalSize === 0) {
    return 0;
  }
  return Math.round(uploadedSoFar / totalSize);
};

export type FileProgressMap = Record<File['name'], FileProgress | undefined>;

interface FileProgress {
  progress: number;
  total: number;
}
