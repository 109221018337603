import * as Api from '../../api/imageApi';

import { Dispatch, createAsyncThunk } from '@reduxjs/toolkit';
import { ProjectTag, SystemTag, Image } from '../../api/caseApiTypes';
import {
  getImageSuccess,
  getImageFailed,
  startBatchMediaSave,
  endBatchMediaSave,
  batchMediaSaveFail,
  batchMediaSaveSuccess,
} from './imagesSlice';
import { batchExecute } from '../../helpers/Batching';
import { retry } from '../../helpers/Retry';

function systemTagsNotAlreadyOnImage(image: Image, systemTags: SystemTag[]): SystemTag[] {
  return systemTags.filter(systemTag => !image.systemTags.some(tag => tag.id === systemTag.id));
}

export async function batchProcessTags<TDispatch extends Dispatch>(
  dispatch: TDispatch,
  media: Image,
  action: (media: Image) => Promise<void>,
) {
  try {
    await retry(
      () => action(media),
      3, // retries
      1000, //delay
    );

    dispatch(batchMediaSaveSuccess());
  } catch (e: any) {
    getImageFailed(e.toString());

    if (e instanceof Error) {
      dispatch(batchMediaSaveFail({ error: e.message, media }));
    } else {
      dispatch(batchMediaSaveFail({ error: 'unknown', media }));
    }
  }
}

export const setProjectTag = createAsyncThunk<void, { media: Image[]; projectTag: ProjectTag }>(
  'images/project-tag/set',
  async ({ media, projectTag }, { dispatch }) => {
    media = media.filter(m => m.projectTag?.id !== projectTag.id);
    if (media.length === 0) {
      return;
    }

    dispatch(startBatchMediaSave({ numTotal: media.length, operation: 'Projekttagg' }));

    await batchExecute(media, 10, media =>
      batchProcessTags(dispatch, media, async media => {
        const result = await Api.setProjectTag(media, projectTag);
        dispatch(getImageSuccess(result));
      }),
    );

    dispatch(endBatchMediaSave());
  },
);

export const clearProjectTag = createAsyncThunk<void, { media: Image[]; projectTag: ProjectTag }>(
  'images/project-tag/clear',
  async ({ media, projectTag }, { dispatch }) => {
    dispatch(startBatchMediaSave({ numTotal: media.length, operation: 'Rensa projekttagg' }));

    await batchExecute(media, 10, media =>
      batchProcessTags(dispatch, media, async media => {
        if (media.projectTag == null || media.projectTag.id !== projectTag.id) {
          return;
        }
        const result = await Api.clearProjectTag(media);
        dispatch(getImageSuccess(result));
      }),
    );

    dispatch(endBatchMediaSave());
  },
);

export const addSystemTags = createAsyncThunk<void, { media: Image[]; systemTags: SystemTag[] }>(
  'images/system-tag/add',
  async ({ media, systemTags }, { dispatch }) => {
    dispatch(startBatchMediaSave({ numTotal: media.length, operation: 'Tagg' }));

    await batchExecute(media, 10, media =>
      batchProcessTags(dispatch, media, async media => {
        const systemTagsNotAdded = systemTagsNotAlreadyOnImage(media, systemTags);
        if (systemTagsNotAdded.length <= 0) {
          return;
        }
        const result = await Api.addSystemTags(media, systemTagsNotAdded);

        dispatch(getImageSuccess(result));
      }),
    );

    dispatch(endBatchMediaSave());
  },
);

export const removeSystemTag = createAsyncThunk<void, { media: Image[]; systemTag: SystemTag }>(
  'images/system-tag/remove',
  async ({ media, systemTag }, { dispatch }) => {
    dispatch(startBatchMediaSave({ numTotal: media.length, operation: 'Ta bort tagg' }));

    await batchExecute(media, 10, media =>
      batchProcessTags(dispatch, media, async media => {
        if (!media.systemTags.some(tag => tag.id === systemTag.id)) {
          return;
        }
        const result = await Api.removeSystemTag(media, systemTag);
        dispatch(getImageSuccess(result));
      }),
    );

    dispatch(endBatchMediaSave());
  },
);
