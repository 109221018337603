import React from 'react';
import { Box, Typography } from '@mui/material';

import { formatDate } from './../date/DateFormatter';

interface Props {
  photoAndRetouchDeadline?: Date | string;
  approvalDeadline?: Date | string;
}

export const CaseDeadlines: React.FC<Props> = ({ photoAndRetouchDeadline, approvalDeadline }) => {
  return (
    <>
      <Box>
        <Typography variant="caption" color="textSecondary" display="inline">
          {'Deadline foto- & retusch: '}
        </Typography>
        <Typography variant="caption" color="textPrimary" display="inline">
          {formatDate(photoAndRetouchDeadline)}
        </Typography>
      </Box>
      <Box>
        <Typography variant="caption" color="textSecondary" display="inline">
          {'Deadline godkännande: '}
        </Typography>
        <Typography variant="caption" color="textPrimary" display="inline">
          {formatDate(approvalDeadline)}
        </Typography>
      </Box>
    </>
  );
};
