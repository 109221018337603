import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';

import { Add, Remove, ZoomIn } from '@mui/icons-material';
import { Grid, Typography, Paper, useTheme, Badge, ToggleButton, ToggleButtonGroup, Divider } from '@mui/material';

import styles from './M3SearchDialog.module.css';

import { FetchResult, M3Article, M3SearchQuery } from '../../api/m3Api';
import { FullScreenDialog } from '../../commonComponents/FullScreenDialog';
import { LoadMoreItem } from '../../commonComponents/LoadMoreItem';
import { MediaCard } from '../../commonComponents/MediaCard';
import { GetThumbnailUri } from '../../helpers/DerivedImageHelpers';
import { useArticleImages } from '../../hooks/UseArticleImages';
import { useAppDispatch, useAppSelector } from '../../hooks/useAppRedux';
import { SessionStorageKeys, useSessionStorage } from '../../hooks/useStorage';
import { CaseTextField } from '../cases/formFieldComponents/CaseTextField';
import { CaseDialog } from '../cases/layoutComponents/CaseDialog';
import { AdditionalAction } from '../image/imageCard/ImageCard';
import { M3MultipleSearchFields } from './M3MultipleSearchFields';
import { freeTextSearch, searchWithFields } from './m3Slice';
import { formatDate } from '../date/DateFormatter';

interface Props {
  searchValue?: string;
  isVisible: boolean;
  onSave?: (articleIds: M3ArticleWithQuantity[]) => void;
  onClose: () => void;
  selectMultiple: boolean;
}
const pageSize = 50;

export interface M3ArticleWithQuantity extends M3Article {
  quantity: number;
}

enum SearchType {
  FreeText = 'FreeText',
  Advanced = 'Advanced',
}

export const M3SearchModal: React.FC<Props> = ({ searchValue, isVisible, onSave, onClose, selectMultiple }) => {
  const dispatch = useAppDispatch();

  const [queries, setQueries] = useSessionStorage<M3SearchQuery[]>(SessionStorageKeys.m3SearchQuery, [
    { value: searchValue ?? '', searchField: 'm3Id' },
    { value: '', searchField: 'description' },
  ]);
  const [query, setQuery] = useSessionStorage<string>(SessionStorageKeys.m3SearchText, searchValue ?? '');
  const [continuation, setContinuation] = useState(0);

  const { m3Articles, totalHits, isSearching } = useAppSelector(state => state.m3);
  const { findImageForArticle } = useArticleImages({
    articleIds: m3Articles.map(a => a.m3Id).filter(a => a) as string[],
    shouldFetch: true,
  });
  const [selected, setSelected] = useState<M3ArticleWithQuantity[]>([]);

  const [numberFreeTextImages, setNumberFreeTextImages] = useState(30);

  const [zoomedImage, setZoomedImage] = useState<string | undefined>();
  const [zoomedAlt, setZoomedAlt] = useState<string | undefined>();

  const [alignment, setAlignment] = React.useState(SearchType.FreeText);

  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    setAlignment(newAlignment as SearchType);
  };

  const { palette, zIndex } = useTheme();

  const saveDisabled = useMemo(() => {
    return !selectMultiple && selected.length !== 0;
  }, [selected, selectMultiple]);

  useEffect(() => {
    setContinuation(0);
  }, [queries, query, alignment]);

  useEffect(() => {
    if (isVisible && alignment === SearchType.Advanced) {
      dispatch(searchWithFields({ queries, continuation, pageSize }));
    }
  }, [queries, continuation, dispatch, isVisible, alignment]);

  useEffect(() => {
    if (isVisible && alignment === SearchType.FreeText) {
      dispatch(freeTextSearch(query));
    }
  }, [query, continuation, dispatch, isVisible, alignment]);

  const saveAndClose = (toSave: M3ArticleWithQuantity[]) => {
    onSave?.(toSave);
    onClose();
    setSelected([]);
  };

  const removeSelected = (article: M3Article) => {
    setSelected(selected.map(s => (s.id === article.id ? { ...s, quantity: s.quantity - 1 } : s)).filter(s => s.quantity > 0));
  };
  const addSelectedArticle = (article: M3Article) => {
    if (selectMultiple) {
      const existing = selected.find(s => s.id === article.id);
      if (existing === undefined) {
        setSelected([...selected, { ...article, quantity: 1 }]);
      } else {
        setSelected(
          selected.reduce((acc, s) => {
            const isSame = s.id === article.id;
            return isSame ? [...acc, { ...s, quantity: s.quantity + 1 }] : [...acc, s];
          }, [] as M3ArticleWithQuantity[]),
        );
      }
    } else {
      saveAndClose([{ ...article, quantity: 1 }]);
    }
  };

  const getActions = (article: M3Article, isSelected: boolean, image: string | undefined): AdditionalAction[] => {
    const actions: AdditionalAction[] = [];

    if (onSave ?? false)
    {
      actions.unshift({
        action: () => addSelectedArticle(article),
        icon: <Add />,
        tooltip: 'Lägg till',
      });
    }
    if (image) {
      actions.unshift({
        action: () => {
          setZoomedImage(image);
          setZoomedAlt(article.name);
        },
        icon: <ZoomIn />,
        tooltip: 'Förstora bild',
      });
    }
    if (isSelected) {
      actions.unshift({
        action: () => removeSelected(article),
        icon: <Remove />,
        tooltip: 'Ta bort',
      });
    }
    return actions;
  };

  const renderM3Article = (article: M3ArticleWithQuantity, idx: number) => {
    const primaryText = `${article.name} ${article.m3Id ?? ''}`;
    const isSelected = selected.some(art => art.m3Id === article.m3Id);
    const selectedStyle = isSelected ? { outline: `2px solid ${palette.primary.main}` } : undefined;

    const { image, fetchResult } = findImageForArticle(article.m3Id);
    const imageVersion = image?.versions[image.activeVersion];
    const imageUri = GetThumbnailUri(imageVersion, 320);
    const imageUri1920 = GetThumbnailUri(imageVersion, 1920);
    const salesStart = article.attributes.itemSalesStart;
    const salesEnd = article.attributes.itemSalesEnd;

    return (
      <Grid item key={idx}>
        <Badge badgeContent={article.quantity} color={'primary'} hidden={article.quantity === 0}>
          <MediaCard
            shouldDisplaySpinner={fetchResult === FetchResult.NotSearched}
            style={selectedStyle}
            imageThumbnailSrc={imageUri ?? '/placeholder320.svg'}
            image={imageVersion}
            imageTitle={primaryText}
            cardHeader={<Typography> {article.name}</Typography>}
            cardBody={
              <div className={styles.m3ArticleCardInfoText}>
                <span>{article.description}</span>
                {(salesEnd || salesStart) && (
                  <>
                    <Divider />
                    <span>Säljstart: {formatDate(salesStart)}</span>
                    <br />
                    <span>Säljslut: {formatDate(salesEnd)}</span>
                  </>
                )}
              </div>
            }
            actions={getActions(article, isSelected, imageUri1920)}
            cardFooter={
              <Typography className={styles.cardFooter} variant={'caption'} color={article.m3Id ? '' : 'indianred'}>
                {article.m3Id ?? 'Saknar M3id'}
              </Typography>
            }
          />
        </Badge>
      </Grid>
    );
  };

  const loadMore = () =>
    alignment === SearchType.Advanced ? setContinuation(m3Articles.length) : setNumberFreeTextImages(numberFreeTextImages + 30);

  let title = 'Lägg till artikel';

  const hits = alignment === SearchType.Advanced ? totalHits : m3Articles.length;
  const articles = alignment === SearchType.Advanced ? m3Articles : m3Articles.slice(0, numberFreeTextImages);
  const selectedWithoutSearch = selected.filter(s => !articles.some(m => m.m3Id === s.m3Id));
  const m3Result = articles.map(article => {
    const alreadySelected = selected.find(s => s.m3Id === article.m3Id);
    return { ...article, quantity: alreadySelected?.quantity ?? 0 };
  });

  return (
    <CaseDialog
      className={styles.container}
      title={onSave ? title : 'Sök'}
      isVisible={isVisible}
      onClose={onClose}
      saveDisabled={saveDisabled}
      onSave={selectMultiple ? () => saveAndClose(selected) : undefined}
      closeLabel={selectMultiple ? 'Avbryt' : 'Stäng'}
      saveLabel={selectMultiple ? 'Lägg till' : undefined}
    >
      <Paper className={styles.queryPanel} style={{ zIndex: zIndex.appBar }}>
        <ToggleButtonGroup
          fullWidth
          color="primary"
          exclusive
          onChange={handleChange}
          value={alignment}
          aria-label="Platform"
          sx={{ mb: 1 }}
          size="small"
        >
          <ToggleButton value={SearchType.FreeText}>Fritext</ToggleButton>
          <ToggleButton value={SearchType.Advanced}>Fält</ToggleButton>
        </ToggleButtonGroup>
        {alignment === SearchType.Advanced && <M3MultipleSearchFields queries={queries} onChange={setQueries} isSearching={isSearching} />}
        {alignment === SearchType.FreeText && (
          <CaseTextField label={'Fritextsökning'} aria-label="Query" value={query} setValue={value => setQuery(value)} autoFocus />
        )}
      </Paper>
      <Grid container gap={1} className={styles.articleGrid}>
        {selectedWithoutSearch.map(renderM3Article)}
        {m3Result.map(renderM3Article)}
        <Grid item>
          <LoadMoreItem
            className={styles.loadMoreContainer}
            itemsLoaded={articles.length}
            count={hits}
            isSearching={isSearching}
            loadMore={loadMore}
          />
        </Grid>
        {m3Result.length === 0 && !isSearching && <Typography>Inga resultat</Typography>}
      </Grid>
      <FullScreenDialog alt={zoomedAlt} item={zoomedImage} open={zoomedImage != null} onClose={() => setZoomedImage(undefined)} />
    </CaseDialog>
  );
};
