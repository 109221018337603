import React from 'react';

import { FormControl, InputLabel } from '@mui/material';

import { Enumerations } from '../../../api/caseApiTypes';
import { MediaValueSelect, OptionInfo } from './MediaValueSelect';
import { MediaValue, getMediaValue } from './MediaValue';
import { useAppSelector } from "../../../hooks/useAppRedux";
import { GetEnumerationByKey } from "../../../appSlice";

interface Props extends CommonProps {
  enumerationKey: keyof Enumerations;
  title: string;
  persistedValue: MediaValue<string | undefined>[] | undefined;
}

interface CommonProps {
  onChange: (value?: string) => void;
  readonly?: boolean;
}

type EditorProps = CommonProps & { editor: MediaValue<string | undefined>[] | undefined }
type StylistProps = CommonProps & { stylist: MediaValue<string | undefined>[] | undefined }
type PhotographerProps = CommonProps & { photographer: MediaValue<string | undefined>[] | undefined }

const GenericEnumerationField: React.FC<Props> = ({ persistedValue, enumerationKey, onChange, readonly, title }) => {
  const enumeration = useAppSelector(GetEnumerationByKey(enumerationKey))
  const value = getMediaValue(persistedValue, undefined, undefined);
  const valueMissing = value && !enumeration?.some(p => p.key === value);

  const handleChange = (value: string) => {
    const result = value === 'unset' ? undefined : value;
    onChange(result);
  };

  let options: OptionInfo<string>[] =
    enumeration?.map(s => ({
      key: s.key,
      label: s.value,
    })) || [];

  options.push({
    key: 'unset',
    label: 'Ej vald',
  });

  if (valueMissing) {
    options.push({
      key: value,
      label: value,
    });
  }

  return (
    <FormControl>
      <InputLabel shrink>{title}</InputLabel>
      <MediaValueSelect values={persistedValue} defaultValue={'unset'} onChange={handleChange} readonly={readonly} options={options} />
    </FormControl>
  );
};



export const CaseRetouchByField: React.FC<EditorProps> = ({ editor, onChange, readonly }) =>
  <GenericEnumerationField persistedValue={editor} onChange={onChange} readonly={readonly} title="Retuschör" enumerationKey="editors"/>

export const ImageStylistField: React.FC<StylistProps> = ({ stylist, onChange, readonly }) =>
  <GenericEnumerationField persistedValue={stylist} onChange={onChange} readonly={readonly} title="Stylist" enumerationKey="stylists"/>

export const ImagePhotographerField: React.FC<PhotographerProps> = ({ photographer, onChange, readonly }) =>
  <GenericEnumerationField persistedValue={photographer} onChange={onChange} readonly={readonly} title="Fotograf" enumerationKey="photographers"/>

