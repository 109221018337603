import { SearchComparison, SearchPredicate } from '../../../../api/searchApiTypes';

export const ctrDateCondition = <T>(property: keyof T, includeNull: boolean, operator: SearchComparison, date: string) => {
  const dateClause = { condition: { property: property, comparison: operator, argument: date } };
  const nullClause = { condition: { property: property, comparison: SearchComparison.Eq, argument: null } };
  if (includeNull) {
    return {
      clauses: [dateClause, nullClause],
      predicate: SearchPredicate.Or,
    };
  }
  return dateClause;
};
