import { FilterableImage, FilterPropertyRecord } from '../../../../api/filterTypes';
import { FilterTreeTimeIntervalComponent } from '../filterComponents/FilterTreeTimeIntervalComponent';
import {
  FilterTreeClassificationComponent,
  FilterTreeImageOriginComponent,
  FilterTreeImageStateComponent,
  FilterTreeImageTypeComponent,
  FilterTreePhotographerComponent,
  FilterTreeStylistComponent,
} from '../filterComponents/FilterTreeComponentTemplate';
import { FilterTreeProjectTagComponent, FilterTreeSystemTagComponent } from '../filterComponents/FilterTreeTagComponent';

export const imageFilterConfiguration: FilterPropertyRecord<FilterableImage> = {
  Image_ProjectTagId: {
    title: 'Projekt',
    Component: FilterTreeProjectTagComponent,
    alwaysShowFilter: true,
  },
  Image_SystemTagIds: {
    title: 'Taggar',
    Component: FilterTreeSystemTagComponent,
    alwaysShowFilter: true,
  },
  CustomFilter_CreatedInterval: {
    title: 'Skapad',
    Component: FilterTreeTimeIntervalComponent,
  },
  Image_ImageOrigin: {
    title: 'Bildkälla',
    Component: FilterTreeImageOriginComponent,
  },
  state: {
    title: 'Bildstatus',
    Component: FilterTreeImageStateComponent,
  },
  type: {
    title: 'Bildtyp',
    Component: FilterTreeImageTypeComponent,
  },
  photographer: {
    title: 'Fotograf',
    Component: FilterTreePhotographerComponent,
  },
  classification: {
    title: 'Rumstyp',
    Component: FilterTreeClassificationComponent,
  },
  stylist: {
    title: 'Stylist',
    Component: FilterTreeStylistComponent,
  },
};
