import _ from 'lodash';

// The customizer function is invoked to compare values. If it returns undefined, comparisons are handled by the method instead.
export const IsDirty = <T>(obj: T | null, src: T | null) =>
  !_.isEqualWith(obj, src, (a, b) => (AreEqualDates(a, b) || AreNullOrUndefined(a, b) ? true : undefined));
export const IsEqual = <T>(obj: T | null, src: T | null) => _.isEqual(obj, src);
export const UniqueObjects = <T>(arr: T[]) => _.uniqWith<T>(arr, IsEqual);

export const GetDifference = <T>(base: T, update: T) =>
  Object.fromEntries(
    Object.typedEntries(update).filter(
      ([key, val]) => !_.isEqual(base[key], val) && !AreEqualDates(base[key], val) && !AreNullOrUndefined(base[key], val),
    ),
  );

const AreNullOrUndefined = (a: any, b: any) => (a === undefined || a === null) && (b === undefined || b === null);

const AreEqualDates = (dateStrA: any, dateStrB: any): boolean => {
  if (!dateStrA || !dateStrB) return false;
  const dateA = new Date(dateStrA);
  const dateB = new Date(dateStrB);
  return dateA.getTime() === dateB.getTime();
};
