import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks/useAppRedux';
import { createFromImagePointers } from '../../cases/caseDetailSlice';
import { Right } from '../../../api/userApi';
import { useRights } from '../../../hooks/useRights';
import { FormatShapes } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Image } from '../../../api/caseApiTypes';
import { useSelectionContext } from './selectionContextContext';

interface Props {
  images: Image[];
}

export function CreateRetouchCaseButton({ images }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const canCreateRetouch = useRights(Right.ManageImage);

  const createRetouchCase = () =>
    dispatch(
      createFromImagePointers({ imagePointers: images.map(img => ({ imageId: img.id, imageVersion: img.activeVersion })), navigate }),
    );

  if (!canCreateRetouch) {
    return <></>;
  }

  return (
    <IconButton onClick={createRetouchCase} title="Skapa retuschbeställning av markerade bilder">
      <FormatShapes />
    </IconButton>
  );
}

export function CreateRetouchCaseFromSelectionButton() {
  const selectionContext = useSelectionContext();
  const selectedImages = useAppSelector(state => state.images.selected[selectionContext]);
  return <CreateRetouchCaseButton images={selectedImages} />;
}
