export const SortableImageFields = ['Created', 'Modified'] as const;

export type SortableImageField = typeof SortableImageFields[number];

export class SortableImageFieldsConverter {
  public static readonly Mapping: Record<SortableImageField, string> = {
    Modified: 'Senast uppdaterad',
    Created: 'Skapad',
  };

  public static ToName(type: SortableImageField): string | undefined {
    return this.Mapping[type];
  }
}
