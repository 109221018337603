import { useTheme } from '@mui/material';
import { PropsWithChildren } from 'react';
import styles from './OutlinedLabel.module.css';
import { useAppSelector } from '../hooks/useAppRedux';

interface Props {
  label: string;
}

export const OutlinedLabel: React.FC<PropsWithChildren & Props> = ({ label, children }) => {
  const { palette } = useTheme();
  const prefersDarkMode = useAppSelector(state => state.app.useDarkMode);
  let borderColor = palette.primary.dark;
  if (prefersDarkMode) {
    borderColor = palette.text.secondary;
  }

  const muiCardGrey = '#202020';
  const backgroundColor = prefersDarkMode ? muiCardGrey : palette.background.default;
  const labelColor = prefersDarkMode ? palette.text.primary : palette.text.secondary;

  return (
    <div style={{ borderColor, backgroundColor }} className={styles.group}>
      <label style={{ color: labelColor, backgroundColor }} className={styles.label}>
        {label}
      </label>
      {children}
    </div>
  );
};
