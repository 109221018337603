import * as React from 'react';

import { Close } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IconButton, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { M3SearchOptions, M3SearchOptionsConverter, M3SearchQuery } from '../../api/m3Api';
import { CaseTextField } from '../cases/formFieldComponents/CaseTextField';

interface Props {
  queries: M3SearchQuery[];
  onChange: (queries: M3SearchQuery[]) => void;
  isSearching: boolean;
}

export const M3MultipleSearchFields: React.FC<Props> = ({ queries, onChange, isSearching }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const removeQueryField = (index: number) => {
    const newQueries = [...queries];
    newQueries.splice(index, 1);
    onChange(newQueries);
  };

  const optionButton = (
    <div>
      <Button
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        fullWidth
      >
        Nytt sökfält
      </Button>
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        {M3SearchOptions.map(searchField => (
          <MenuItem
            key={searchField}
            onClick={() => {
              setAnchorEl(null);
              onChange([...queries, { value: '', searchField }]);
            }}
          >
            {M3SearchOptionsConverter.ToName(searchField)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );

  const renderQuery = (q: M3SearchQuery, i: number) => {
    const updateValue = (value: string) => {
      const newQueries = [...queries];
      newQueries[i] = { ...newQueries[i], value };
      onChange(newQueries);
    };

    return (
      <CaseTextField
        key={i}
        label={M3SearchOptionsConverter.ToName(q.searchField)}
        aria-label="Query"
        value={q.value}
        setValue={value => updateValue(value)}
        autoFocus={i === 0}
        InputProps={{
          endAdornment: (
            <>
              {queries.length > 1 && (
                <IconButton onClick={() => removeQueryField(i)}>
                  <Close />
                </IconButton>
              )}
            </>
          ),
        }}
      />
    );
  };

  return (
    <>
      {optionButton}
      <Stack gap={'0.25em'} marginTop={'1em'}>
        {queries.map(renderQuery)}
      </Stack>
    </>
  );
};
