import { useMemo, useState } from 'react';

export const useHover = () => {
  const [hovered, setHovered] = useState(false);

  const hoverHandlers = useMemo(
    () => ({
      onMouseOver() {
        setHovered(true);
      },
      onMouseEnter() {
        setHovered(true);
      },
      onMouseOut() {
        setHovered(false);
      },
    }),
    [],
  );

  return [hovered, hoverHandlers] as const;
};
