import React, { useEffect, useMemo, useState } from 'react';

import { Add, Close, Delete, Done, Edit, Info, NoPhotography, QuestionMark, Search } from '@mui/icons-material';
import { Box, IconButton, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField, Tooltip, Typography } from '@mui/material';
import { Article, DeliveryStatus, DeliveryStatusConverter } from '../../api/caseApiTypes';
import { FetchResult } from '../../api/m3Api';
import { EnlargableImage } from '../../commonComponents/EnlargableImage';
import { useArticleImages } from '../../hooks/UseArticleImages';
import { useArticle } from '../../hooks/useArticles';
import { M3ArticleWithQuantity, M3SearchModal } from '../m3Search/M3SearchDialog';
import { CaseTextField } from './formFieldComponents/CaseTextField';
import { CaseArticleInfo } from './formFieldComponents/CaseArticleInfo';
import styles from './CaseArticles.module.css';
import { generateRandomString } from '../../helpers/StringHelpers';

interface Props {
  articles: Article[] | null;
  onAddMany?: (articles: Article[]) => void;
  onUpsert?: (article: Article) => void;
  onDelete?: (article: Article) => void;
  isNewCase?: boolean;
  readonly?: boolean;
  renderExtraInfo?: boolean;
  renderDeliveryStatus?: boolean;
  pluralVerb?: string;
  maxArticles?: number;
}

export const CaseArticles: React.FC<Props> = ({
  articles,
  onAddMany,
  onUpsert,
  onDelete,
  isNewCase,
  readonly,
  renderExtraInfo,
  renderDeliveryStatus,
  maxArticles,
  pluralVerb = 'artiklar',
}) => {
  const isReadOnly = isNewCase || readonly;

  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [editArticle, setEditArticle] = useState<string>();

  const articleIds = articles?.map(a => a.articleId).filter(a => a !== undefined);
  const { findImageForArticle } = useArticleImages({ articleIds, shouldFetch: true });
  const canAddArticles = maxArticles == null || (articles?.length ?? 0) < maxArticles;

  const addBatchArticles = (articles: M3ArticleWithQuantity[]) => {
    if (onAddMany) {
      onAddMany(
        articles.map(article => ({
          id: generateRandomString(20),
          articleId: article.m3Id ?? '',
          productName: article.name,
          productDescription: article.description,
          quantity: article.quantity,
        })),
      );
    }
  };

  const CaseEditArticleForm: React.FC<{ article?: Article; articleReadOnly?: boolean; disabled?: boolean }> = ({
    article,
    articleReadOnly,
    disabled,
  }) => {
    const { fetchResult: fetchResultImage, image } = findImageForArticle(article?.articleId);

    const [productName, setProductName] = useState<string>(article?.productName ?? '');
    const [productDescription, setProductDescription] = useState<string>(article?.productDescription ?? '');
    const [articleId, setArticleId] = useState<string>(article?.articleId ?? '');

    const [quantity, setQuantity] = useState(article?.quantity ?? 1);
    const [deliveryStatus, setDeliveryStatus] = useState(article?.deliveryStatus ?? DeliveryStatus.Unspecified);

    const { fetchResult, article: newArticle } = useArticle({ articleId });
    const hasM3Article = useMemo(() => fetchResult === FetchResult.Found, [fetchResult]);

    const [articleInfoOpen, setArticleInfoOpen] = useState(false);

    const isValidArticleEntry = useMemo(() => {
      let result: boolean;
      if (hasM3Article) {
        result = articleId === newArticle?.m3Id && productName === newArticle?.name && productDescription === newArticle?.description;
      } else {
        result = !articleId && !!productName;
      }
      return result;
    }, [articleId, productName, productDescription, newArticle, hasM3Article]);

    useEffect(() => {
      if (hasM3Article) {
        setArticleId(newArticle?.m3Id || article?.articleId || '');
        setProductName(newArticle?.name || article?.productName || '');
        setProductDescription(newArticle?.description || article?.productDescription || '');
      } else {
        setProductName(article?.productName || '');
        setProductDescription(article?.productDescription || '');
      }
    }, [articleId, newArticle, article, hasM3Article]);

    const clearForm = () => {
      setProductName('');
      setArticleId('');
      setQuantity(1);
      setDeliveryStatus(DeliveryStatus.Unspecified);
    };

    const closeEditMode = () => setEditArticle(undefined);

    const upsertArticle = () => {
      onUpsert?.({ ...article, productName, productDescription, articleId, quantity, deliveryStatus });
      if (!article) {
        clearForm();
      }
      closeEditMode();
    };

    const deleteArticle = () => {
      if (article == null) return;

      onDelete?.(article);
      closeEditMode();
    };

    const onChangeQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = Number(e.currentTarget.value);

      if (isNaN(value) || value < 1) {
        value = 1;
      }

      setQuantity(value);
    };

    const toggleArticleInfo = () => {
      setArticleInfoOpen(s => !s);
    };

    const handleDeliveryStatusUpdate = (event: SelectChangeEvent<DeliveryStatus>) => {
      setDeliveryStatus(event.target.value as DeliveryStatus);
    };

    const deliveryStatusValues = [DeliveryStatus.Unspecified, DeliveryStatus.Ordered, DeliveryStatus.InStock].map(value => (
      <MenuItem key={value} value={value}>
        {DeliveryStatusConverter.ToName(value)}
      </MenuItem>
    ));

    const hasNotEdited =
      (article == null && !productName && !articleId) ||
      (article &&
        article.productName === productName &&
        article.productDescription === productDescription &&
        article.articleId === articleId &&
        article.quantity === quantity);

    const saveIsDisabled = disabled || hasNotEdited || !isValidArticleEntry;

    const saveOnEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter' && !saveIsDisabled) {
        upsertArticle();
      }
    };

    const articleDataComponent = (
      <>
        {articleReadOnly ? (
          <>
            <div>
              <span className={styles.articleHeader}>{articleId}</span>
              <span className={styles.articleHeaderSeparator}>|</span>
              <strong>{productName}</strong>
            </div>
            <Typography variant="body2">{productDescription}</Typography>
          </>
        ) : (
          <>
            <CaseTextField
              name="articleNumber"
              value={articleId}
              setValue={setArticleId}
              label="M-nummer"
              disabled={disabled}
              className={styles.field}
              onKeyDown={saveOnEnter}
            />
            <CaseTextField
              name="productName"
              value={productName}
              setValue={setProductName}
              label="Produktnamn"
              disabled={disabled || !!articleId}
              className={styles.field}
              onKeyDown={saveOnEnter}
            />
            <CaseTextField
              name="productDescription"
              value={productDescription}
              setValue={setProductDescription}
              label="Produktbeskrivning"
              disabled={disabled || !!articleId}
              className={styles.field}
              onKeyDown={saveOnEnter}
            />
          </>
        )}
      </>
    );

    const articleImageComponent = (
      <>
        {!article && (
          <>
            <IconButton onClick={() => setModalIsVisible(true)} disabled={disabled}>
              <Search />
            </IconButton>
            <IconButton size="small" onClick={upsertArticle} disabled={saveIsDisabled}>
              <Add />
            </IconButton>
          </>
        )}
        {hasM3Article && fetchResultImage === FetchResult.Found && image && (
          <EnlargableImage image={image} title={productName} className={styles.avatar} />
        )}
        {hasM3Article && fetchResultImage === FetchResult.NotFound && (
          <Tooltip title="Artikeln har ingen bild kopplad">
            <NoPhotography />
          </Tooltip>
        )}
        {article && !hasM3Article && (
          <Tooltip title="Artikeln kunde inte hittas">
            <QuestionMark />
          </Tooltip>
        )}
      </>
    );

    const articleInformationButtonComponent = (
      <>
        {newArticle && (
          <>
            <IconButton size="small" onClick={toggleArticleInfo}>
              <Info />
            </IconButton>
          </>
        )}
      </>
    );

    const articleInformationComponent = <>{articleInfoOpen && newArticle && <CaseArticleInfo article={newArticle} />}</>;

    const articleQuantityComponent = articleReadOnly ? (
      <>Antal: {quantity}</>
    ) : (
      article && (
        <div className={styles.buttonContainer}>
          <TextField
            name="quantity"
            type="number"
            value={quantity}
            onChange={onChangeQuantity}
            label="Antal"
            disabled={disabled}
            className={styles.shortField}
            // select all text when focused
            onFocus={e => e.target.select()}
          />
          <div className={styles.spacer} />
          <IconButton size="small" onClick={upsertArticle} disabled={saveIsDisabled}>
            <Done />
          </IconButton>
          <IconButton size="small" onClick={deleteArticle} disabled={disabled}>
            <Delete />
          </IconButton>
        </div>
      )
    );

    const articleEditComponent = (
      <>
        {articleReadOnly && article && (
          <IconButton size="small" onClick={() => setEditArticle(article.id)} disabled={disabled}>
            <Edit />
          </IconButton>
        )}
        {!articleReadOnly && article && (
          <IconButton size="small" onClick={closeEditMode} disabled={disabled}>
            <Close />
          </IconButton>
        )}
      </>
    );

    return (
      <>
        <Paper variant="outlined" className={styles.articleCard}>
          <div className={styles.article}>
            <div className={styles.articleOverviewEdit}>
              <div className={styles.articleData}>{articleDataComponent}</div>
              <div className={styles.articleEditRow}>
                <div className={styles.articleQuantity}>{articleQuantityComponent}</div>
                <div>{articleInformationButtonComponent}</div>
                <div>{articleEditComponent}</div>
              </div>
            </div>
            <div className={styles.articleImage}>{articleImageComponent}</div>
          </div>
          {articleInformationComponent}
        </Paper>
        {renderExtraInfo && renderDeliveryStatus && (
          <>
            {articleReadOnly ? (
              DeliveryStatusConverter.ToName(deliveryStatus)
            ) : (
              <>
                <InputLabel shrink>Leveransstatus</InputLabel>
                <Select value={deliveryStatus} onChange={handleDeliveryStatusUpdate} className={styles.field} disabled={disabled}>
                  {deliveryStatusValues}
                </Select>
              </>
            )}
          </>
        )}
      </>
    );
  };

  const editableFinalRow = canAddArticles ? (
    <Box className={styles.fullWidthArticle}>
      <CaseEditArticleForm disabled={editArticle != null} />
    </Box>
  ) : (
    <div className={styles.row}>Maximalt antal {pluralVerb} tillagda i beställningen.</div>
  );

  const finalRow = !isReadOnly ? (
    editableFinalRow
  ) : (
    <div>{isNewCase ? `Du kan lägga till ${pluralVerb} efter beställningen är sparad` : `Inga ${pluralVerb} tillagda`}</div>
  );

  return (
    <Box className={styles.articles}>
      {renderExtraInfo && renderDeliveryStatus && <div>Leveransstatus</div>}

      {articles?.map((article, index) => (
        <CaseEditArticleForm key={index} article={article} articleReadOnly={editArticle !== article.id} />
      ))}
      {finalRow}
      <M3SearchModal selectMultiple onSave={addBatchArticles} isVisible={modalIsVisible} onClose={() => setModalIsVisible(false)} />
    </Box>
  );
};
