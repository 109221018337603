import React, { useEffect } from 'react';

import { ThemeProvider, StyledEngineProvider, createTheme, ThemeOptions } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { useSnackbar } from 'notistack';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import './App.css';
import styles from './App.module.css';

import { initialiseApp, toastConsumed } from './appSlice';
import { ErrorNotifier } from './features/alerts/ErrorNotifier';
import { useAppDispatch, useAppSelector } from './hooks/useAppRedux';

export type AppProps = {
  router: ReturnType<typeof createBrowserRouter>;
};

const App: React.FC<AppProps> = ({ router }: AppProps) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const useDarkMode = useAppSelector(state => state.app.useDarkMode);
  const isInitialized = useAppSelector(state => state.app.isInitialized);
  const toast = useAppSelector(state => state.app.toast);

  useEffect(() => {
    if (!isInitialized) dispatch(initialiseApp());
  }, [dispatch, isInitialized]);

  useEffect(() => {
    if (toast) {
      const { message, variant, persist, preventDuplicate } = toast;
      enqueueSnackbar(message, { variant, persist, preventDuplicate });
      dispatch(toastConsumed());
    }
  }, [dispatch, toast, enqueueSnackbar]);

  if (!isInitialized) {
    return null;
  }

  const mode = useDarkMode ? 'dark' : 'light';
  const modeBasedContrastColour = useDarkMode ? '#DDD' : '#333';

  let main: string;
  let forceDarkHeader = false;
  if (window.location.hostname === 'localhost') {
    main = '#069';
  } else if (window.location.hostname.includes('test')) {
    main = '#ffa600';
  } else if (window.location.hostname.includes('qa')) {
    main = '#9d2135';
  } else {
    main = modeBasedContrastColour;
    forceDarkHeader = true;
  }

  const themeOptions: ThemeOptions = {
    components: {
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiFormControl: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: 'standard',
        },
      },
    },
    palette: {
      mode,
      primary: {
        main,
      },
      secondary: {
        main: '#e75125',
      },
    },
  };

  if (forceDarkHeader) {
    themeOptions.palette = {
      ...themeOptions.palette,
      header: {
        main: '#000',
        contrastText: '#FFF',
      },
    };
  }

  const theme = createTheme(themeOptions);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <div className={`root ${useDarkMode ? styles.darkMode : styles.lightMode}`}>
          <CssBaseline />
          <RouterProvider router={router} />
          <ErrorNotifier />
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export const Routes = {
  Images: '/images',
  Login: '/login',
  Cases: '/cases',
  EditCase: '/case/:id',
  NewCase: '/new-case/:typeSlug',
  CalendarPlanning: '/calendar-planning',
  KanbanPlanning: '/kanban-planning',
  ProductContextualKanbanPlanning: '/product-context-kanban-planning',
  Clipbooks: '/clipbooks',
  Splat: '*',
};

export const QueryParam = {
  RedirectTo: 'redirect_uri',
  Email: 'email',
  StartDate: 'startDate',
};

export default App;
