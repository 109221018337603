import { FullScreenDialog } from '../../commonComponents/FullScreenDialog';
import { useAppDispatch, useAppSelector } from '../../hooks/useAppRedux';
import { closeZoomedImage } from './imagesSlice';

export function ImageFullScreenDisplay() {
  const dispatch = useAppDispatch();
  const zoomedImage = useAppSelector(state => state.images.zoomedImage);

  return (
    <FullScreenDialog
      alt={zoomedImage?.fileName}
      item={zoomedImage}
      open={zoomedImage != null}
      onClose={() => dispatch(closeZoomedImage())}
    />
  );
}
