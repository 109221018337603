import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { Settings } from '@mui/icons-material';

import styles from './AdminPanel.module.css';

import { SystemTags } from '../tags/SystemTags';
import { ProjectTags } from '../tags/ProjectTags';
import { CaseDialog, CaseDialogProps } from '../cases/layoutComponents/CaseDialog';
import { useAppSelector } from '../../hooks/useAppRedux';

enum TabId {
  None,
  SystemTags,
  UserTags,
  ProjectTags,
}

interface TabInfo {
  tabId: TabId;
  title: string;
  component: React.ReactNode;
}

const tabs: TabInfo[] = [
  {
    tabId: TabId.SystemTags,
    title: 'Systemtaggar',
    component: <SystemTags />,
  },
  {
    tabId: TabId.ProjectTags,
    title: 'Projekttaggar',
    component: <ProjectTags />,
  },
];

export const AdminPanel: React.FC<CaseDialogProps> = ({ ...modalProps }) => {
  const [selectedTab, setSelectedTab] = useState(TabId.SystemTags);

  const version = useAppSelector(state => state.app.version);

  const handleChange = (_: any, newValue: any) => {
    setSelectedTab(newValue as TabId);
  };

  const activeTabInfo = tabs.find(tabInfo => tabInfo.tabId === selectedTab);

  return (
    <CaseDialog {...modalProps}>
      <Box className={styles.container}>
        <Box className={styles.heading}>
          <Box className={styles.headingWithIcon}>
            <Settings />
            <Typography variant="h5">Administration</Typography>
          </Box>
          <Typography variant="subtitle1">Version: {version}</Typography>
        </Box>
        <Box className={styles.body}>
          <Tabs className={styles.tabs} orientation="vertical" value={selectedTab} variant="scrollable" onChange={handleChange}>
            {tabs.map(tabInfo => (
              <Tab className={styles.tab} key={tabInfo.tabId} label={tabInfo.title} value={tabInfo.tabId} />
            ))}
          </Tabs>
          <Box className={styles.tabPane}>
            {activeTabInfo != null && (
              <>
                <Typography variant="h6">{activeTabInfo.title}</Typography>
                {activeTabInfo.component}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </CaseDialog>
  );
};
