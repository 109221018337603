import axios, { Canceler } from 'axios';
import { PaginatedResponse, ProjectTag, SystemTag, UserTag } from './caseApiTypes';

let cancelTokens: Record<string, Canceler | undefined> = {};

export async function searchSystemTags(query?: string, pageSize?: number): Promise<PaginatedResponse<SystemTag>> {
  return searchTags('system-tag', query, pageSize);
}

export async function searchUserTags(query?: string, pageSize?: number): Promise<PaginatedResponse<UserTag>> {
  return searchTags('user-tag', query, pageSize);
}

export async function searchProjectTags(query?: string, pageSize?: number): Promise<PaginatedResponse<ProjectTag>> {
  return searchTags('project-tag', query, pageSize);
}

export async function createSystemTag(tag: SystemTag): Promise<SystemTag> {
  const url = `/api/case/system-tag`;

  const { data } = await axios.post<SystemTag>(url, tag);

  return data;
}

export async function createUserTag(tag: UserTag): Promise<UserTag> {
  const url = `/api/case/user-tag`;

  const { data } = await axios.post<UserTag>(url, tag);

  return data;
}

export async function createProjectTag(tag: ProjectTag): Promise<ProjectTag> {
  const url = `/api/case/project-tag`;

  const { data } = await axios.post<ProjectTag>(url, tag);

  return data;
}

export async function updateSystemTag(tag: SystemTag): Promise<SystemTag> {
  const url = `/api/case/system-tag/${tag.id}`;

  const { data } = await axios.put<SystemTag>(url, tag);

  return data;
}

export async function updateUserTag(tag: UserTag): Promise<UserTag> {
  const url = `/api/case/user-tag/${tag.id}`;

  const { data } = await axios.put<UserTag>(url, tag);

  return data;
}

export async function updateProjectTag(tag: ProjectTag): Promise<ProjectTag> {
  const url = `/api/case/project-tag/${tag.id}`;

  const { data } = await axios.put<ProjectTag>(url, tag);

  return data;
}

export async function deleteSystemTag(id: string): Promise<void> {
  await axios.delete(`/api/case/system-tag/${id}`);
}

export async function deleteUserTag(id: string): Promise<void> {
  await axios.delete(`/api/case/user-tag/${id}`);
}

export async function deleteProjectTag(id: string): Promise<void> {
  await axios.delete(`/api/case/project-tag/${id}`);
}

type TagSlug = `${'user' | 'system' | 'project'}-tag`;

async function searchTags<T>(context: TagSlug, query?: string, pageSize?: number): Promise<T> {
  const cancel = cancelTokens[context];
  if (cancel) {
    cancel();
  }

  const cancelToken = new axios.CancelToken(c => (cancelTokens[context] = c));

  const url = `/api/case/${context}/search?pageSize=${pageSize || '1000'}${query ? `&query={query}` : ''}`;

  const { data } = await axios.get<T>(url, { cancelToken });

  cancelTokens[context] = undefined;

  return data;
}
