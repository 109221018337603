export function downloadFile(uri: string, name: string, onFail: () => void, onSuccess: (name: string) => void) {
  fetch(uri)
    .then(resp => resp.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      onSuccess(name);
    })
    .catch(onFail);
}
