import React, { useEffect, useState } from 'react';

import { Delete, ExpandLess, ExpandMore, FilterAlt } from '@mui/icons-material';
import { Badge, IconButton, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import styles from './FilterPanel.module.css';

import { FilterableItem, FilterPropertyRecord, FilterState } from '../../api/filterTypes';
import { setFilterState } from '../../appSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/useAppRedux';
import { SearchContext } from '../../hooks/useSearchState';
import { LocalStorageKeys, useLocalStorage } from '../../hooks/useStorage';
import { FilterComponent } from './filter/FilterComponent';
import { caseFilterConfiguration } from './filter/configs/caseFilterConfiguration';
import { imageFilterConfiguration } from './filter/configs/imageFilterConfiguration';
import { planningCalendarFilterConfiguration } from './filter/configs/planningCalendarFilterConfiguration';
import { caseKanbanFilterConfiguration } from './filter/configs/caseKanbanFilterConfiguration';

export const FilterPanel = () => {
  const dispatch = useAppDispatch();
  const { activeFilterConsumers } = useAppSelector(state => state.app);

  const activeFilters = Object.typedEntries(activeFilterConsumers)
    .filter(([_, value]) => value.length > 0)
    .map(([key, _]) => key);

  const [searchFilterState, setSearchFilterState] = useLocalStorage<Record<string, FilterState<FilterableItem>>>(
    LocalStorageKeys.sidePanelFilterState,
    {},
  );

  useEffect(() => {
    dispatch(setFilterState(searchFilterState));
  }, [dispatch, searchFilterState]);

  const stateSetter = (key: string) => (newFilterState: FilterState<FilterableItem>) => {
    const state = { ...searchFilterState };
    state[key] = newFilterState;
    setSearchFilterState(state);
  };

  // If the active filter is not in the searchFilterState, add it
  activeFilters.forEach((filter, index) => {
    const filterState = searchFilterState[filter];
    if (filterState == null) {
      stateSetter(filter)({});
      return null;
    }
  });

  // If the filterState stored in local storage is not compatible with the current filter configuration, reset those properties
  const incompatibleFilters = Object.typedKeys(searchFilterState).filter(key => !(key in SearchContext));
  if (incompatibleFilters.length > 0) {
    const newState = { ...searchFilterState };
    incompatibleFilters.forEach(key => delete newState[key]);
    setSearchFilterState(newState);
    return null;
  }

  const filter = activeFilters
    .filter(filter => Object.keys(AvailableFilters).includes(filter))
    .map(filterKey => (
      <Filter key={filterKey} filterKey={filterKey} stateSetter={stateSetter(filterKey)} filterState={searchFilterState[filterKey]} />
    ));

  return <>{filter}</>;
};

interface LocalFilterPanelProps extends FilterStateProps {
  searchContext: SearchContext;
}

export const LocalFilterPanel: React.FC<LocalFilterPanelProps> = ({ searchContext, ...rest }) => (
  <Filter filterKey={SearchContext[searchContext]} {...rest} />
);

interface AvailableFilter<T extends FilterableItem> {
  configuration: FilterPropertyRecord<T>;
  filterName?: string;
}

const AvailableFilters: Record<string, AvailableFilter<any>> = {
  [SearchContext[SearchContext.ImagesGrid]]: {
    configuration: imageFilterConfiguration,
    filterName: 'Bilder',
  },

  [SearchContext[SearchContext.AllCasesList]]: {
    filterName: 'Beställningar',
    configuration: caseFilterConfiguration,
  },
  [SearchContext[SearchContext.PlanningCalendar]]: {
    filterName: 'Kalendervyn',
    configuration: planningCalendarFilterConfiguration,
  },
  [SearchContext[SearchContext.RetouchImagePicker]]: {
    configuration: imageFilterConfiguration,
  },
  [SearchContext[SearchContext.PlanningKanban]]: {
    filterName: 'CaseKanban',
    configuration: caseKanbanFilterConfiguration,
  },
};

interface FilterStateProps {
  filterState: FilterState<FilterableItem>;
  stateSetter: (newState: FilterState<FilterableItem>) => void;
}

interface Props extends FilterStateProps {
  filterKey: string;
}

const Filter: React.FC<Props> = ({ filterKey, filterState, stateSetter }) => {
  const [expanded, setExpanded] = useState(true);
  const { filterName, configuration } = AvailableFilters[filterKey];

  // If the active filter is not in the searchFilterState, add it
  if (filterState == null) {
    stateSetter({});
    return null;
  }

  // If the filterProperty stored in local storage is not compatible with the current filter configuration, reset those properties
  const activeFilters = Object.typedKeys(configuration);
  const incompatibleFilters = Object.typedKeys(filterState).filter(key => !activeFilters.includes(key));
  if (incompatibleFilters.length > 0) {
    const newState = { ...filterState };
    incompatibleFilters.forEach(key => delete newState[key]);
    stateSetter(newState);
    return null;
  }

  const filterCount = Object.values(filterState)
    .flatMap(x => x.value)
    .filter(filterOption => filterOption !== '').length;

  const hasTitle = filterName != null;
  const onClearFilter = (e: React.MouseEvent) => {
    e.stopPropagation();
    stateSetter({});
  };

  return (
    <>
      {hasTitle && (
        <ListItemButton dense onClick={() => setExpanded(!expanded)}>
          <ListItemIcon sx={{}}>
            <FilterAlt />
          </ListItemIcon>
          <ListItemText disableTypography className={styles.filterHeader} primary={'Filter'} />
          {expanded ? <ExpandLess /> : <ExpandMore />}
          {filterCount !== 0 && (
            <IconButton onClick={e => onClearFilter(e)}>
              <Badge badgeContent={filterCount} color="primary">
                <Delete />
              </Badge>
            </IconButton>
          )}
        </ListItemButton>
      )}
      {expanded && <FilterComponent filterState={filterState} setFilterState={stateSetter} filterConfig={configuration} />}
    </>
  );
};
