import { Fragment } from 'react';
import { Delete } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

import { Image } from '../../../api/caseApiTypes';

export type RemoveImageButtonProps = {
  image: Image | undefined;
  canRemove: boolean;
  onRemove: (() => void) | undefined;
};

export function RemoveImageButton({ image, canRemove, onRemove }: RemoveImageButtonProps) {
  if (!canRemove) {
    return <Fragment />;
  }

  const onClickOnDelete = () => {
    if (window.confirm(`Är du säker på att du vill ta bort denna bild?`)) {
      onRemove && image && onRemove();
    }
  };

  const isDisabled = !image || !onRemove;

  const icon = (
    <IconButton color="primary" aria-label="settings" size="small" disabled={isDisabled} onClick={onClickOnDelete}>
      <Delete />
    </IconButton>
  );

  if (isDisabled) {
    return icon;
  }

  return <Tooltip title="Ta bort bild">{icon}</Tooltip>;
}
