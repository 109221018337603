import React from 'react';
import { Box, Typography } from '@mui/material';

import { Case } from '../../api/caseApiTypes';

interface Props {
  item: Case;
}

export const ArticleIdsList: React.FC<Props> = ({ item }) => {
  const additionalArticleIds = item?.articles?.map(article => article.articleId) ?? [];
  const ids = additionalArticleIds.filter(id => id);
  const firstId = ids[0];
  const rest = ids.slice(1);

  if (!firstId) {
    return <Box />;
  }

  return (
    <Box>
      <Typography variant="caption">{firstId}</Typography>
      {rest.map(id => (
        <span key={id}>
          <span>, </span>
          <Typography variant="caption">{id}</Typography>
        </span>
      ))}
    </Box>
  );
};
