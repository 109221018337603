import React, { useState } from 'react';
import { useAppDispatch } from '../../hooks/useAppRedux';

import styles from './CaseTags.module.css';

import { Box, Chip } from '@mui/material';
import { Add, Folder } from '@mui/icons-material';

import { Case, ProjectTag, SystemTag, UserTag } from '../../api/caseApiTypes';
import { SelectSystemTagModal } from '../tags/SelectSystemTagModal';
import { addSystemTags, clearProjectTag, removeSystemTag, removeUserTag, setProjectTag } from '../cases/caseDetailSlice';
import { ColorTag } from '../tags/ColorTag';
import { SelectableUserTag } from '../tags/SelectableUserTag';
import { SelectProjectTagModal } from '../tags/SelectProjectTagModal';

type NewTagType = `${'System' | 'User' | 'Project'}Tag`;

interface Props {
  caze: Case;
  className?: string;
  size?: 'small' | 'medium';
  readonly?: boolean;
}

export const CaseTags = ({ caze, className, size, readonly }: Props) => {
  const dispatch = useAppDispatch();
  const [newTagType, setNewTagType] = useState<NewTagType | null>(null);

  const onModalClose = () => {
    setNewTagType(null);
  };

  const onSaveSystemTags = (systemTags: SystemTag[]) => {
    onModalClose();

    if (systemTags.length > 0) {
      dispatch(addSystemTags({ item: caze, systemTags }));
    }
  };

  const onDeleteSystemTag = (systemTag: SystemTag) => {
    dispatch(removeSystemTag({ item: caze, systemTag }));
  };

  const onDeleteUserTag = (userTag: UserTag) => {
    dispatch(removeUserTag({ item: caze, userTag }));
  };

  const onSetProjectTag = (projectTag: ProjectTag | undefined) => {
    onModalClose();

    if (projectTag == null) {
      dispatch(clearProjectTag(caze));
    } else {
      dispatch(setProjectTag({ item: caze, projectTag }));
    }
  };

  const onClearProjectTag = () => {
    dispatch(clearProjectTag(caze));
  };

  return (
    <div className={className}>
      <div className={styles.container}>
        {caze.projectTag != null && (
          <ColorTag hex={caze.projectTag.color} label={caze.projectTag.name} onDelete={onClearProjectTag} size={size} />
        )}
        {caze.systemTags?.map((tag, index) =>
          readonly ? (
            <ColorTag key={index} hex={tag.color} label={tag.name} size={size} />
          ) : (
            <ColorTag key={index} hex={tag.color} label={tag.name} size={size} onDelete={() => onDeleteSystemTag(tag)} />
          ),
        )}
        {caze.userTags?.map((tag, index) =>
          readonly ? (
            <SelectableUserTag key={index} userTag={tag} size={size} />
          ) : (
            <SelectableUserTag key={index} userTag={tag} size={size} onDelete={() => onDeleteUserTag(tag)} />
          ),
        )}
        {!readonly && (
          <>
            <Box className={styles.newTag}>
              <Chip
                color="primary"
                icon={<Folder />}
                className={styles.create}
                size={size}
                label={caze.projectTag ? 'Ändra projekt' : 'Välj projekt'}
                onClick={() => setNewTagType('ProjectTag')}
              />
              <SelectProjectTagModal open={newTagType === 'ProjectTag'} onClose={onModalClose} onSave={onSetProjectTag} />

              <Chip
                color="primary"
                icon={<Add />}
                className={styles.create}
                size={size}
                label="Ny tagg"
                onClick={() => setNewTagType('SystemTag')}
              />
              <SelectSystemTagModal open={newTagType === 'SystemTag'} onClose={onModalClose} onSave={onSaveSystemTags} />
            </Box>
          </>
        )}
      </div>
    </div>
  );
};
