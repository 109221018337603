import React from 'react';
import { CaseTextField } from './CaseTextField';
import { MediaValue, getMediaValue } from './MediaValue';

interface Props {
  description?: MediaValue<string | undefined>[];
  onChange: (value: string) => void;
  minRows?: number;
  readonly?: boolean;
  label?: string;
}

export const CaseProductDescriptionField: React.FC<Props> = ({ description, onChange, minRows = 8, readonly, label = 'Beskrivning' }) => {
  return (
    <CaseTextField
      variant="outlined"
      multiline
      label={label}
      name="description"
      minRows={minRows}
      value={getMediaValue(description, '', 'Blandade värden')}
      setValue={onChange}
      disabled={readonly}
    />
  );
};
