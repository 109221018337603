import axios, { AxiosProgressEvent } from 'axios';
import * as tus from 'tus-js-client';

function getCookie(name?: string) {
  if (!name) {
    return '';
  }

  const cookies = document.cookie.split(';');
  for (let cookie of cookies) {
    cookie = cookie.trim();
    if (cookie.startsWith(name + '=')) {
      return cookie.substring(cookie.indexOf('=') + 1);
    }
  }
  return '';
}

function toAxiosProgress(uploaded: number, total: number): AxiosProgressEvent {
  return {
    lengthComputable: true,
    bytes: 0,
    loaded: uploaded,
    total: total,
    progress: uploaded / total,
  };
}

export async function uploadFiles(
  files: File[],
  operationName: string,
  metadata: { [key: string]: string },
  onUploadProgress?: (event: AxiosProgressEvent, file: File) => void,
): Promise<void> {
  const uploads = files.map(async file => {
    const uploadPromise = new Promise<void>(async (onSuccess, onError) => {
      const upload = new tus.Upload(file, {
        endpoint: '/api/upload-file',
        headers: {
          // Add axios XSRF cookie
          [axios.defaults.xsrfHeaderName!]: getCookie(axios.defaults.xsrfCookieName),
        },
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
          filename: file.name,
          contentType: file.type,
          operationName,
          ...metadata,
        },
        storeFingerprintForResuming: true,
        removeFingerprintOnSuccess: true,
        // CloudFlare limits us at 100MB
        chunkSize: 99 * 1024 * 1024,
        onProgress: onUploadProgress ? (uploaded, total) => onUploadProgress(toAxiosProgress(uploaded, total), file) : undefined,
        onSuccess,
        onError,
      });
      const previousUploads = await upload.findPreviousUploads();
      for (let previousUpload of previousUploads) {
        upload.resumeFromPreviousUpload(previousUpload);
      }
      upload.start();
    });
    return uploadPromise;
  });

  await Promise.allSettled(uploads);
}
