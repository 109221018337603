import { DragMetaInput } from '@fullcalendar/core/internal';
import { Draggable } from '@fullcalendar/interaction';

export const MakePlannable = (className: string, eventDataGenerator: (id: string) => DragMetaInput) => {
  const elements = Array.from(document.getElementsByClassName(className));
  elements.forEach(elem => {
    const draggableAttribute = 'isDraggable';
    if (elem.hasAttribute(draggableAttribute)) {
      return;
    }

    elem.setAttribute(draggableAttribute, draggableAttribute);

    new Draggable(elem as HTMLElement, {
      eventData: eventDataGenerator(elem.id),
    });
  });
};
