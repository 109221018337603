export function replaceNbsps(str: string) {
  var regExp = new RegExp(String.fromCharCode(160), "g");
  return str.replace(regExp, " ");
}

// Original code comes from Option 3 in https://stackoverflow.com/a/27747377
function dec2hex(dec: number) {
  return dec.toString(16).padStart(2, '0');
}
export const generateRandomString = (len: number = 20) => {
    const arr = new Uint8Array(len / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join('');
};
