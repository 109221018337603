import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as Api from '../../api/m3Api';
import { M3Article, M3SearchResult, M3SearchQuery } from '../../api/m3Api';
import { ThunkConfig } from '../../app/store';
import { areListsEqual } from '../../helpers/ListHelpers';

export enum ArticleStatus {
  Loading = 1,
  NotFound = 2,
}

interface M3State {
  query: string;
  queryWithFields: M3SearchQuery[];
  pageSize: number;
  totalHits: number;
  error: string | null;
  isSearching: boolean;
  m3Articles: M3Article[];
  m3ArticlesById: Record<string, M3Article | ArticleStatus>;
}

const initialState: M3State = {
  query: '',
  queryWithFields: [],
  pageSize: 0,
  totalHits: 0,
  error: null,
  isSearching: false,
  m3Articles: [],
  m3ArticlesById: {},
};

export const m3Slice = createSlice({
  name: 'm3',
  initialState,
  reducers: {
    startSearching(state) {
      state.isSearching = true;
    },
    searchSuccess(state, action: PayloadAction<{ result: M3SearchResult }>) {
      const { variants, token, hits, pageSize } = action.payload.result;

      const continuation = token ?? 0;
      for (const m3Article of action.payload.result.variants) {
        if (m3Article.m3Id) {
          state.m3ArticlesById[m3Article.m3Id.toUpperCase()] = m3Article;
          state.m3ArticlesById[m3Article.m3Id.toLowerCase()] = m3Article;
        }
      }

      if (continuation === 0) {
        state.m3Articles = variants;
      } else {
        for (let i = 0; i < variants.length; i++) {
          state.m3Articles[continuation + i] = variants[i];
        }
      }

      state.pageSize = pageSize;
      state.isSearching = false;
      state.totalHits = hits + continuation;
      if (hits > pageSize || hits === 0) {
        state.totalHits = hits + continuation;
      } else {
        state.totalHits = hits + continuation + 1;
      }
    },
    searchFailed(state, action: PayloadAction<string>) {
      state.isSearching = false;
      state.m3Articles = [];
      state.error = action.payload;
    },
    resetResults(state, action: PayloadAction<{ query?: string; queryWithFields?: M3SearchQuery[] }>) {
      state.m3Articles = [];
      state.error = null;
      state.query = action.payload.query ?? '';
      state.queryWithFields = action.payload.queryWithFields ?? [];
      state.totalHits = 0;
      state.pageSize = 0;
    },
    getM3Articles(state, action: PayloadAction<string[]>) {
      state.isSearching = true;
      for (const id of action.payload) {
        state.m3ArticlesById[id.toUpperCase()] = ArticleStatus.Loading;
        state.m3ArticlesById[id.toLowerCase()] = ArticleStatus.Loading;
      }
    },
    getM3ArticlesSuccess(state, action: PayloadAction<M3Article[]>) {
      state.isSearching = false;
      for (const variant of action.payload) {
        if (variant.m3Id) {
          state.m3ArticlesById[variant.m3Id.toLowerCase()] = variant;
          state.m3ArticlesById[variant.m3Id.toUpperCase()] = variant;
        }
      }
    },
  },
});

export const { startSearching, searchSuccess, searchFailed, resetResults, getM3ArticlesSuccess, getM3Articles } = m3Slice.actions;

export const searchWithFields = createAsyncThunk<void, { queries: M3SearchQuery[]; continuation?: number; pageSize?: number }, ThunkConfig>(
  'cases/querySearch',
  async ({ queries, continuation, pageSize }, { dispatch, getState }) => {
    const newQueries = queries.filter(q => q.value);

    try {
      if (!areListsEqual(newQueries, getState().m3.queryWithFields)) {
        dispatch(resetResults({ queryWithFields: newQueries }));
      }

      if (newQueries.length > 0) {
        dispatch(startSearching());

        const result = await Api.searchWithFilters({ queries: newQueries, continuation, pageSize });

        dispatch(searchSuccess({ result }));
      }
    } catch (error: any) {
      await dispatch(searchFailed(error.toString()));
    }
  },
);

export const freeTextSearch = createAsyncThunk<void, string, ThunkConfig>('cases/freeTextSearch', async (query, { dispatch, getState }) => {
  try {
    if (query !== getState().m3.query) {
      dispatch(resetResults({ query: query }));
    }

    if (query) {
      dispatch(startSearching());

      const result = await Api.freeTextSearch(query);

      dispatch(searchSuccess({ result }));
    }
  } catch (error: any) {
    await dispatch(searchFailed(error.toString()));
  }
});

export const getByM3Ids = createAsyncThunk<void, string[], ThunkConfig>('cases/search', async (ids, { dispatch, getState }) => {
  try {
    const { m3 } = getState();
    // Remove empty entries and ids we already have loaded
    ids = ids.filter(id => id.length > 0 && m3.m3ArticlesById[id] === undefined);
    if (ids.length === 0) {
      return;
    }

    dispatch(getM3Articles(ids));

    const result = await Api.getByM3Ids(ids);

    dispatch(getM3ArticlesSuccess(result));
  } catch (error: any) {
    dispatch(searchFailed(error.toString()));
  }
});

export default m3Slice.reducer;
