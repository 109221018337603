import { Checkbox, FormControlLabel, IconButton, MenuItem, Paper, TextField } from '@mui/material';
import styles from './ImagesSearchPanel.module.css';
import { SearchQuery } from '../../commonComponents/SearchQuery';
import { SortableImageField, SortableImageFields, SortableImageFieldsConverter } from '../../api/ImageApiTypes';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import React, { CSSProperties, Dispatch, SetStateAction } from 'react';

interface Props {
  style?: CSSProperties;
  className?: string;
  onSearch: (query: string) => void;
  isSearching: boolean;
  count?: number;
  searchOnlyFileName?: boolean;
  toggleSearchOnlyFileName?: () => void;
  orderBy?: string;
  setOrderBy?: Dispatch<SetStateAction<SortableImageField>>;
  orderDesc?: boolean;
  toggleOrderDesc?: () => void;
  additionalButtons?: React.ReactNode;
}

export const ImagesSearchPanel: React.FC<Props> = ({
  style,
  className,
  onSearch,
  isSearching,
  count,
  searchOnlyFileName,
  toggleSearchOnlyFileName,
  orderBy,
  setOrderBy,
  orderDesc,
  toggleOrderDesc,
  additionalButtons,
}) => {
  const renderButtons = React.Children.count(additionalButtons) > 0;

  const renderCount = count != null;
  const countLabel = `Antal träffar: ${count}`;

  const orderByComponent = () => {
    if (orderBy == null || setOrderBy == null || orderDesc == null || toggleOrderDesc == null) {
      return;
    }

    if (SortableImageFields.every(a => a !== orderBy)){
      setOrderBy(SortableImageFields[0]);
    }

    return (
      <div className={`${styles.sortButton} ${styles.row}`}>
        <TextField
          select
          fullWidth
          variant={'outlined'}
          size={'small'}
          value={orderBy}
          label={'Sortera'}
          onChange={value => setOrderBy(value.target.value as SortableImageField)}
        >
          {SortableImageFields.map(val => (
            <MenuItem key={val} value={val}>
              {SortableImageFieldsConverter.ToName(val)}
            </MenuItem>
          ))}
        </TextField>
        <IconButton onClick={toggleOrderDesc}>{orderDesc ? <ArrowDownward /> : <ArrowUpward />}</IconButton>
      </div>
    );
  };

  const searchOnlyFileNameComponent = () => {
    if (searchOnlyFileName == null || toggleSearchOnlyFileName == null) {
      return;
    }
    return (
      <FormControlLabel
        className={styles.zeroMargin}
        control={<Checkbox onChange={toggleSearchOnlyFileName} checked={searchOnlyFileName} />}
        label="Filnamn"
      />
    );
  };

  return (
    <Paper className={`${styles.searchPanel} ${className}`} style={style} elevation={1}>
      <div className={`${styles.row} ${styles.gap}`}>
        <div className={styles.row}>
          <SearchQuery className={styles.query} onSearch={onSearch} isSearching={isSearching} />

          {searchOnlyFileNameComponent()}
        </div>

        {orderByComponent()}

        {renderCount && <div>{countLabel}</div>}
      </div>
      <div className={styles.row}>{renderButtons && additionalButtons}</div>
    </Paper>
  );
};
