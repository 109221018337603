import React from 'react';
import styles from './CaseFormButtonContainer.module.css';
import { Divider } from '@mui/material';

interface Props {
  children?: React.ReactNode;
  className?: string;
  renderDivider?: boolean;
}

export const CaseFormButtonContainer: React.FC<Props> = ({ renderDivider = false, className, children }) => {
  const classes = [styles.buttonContainer];
  if (className != null) {
    classes.push(className);
  }
  return (
    <>
      {renderDivider && <Divider className={styles.divider} />}
      <div className={classes.join(' ')}>{children}</div>
    </>
  );
};
