import React from 'react';
import { Case } from '../../api/caseApiTypes';
import { useAppDispatch } from '../../hooks/useAppRedux';
import styles from './CaseComments.module.css';
import { Comments } from '../comment/Comments';
import { addComment, deleteComment } from './caseDetailSlice';

interface Props {
  caze: Case;
}

export const CaseComments: React.FC<Props> = ({ caze }) => {
  const dispatch = useAppDispatch();

  return (
    <div className={styles.container}>
      <Comments
        comments={caze?.comments}
        onAdd={comment => dispatch(addComment({ item: caze, comment }))}
        onDelete={commentId => dispatch(deleteComment({ item: caze, commentId }))}
      />
    </div>
  );
};
