import { OpenInNew, Cancel, Upgrade } from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import { Typography, Tabs, Tab, Box, CircularProgress, Link } from '@mui/material';
import { ColoredTag, Image, ImageOrigin } from '../../../../api/caseApiTypes';
import { DropzoneCallback, DropzoneWithProgress } from '../../../../commonComponents/DropzoneWithProgress';
import { MediaVisualizer } from '../../../../commonComponents/MediaVisualizer';
import { HasThumbnail, HasPreviewVideo } from '../../../../helpers/DerivedImageHelpers';
import { ByCreatedAt } from '../../../../helpers/Sorting';
import { CaseDialog } from '../../../cases/layoutComponents/CaseDialog';
import { ImagesGridDetailsEnrichmentView } from '../ImagesGridDetailsEnrichmentView';
import { ImagesGridDetails3DSpecificationsView, ImagesGridDetailsPIMSpecificationsView } from '../ImagesGridDetailsSpecificationsView';
import { uploadVersion } from '../../imagesSlice';
import { Link as RouterLink } from 'react-router-dom';
import { useState } from 'react';
import { UploadProgressHandlerForFile } from '../../../../api/commonApiTypes';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useAppRedux';
import { ColorTag } from '../../../tags/ColorTag';
import { ImagesGridDetailsOriginChip } from '../ImagesGridDetailsOriginChip';
import { ImagesGridDetails } from '../ImagesGridDetails';
import { Right } from '../../../../api/userApi';
import { useRights } from '../../../../hooks/useRights';
import { FullScreenDialog } from '../../../../commonComponents/FullScreenDialog';

import styles from './SingleMediaPreview.module.css';
import { useArticle } from '../../../../hooks/useArticles';

export type Props = {
  media: Image;
};

export function SingleMediaPreview({ media }: Props) {
  const dispatch = useAppDispatch();
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const fileUploadInProgress = useAppSelector(state => state.images.fileUploadInProgress);
  const [selectedTab, setSelectedTab] = useState('0');
  const [files, setFiles] = useState<File[]>([]);
  const [onUploadProgress, setOnUploadProgress] = useState<{ handler?: UploadProgressHandlerForFile }>({});
  const [dropFileActionDialogVisible, setDropFileActionDialogVisible] = useState(false);
  const hasImageEditingAccess = useRights(Right.ManageImage);
  const mainArticle = useArticle({ articleId: media.articleId ?? '' });

  const activeVersion = media.versions[media.activeVersion];

  const getLinkToCase = ({ originIdentifier, originSystem }: ImageOrigin, text = 'Öppna länk till beställning i ny flik'): JSX.Element => {
    if (originSystem !== 'Case') {
      return <></>;
    }

    return (
      <Link target={'_blank'} component={RouterLink} to={`/case/${originIdentifier}`} sx={{ ml: '4px', mr: '4px' }}>
        <Typography variant="subtitle1" component="span">
          {text}
        </Typography>
        <OpenInNew />
      </Link>
    );
  };

  const firstVersion = Object.values(media.versions)
    .slice(0, 1)
    .find(v => v.origin.originSystem === 'Case');

  const lastRetouchedVersion = Object.values(media.versions)
    .slice(1)
    .sort(ByCreatedAt('desc'))
    .find(v => v.origin.originSystem === 'Case' && v.origin.originIdentifier !== firstVersion?.origin.originIdentifier);

  const actions = [
    firstVersion ? getLinkToCase(firstVersion.origin, 'Originalbeställning') : <></>,
    lastRetouchedVersion ? getLinkToCase(lastRetouchedVersion.origin, 'Retuschbeställning') : <></>,
  ];

  const showSpinner = !HasThumbnail(activeVersion, 1920) && !HasPreviewVideo(activeVersion);

  const hasSpecifications = (media.specifications?.length ?? 0) > 0 || mainArticle.article !== null;
  const pimSpecification = media.specifications?.find(s => s.id === 'PIM')?.items;
  const hasPIMTab = pimSpecification?.length || mainArticle.article;
  const portal3DSpecification = media.specifications?.find(s => s.id === '3DPortal')?.items;
  const has3DPortalTab = portal3DSpecification?.length;

  if ((!hasPIMTab && selectedTab === '1') || (!has3DPortalTab && selectedTab === '2')) {
    setSelectedTab('0');
  }

  const dataView = hasSpecifications ? (
    <TabContext value={selectedTab}>
      <Tabs
        value={selectedTab}
        onChange={(_e, tab) => setSelectedTab(tab)}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label="Berikning" value="0" />
        {hasPIMTab && <Tab label="PIM" value="1" />}
        {has3DPortalTab && <Tab label="3D Portal" value="2" />}
      </Tabs>
      <TabPanel value="0">
        <ImagesGridDetailsEnrichmentView image={media} />
      </TabPanel>
      <TabPanel value="1">
        <ImagesGridDetailsPIMSpecificationsView specifications={pimSpecification} article={mainArticle.article} type={media.type} />
      </TabPanel>
      <TabPanel value="2">
        <ImagesGridDetails3DSpecificationsView specifications={portal3DSpecification} />
      </TabPanel>
    </TabContext>
  ) : (
    <ImagesGridDetailsEnrichmentView image={media} />
  );

  const tagsToRender = ([media.projectTag].filter(t => t != null) as ColoredTag[]).concat(media.systemTags);

  const onUploadVersion = () => {
    setDropFileActionDialogVisible(false);
    if (media == null || files == null || !onUploadProgress?.handler || files.length !== 1) {
      return;
    }

    dispatch(uploadVersion({ image: media, previousVersion: activeVersion, files, onUploadProgress: onUploadProgress.handler }));
  };

  const onDrop: DropzoneCallback = (files, onUploadProgress) => {
    setFiles(files);
    setOnUploadProgress({ handler: onUploadProgress });
    setDropFileActionDialogVisible(true);
  };

  const closeDropFileActionDialog = () => {
    setFiles([]);
    setOnUploadProgress({});
    setDropFileActionDialogVisible(false);
  };

  const dropFileActionDialog = (
    <CaseDialog
      title="Bekräfta ersättning av bild"
      isVisible={dropFileActionDialogVisible}
      closeLabel="Avbryt"
      CloseIcon={Cancel}
      saveLabel="Ersätt"
      SaveIcon={Upgrade}
      onClose={closeDropFileActionDialog}
      onSave={onUploadVersion}
    />
  );

  const thumbnailMedia = (
    <Box className={styles.thumbnail}>
      {showSpinner ? (
        <CircularProgress />
      ) : (
        <div onClick={() => setIsFullScreen(true)}>
          <MediaVisualizer alt={activeVersion.fileName} item={activeVersion} size="small" />
        </div>
      )}
    </Box>
  );

  const thumbnail = (
    <div className={styles.thumbnailWrapper}>
      {!hasImageEditingAccess ? (
        thumbnailMedia
      ) : (
        <DropzoneWithProgress onDrop={onDrop} singleFile noClick fileUploadInProgress={fileUploadInProgress}>
          {thumbnailMedia}
        </DropzoneWithProgress>
      )}
    </div>
  );

  return (
    <>
      <ImagesGridDetails title="" headerItems={[]} actions={actions}>
        {thumbnail}
        <Box className={styles.productNameRow}>
          <Typography variant="h6">{media.productName}</Typography>
          <ImagesGridDetailsOriginChip origin={activeVersion.origin} />
        </Box>
        {media.productDescription && <Typography variant="subtitle1">{media.productDescription}</Typography>}

        <Box display="inline-block" width="100%">
          {tagsToRender.map(t => (
            <ColorTag hex={t.color} label={t.name} key={t.id} size="small" />
          ))}
        </Box>

        {dataView}
      </ImagesGridDetails>
      <FullScreenDialog alt={activeVersion.fileName} item={activeVersion} open={isFullScreen} onClose={() => setIsFullScreen(false)} />
      {hasImageEditingAccess && dropFileActionDialog}
    </>
  );
}
