import { Case } from '../../api/caseApiTypes';
import { compareAsc, parseISO } from 'date-fns';

/*
 * Calculates the priority to be set when case is prioritised at the start of an array
 * */
const calculatePriorityTop = (caze: Case, right: Case) => {
  // Avoid changing if not necessary
  if (caze.priority - right.priority > 1000) {
    return;
  }

  // Use timestamp as priority, add 1000 if necessary to keep large enough distance
  const newTs = new Date().getTime();
  return newTs - right.priority > 1000 ? newTs : newTs + 1000;
};

/*
 * Calculates the priority to be set when case is to be prioritised at the end of an array
 * */
const calculatePriorityBottom = (caze: Case, left: Case) => {
  // Avoid changing if not necessary
  if (left.priority - caze.priority > 1000) {
    return;
  }

  // Set priority to left neighbour priority - 1000
  return left.priority - 1000;
};

/*
 * Calculates the priority to set when case is to be placed between two cases
 * */
const calculatePriorityBetween = (caze: Case, left: Case, right: Case) => {
  const halfDistance = (left.priority - right.priority) / 2;
  const leftDistance = left.priority - caze.priority;
  const rightDistance = caze.priority - right.priority;

  // If sufficiently large gap between neighbours, and sufficiently far from both neighbours, don't change priority
  if (halfDistance > 1000 && leftDistance > 1000 && rightDistance > 1000) {
    return;
  }

  // Try to keep even numbers as long as possible
  const priorityOffset = halfDistance > 2 ? Math.ceil(halfDistance) : halfDistance;
  return right.priority + priorityOffset;
};

/*
 * Calculates the new priority to set for the case.
 *
 * We try to avoid changing priority if not necessary.
 *
 * Always try to keep at minimum distance 1000 between two cases priority.
 *
 * A distance of 1000 is deemed to be far enough away from neighbouring cases to allow
 * multiple worst-case-scenario prioritisation actions, but, since we are seeding priority
 * from millisecond precision timestamps, regular usage is likely to result in much larger
 * gaps without often triggering the edge cases.
 * */
export const CalculatePriority = (caze: Case, container: Case[], newPosition: number, oldPosition: number, sameArray: boolean) => {
  // Target array is empty, priority irrelevant
  if (container.length === 0) {
    return;
  }

  // If the case is placed in the same array as it originated, subtract one from comparison length
  const endPosition = sameArray ? container.length - 1 : container.length;

  switch (newPosition) {
    // Placed at start of array
    case 0:
      return calculatePriorityTop(caze, container[0]);

    // Placed last in array
    case endPosition:
      return calculatePriorityBottom(caze, container[container.length - 1]);

    // Placed between items in array
    default:
      // If placed on the same array and further down than originally, offset the neighbour positions
      const offset = sameArray && newPosition > oldPosition ? 1 : 0;

      const leftNeighbour = container[newPosition + offset - 1];
      const rightNeighbour = container[newPosition + offset];

      return calculatePriorityBetween(caze, leftNeighbour, rightNeighbour);
  }
};

/*
 * Case sorting function that uses priority to place higher prioritised
 * items first, falling back to creation date if priority is identical.
 * */
export const ByPriority = (a: Case, b: Case) => {
  if (a.priority !== b.priority) {
    return b.priority - a.priority;
  }

  const getDate = (d: string | undefined) => (d == null ? new Date(3000, 1, 1) : parseISO(d));

  return compareAsc(getDate(a.created), getDate(b.created));
};
