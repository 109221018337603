import React from 'react';

import {Box, CardMedia, Divider, Link, Paper, Tooltip, Typography} from '@mui/material';

import styles from './CaseKanbanCard.module.css';

import {AttachmentCategory, Case, lookupEnum} from '../../api/caseApiTypes';
import { ArticleIdsList } from '../articles/ArticleIdsList';
import { CaseContainer } from '../cases/layoutComponents/CaseContainer';
import { useAppSelector } from '../../hooks/useAppRedux';
import { GetThumbnailUri } from '../../helpers/DerivedImageHelpers';

interface Props {
  caze: Case;
}

export const CaseKanbanCard: React.FC<Props> = ({ caze }) => {
  const primaryArticle = caze.articles?.[0];
  const renderDivider = primaryArticle?.productDescription && caze.description;


  let firstAttachmentWithDerivedImagesUri: string | undefined = '';
  if (caze?.attachments?.length) {
    for (const attachment of caze.attachments) {
      if (attachment.category === AttachmentCategory.SupplementaryInformation) {
        firstAttachmentWithDerivedImagesUri = GetThumbnailUri(attachment, 320);
        if (firstAttachmentWithDerivedImagesUri) {
          break;
        }
      }
    }
  }
  const photographerName = lookupEnum(useAppSelector(state => state.app.enumerations)?.photographers, caze?.photographer);

  return (
    <Link className={styles.link} href={`/case/${caze.lookupEntry ?? caze.id}`} target="_blank" rel="noopener noreferrer" underline="none">
      <CaseContainer component={Paper} className={styles.card} type={caze.type}>

        <Box className={styles.cardContainer}>
          <Box className={styles.columnLeft}>
            <Box className={styles.cardHeader}>
              <Typography variant="subtitle2">{primaryArticle?.productName}</Typography>
              <ArticleIdsList item={caze} />
            </Box>

            <Box className={styles.mainDescription}>
              <Tooltip title={primaryArticle?.productDescription} enterDelay={500} followCursor>
                <Typography className={styles.description} variant="body2">{primaryArticle?.productDescription}</Typography>
              </Tooltip>
            </Box>

            <Box className={styles.photographerContainer}>
              <Typography className={styles.photographer} variant="subtitle2">{photographerName}</Typography>
            </Box>
          </Box>
          {firstAttachmentWithDerivedImagesUri !== '' && (
            <Box className={styles.columnRight}>
              <CardMedia className={styles.media} component="img" image={firstAttachmentWithDerivedImagesUri} />
            </Box>
          )}
        </Box>
      </CaseContainer>
    </Link>
  );
};
