import { Upgrade, AddPhotoAlternate, Cancel } from '@mui/icons-material';
import { Button } from '@mui/material';
import { ReplaceMediaAction, replaceImage, replaceVideo, retouchUploadVariantImages } from '../../cases/caseDetailSlice';
import { CaseDialog } from '../../cases/layoutComponents/CaseDialog';
import { useAppDispatch } from '../../../hooks/useAppRedux';
import { Case, Image, ImageType } from '../../../api/caseApiTypes';
import { UploadProgressHandlerForFile } from '../../../api/commonApiTypes';

export type Props = {
  caze: Case | undefined;
  image: Image | undefined;
  files: File[];
  replaceMediaAction: ReplaceMediaAction | undefined;
  canReplaceImage: boolean;
  canCreateVariant: boolean;
  visible: boolean;
  onUploadProgressHandler: UploadProgressHandlerForFile | undefined;
  onClose: () => void;
  setDropFileActionDialogVisible: (visible: boolean) => void;
};

export function DropFileActionDialog({
  caze,
  image,
  files,
  replaceMediaAction,
  canReplaceImage,
  canCreateVariant,
  visible,
  onUploadProgressHandler,
  onClose,
  setDropFileActionDialogVisible,
}: Props) {
  const dispatch = useAppDispatch();

  const actualReplaceImageAction = replaceMediaAction || (image?.type === ImageType.Video ? replaceVideo : replaceImage);
  const onReplaceImage = () => {
    setDropFileActionDialogVisible(false);
    if (caze == null || image == null || files == null || onUploadProgressHandler == null) {
      return;
    }
    dispatch(actualReplaceImageAction({ caze, image, files, onUploadProgress: onUploadProgressHandler }));
  };
  const replaceFileButton = (
    <Button
      key="replaceImageButton"
      variant="contained"
      color="primary"
      startIcon={<Upgrade />}
      onClick={onReplaceImage}
      disabled={files.length !== 1}
    >
      Ersätt bild
    </Button>
  );

  const variantsLabel = files.length > 1 ? 'Varianter' : 'Variant';
  const createVariantsButtons = [
    <Button
      key="createVariantsButton"
      variant="contained"
      color="primary"
      startIcon={<AddPhotoAlternate />}
      onClick={() => onCreateVariants(true)}
    >
      {variantsLabel + ' med artiklar'}
    </Button>,
    <Button
      key="createVariantsNoArticlesButton"
      variant="contained"
      color="primary"
      startIcon={<AddPhotoAlternate />}
      onClick={() => onCreateVariants(false)}
    >
      {variantsLabel + ' utan artiklar'}
    </Button>,
  ];

  const onCreateVariants = (copyArticles: boolean) => {
    setDropFileActionDialogVisible(false);
    if (caze == null || image == null || files == null || !onUploadProgressHandler) {
      return;
    }
    dispatch(retouchUploadVariantImages({ caze, image, files, copyArticles, onUploadProgress: onUploadProgressHandler }));
  };

  const fileActionButtons = [];
  if (canReplaceImage) {
    fileActionButtons.push(replaceFileButton);
  }
  if (canCreateVariant) {
    fileActionButtons.push(...createVariantsButtons);
  }

  return (
    <CaseDialog
      title="Bekräfta uppladdning"
      isVisible={visible}
      closeLabel="Avbryt"
      CloseIcon={Cancel}
      onClose={onClose}
      buttons={fileActionButtons}
    />
  );
}
