import React from 'react';

import { useTheme } from '@mui/material';

import styles from './PrimaryImageWrapper.module.css';

import { ImageType, ImageTypeConverter } from '../../../api/caseApiTypes';
import { useAppDispatch } from '../../../hooks/useAppRedux';
import { useHover } from '../../../hooks/useHover';
import { updateImageType } from '../../images/imagesSlice';
import { ImageCardWrapper } from '../CaseMedia';

export const PrimaryImageWrapper: ImageCardWrapper = ({ image, imageCard, readonly }) => {
  const dispatch = useAppDispatch();
  const [hovered, hoverHandlers] = useHover();

  const { palette } = useTheme();

  const backgroundColor = palette.mode === 'light' ? '#e9e9e9' : '#444';

  if (![ImageType.Product, ImageType.ProductAlt].includes(image?.type)) {
    return imageCard;
  }

  const nextType = image?.type === ImageType.Product ? ImageType.ProductAlt : ImageType.Product;

  const buttonClicked = () => {
    dispatch(updateImageType({ imageId: image?.id, type: nextType }));
  };

  const badge = !hovered ? (
    <div className={styles.badge} {...hoverHandlers} style={{ backgroundColor }}>
      <span>{ImageTypeConverter.ToName(image?.type)}</span>
    </div>
  ) : (
    <button className={styles.badge} onClick={buttonClicked} {...hoverHandlers}>
      <span>Sätt som {ImageTypeConverter.ToName(nextType)}</span>
    </button>
  );

  return (
    <div className={styles.anchor}>
      {imageCard}
      {badge}
    </div>
  );
};
