import React from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import styles from './CaseDeliveryStatusField.module.css';

import { Case, LegacyDeliveryStatus } from '../../../api/caseApiTypes';
import { formatISO, parseISO } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers';

interface Props {
  caze: Case;
  onChange: (partial: Partial<Case>) => void;
  readonly?: boolean;
}

export const CaseDeliveryStatusField: React.FC<Props> = ({ caze, onChange, readonly }) => {
  const handleDeliveryStatusUpdate = (event: SelectChangeEvent) => {
    const partial: Partial<Case> = {
      deliveryStatus: event.target.value as LegacyDeliveryStatus,
    };

    if (partial.deliveryStatus !== LegacyDeliveryStatus.Ordered) {
      partial.estimatedDeliveryDate = undefined;
    }

    onChange(partial);
  };

  const parseDate = (value?: string) => {
    if (value == null) {
      return null;
    }

    return parseISO(value);
  };

  const parseString = (value?: Date | null) => {
    if (value == null) {
      return;
    }

    return formatISO(value, {
      representation: 'date',
    });
  };

  const displayDateField = caze.deliveryStatus === LegacyDeliveryStatus.Ordered;

  return (
    <Grid container spacing={2} alignItems="flex-end">
      <Grid item xs={displayDateField ? 3 : 12}>
        <FormControl className={styles.expanded}>
          <InputLabel shrink>Leveransstatus</InputLabel>
          <Select value={caze.deliveryStatus} onChange={handleDeliveryStatusUpdate} displayEmpty disabled={readonly}>
            <MenuItem value={LegacyDeliveryStatus.Ordered}>Beställd</MenuItem>
            <MenuItem value={LegacyDeliveryStatus.Available}>I fotolager</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {displayDateField && (
        <Grid item xs={9}>
          <DatePicker
            className={styles.expanded}
            label="Estimerat leveransdatum"
            value={parseDate(caze.estimatedDeliveryDate)}
            onChange={v => onChange({ estimatedDeliveryDate: parseString(v) })}
            disabled={readonly}
            slotProps={{ textField: { fullWidth: true } }}
          />
        </Grid>
      )}
    </Grid>
  );
};
