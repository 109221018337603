import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { MediaValue, getMediaValue } from './MediaValue';

export interface Props<T> {
  values: MediaValue<T | undefined>[] | undefined;
  defaultValue: T;
  readonly?: boolean;
  options: OptionInfo<T>[];
  onChange: (value: T) => void;
}

type Mixed<T> = T | 'mixed';

export interface OptionInfo<T> {
  key: Mixed<T>;
  label: string;
  hidden?: boolean;
}

type OptionKey = string | number;

export function MediaValueSelect<T extends OptionKey>({ values, defaultValue, readonly, options, onChange }: Props<T>) {
  const currentValue = getMediaValue(values, defaultValue, 'mixed') || defaultValue;
  const mixedOptions: OptionInfo<T>[] = [
    ...options,
    {
      key: 'mixed',
      label: 'Blandade värden',
      hidden: currentValue !== 'mixed',
    },
  ];

  const getOption = (option: OptionInfo<T>) => (
    <MenuItem key={option.key} value={option.key} style={option.hidden ? { display: 'none' } : {}}>
      {option.label}
    </MenuItem>
  );

  const handleChange = (event: SelectChangeEvent<Mixed<T>>) => {
    if (event.target.value === 'mixed') {
      return;
    }

    const result = event.target.value as T;
    onChange(result);
  };

  return (
    <Select value={currentValue} onChange={handleChange} disabled={readonly}>
      {mixedOptions.map(getOption)}
    </Select>
  );
}
