import React from 'react';

import { CardMedia } from '@mui/material';

import styles from './EnlargableImage.module.css';

import { Image } from '../api/caseApiTypes';
import { GetThumbnailUri } from '../helpers/DerivedImageHelpers';
import { FullScreenDialog } from './FullScreenDialog';

export const EnlargableImage = ({ image, title, className }: { image: Image; title: string; className?: string }) => {
  const [open, setOpen] = React.useState(false);

  let onClick: (() => void) | undefined = () => setOpen(true);

  const imageUri = GetThumbnailUri(image.versions[image.activeVersion], 320) ?? '/placeholder320.svg';
  const imageUri1920 = GetThumbnailUri(image.versions[image.activeVersion], 1920);

  const classes = [];
  if (className) {
    classes.push(className);
  }
  if (imageUri1920 == null) {
    onClick = undefined;
  } else {
    classes.push(styles.zoomCursor);
  }

  return (
    <>
      <CardMedia component={'img'} image={imageUri} alt={title} className={classes.join(' ')} onClick={onClick} />
      {imageUri1920 && <FullScreenDialog alt={title} item={imageUri1920} open={open} onClose={() => setOpen(false)} />}
    </>
  );
};
