import { useState } from 'react';

import { Delete } from '@mui/icons-material';
import { Button, Paper } from '@mui/material';

import styles from './RetouchCaseDetail.module.css';

import { AttachmentCategory, RetouchCase, Image } from '../../../api/caseApiTypes';
import { Right } from '../../../api/userApi';
import { useAppDispatch, useAppSelector } from '../../../hooks/useAppRedux';
import { useEitherRights, useRights } from '../../../hooks/useRights';
import { ImagesGridDetailsView } from '../../images/detailsView/ImagesGridDetailsView';
import { CaseAttachments } from '../CaseAttachments';
import { CaseComments } from '../CaseComments';
import { CaseMedia } from '../CaseMedia';
import { CaseStateProgress } from '../CaseStateProgress';
import { CaseTags } from '../CaseTags';
import { CaseTypeDetailComponentProps } from '../CaseTypeDetail';
import { CaseDeadlineDates } from '../formFieldComponents/CaseDeadlineDates';
import { CaseTextField } from '../formFieldComponents/CaseTextField';
import { CaseExpansion } from '../layoutComponents/CaseExpansion';
import { CaseFormBlock } from '../layoutComponents/CaseFormBlock';
import { CaseFormButtonContainer } from '../layoutComponents/CaseFormButtonContainer';
import { CaseFormContent } from '../layoutComponents/CaseFormContent';
import { CaseFormSidebar } from '../layoutComponents/CaseFormSidebar';
import { FormFieldsWrapper } from './FormFieldsWrapper';
import { RetouchCaseDetailFormFields } from './RetouchCaseDetailFormFields';
import { CaseFormSection } from './CaseFormSection';
import { ShowDeliverySections } from './CaseFormLogic';
import { CaseProceedStateButton } from '../CaseProceedStateButton';
import { uploadImage } from '../caseDetailSlice';
import { RetouchSourceImageSection } from '../RetouchSourceImageSection';
import { getInspirationFilesUri, getReferenceFilesUri } from '../../../api/caseApi';

import { isAnyMediaSelected, resetSelectedContext, setSelectedMedia } from '../../images/imagesSlice';
import { useSelectionContext } from '../../images/selectedImagesContext/selectionContextContext';
import { SelectionActionButtonsCard } from '../../images/selectedImagesContext/SelectionActionButtons';

type TCase = RetouchCase;

interface Props extends CaseTypeDetailComponentProps {
  caze: TCase;
}

export const RetouchCaseDetail: React.FC<Props> = ({
  caze,
  isNewCase,
  readonly,
  onDeleteCase,
  onDeleteAttachment,
  onCasePartialChange,
}) => {
  const dispatch = useAppDispatch();
  const isAdmin = useRights(Right.Administration);
  const canUploadImage = useEitherRights([Right.Administration, Right.ManageImage]);

  const [highlightedImageIds, setHighlightedImageIds] = useState<string[]>([]);

  const selectionContext = useSelectionContext();
  const anySelected = useAppSelector(isAnyMediaSelected(selectionContext));
  const clearSelection = () => dispatch(resetSelectedContext(selectionContext));
  const selectImage = (img: Image) => dispatch(setSelectedMedia({ selectionContext, media: [img] }));

  const canRemoveCase = isAdmin || !readonly;

  return (
    <>
      <CaseFormContent>
        <FormFieldsWrapper caze={caze} onChange={onCasePartialChange} isNewCase={isNewCase}>
          <RetouchCaseDetailFormFields
            caze={caze}
            onChange={onCasePartialChange}
            onDeleteAttachment={onDeleteAttachment}
            readonly={readonly}
            isNewCase={isNewCase}
          />
        </FormFieldsWrapper>
        <RetouchSourceImageSection
          caze={caze}
          section="reference"
          title="Bilder att retuschera"
          readonly={readonly}
          canUpload={true}
          getFilesUri={getReferenceFilesUri}
          getSectionPointers={c => c?.reference}
          setHighlightedImageIds={setHighlightedImageIds}
          onSelectImage={selectImage}
          onCasePartialChange={onCasePartialChange}
        />
        <RetouchSourceImageSection
          caze={caze}
          section="inspiration"
          title="Referensbilder"
          readonly={true}
          canUpload={false}
          getFilesUri={getInspirationFilesUri}
          getSectionPointers={c => c?.inspiration}
          setHighlightedImageIds={setHighlightedImageIds}
          onSelectImage={selectImage}
          onCasePartialChange={onCasePartialChange}
        />
        {isNewCase && (
          <CaseTextField
            label="Retuschkommentar"
            value={caze.retouchChanges}
            setValue={v => onCasePartialChange({ retouchChanges: v })}
            readonly={readonly}
          />
        )}
        <CaseFormSection title="Retuscherade Bilder" caseId={caze.id}>
          <CaseMedia
            caze={caze}
            mediaPointers={caze.imagePointers}
            caseReadonly={readonly}
            disableAddNew
            highlightedImageIds={highlightedImageIds}
            uploadMediaAction={uploadImage}
          />
        </CaseFormSection>
        {ShowDeliverySections(caze) && canUploadImage && (
          <CaseFormSection title="Original" caseId={caze.id}>
            <CaseAttachments
              caze={caze}
              onDelete={onDeleteAttachment}
              category={AttachmentCategory.OriginalImage}
              right={Right.ManageImage}
              isNewCase={isNewCase}
            />
          </CaseFormSection>
        )}
        <CaseProceedStateButton caze={caze} isNew={isNewCase} />
      </CaseFormContent>
      <CaseFormSidebar>
        <CaseFormBlock>
          <CaseStateProgress caze={caze} isNewCase={isNewCase} />

          <CaseDeadlineDates caze={caze} onChange={onCasePartialChange} readonly={readonly} />

          <CaseTags caze={caze} readonly={isNewCase || readonly} size="small" />

          {canRemoveCase && (
            <CaseFormButtonContainer renderDivider>
              <Button variant="outlined" color="secondary" startIcon={<Delete />} onClick={onDeleteCase} disabled={isNewCase}>
                Ta Bort Beställningen
              </Button>
            </CaseFormButtonContainer>
          )}
        </CaseFormBlock>

        {anySelected && (
          <>
            <SelectionActionButtonsCard />
            <Paper className={styles.imageInformation}>
              <ImagesGridDetailsView onClose={clearSelection} />
            </Paper>
          </>
        )}

        <CaseFormBlock>
          <CaseExpansion title="Kommentarer" hidden={isNewCase} compact>
            <CaseComments caze={caze} />
          </CaseExpansion>
        </CaseFormBlock>
      </CaseFormSidebar>
    </>
  );
};
