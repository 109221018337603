import React, { useCallback } from 'react';

import List from '@mui/material/List';

import { Attachment, AttachmentCategory, Case } from '../../api/caseApiTypes';
import { Right } from '../../api/userApi';
import { useAppDispatch } from '../../hooks/useAppRedux';
import { useEitherRights } from '../../hooks/useRights';
import { AddAttachmentListItem } from '../attachment/AddAttachmentListItem';
import { AttachmentListItem } from '../attachment/AttachmentListItem';
import { uploadAttachment } from './caseDetailSlice';
import { UploadProgressHandlerForFile } from '../../api/commonApiTypes';

interface Props {
  className?: string;
  caze: Case;
  onDelete?: (attachment: Attachment) => void;
  category: AttachmentCategory;
  right: Right;
  isNewCase?: boolean;
}

export const CaseAttachments: React.FC<Props> = ({ className, caze, onDelete, category, right, isNewCase }) => {
  const canManageAttachments = useEitherRights([Right.Administration, right]);
  const dispatch = useAppDispatch();

  const onDrop = useCallback(
    (files: File[], onUploadProgress: UploadProgressHandlerForFile) => {
      dispatch(uploadAttachment({ caze, category, files, onUploadProgress }));
    },
    [dispatch, caze, category],
  );

  const attachments = (caze?.attachments ?? []).filter(attachment => attachment.category === category);

  return (
    <div className={className}>
      {isNewCase && <p>Du kan lägga till bilagor efter beställningen är sparad</p>}
      {!isNewCase && (
        <List>
          {canManageAttachments && <AddAttachmentListItem onDrop={onDrop} category={category} />}
          {attachments
            .map((attachment, index) => (
              <AttachmentListItem key={index} attachment={attachment} onDelete={onDelete} readonly={!canManageAttachments} />
            ))
            .reverse()}
        </List>
      )}
    </div>
  );
};
