import { FilterState, PropClauseResolverConfig, FilterableItem, FilterStatePropertyValue } from '../../../api/filterTypes';
import { Clause, PredicateClause, SearchPredicate } from '../../../api/searchApiTypes';

Object.typedKeys = Object.keys;

export interface SearchFilters<T> {
  userFilters?: FilterState<T>;
  defaultFilters?: FilterState<T>;
  requiredFilters?: FilterState<T>;
}

export class SearchFilterTranslator<T extends FilterableItem> {
  private readonly propClauseResolverConfig: PropClauseResolverConfig<T>;

  constructor(propClauseResolverConfig: PropClauseResolverConfig<T>) {
    this.propClauseResolverConfig = propClauseResolverConfig;
  }

  public getClauses({ userFilters, defaultFilters, requiredFilters }: SearchFilters<T>): PredicateClause<T> {
    const filterState: FilterState<T> = defaultFilters != null ? { ...defaultFilters } : {};
    // possibly overwrite the defaultFilters with userFilters
    for (let entry of Object.entries(userFilters ?? {})) {
      const [key, value] = entry as [keyof T, FilterStatePropertyValue];
      if (value.value.length !== 0) {
        filterState[key] = value;
      }
    }

    const clause = this.constructClause(filterState);
    if (requiredFilters == null) {
      return clause;
    } else {
      const clauses = [this.constructClause(requiredFilters), clause];
      return { clauses, predicate: SearchPredicate.Or };
    }
  }

  private constructClause(filterState: FilterState<T>): PredicateClause<T> {
    const clauses: Clause<T>[] = [];
    for (let propertyName of Object.typedKeys(filterState)) {
      const clause = this.getClauseForProperty(propertyName, filterState);
      if (clause !== null) {
        clauses.push(clause);
      }
    }
    return { clauses, predicate: SearchPredicate.And };
  }

  private getClauseForProperty(property: keyof T, filterState: FilterState<T>): Clause<T> | null {
    const propertyValue = filterState[property];
    if (propertyValue == null || propertyValue.value.length === 0) {
      return null;
    }

    const propResolver = this.propClauseResolverConfig[property];

    if (propResolver == null) {
      console.error(`No propertyResolver exists for ${property.toString()}`);
      return null;
    }
    return propResolver(property, propertyValue);
  }
}
