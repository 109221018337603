import axios, { Canceler } from 'axios';

export enum Right {
  ReadCase = 'case.read',
  WriteCase = 'case.write',
  Administration = 'case.admin',
  ConfirmCase = 'case.confirm',
  CloseCase = 'case.close',
  ManageImage = 'case.writeImage',
  Comment = 'case.comment',
  WriteTags = 'tags.write',
  PlanCalendar = 'case.planCalendar',
}

export class Rights {
  public static AllExcept(excludedRights: Right[] | Right): Right[] {
    excludedRights = Array.isArray(excludedRights) ? excludedRights : [excludedRights];
    return Object.values(Right)
      .map((val: any) => val as Right)
      .filter(val => !excludedRights.includes(val));
  }
}

export interface User {
  id: string;
  fullName: string;
  email: string;
  rights?: Right[];
}

interface ExternalUser {
  id: string;
  fullName: string;
  email: string;
  canReadCase: boolean;
  canWriteCase: boolean;
  isAdministrator: boolean;
  canConfirmCase: boolean;
  canCloseCase: boolean;
  canManageImage: boolean;
  canComment: boolean;
  canWriteTags: boolean;
}

export async function getUser(): Promise<User | null> {
  const { status, data } = await axios.get<ExternalUser>('/user/info');

  if (status === 200) {
    const result: User = data;

    result.rights = [
      ...(data.canReadCase ? [Right.ReadCase] : []),
      ...(data.canWriteCase ? [Right.WriteCase] : []),
      ...(data.isAdministrator ? [Right.Administration] : []),
      ...(data.canConfirmCase ? [Right.ConfirmCase] : []),
      ...(data.canCloseCase ? [Right.CloseCase] : []),
      ...(data.canManageImage ? [Right.ManageImage] : []),
      ...(data.canComment ? [Right.Comment] : []),
      ...(data.canWriteTags ? [Right.WriteTags] : []),
    ];

    return result;
  }

  return null;
}

interface SearchUsersResult {
  users: User[];
}

let canceler: Canceler | null = null;

export async function searchAdUsers(query: string): Promise<User[]> {
  canceler?.();
  const cancelToken = new axios.CancelToken(c => (canceler = c));

  const { data } = await axios.get<SearchUsersResult>('/api/user/search', { params: { query }, cancelToken });

  canceler = null;
  return data.users;
}
