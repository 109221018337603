import React from 'react';

import { Case } from '../../../api/caseApiTypes';
import { CaseTitle } from '../formFieldComponents/CaseTitle';

interface Props {
  children?: React.ReactNode;
  caze: Case;
  isNewCase: boolean;
  onChange: (partial: Partial<Case>) => void;
}

const FORM_NAME = 'CASE_FORM';
export const ValidateFormWrapper: () => boolean = () => {
  const form = document.getElementById(FORM_NAME) as HTMLFormElement;
  if (!form) return false;

  form.requestSubmit();
  return form.checkValidity();
};

const submit = (event: React.FormEvent) => {
  event.preventDefault();
  event.stopPropagation();
};

export const FormFieldsWrapper: React.FC<Props> = ({ caze, isNewCase, onChange, children }) => {
  const onCaseTitleChange = (title: string) => {
    onChange({ title });
  };

  return (
    <form id={FORM_NAME} autoComplete="off" onSubmit={submit}>
      <CaseTitle caze={caze} isNewCase={isNewCase} onCaseTitleChange={onCaseTitleChange} />

      {children}
    </form>
  );
};
