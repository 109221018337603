import { useState } from 'react';
import { useAppDispatch } from '../../../hooks/useAppRedux';
import { newClipbook } from '../../clipbooks/clipbooksSlice';
import { ListItem, TextField } from '@mui/material';

export const ClipbookCreateField = () => {
  const dispatch = useAppDispatch();
  const [newName, setNewName] = useState<string>('');
  return (
    <ListItem>
      <TextField
        value={newName}
        onChange={e => setNewName(e.currentTarget.value)}
        placeholder="Ny grupp"
        fullWidth
        sx={{ pl: 2 }}
        inputProps={{ style: { fontSize: '0.8rem' } }}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            dispatch(newClipbook({ name: newName }));
            setNewName('');
          }
        }}
      />
    </ListItem>
  );
};
