import React from 'react';

import styles from './FullScreenDialog.module.css';

import { Close } from '@mui/icons-material';
import { Dialog, IconButton, PaperProps, Toolbar } from '@mui/material';
import { DerivedImageHolder } from '../api/caseApiTypes';
import { MediaVisualizer } from './MediaVisualizer';

interface Props {
  alt: string | undefined;
  item: string | DerivedImageHolder | undefined;
  open: boolean;
  onClose: () => void;
}

export const FullScreenDialog: React.FC<Props> = ({ alt, item, open, onClose }) => {
  const buttonStyle: React.CSSProperties = { color: 'black' };
  const paperProps: PaperProps = { style: { backgroundColor: 'white' } };
  return (
    <Dialog fullScreen open={open} onClose={onClose} className={styles.dialog} PaperProps={paperProps}>
      <Toolbar className={styles.toolbar}>
        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
          <Close style={buttonStyle} />
        </IconButton>
      </Toolbar>
      <MediaVisualizer item={item} alt={alt} autoPlayVideo={true} size="full" />
    </Dialog>
  );
};
