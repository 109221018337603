import { useEffect } from 'react';

import { FilterableItem, FilterState } from '../api/filterTypes';
import { addActiveFilterConsumer, removeActiveFilterConsumer } from '../appSlice';
import { useAppDispatch, useAppSelector } from './useAppRedux';
import { useRandomString } from './useRandomString';
import { SearchContext } from './useSearchState';

export function useUserFilters(searchContext: SearchContext) {
  const filterConsumer = useRandomString();
  const dispatch = useAppDispatch();

  const { filter } = useAppSelector(state => state.app);
  const filterKey = SearchContext[searchContext];
  const userFilters = filter[filterKey] as FilterState<FilterableItem>;

  useEffect(() => {
    dispatch(addActiveFilterConsumer({ filter: filterKey, filterConsumer }));
    return () => {
      dispatch(removeActiveFilterConsumer({ filter: filterKey, filterConsumer }));
    };
  }, [dispatch, filterKey, filterConsumer]);

  return userFilters;
}
