import React from 'react';

import { ImageType, ImageTypeConverter, ImageTypes } from '../../../api/caseApiTypes';
import { FormControl, InputLabel } from '@mui/material';
import { MediaValueSelect, OptionInfo } from './MediaValueSelect';
import { MediaValue } from './MediaValue';

interface Props {
  imageType: MediaValue<ImageType | undefined>[] | undefined;
  onChange: (value: ImageType) => void;
  readonly?: boolean;
}

const Options: OptionInfo<ImageType>[] = ImageTypes.AllExcept([ImageType.Unknown]).map(key => ({
  key,
  label: ImageTypeConverter.ToName(key),
}));

export const ImageTypeField: React.FC<Props> = ({ onChange, readonly, imageType }) => {
  return (
    <FormControl>
      <InputLabel shrink>Bildtyp</InputLabel>
      <MediaValueSelect values={imageType} defaultValue={ImageType.Unknown} onChange={onChange} readonly={readonly} options={Options} />
    </FormControl>
  );
};
