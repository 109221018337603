import React from 'react';

import { Attachment, AttachmentCategory, Case, RetouchCase } from '../../../api/caseApiTypes';
import { CaseDescriptionField } from '../formFieldComponents/CaseDescriptionField';
import { CaseFormSection } from './CaseFormSection';
import { CaseAttachments } from '../CaseAttachments';
import { Right } from '../../../api/userApi';
import { makeMediaValue } from '../formFieldComponents/MediaValue';
import {CaseRetouchByField} from "../formFieldComponents/GenericEnumerationFields";

interface Props {
  caze: RetouchCase;
  onChange: (partial: Partial<Case>) => void;
  onDeleteAttachment: (attachment: Attachment) => void;
  readonly?: boolean;
  isNewCase?: boolean;
}

export const RetouchCaseDetailFormFields: React.FC<Props> = ({ caze, onChange, onDeleteAttachment, readonly, isNewCase }) => {
  return (
    <>
      <CaseFormSection title="Beställningsinformation" caseId={caze.id}>
        <CaseDescriptionField description={caze.description} onChange={v => onChange({ description: v })} readonly={readonly} />
        <CaseRetouchByField
          editor={makeMediaValue(caze.retouchedBy)}
          onChange={v => onChange({ retouchedBy: v })}
          readonly={readonly}
        />
        <CaseAttachments
          caze={caze}
          onDelete={onDeleteAttachment}
          category={AttachmentCategory.SupplementaryInformation}
          right={Right.WriteCase}
          isNewCase={isNewCase}
        />
      </CaseFormSection>
    </>
  );
};
