import React, { useEffect, useCallback } from 'react';
import { useAppSelector } from '../../hooks/useAppRedux';

import styles from './PendingChangesDrawer.module.css';

import { Button, Box, CircularProgress, Drawer, useTheme, Typography } from '@mui/material';
import { Check, Save } from '@mui/icons-material';
import { CaseDialog } from './layoutComponents/CaseDialog';

import { useBlocker } from 'react-router-dom';
import type { BlockerFunction } from 'react-router-dom';

interface Props {
  pendingChangesExist: boolean;
  saveDisabled?: boolean;
  onSave: () => void;
}

export const PendingChangesDrawer: React.FC<Props> = ({ pendingChangesExist, saveDisabled, onSave }) => {
  const isLoading = useAppSelector(state => state.cases.isLoading);
  const { zIndex } = useTheme();

  let shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => pendingChangesExist && currentLocation.pathname !== nextLocation.pathname,
    [pendingChangesExist],
  );
  let blocker = useBlocker(shouldBlock);

  // Reset the blocker if the user cleans the form
  useEffect(() => {
    if (blocker.state === 'blocked' && !pendingChangesExist) {
      blocker.reset();
    }
  }, [blocker, pendingChangesExist]);

  return (
    <>
      <Drawer
        anchor="bottom"
        variant="persistent"
        open={pendingChangesExist}
        BackdropProps={{ invisible: true }}
        className={styles.drawer}
        style={{ zIndex: zIndex.drawer + 2 }}
      >
        <Box display="flex" justifyContent="center" className={styles.pendingChangesContainer}>
          <Box className={styles.wrapper}>
            <Button variant="contained" color="primary" startIcon={<Save />} onClick={() => onSave()} disabled={isLoading || saveDisabled}>
              Spara
            </Button>
            {isLoading && <CircularProgress size={30} className={styles.loading} />}
          </Box>
        </Box>
      </Drawer>
      <CaseDialog
        isVisible={blocker.state === 'blocked'}
        saveLabel="Ja"
        onSave={() => blocker.proceed?.()}
        SaveIcon={Check}
        closeLabel="Nej"
        onClose={() => blocker.reset?.()}
        children={
          <Typography variant="body2">Du har ändringar som ännu inte sparats. Är du säker på att du vill lämna denna sida?</Typography>
        }
      />
    </>
  );
};
