import { FilterableCase, FilterPropertyRecord } from '../../../../api/filterTypes';
import {
  FilterTreeAllCaseStateComponent,
  FilterTreeMyCasesComponent,
  FilterTreeCaseTypesComponent,
  FilterTreePhotographerComponent,
  FilterTreeStylistComponent,
  FilterTreeEditorComponent,
} from '../filterComponents/FilterTreeComponentTemplate';
import { FilterTreeProjectTagComponent, FilterTreeSystemTagComponent } from '../filterComponents/FilterTreeTagComponent';

export const caseFilterConfiguration: FilterPropertyRecord<FilterableCase> = {
  Case_OwnerId: {
    title: 'Mina Beställningar',
    Component: FilterTreeMyCasesComponent,
    alwaysShowFilter: true,
  },
  Case_ProjectTagId: {
    title: 'Projekt',
    Component: FilterTreeProjectTagComponent,
    alwaysShowFilter: true,
  },
  Case_SystemTagIds: {
    title: 'Taggar',
    Component: FilterTreeSystemTagComponent,
    alwaysShowFilter: true,
  },
  Case_CaseState: {
    title: 'Beställningsstatus',
    Component: FilterTreeAllCaseStateComponent,
  },
  type: {
    title: 'Beställningstyp',
    Component: FilterTreeCaseTypesComponent,
  },
  photographer: {
    title: 'Fotograf',
    Component: FilterTreePhotographerComponent,
  },
  stylist: {
    title: 'Stylist',
    Component: FilterTreeStylistComponent,
  },
  retouchedBy: {
    title: 'Retuschör',
    Component: FilterTreeEditorComponent,
  },
};
