import { BookmarkAdd } from '@mui/icons-material';
import { IconButton, Popover, Button } from '@mui/material';
import { useRef, useState } from 'react';
import { Case } from '../../../api/caseApiTypes';
import { useAppDispatch } from '../../../hooks/useAppRedux';
import { addImagesToClipbook } from '../clipbooksSlice';

export interface AddCaseMediaToClipbookButtonProps {
  caze: Case;
}

export function AddCaseMediaToClipbookButton({ caze }: AddCaseMediaToClipbookButtonProps) {
  const anchorRef = useRef(null);
  const dispatch = useAppDispatch();
  const imageIds = caze.imagePointers.map(mp => mp.imageId);
  const [open, setOpen] = useState(false);
  const addToActiveClipbook = () => dispatch(addImagesToClipbook({ imageIds }));
  const addToNewClipbook = () => dispatch(addImagesToClipbook({ imageIds, clipbookName: caze.title }));

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={e => {
          e.stopPropagation();
          setOpen(true);
        }}
        title="Lägg till alla bilder i den aktiva gruppen"
      >
        <BookmarkAdd />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        onClose={e => {
          setOpen(false);
        }}
      >
        <Button
          onClick={e => {
            e.stopPropagation();
            addToNewClipbook();
            setOpen(false);
          }}
        >
          Ny grupp
        </Button>
        <Button
          onClick={e => {
            e.stopPropagation();
            addToActiveClipbook();
            setOpen(false);
          }}
        >
          Aktiv grupp
        </Button>
      </Popover>
    </>
  );
}
