import {
  Case,
  CaseVideoRequest,
  CaseVideoRequestChannel,
  CaseVideoRequestDuration,
  CaseVideoRequestFormat,
  CaseVideoRequestType,
  DefaultCaseVideoRequest,
} from '../../../api/caseApiTypes';
import { CaseTextField } from '../formFieldComponents/CaseTextField';
import { CaseFormSection } from './CaseFormSection';
import styles from './CaseVideoRequestForm.module.css';
import { Checkbox, CheckboxProps, FormControlLabel, Typography } from '@mui/material';
import { useAppDispatch } from '../../../hooks/useAppRedux';
import { updateCaseVideoRequest } from '../caseDetailSlice';
import { PropsWithChildren } from 'react';
import { DeepPartial } from '../../../helpers/DeepPartial';

type CaseVideoRequestFormSectionProps = {
  title: string;
};

function CaseVideoRequestFormSection({ title, children }: PropsWithChildren<CaseVideoRequestFormSectionProps>) {
  return (
    <div className={styles.formSection}>
      <Typography className={styles.formSectionTitle} variant="subtitle2">
        {title}
      </Typography>
      <div>{children}</div>
    </div>
  );
}

function VideoRequestCheckbox({ title, ...checkboxProps }: CheckboxProps & { title: string }) {
  return (
    <FormControlLabel
      control={<Checkbox {...checkboxProps} />}
      label={<Typography variant="subtitle2">{title}</Typography>}
      labelPlacement="start"
    />
  );
}

export type Props = {
  caze: Case;
};

export function CaseVideoRequestForm({ caze, children }: PropsWithChildren<Props>) {
  const dispatch = useAppDispatch();
  const updateVideoRequest = (videoRequest: DeepPartial<CaseVideoRequest>) => {
    dispatch(updateCaseVideoRequest({ videoRequest }));
  };

  const updateFormat = (formats: DeepPartial<CaseVideoRequestFormat>) => {
    updateVideoRequest({ formats });
  };

  const updateChannel = (channels: Partial<CaseVideoRequestChannel>) => {
    updateVideoRequest({ channels });
  };

  const updateType = (types: Partial<CaseVideoRequestType>) => {
    updateVideoRequest({ types });
  };

  const updateDuration = (durations: Partial<CaseVideoRequestDuration>) => {
    updateVideoRequest({ durations });
  };

  const request = caze.videoRequest || DefaultCaseVideoRequest;

  return (
    <CaseFormSection caseId={caze.id} title="Bildbeställning Rörligt" description="Här beskriver du dina önskemål om rörligt">
      <div className={styles.videoForm}>
        <CaseTextField
          label="Beskrivning"
          multiline
          minRows={2}
          variant="outlined"
          value={request.description}
          setValue={description => updateVideoRequest({ description })}
          className={styles.descriptionText}
        />
        <CaseVideoRequestFormSection title="Format">
          <VideoRequestCheckbox
            title="Stående"
            checked={request.formats.portrait}
            onChange={e => updateFormat({ portrait: e.currentTarget.checked })}
          />
          <VideoRequestCheckbox
            title="Liggande"
            checked={request.formats.landscape}
            onChange={e => updateFormat({ landscape: e.currentTarget.checked })}
          />
        </CaseVideoRequestFormSection>

        <CaseVideoRequestFormSection title="Kanal">
          <VideoRequestCheckbox
            title="Sociala Medier"
            checked={request.channels.socialMedia}
            onChange={e => updateChannel({ socialMedia: e.currentTarget.checked })}
          />
          <VideoRequestCheckbox
            title="Banners"
            checked={request.channels.banners}
            onChange={e => updateChannel({ banners: e.currentTarget.checked })}
          />
          <VideoRequestCheckbox
            title="YouTube"
            checked={request.channels.youTube}
            onChange={e => updateChannel({ youTube: e.currentTarget.checked })}
          />
          <VideoRequestCheckbox
            title="Hemsidan"
            checked={request.channels.mioWebsite}
            onChange={e => updateChannel({ mioWebsite: e.currentTarget.checked })}
          />
          <VideoRequestCheckbox
            title="Butiks-TV"
            checked={request.channels.storeTv}
            onChange={e => updateChannel({ storeTv: e.currentTarget.checked })}
          />
        </CaseVideoRequestFormSection>

        <CaseVideoRequestFormSection title="Typ av film">
          <VideoRequestCheckbox
            title="Erbjudande"
            checked={request.types.campaign}
            onChange={e => updateType({ campaign: e.currentTarget.checked })}
          />
          <VideoRequestCheckbox
            title="Produkt"
            checked={request.types.product}
            onChange={e => updateType({ product: e.currentTarget.checked })}
          />
          <VideoRequestCheckbox
            title="Inspiration"
            checked={request.types.inspiration}
            onChange={e => updateType({ inspiration: e.currentTarget.checked })}
          />
          <VideoRequestCheckbox
            title="Funktion"
            checked={request.types.function}
            onChange={e => updateType({ function: e.currentTarget.checked })}
          />
        </CaseVideoRequestFormSection>

        <CaseVideoRequestFormSection title="Filmlängd">
          <VideoRequestCheckbox
            title="6 sek"
            checked={request.durations.seconds6}
            onChange={e => updateDuration({ seconds6: e.currentTarget.checked })}
          />
          <VideoRequestCheckbox
            title="15 sek"
            checked={request.durations.seconds15}
            onChange={e => updateDuration({ seconds15: e.currentTarget.checked })}
          />
          <VideoRequestCheckbox
            title="30 sek"
            checked={request.durations.seconds30}
            onChange={e => updateDuration({ seconds30: e.currentTarget.checked })}
          />
          <VideoRequestCheckbox
            title="&gt;1 min"
            checked={request.durations.over1Minute}
            onChange={e => updateDuration({ over1Minute: e.currentTarget.checked })}
          />
        </CaseVideoRequestFormSection>
      </div>
      {children}
    </CaseFormSection>
  );
}
